import { BDContainer } from '@brightdrop/bd-ui';
import { useTranslations } from '@brightdrop/localization-client';
import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { refreshAuthToken } from '~/common/apis/api.interceptors';
import { AlertMessage } from '~/common/components';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import { isMSALAuthError } from '~/common/utils/auth/auth.utils';

import Header from '../../features/header/Header';
import {
  selectAuthDomainHint,
  selectLoginAttempt,
} from '../../features/session/authSlice.selectors';
import { msalInstance } from '../../features/session/msalInstance';
import { useAppDispatch } from '../store';

const MESSAGES = {
  'common:alertMsg.messageView.serviceUnavailable.header':
    'Temporarily Unavailable',
  'common:alertMsg.messageView.serviceUnavailable.body':
    'The BrightDrop application is not available at this time. Please try again later.',
  'common:alertMsg.messageView.serviceUnavailable.button': 'Try Again',
};

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { translations } = useTranslations(MESSAGES);
  const history = useHistory();
  const onClick = () => {
    history.push('/');
    resetErrorBoundary();
  };

  const [ready, setReady] = useState(false);

  const loginAttempts = useSelector(selectLoginAttempt);
  const domainHint = useSelector(selectAuthDomainHint);
  const { authenticationType } = useCustomAuthentication();

  useEffect(() => {
    // force login on unexpected auth error
    if (isMSALAuthError(error) && msalInstance) {
      refreshAuthToken({
        authenticationType,
        dispatch,
        forceRefresh: true,
        forceLoginOnSilentFailure: true,
        domainHint,
      });
    }
    console.error('BrightDrop Web - Unexpected App Error', error);
    setReady(true);
  }, [authenticationType, dispatch, domainHint, error, loginAttempts]);

  return (
    <>
      <Header />
      {ready && (
        <BDContainer id="error-fallback">
          <Container maxWidth={false} className="contentContainer">
            <Box flex="1" className="contentWrapper" position="relative">
              <AlertMessage
                header={
                  translations[
                    'common:alertMsg.messageView.serviceUnavailable.header'
                  ]
                }
                body={
                  translations[
                    'common:alertMsg.messageView.serviceUnavailable.body'
                  ]
                }
                linkBtn={
                  translations[
                    'common:alertMsg.messageView.serviceUnavailable.button'
                  ]
                }
                topic="serviceUnavailable"
                id="service-unavailable"
                onClick={onClick}
              />
            </Box>
          </Container>
        </BDContainer>
      )}
    </>
  );
};
export default ErrorFallback;

import { DateTime } from 'luxon';

import {
  DEFAULT_DATE_FORMAT,
  LanguageLocale,
} from '../constants/common.constant';

export const formatDateTimeForAPI = (
  dateTime: DateTime,
  format: string
): string => dateTime.toUTC().toFormat(format).replace(' ', 'T');

export const formatFromDateTimeObj = (
  dateTime: DateTime,
  format: string = DEFAULT_DATE_FORMAT,
  locale: string = LanguageLocale.EN,
  timeZone?: string
): string => {
  return dateTime.setLocale(locale).setZone(timeZone).toFormat(format);
};

export function formatToDefaultDateFormat(dateString: string) {
  return DateTime.fromISO(dateString, { zone: 'utc' }).toFormat(
    DEFAULT_DATE_FORMAT
  );
}

/**
 * Formats a UTC date string according to specific display rules.
 * Display Rules
 * - In the last seven days - [Day of the week] at xx:xx AM / PM
 * - Seven days to one year - [Month] [Day] at xx:xx AM / PM
 * @param {string} utcDateString - The UTC date string to format.
 * @returns {string} The formatted date string.
 */
export function formatNotificationDate(utcDateString: string): string {
  const date = new Date(utcDateString); // Parse the UTC date string into a Date object
  const now = new Date(); // Get the current date

  // Calculate the difference in days
  const differenceInTime = now.getTime() - date.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  // Helper function to format the time
  const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isAM = hours < 12;

    const formattedHours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes

    return `${formattedHours}:${formattedMinutes} ${isAM ? 'AM' : 'PM'}`;
  };

  // Helper function to get the day of the week
  const getDayOfWeek = (date: Date) => {
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return daysOfWeek[date.getDay()];
  };

  // Helper to check if the date is today
  const isToday = now.toDateString() === date.toDateString();

  // Helper function to get the month and day
  const getMonthAndDay = (date: Date) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();

    return `${month} ${day}`;
  };

  // Formatting logic based on the difference in days
  if (isToday) {
    // If the date is today
    return `Today at ${formatTime(date)}`;
  } else if (differenceInDays < 7) {
    // In the last seven days
    return `${getDayOfWeek(date)} at ${formatTime(date)}`;
  } else if (differenceInDays < 365) {
    // Between seven days and one year
    return `${getMonthAndDay(date)} at ${formatTime(date)}`;
  } else {
    // Beyond one year
    return `${getMonthAndDay(date)}, ${date.getFullYear()} at ${formatTime(
      date
    )}`;
  }
}

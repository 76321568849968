import { defaultsDeep } from 'lodash';

import { RootState } from '~/app/rootReducer';
import {
  API_VERSION_DEFAULTS,
  ASSETS_API_BASE_CONFIG,
  BD_FLEET_HEADER_NAME,
  BD_HUB_HEADER_NAME,
  CONTENT_TYPE_HEADER,
  CONTENT_TYPE_VALUE,
  CS_CONTINUATION_TOKEN_HEADER_NAME,
  DEFAULT_API_CONFIG,
  REMOTE_COMMAND_API_CONFIG,
} from '~/common/apis/api.constants';
import {
  ACCEPTED_EXPORT_FILE_TYPE,
  AppEnv,
  LanguageLocale,
  LOCALE_FORMAT_DATE,
  SearchConfigType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import {
  AddAssetFormFields,
  ApiAsset,
  type ApiAssetRecall,
  ApiAssetTelemetryKey,
  ApproveVehicle,
  Asset,
  AssetComplianceDetail,
  AssetEvent,
  AssetEventAction,
  AssetIgnitionStatus,
  AssetModel,
  AssetProtectedFormFields,
  type AssetRecall,
  AssetRemoteCommandExecuteResult,
  AssetServiceStatus,
  AssetStatusResult,
  RejectApproveVehiclesResult,
  RejectVehicle,
} from '~/common/models/asset.model';
import {
  AdvisorVehicleListItem,
  ASSET_REPORT_FIELD_NAMES,
  AssetReportItem,
  PendingVehicleItem,
} from '~/common/models/asset-report.model';
import {
  AssetRawLocation,
  FormPayload,
  PagedResult,
  PagedResultWithErrors,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { CHARGING_SCHEDULE_FLAG_NAME } from '~/common/models/featureFlags.model';
import { ApiRecallsItem } from '~/common/models/recalls.model';
import {
  addAcceptLanguageHeader,
  addCSFleetIdHeader,
  addCSHubIdHeader,
  addCSOrganizationIdHeader,
  addHeader,
  addOrganizationIdHeader,
  hasApiResult,
  resultIsArray,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
  makeThunkCreatorConfig,
} from '~/common/utils/store/thunk.helper';
import { PeripheralVehiclesConfig } from '~/features/peripherals/config/constants/state';

import {
  AssetHistoryParams,
  AssetListSession,
  AssetPaginatedListParams,
  AssetParams,
  AssetReportConfigType,
  AssetRouteParams,
  PendingVehiclePaginatedListParams,
  PendingVehicleResponse,
  SearchFilterInfo,
  UpdateAssetFormPayload,
} from '../assetsSlice';
import { AssetListParams } from '../form/utils/assetForm.utils';
import {
  checkDiagnosticsError,
  mapApiAssetComplianceToAssetComplianceDetail,
  mapAssetApiResponseToAsset,
  mapAssetFormToApiRequest,
  mapEventApiResponseToEvent,
  mapGeoJSONApiResponseToLocation,
  mapVehicleDetailsRecalls,
} from '../mappers/asset.mappers';
import {
  ApiBatteryReadinessItem,
  ApiReadinessItem,
  ApiTiresReadinessItem,
  mapApiAdvisorVehicleListItem,
  mapApiAssetBatteryReadinessToReportItem,
  mapApiAssetReadinessToReportItem,
  mapApiAssetTireReadinessToReportItem,
  mapApiRecallsToReportItem,
} from '../report/mappers/asset-report.mappers';
import {
  buildAdvisorVehicleQueryParam,
  buildAssetComplianceQuery,
  buildAssetQuery,
  buildAssetSort,
  buildVehicleQuery,
  formatFilterCriteriaForVehicles,
  formatSearchCriteriaForVehicles,
} from '../utils/assets.utils';

const BASE_CONFIG = ASSETS_API_BASE_CONFIG;
const VEHICLE_LIST_CONFIG = {
  ...DEFAULT_API_CONFIG,
  axiosConfig: {
    ...DEFAULT_API_CONFIG.axiosConfig,
    timeout:
      globalThis.appConfig.env === AppEnv.DEV ||
      globalThis.appConfig.env === AppEnv.QA
        ? 15000
        : 45000, //TODO:Update timeout for production after validating the performance
  },
};
const VEHICLE_LIST_API_EXPORT_CONFIG = makeThunkCreatorConfig({
  timeout:
    globalThis.appConfig.env === AppEnv.DEV ||
    globalThis.appConfig.env === AppEnv.QA
      ? 15000
      : 45000, //TODO:Update timeout for production after validating the performance
  retries: 2,
  delay: 100,
  customHeaders: {
    [CONTENT_TYPE_HEADER]: CONTENT_TYPE_VALUE,
    Accept: ACCEPTED_EXPORT_FILE_TYPE,
  },
});
// Toogle for using /location/geojson vs /location for asset location service
const GEOJSON_TOGGLE = true;

enum AssetsActionType {
  GET_ASSETS = 'assets/fetchAssets',
  GET_VEHICLE_NOTIFICATION_ASSETS = 'assets/getVehicleNotificationAssets',
  GET_PENDING_VEHICLES = 'boarding/vehicles/pending',
  GET_PERIPHERAL_VEHICLES = 'assets/getPeripheralVehicles',
  GET_READINESS_REPORT = 'assets/getReadinessReport',
  GET_TIRES_REPORT = 'assets/getTiresReport',
  GET_BATTERY_REPORT = 'assets/getBatteryReport',
  GET_RECALLS = 'assets/getRecalls',
  GET_ASSETS_SEARCH = 'assets/fetchAssetsSearch',
  GET_ASSETS_COMPLIANCE = 'assets/getAssetsCompliance',
  GET_ASSET_DETAILS = 'assets/fetchDetails',
  GET_ASSET_RECALLS = 'assets/fetchRecalls',
  GET_ASSET_HISTORY = 'assets/fetchHistory',
  GET_ASSET_LOCATION = 'asset/fetchLocation',
  ADD_ASSET = 'assets/addNewAsset',
  UPDATE_ASSET = 'assets/updateAsset',
  LOCK_ASSET = 'assets/lockAsset',
  UPDATE_AVAILABILITY = 'assets/updateServiceAvailability',
  REPROVISION_ASSET = 'assets/reProvisionAsset',
  REMOTE_COMMAND_ASSET = 'assets/executeRemoteCommand',
  GET_ASSET_STATUS = 'assets/getAssetStatus',
  GET_ASSET_IGNITION = 'assets/getAssetIgnition',
  REJECT_VEHICLE = 'REJECT_VEHICLE',
  APPROVE_VEHICLE = 'APPROVE_VEHICLE',
  /* TODO: following actions should be reviewed for removal */
  DELETE_ASSET = 'assets/deleteAsset',
  GET_NEXT_EP_SERIAL = 'assets/getNextEPalletSerial',
  GET_TRACKER_SERIALS = 'assets/getTrackerSerials',
  GET_EP_VERSIONS = 'assets/getEPalletVersions',
  GET_VEHICLE_LIST_OVERVIEW_EXPORT = 'assets/getOverviewExport',
  GET_VEHICLE_LIST_BATTERY_EXPORT = 'assets/getBatteryExport',
  GET_VEHICLE_LIST_FLUIDS_EXPORT = 'assets/getFluidsExport',
  GET_ADVISOR_VEHICLE_LIST = 'assets/getAdvisorVehicleList',
}

const getAssets = makeThunk(
  AssetsActionType.GET_ASSETS,
  makeGetPayloadCreator<ApiResponse<Asset[]>, AssetListParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (routeParams) => {
      return {
        requestParams: {
          organizationId: routeParams.organizationsId || '',
          hubId: routeParams.hubsId || '',
          fleetId: routeParams.fleetsId || '',
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>, state) => {
      if (!!response && resultIsArray<Partial<ApiAsset>>(response)) {
        const errors = new Array<BDError>();
        const result = {
          ...response,
          result: response.result?.length
            ? response.result.reduce((mappedAssets, apiAsset) => {
                try {
                  mappedAssets.push(mapAssetApiResponseToAsset(apiAsset));
                } catch (e) {
                  // handle mapping errors without bombing entire list response
                  const error = {
                    name: 'Get Assets Mapping Error',
                    type: BDAppErrorType.VALIDATION,
                    message:
                      e instanceof Error
                        ? e.message
                        : 'Failed to map asset API response',
                    data: apiAsset,
                  };
                  errors.push(BDError.asJson(error));
                }
                return mappedAssets;
              }, new Array<Asset>())
            : [],
        };
        if (errors.length) {
          result.errors = errors;
        }

        return result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset list response', { data: response });
    },
  })
);

const getPendingVehicles = makeThunk(
  AssetsActionType.GET_PENDING_VEHICLES,
  makeGetPayloadCreator<
    ApiResponse<PendingVehicleResponse>,
    PendingVehiclePaginatedListParams
  >({
    url: (params) =>
      `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.pendingVehiclesListAPI}/boarding/vehicles/pending?`
        .concat(params.rowsPerPage ? `size=${params.rowsPerPage}` : '')
        .concat(params.searchTerm ? `&vin=${params.searchTerm}` : ''),
    axiosOptions: ({ organizationsId, continuationToken }, state) =>
      addAcceptLanguageHeader(
        addCSOrganizationIdHeader(
          continuationToken
            ? addHeader(
                BASE_CONFIG,
                CS_CONTINUATION_TOKEN_HEADER_NAME,
                continuationToken
              )
            : BASE_CONFIG,
          organizationsId
        ),
        state.profile.currentLocale
      ),
    responseAdapter: (
      response: unknown
    ): ApiResponse<PendingVehicleResponse> => {
      if (response && typeof response === 'object' && 'result' in response) {
        const apiResponse = response as ApiResponse<PendingVehicleResponse>;
        return {
          ...apiResponse,
          result: {
            totalItems: apiResponse.result.totalItems,
            token: apiResponse.result.token,
            items: Array.isArray(apiResponse.result?.items)
              ? (apiResponse.result.items.map((item) =>
                  defaultsDeep({}, item, {
                    sessionId: '',
                    vin: '',
                    organizationId: '',
                    createdOn: '',
                    status: '',
                    vehicleYear: '',
                    vehicleMake: '',
                    vehicleModel: '',
                    vehicleImageSrc: '',
                  })
                ) as PendingVehicleItem[])
              : [],
          },
        };
      }
      throw new BDError('Unexpected pending vehicles response format', {
        data: response,
      });
    },
  })
);

const getVehicleListOverviewExport = makeThunk(
  AssetsActionType.GET_VEHICLE_LIST_OVERVIEW_EXPORT,
  makePostPayloadCreator<BlobPart, AssetParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListExport}/views/readinessReport/export`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(
              VEHICLE_LIST_API_EXPORT_CONFIG,
              BD_FLEET_HEADER_NAME,
              fleetsId
            ),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ sessionId }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });
      const query = buildVehicleQuery(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          query,
          sort,
        },
      };
    },
  })
);
const getVehicleListBatteryExport = makeThunk(
  AssetsActionType.GET_VEHICLE_LIST_BATTERY_EXPORT,
  makePostPayloadCreator<BlobPart, AssetParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListExport}/views/batteryReadinessReport/export`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(
              VEHICLE_LIST_API_EXPORT_CONFIG,
              BD_FLEET_HEADER_NAME,
              fleetsId
            ),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ sessionId }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });
      const query = buildVehicleQuery(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          query,
          sort,
        },
      };
    },
  })
);
const getVehicleListFluidsExport = makeThunk(
  AssetsActionType.GET_VEHICLE_LIST_FLUIDS_EXPORT,
  makePostPayloadCreator<BlobPart, AssetParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListExport}/views/fluidReadinessReport/export`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(
              VEHICLE_LIST_API_EXPORT_CONFIG,
              BD_FLEET_HEADER_NAME,
              fleetsId
            ),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: ({ sessionId }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });
      const query = buildVehicleQuery(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          query,
          sort,
        },
      };
    },
  })
);
const getVehicleNotificationAssets = makeThunk(
  AssetsActionType.GET_VEHICLE_NOTIFICATION_ASSETS,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/readinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Vehicle Notification Assets error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset report response', { data: response });
    },
  })
);

const getPeripheralAssetListQuerySessionConfig = ({
  state,
  sessionId,
}: {
  sessionId: string;
  state: RootState;
}): Partial<AssetListSession> | undefined => {
  const sessionConfig =
    state.assets.sessionConfigs?.[PeripheralVehiclesConfig.LIST_VIEW]?.[
      sessionId
    ];
  return sessionConfig;
};

const getPeripheralVehicles = makeThunk(
  AssetsActionType.GET_PERIPHERAL_VEHICLES,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListOverviewAPI}/views/readinessReport`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(BASE_CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: (
      { page, rowsPerPage: size, sessionId, continuationToken, ...params },
      state
    ) => {
      const sessionConfig = getPeripheralAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES,
        false
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
          token: continuationToken ?? null,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Peripheral Vehicle Assets error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset report response', { data: response });
    },
  })
);

const getAssetListQuerySessionConfig = ({
  state,
  sessionId,
}: {
  sessionId: string;
  state: RootState;
}): Partial<AssetListSession> | undefined => {
  const sessionConfig =
    state.assets.sessionConfigs?.[SessionConfigType.LIST_VIEW]?.[sessionId];
  return sessionConfig;
};

const getReadinessReport = makeThunk(
  AssetsActionType.GET_READINESS_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListOverviewAPI}/views/readinessReport`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(VEHICLE_LIST_CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: (
      { rowsPerPage: size, sessionId, continuationToken, ...params },
      state
    ) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES,
        false
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          size,
          query,
          sort,
          token: continuationToken ?? null,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Readiness Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset report response', { data: response });
    },
  })
);

const getTiresReport = makeThunk(
  AssetsActionType.GET_TIRES_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/tireReadinessReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiTiresReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetTireReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Tires Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset tire readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset tire report response', {
        data: response,
      });
    },
  })
);

const getBatteryReport = makeThunk(
  AssetsActionType.GET_BATTERY_REPORT,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetReportItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.vehicleListBattery}/views/batteryReadinessReport`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(VEHICLE_LIST_CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),

    argAdapter: (
      { page, rowsPerPage: size, sessionId, continuationToken, ...params },
      state
    ) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const query = buildAssetQuery(
        sessionConfig,
        params,
        ASSET_REPORT_FIELD_NAMES,
        false,
        true
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
          token: continuationToken ?? null,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiBatteryReadinessItem>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetBatteryReadinessToReportItem(apiAsset)
                    );
                  } catch (e) {
                    errors.push({
                      name: 'Get Battery Report error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map asset battery readiness API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unexpected asset battery report response', {
        data: response,
      });
    },
  })
);
const getRecalls = makeThunk(
  AssetsActionType.GET_RECALLS,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<ApiRecallsItem>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.recallsAPI}/fieldactions/vehicleFieldActionReport`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHeader(
            addHeader(VEHICLE_LIST_CONFIG, BD_FLEET_HEADER_NAME, fleetsId),
            BD_HUB_HEADER_NAME,
            hubsId
          ),
          organizationsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: (
      { rowsPerPage: size, sessionId, continuationToken, ...params },
      state
    ) => {
      const sessionConfig = getAssetListQuerySessionConfig({
        sessionId,
        state,
      });

      const filterCriteria = formatFilterCriteriaForVehicles(
        sessionConfig?.filterType
      );
      const searchCriteria = formatSearchCriteriaForVehicles(
        sessionConfig?.searchCriteria
      );

      return {
        requestBody: {
          size,
          filterCriteria,
          searchCriteria,
          token: continuationToken,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<PagedResult<ApiRecallsItem>>(response)) {
        const errors = new Array<BDError>();
        const { items, total_items, continuation_token } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            continuation_token,
            items: items?.length
              ? items.reduce((mappedAssets, apiRecall) => {
                  try {
                    mappedAssets.push(mapApiRecallsToReportItem(apiRecall));
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Recall list error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map recall list API response',
                      data: apiRecall,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetReportItem>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected Recall list response', {
        data: response,
      });
    },
  })
);
const getAssetsCompliance = makeThunk(
  AssetsActionType.GET_ASSETS_COMPLIANCE,
  makePostPayloadCreator<
    ApiResponse<PagedResultWithErrors<AssetComplianceDetail>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetComplianceAPI}/views/complianceReport`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(DEFAULT_API_CONFIG, state.profile.currentLocale),
    argAdapter: ({ page, rowsPerPage: size, sessionId, ...params }, state) => {
      const sessionConfig =
        state.assets.sessionConfigs?.[AssetReportConfigType.COMPLIANCE_VIEW]?.[
          sessionId
        ];
      const isChargeStatusFlagEnabled =
        !!state.featureFlags.entities[CHARGING_SCHEDULE_FLAG_NAME]?.value;
      const query = buildAssetComplianceQuery(
        sessionConfig,
        params,
        isChargeStatusFlagEnabled
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<AssetComplianceDetail>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(
                      mapApiAssetComplianceToAssetComplianceDetail(apiAsset)
                    );
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Get Assets Compliance error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'Failed to map Asset compliance API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<AssetComplianceDetail>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset compliance response', {
        data: response,
      });
    },
  })
);

const searchAssets = makeThunk(
  AssetsActionType.GET_ASSETS_SEARCH,
  makePostPayloadCreator<
    ApiResponse<PagedResult<Asset>>,
    AssetPaginatedListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/searches`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (
      { page, rowsPerPage: size, sessionId, ...contextFilter },
      state
    ) => {
      const sessionConfig =
        state.assets.sessionConfigs?.[SearchConfigType.SEARCH_VIEW]?.[
          sessionId
        ];
      const query = buildAssetQuery(
        sessionConfig,
        contextFilter,
        ASSET_REPORT_FIELD_NAMES
      );
      const sort = buildAssetSort(sessionConfig, ASSET_REPORT_FIELD_NAMES);
      return {
        requestBody: {
          page,
          size,
          query,
          sort,
        },
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>, state) => {
      if (
        !!response &&
        hasApiResult<PagedResult<Partial<ApiAsset>>>(response)
      ) {
        const errors = new Array<BDError>();
        const { items, total_items } = response.result;
        const result = {
          ...response,
          result: {
            total_items,
            items: items?.length
              ? items.reduce((mappedAssets, apiAsset) => {
                  try {
                    mappedAssets.push(mapAssetApiResponseToAsset(apiAsset));
                  } catch (e) {
                    // handle mapping errors without bombing entire list response
                    errors.push({
                      name: 'Search Assets error',
                      type: BDAppErrorType.VALIDATION,
                      message:
                        e instanceof Error
                          ? e.message
                          : 'failed to map Asset API response',
                      data: apiAsset,
                    });
                  }
                  return mappedAssets;
                }, new Array<Asset>())
              : [],
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      // bomb entire list response if response does not match expected format
      throw new BDError('Unexpected asset list response', { data: response });
    },
  })
);

const getAssetDetails = makeThunk(
  AssetsActionType.GET_ASSET_DETAILS,
  makeGetPayloadCreator<ApiResponse<Asset>, Required<AssetRouteParams>>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/views/enhancements/:assetsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(DEFAULT_API_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetsId }) => ({ requestParams: { assetsId } }),
    responseAdapter: (response: unknown, state): ApiResponse<Asset> => {
      if (!!response && hasApiResult<ApiAsset>(response)) {
        const errors = new Array<BDError>();
        const apiAsset = response.result;
        try {
          // Map API response to UI Asset
          const mappedAsset = mapAssetApiResponseToAsset(apiAsset);

          const diagnosticsError = checkDiagnosticsError(mappedAsset);
          if (diagnosticsError) {
            errors.push({
              name: 'Asset Diagnostic Error',
              type: BDAppErrorType.VALIDATION,
              message: diagnosticsError,
            });
          }
          return {
            ...response,
            result: mappedAsset,
            errors,
          };
        } catch (e) {
          // handle mapping errors without bombing entire list response
          errors.push({
            name: 'Asset Details API Error',
            type: BDAppErrorType.API,
            message:
              e instanceof Error
                ? e.message
                : 'Failed to map asset API response',
            data: apiAsset,
          });

          return {
            ...response,
            errors: [
              BDError.asJson(
                e instanceof Error
                  ? e
                  : new Error('Failed to parse asset detail response')
              ),
            ],
          } as ApiResponse<Asset>;
        }
      }
      throw new BDError('Unexpected asset detail response', { data: response });
    },
  })
);

const getAssetRecalls = makeThunk(
  AssetsActionType.GET_ASSET_RECALLS,
  makeGetPayloadCreator<
    ApiResponse<AssetRecall[] | null>,
    Required<AssetRouteParams>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetRecallsAPI}/fieldactions/lookup/:assetsId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(DEFAULT_API_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetsId }) => ({ requestParams: { assetsId } }),
    responseAdapter: (
      response: unknown,
      state
    ): ApiResponse<AssetRecall[] | null> => {
      if (!!response && hasApiResult<ApiAssetRecall>(response)) {
        const errors = new Array<BDError>();
        const apiAssetRecalls = response.result.fieldActions;
        try {
          const locale = (state.profile.currentLocale ??
            LanguageLocale.EN) as keyof typeof LOCALE_FORMAT_DATE;
          //null case is to handle when Field action not found for vin
          const recalls = apiAssetRecalls
            ? mapVehicleDetailsRecalls(apiAssetRecalls, locale)
            : null;
          return {
            ...response,
            result: recalls,
            errors,
          };
        } catch (e) {
          // handle mapping errors without bombing entire list response
          errors.push({
            name: 'Asset Details Recalls API Error',
            type: BDAppErrorType.API,
            message:
              e instanceof Error
                ? e.message
                : 'Failed to map asset API response',
            data: apiAssetRecalls,
          });

          return {
            ...response,
            result: response.result?.fieldActions,
            errors: [
              BDError.asJson(
                e instanceof Error
                  ? e
                  : new Error('Failed to parse asset detail response')
              ),
            ],
          } as ApiResponse<AssetRecall[]>;
        }
      }
      throw new BDError('Unexpected asset detail response', {
        data: response,
      });
    },
  })
);

const getAssetHistory = makeThunk(
  AssetsActionType.GET_ASSET_HISTORY,
  makeGetPayloadCreator<ApiResponse<AssetEvent[]>, AssetHistoryParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetHistoryAPI}/:assetsId/history`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({
      assetsId,
      startDate,
      endDate,
      actions = [
        AssetEventAction.POWER_ON,
        AssetEventAction.POWER_OFF,
        AssetEventAction.LOCK,
        AssetEventAction.DOOR,
      ],
    }) => ({
      requestParams: {
        assetsId,
        startDate,
        endDate,
        actions: actions.join(','),
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result?.items?.length
        ? mapEventApiResponseToEvent(response?.result?.items)
        : [],
    }),
  })
);

const getAssetLocation = makeThunk(
  AssetsActionType.GET_ASSET_LOCATION,
  makeGetPayloadCreator<ApiResponse<AssetRawLocation[]>, AssetHistoryParams>({
    url: (params) =>
      params.locationUpgradeFlagActive
        ? `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetLocationsAPI}/:assetsId/location/geojson`
        : `${globalThis.appConfig.apiBaseUrl}/assets/:assetsId/location${
            GEOJSON_TOGGLE ? '/geojson' : ''
          }`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetsId, startDate, endDate }) => ({
      requestParams: {
        assetsId,
        startDate,
        endDate,
      },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: response?.result?.geojson?.geometry?.coordinates?.length
        ? mapGeoJSONApiResponseToLocation(response?.result.geojson)
        : {},
    }),
  })
);

const addAsset = makeThunk(
  AssetsActionType.ADD_ASSET,
  makePostPayloadCreator<ApiResponse<Asset>, AddAssetFormFields>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}`,
    argAdapter: (assetForm) => ({ requestBody: assetForm }),
  })
);

const rejectVehicle = makeThunk(
  AssetsActionType.REJECT_VEHICLE,
  makePostPayloadCreator<
    ApiResponse<RejectApproveVehiclesResult>,
    RejectVehicle
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.rejectConfirmVehicleAPI}/onboarding/vehicles/ownership/reject`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({ requestBody: payload }),
  })
);

const approveVehicle = makeThunk(
  AssetsActionType.APPROVE_VEHICLE,
  makePostPayloadCreator<
    ApiResponse<RejectApproveVehiclesResult>,
    ApproveVehicle
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.rejectConfirmVehicleAPI}/onboarding/vehicles/ownership/confirm`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({ requestBody: payload }),
  })
);

const reProvisionAsset = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePutPayloadCreator<ApiResponse<void>, { assetId: string }>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/admin/telemetry/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId }) => ({
      requestParams: { assetId },
    }),
  })
);
const saveAssetProtectedFields = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePostPayloadCreator<
    ApiResponse<void>,
    AssetProtectedFormFields & { assetId: string }
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/admin/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId, ...fields }) => ({
      requestParams: { assetId },
      requestBody: fields,
    }),
  })
);

const updateAsset = makeThunk(
  AssetsActionType.UPDATE_ASSET,
  makePutPayloadCreator<ApiResponse<Asset>, UpdateAssetFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: { assetId: payload.params.assetsId },
      requestBody: mapAssetFormToApiRequest(payload),
    }),
  })
);

const lockAsset = makeThunk(
  AssetsActionType.LOCK_ASSET,
  makePutPayloadCreator<
    ApiResponse,
    FormPayload<{ value: boolean }, keyof AssetRouteParams>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId/status/lock`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ formFields, params }) => ({
      requestParams: { assetId: params.assetsId },
      requestBody: formFields,
    }),
  })
);

const updateServiceAvailability = makeThunk(
  AssetsActionType.UPDATE_AVAILABILITY,
  makePostPayloadCreator<
    ApiResponse<Asset>,
    {
      assetId: string;
      currentState: AssetServiceStatus;
      desiredState: AssetServiceStatus;
    }
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId/states/availability`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: ({ assetId, currentState, desiredState }) => ({
      requestParams: { assetId },
      requestBody: {
        current: currentState,
        desired: desiredState,
      },
    }),
  })
);

const deleteAsset = makeThunk(
  AssetsActionType.DELETE_ASSET,
  makeDeletePayloadCreator<ApiResponse, string>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/:assetId`,
    argAdapter: (assetId) => ({
      requestParams: { assetId },
    }),
  })
);

const getNextEPalletSerial = makeThunk(
  AssetsActionType.GET_NEXT_EP_SERIAL,
  makeGetPayloadCreator<ApiResponse<string>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/nextEPalletSerial`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

const getTrackerSerials = makeThunk(
  AssetsActionType.GET_TRACKER_SERIALS,
  makeGetPayloadCreator<ApiResponse<string[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/trackerSerials`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

const getEPalletVersions = makeThunk(
  AssetsActionType.GET_EP_VERSIONS,
  makeGetPayloadCreator<ApiResponse<AssetModel[]>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.default}/ePalletVersions`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
  })
);

type RemoteCommandParams = {
  type: string;
  command: string;
  assetId: string;
  organizationsId: string;
};

const executeRemoteCommand = makeThunk(
  AssetsActionType.REMOTE_COMMAND_ASSET,
  makeGetPayloadCreator<
    ApiResponse<AssetRemoteCommandExecuteResult>,
    RemoteCommandParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.remoteCommandsAPI}/remotecommands/:type/:command/:assetId`,
    axiosOptions: ({ organizationsId }, state) =>
      addCSOrganizationIdHeader(
        addAcceptLanguageHeader(
          REMOTE_COMMAND_API_CONFIG,
          state.profile.currentLocale
        ),
        organizationsId
      ),
    argAdapter: (params) => ({
      requestParams: {
        assetId: params.assetId,
        command: params.command,
        type: params.type,
      },
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<AssetRemoteCommandExecuteResult>(response)
      ) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected remote command response', {
        data: response,
      });
    },
  })
);

type AssetStatusRequestParams = AssetListParams & {
  assetId: string;
  assetStatuses: Set<ApiAssetTelemetryKey>;
};

const getAssetStatuses = makeThunk(
  AssetsActionType.GET_ASSET_STATUS,
  makeGetPayloadCreator<
    ApiResponse<AssetStatusResult>,
    AssetStatusRequestParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetStatusesAPI}/views/status/:assetId?statuses=:assetStatuses`,
    axiosOptions: ({ organizationsId, fleetsId, hubsId }, state) =>
      addAcceptLanguageHeader(
        addCSHubIdHeader(
          addCSFleetIdHeader(
            addCSOrganizationIdHeader(BASE_CONFIG, organizationsId),
            fleetsId
          ),
          hubsId
        ),
        state.profile.currentLocale
      ),
    argAdapter: (params) => ({
      requestParams: {
        assetId: params.assetId,
        assetStatuses: [...params.assetStatuses].join(','),
      },
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<AssetStatusResult>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected get asset status response', {
        data: response,
      });
    },
  })
);

const getAssetIgnition = makeThunk(
  AssetsActionType.GET_ASSET_IGNITION,
  makeGetPayloadCreator<
    ApiResponse<{ ignitionStatus: AssetIgnitionStatus }>,
    { assetId: string }
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.assetIgnitionAPI}/views/vehicleIgnitionStatus/:assetId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(BASE_CONFIG, state.profile.currentLocale),
    argAdapter: (params) => ({
      requestParams: {
        assetId: params.assetId,
      },
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<{ ignitionStatus: AssetIgnitionStatus }>(response)
      ) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected get asset status response', {
        data: response,
      });
    },
  })
);
const getAdvisorVehicleList = makeThunk(
  AssetsActionType.GET_ADVISOR_VEHICLE_LIST,
  makeGetPayloadCreator<
    ApiResponse<PagedResultWithErrors<AdvisorVehicleListItem>>,
    AssetPaginatedListParams & SearchFilterInfo
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.advisorVehicleListApi}/views/vehicleList`,
    axiosOptions: (
      { continuationToken, organizationsId, fleetsId, hubsId },
      state
    ) => {
      const commonHeaders = addAcceptLanguageHeader(
        addCSHubIdHeader(
          addCSFleetIdHeader(
            addCSOrganizationIdHeader(BASE_CONFIG, organizationsId),
            fleetsId
          ),
          hubsId
        ),
        state.profile.currentLocale
      );
      return continuationToken
        ? addHeader(
            commonHeaders,
            CS_CONTINUATION_TOKEN_HEADER_NAME,
            continuationToken
          )
        : commonHeaders;
    },
    argAdapter: ({ searchCriteria, filterCriteria }) => {
      const params = buildAdvisorVehicleQueryParam({
        search: searchCriteria,
        filter: filterCriteria,
      });
      return {
        requestParams: params,
      };
    },
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (
        !!response &&
        hasApiResult<PagedResult<AdvisorVehicleListItem>>(response)
      ) {
        const { items, total_items, continuation_token } = response.result;
        const errors = new Array<BDError>();
        const mappedItems = items?.length
          ? items.map((item) => mapApiAdvisorVehicleListItem(item))
          : [];
        const result = {
          ...response,
          result: {
            total_items,
            items: mappedItems,
            continuation_token,
          },
        };
        return errors.length
          ? { ...result, errors: errors.map((e) => BDError.asJson(e)) }
          : result;
      }
      throw new BDError('Unable to parse org list response', {
        type: BDAppErrorType.VALIDATION,
        data: { response },
      });
    },
  })
);

export const ASSETS_ACTIONS = {
  getAssets,
  getPendingVehicles,
  getReadinessReport,
  getTiresReport,
  getBatteryReport,
  getRecalls,
  getAssetsCompliance,
  getVehicleNotificationAssets,
  getPeripheralVehicles,
  searchAssets,
  getAssetDetails,
  getAssetRecalls,
  getAssetHistory,
  getAssetLocation,
  addAsset,
  rejectVehicle,
  approveVehicle,
  updateAsset,
  lockAsset,
  updateServiceAvailability,
  deleteAsset,
  getNextEPalletSerial,
  getTrackerSerials,
  getEPalletVersions,
  reProvisionAsset,
  saveAssetProtectedFields,
  executeRemoteCommand,
  getAssetStatuses,
  getVehicleListOverviewExport,
  getVehicleListBatteryExport,
  getVehicleListFluidsExport,
  getAssetIgnition,
  getAdvisorVehicleList,
};

import {
  AlertPermission,
  AssetPermission,
  FleetPermission,
  HubPermission,
  InsightPermission,
  InspectionPermission,
  OrganizationPermission,
  PeripheralPermission,
  ProfilePermission,
  Role,
  VehicleOrderPermission,
} from '@gm-commercial/profile-model';
import { lazy } from 'react';

import {
  ADD_VEHICLE_MODAL_V05_FLAG,
  CAPS_FLAG_NAME,
  DVIC_FLAG_NAME,
  INSIGHTS_FLAG_NAME,
  MIXED_PLAN_FLAG,
  MRT_FLAG_NAME,
  ORG_DETAILS_FLAG,
  PERIPHERALS_FLAG_NAME,
  SETTINGS_AND_NOTIFICATIONS_DUAL_CAM,
  USER_LIST_V05_FLAG,
} from '~/common/models/featureFlags.model';
import { NOTIFICATIONS_REQUIRED_ROLES } from '~/common/models/notification.model';
import {
  ACCOUNT_FLEET_PROGRAMS,
  ACCOUNT_ORG_DETAILS,
  ACCOUNT_PLAN_DETAIL_VIEW_PATH,
  ACCOUNT_PLAN_LIST_VIEW_PATH,
  ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
  ACCOUNT_PROFILE_PERSONAL_PATH,
  ACCOUNT_SETTINGS_CAMERA_VIEW_PATH,
  ACCOUNT_USER_LIST_PATH,
} from '~/common/models/pages/accountPages.model';
import {
  ADVISOR_PENDING_VEHICLES_LIST,
  ADVISOR_VEHICLE_LIST,
  FLEET_ADD_VEHICLES_LIST,
  FLEET_HOME,
  FLEET_NOTIFICATIONS,
  FLEET_VEHICLE_LIST,
  FLEET_VEHICLE_LIST_DETAILS,
  FLEET_VEHICLE_ORDERS_DETAILS,
  FLEET_VEHICLE_ORDERS_LIST,
  FLEET_VEHICLE_PERIPHERALS_LIST,
} from '~/common/models/pages/fleetPages.model';
import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_SAFETY_AND_SECURITY,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES,
} from '~/common/models/pages/settingsPages.model';
import {
  AppRoutePaths,
  RouteConfig,
  RouteName,
} from '~/common/models/route.model';
import { UTILIZATION_FLAG_NAME } from '~/features/utilization/utilization.constant';

import { ACCOUNT_ROUTES } from './routes/account.config';
import { SUPPORT_ROUTES } from './routes/support.config';

export interface RouteParams {
  organizationsId?: string;
  hubsId?: string;
  fleetsId?: string;
  assetsId?: string;
  usersId?: string;
  reportId?: string;
  assetType?: string;
  plansId?: string;
  inspectionsId?: string;
  viewType?: string;
  ordersId?: string;
  categoryId?: string;
  topicId?: string;
}

const UserList = lazy(
  () => import('../../features/users/list/AdvisorUsersListView')
);
//10/18/24: Below entry utilizes the MRT component for the User List page accessible via the Account Navigation.
const CSPlatformUserList = lazy(
  () => import('../../features/users/list/AccountUsersListView')
);
const UserDetails = lazy(
  () => import('../../features/users/details/UserDetailsNew')
);
const MapView = lazy(() => import('../../features/map/MapView'));
const HubList = lazy(() => import('../../features/hubs/list/HubList'));
const OrderList = lazy(
  () => import('../../features/orders/list/VehicleOrderListView')
);
const MRTOrderList = lazy(
  () => import('../../features/orders/list/MRT/VehicleOrderListView')
);
const HubDetails = lazy(() => import('../../features/hubs/details/HubDetails'));
const FleetList = lazy(() => import('../../features/fleets/list/FleetList'));
const FleetDetails = lazy(
  () => import('~/features/fleets/details/FleetDetails')
);
const AssetDetails = lazy(
  () => import('~/features/assets/details/AssetDetails')
);
const CSPlatformAssetDetails = lazy(
  () => import('~/features/assets/details/AssetDetailsNew')
);
const CSVehicleOrderDetails = lazy(
  () => import('~/features/orders/details/VehicleOrderDetailsView')
);

const UserTermsConditions = lazy(
  () => import('~/features/users/termsConditions/UserTermsConditions')
);
const OrganizationTermsConditions = lazy(
  () =>
    import(
      '~/features/organizations/termsConditions/OrganizationTermsConditions'
    )
);
const DashboardView = lazy(() => import('~/features/dashboard/DashboardView'));
const OrganizationList = lazy(
  () => import('~/features/organizations/list/OrganizationListView')
);
const OrganizationDetails = lazy(
  () => import('~/features/organizations/details/OrganizationDetails')
);

const DailyUtilizationSummary = lazy(
  () => import('~/features/utilization/summary/DailyUtilizationSummary')
);

const AssetListReportView = lazy(
  () => import('~/features/assets/report/AssetListReportView')
);

const AdvisorVehicleList = lazy(
  () => import('~/features/vehicles/list/advisor/AdvisorVehicleList')
);

const PrivacyStatement = lazy(
  () => import('~/features/privacyStatement/Privacy')
);
const UserTerms = lazy(() => import('~/features/termsCondition/UserTerms'));
const EditFleetConfiguration = lazy(
  () => import('~/features/fleets/details/EditFleetConfiguration')
);
const InsightList = lazy(() => import('~/features/insights/list/InsightList'));
const InsightDetails = lazy(
  () => import('~/features/insights/details/InsightDetails')
);
const AssetInspectionsListView = lazy(
  () => import('~/features/inspections/list/AssetInspectionsListView')
);
const AssetInspectionsDetailsView = lazy(
  () => import('~/features/inspections/details/AssetInspectionsDetailsView')
);
const PeripheralsList = lazy(
  () => import('~/features/peripherals/components/list/PeripheralsList')
);
const CSPlatformDashboardView = lazy(
  () => import('~/features/dashboard/csPlatform/CSPlatformDashboardView')
);
const CSPlatformSettingsView = lazy(
  () => import('~/features/settings/CSPlatformSettingsView')
);
const CSPlatformSettingsNotificationsUsersView = lazy(
  () => import('~/features/settings/CSPlatformSettingsNotificationsUsersView')
);
const CSPlatformSettingsNotificationsAccountsView = lazy(
  () =>
    import('~/features/settings/CSPlatformSettingsNotificationsAccountsView')
);

const CSPlatformAccountPreferencesView = lazy(
  () =>
    import('~/features/account/preferences/CSPlatformAccountPreferencesView')
);
const AccountProfileView = lazy(
  () => import('~/features/account/profile/AccountProfileView')
);
const Notifications = lazy(
  () => import('~/routes/notifications/Notifications')
);

const CSPlatformOrgDetailsView = lazy(
  () => import('~/features/organizations/details/OrganizationDetails')
);
const CSPlatformFleetPurchaseProgramsView = lazy(
  () => import('~/features/fleets/purchasePrograms/FleetPurchasePrograms')
);

const AddVehiclesListView = lazy(
  () => import('~/features/assets/addVehicles/list/AddVehiclesListView')
);
const PlanListView = lazy(() => import('~/features/plans/PlanListView'));
const PlanDetailView = lazy(() => import('~/features/plans/PlanDetailView'));

const AdvisorPendingListView = lazy(
  () => import('~/features/assets/pending/AdvisorPendingListView')
);

const CSPlatformSettingsNotificationsSafetyAndSecurityView = lazy(
  () =>
    import(
      '~/features/settings/CSPlatformSettingsNotificationsSafetyAndSecurityView'
    )
);

const CSPlatformSettingsNotificationsCamerasView = lazy(
  () => import('~/features/settings/CSPlatformSettingsNotificationsCamerasView')
);

const DASHBOARD_ROUTE: RouteConfig = {
  name: RouteName.DASHBOARD,
  path: [
    '/organizations/:organizationsId/dashboard',
    '/organizations/:organizationsId/hubs/:hubsId/dashboard',
    '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/dashboard',
  ],
  authorizedPerms: [AssetPermission.READ, OrganizationPermission.READ],
  authorizedRoles: [
    Role.SYSTEM_MANAGER,
    Role.ORG_MANAGER,
    Role.HUB_MANAGER,
    Role.FLEET_MANAGER,
  ],
  secure: true,
  component: DashboardView,
};

export const APP_ROUTES_CONFIG_MAPPING = {
  ...ACCOUNT_ROUTES,
  ...SUPPORT_ROUTES,
  [RouteName.USER_LIST]: {
    name: RouteName.USER_LIST,
    path: [
      '/users',
      '/organizations/:organizationsId/users',
      '/organizations/:organizationsId/fleets/:fleetsId/users',
      '/organizations/:organizationsId/hubs/:hubsId/users',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/users',
    ],
    authorizedPerms: [ProfilePermission.READ],
    excludedRoles: [
      Role.OPERATOR,
      Role.SERVICE_TECHNICIAN,
      Role.POC_ROUTEMANAGER,
    ],
    secure: true,
    component: UserList,
  },
  [RouteName.ASSET_DETAILS]: {
    name: RouteName.ASSET_DETAILS,
    path: [
      '/assets/:assetsId',
      '/organizations/:organizationsId/assets/:assetsId',
      '/organizations/:organizationsId/fleets/:fleetsId/assets/:assetsId',
      '/organizations/:organizationsId/hubs/:hubsId/assets/:assetsId',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/assets/:assetsId',
    ],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AssetDetails,
  },
  [RouteName.ORG_LIST]: {
    name: RouteName.ORG_LIST,
    path: AppRoutePaths.ORGANIZATION_LIST,
    authorizedPerms: [OrganizationPermission.READ],
    authorizedRoles: [Role.SYSTEM_MANAGER, Role.SYSTEM_READER],
    secure: true,
    component: OrganizationList,
  },
  [RouteName.ADVISOR_ORG_DETAILS]: {
    name: RouteName.ADVISOR_ORG_DETAILS,
    path: '/organizations/:organizationsId',
    authorizedPerms: [OrganizationPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.SYSTEM_READER,
      Role.POC_GROCERYMANAGER,
    ],
    secure: true,
    component: OrganizationDetails,
  },
  [RouteName.ADVISOR_PENDING_VEHICLES]: {
    name: RouteName.ADVISOR_PENDING_VEHICLES,
    path: [ADVISOR_PENDING_VEHICLES_LIST],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AdvisorPendingListView,
  },
  [RouteName.USER_DETAILS]: {
    name: RouteName.USER_DETAILS,
    path: [
      '/users/:usersId',
      '/organizations/:organizationsId/users/:usersId',
      '/organizations/:organizationsId/fleets/:fleetsId/users/:usersId',
      '/organizations/:organizationsId/hubs/:hubsId/users/:usersId',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/users/:usersId',
    ],
    authorizedPerms: [ProfilePermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: UserDetails,
  },
  [RouteName.HUB_LIST]: {
    name: RouteName.HUB_LIST,
    path: '/organizations/:organizationsId/hubs',
    authorizedPerms: [HubPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.SERVICE_TECHNICIAN,
      Role.SYSTEM_READER,
    ],
    secure: true,
    component: HubList,
  },
  [RouteName.HUB_DETAILS]: {
    name: RouteName.HUB_DETAILS,
    path: '/organizations/:organizationsId/hubs/:hubsId',
    authorizedPerms: [HubPermission.READ],
    excludedRoles: [Role.FLEET_MANAGER, Role.OPERATOR],
    secure: true,
    component: HubDetails,
  },
  [RouteName.FLEET_LIST]: {
    name: RouteName.FLEET_LIST,
    path: [
      '/organizations/:organizationsId/fleets',
      '/organizations/:organizationsId/hubs/:hubsId/fleets',
    ],
    authorizedPerms: [FleetPermission.READ],
    excludedRoles: [Role.FLEET_MANAGER, Role.OPERATOR],
    secure: true,
    component: FleetList,
  },
  [RouteName.FLEET_DETAILS]: {
    name: RouteName.FLEET_DETAILS,
    path: ['/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId'],
    authorizedPerms: [FleetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: FleetDetails,
  },
  [RouteName.EDIT_FLEET_CONFIGURATION]: {
    name: RouteName.EDIT_FLEET_CONFIGURATION,
    path: [
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/editConfigs',
    ],
    authorizedPerms: [FleetPermission.UPDATE],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: EditFleetConfiguration,
  },
  [RouteName.MAP]: {
    name: RouteName.MAP,
    path: [
      '/organizations/:organizationsId/map',
      '/organizations/:organizationsId/hubs/:hubsId/map',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/map',
    ],
    authorizedPerms: [AssetPermission.READ, OrganizationPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.HUB_MANAGER,
      Role.FLEET_MANAGER,
      Role.SYSTEM_READER,
    ],
    secure: true,
    component: MapView,
  },
  [RouteName.DASHBOARD]: DASHBOARD_ROUTE,
  [RouteName.USER_TERMS_AND_CONDITIONS]: {
    name: RouteName.USER_TERMS_AND_CONDITIONS,
    path: '/users/:usersId/terms-and-conditions',
    authorizedPerms: [],
    secure: true,
    component: UserTermsConditions,
  },
  [RouteName.ORGANIZATION_TERMS_AND_CONDITIONS]: {
    name: RouteName.ORGANIZATION_TERMS_AND_CONDITIONS,
    path: '/organizations/:organizationsId/terms-and-conditions',
    authorizedPerms: [],
    secure: true,
    component: OrganizationTermsConditions,
  },
  [RouteName.UTILIZATION_REPORT]: {
    name: RouteName.UTILIZATION_REPORT,
    path: ['/utilization/report'],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: DailyUtilizationSummary,
    requiredFeatureFlags: [UTILIZATION_FLAG_NAME],
  },
  [RouteName.ASSET_LIST_REPORT]: {
    name: RouteName.ASSET_LIST_REPORT,
    path: [
      AppRoutePaths.ASSET_LIST,
      '/organizations/:organizationsId/assetReport',
      '/organizations/:organizationsId/fleets/:fleetsId/assetReport',
      '/organizations/:organizationsId/hubs/:hubsId/assetReport',
      '/organizations/:organizationsId/hubs/:hubsId/fleets/:fleetsId/assetReport',
      FLEET_VEHICLE_LIST,
    ],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AssetListReportView,
  },
  [RouteName.ADVISOR_VEHICLE_LIST]: {
    name: RouteName.ADVISOR_VEHICLE_LIST,
    path: [ADVISOR_VEHICLE_LIST],
    authorizedRoles: [Role.SYSTEM_MANAGER, Role.SYSTEM_READER],
    authorizedPerms: [AssetPermission.READ],
    secure: true,
    component: AdvisorVehicleList,
  },
  [RouteName.PRIVACY]: {
    name: RouteName.PRIVACY,
    path: ['/privacy-statement'],
    authorizedPerms: [],
    secure: false,
    component: PrivacyStatement,
  },
  [RouteName.USER_TERMS]: {
    name: RouteName.USER_TERMS,
    path: ['/user-terms'],
    authorizedPerms: [],
    secure: false,
    component: UserTerms,
  },
  [RouteName.INSIGHT_LIST]: {
    name: RouteName.INSIGHT_LIST,
    path: ['/insights'],
    authorizedPerms: [InsightPermission.READ],
    secure: true,
    component: InsightList,
    requiredFeatureFlags: [INSIGHTS_FLAG_NAME],
  },
  [RouteName.INSIGHT_DETAILS]: {
    name: RouteName.INSIGHT_DETAILS,
    path: ['/insights/details'],
    authorizedPerms: [InsightPermission.READ],
    secure: true,
    component: InsightDetails,
    requiredFeatureFlags: [INSIGHTS_FLAG_NAME],
  },
  [RouteName.ASSET_INSPECTIONS_LIST]: {
    name: RouteName.ASSET_INSPECTIONS_LIST,
    path: ['/inspections'],
    authorizedPerms: [InspectionPermission.READ],
    secure: true,
    component: AssetInspectionsListView,
    requiredFeatureFlags: [DVIC_FLAG_NAME],
  },
  [RouteName.ASSET_INSPECTIONS_DETAILS]: {
    name: RouteName.ASSET_INSPECTIONS_DETAILS,
    path: ['/inspections/details'],
    authorizedPerms: [InspectionPermission.READ],
    secure: true,
    component: AssetInspectionsDetailsView,
    requiredFeatureFlags: [DVIC_FLAG_NAME],
  },

  // NEW PAGES FOR CS PLATFORM
  [RouteName.CS_PLATFORM_ASSET_DETAILS]: {
    name: RouteName.CS_PLATFORM_ASSET_DETAILS,
    path: [
      FLEET_VEHICLE_LIST_DETAILS,
      `${FLEET_VEHICLE_LIST_DETAILS}/:viewType`,
    ],
    authorizedPerms: [AssetPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformAssetDetails,
  },
  [RouteName.CS_PLATFORM_PERIPHERALS_LIST]: {
    name: RouteName.CS_PLATFORM_PERIPHERALS_LIST,
    path: [FLEET_VEHICLE_PERIPHERALS_LIST],
    authorizedPerms: [PeripheralPermission.READ],
    authorizedRoles: [
      Role.SYSTEM_MANAGER,
      Role.ORG_MANAGER,
      Role.FLEET_MANAGER,
    ],
    secure: true,
    component: PeripheralsList,
    featureFlagComponent: PeripheralsList,
    requiredFeatureFlags: [PERIPHERALS_FLAG_NAME],
  },
  [RouteName.CS_PLATFORM_ORDER_LIST]: {
    name: RouteName.CS_PLATFORM_ORDER_LIST,
    path: [FLEET_VEHICLE_ORDERS_LIST],
    authorizedPerms: [
      VehicleOrderPermission.READ,
      VehicleOrderPermission.UPDATE,
      VehicleOrderPermission.DELETE,
      VehicleOrderPermission.CREATE,
    ],
    secure: true,
    component: OrderList,
    featureFlagComponent: MRTOrderList,
    requiredFeatureFlags: [MRT_FLAG_NAME],
  },
  [RouteName.CS_PLATFORM_ORDER_DETAILS]: {
    name: RouteName.CS_PLATFORM_ORDER_DETAILS,
    path: [FLEET_VEHICLE_ORDERS_DETAILS],
    authorizedPerms: [
      VehicleOrderPermission.READ,
      VehicleOrderPermission.UPDATE,
    ],
    secure: true,
    component: CSVehicleOrderDetails,
  },
  [RouteName.CS_PLATFORM_DASHBOARD]: {
    name: RouteName.CS_PLATFORM_DASHBOARD,
    path: [FLEET_HOME],
    authorizedPerms: [AssetPermission.READ, OrganizationPermission.READ],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformDashboardView,
  },
  [RouteName.CS_PLATFORM_SETTINGS]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [
      SETTINGS_NOTIFICATIONS_VEHICLES,
      `${SETTINGS_NOTIFICATIONS_VEHICLES}/:viewType`,
    ],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsView,
  },
  [RouteName.CS_PLATFORM_USERS_NOTIFICATIONS_SETTING]: {
    name: RouteName.CS_PLATFORM_USERS_NOTIFICATIONS_SETTING,
    path: [SETTINGS_NOTIFICATIONS_USERS],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsNotificationsUsersView,
  },
  [RouteName.CS_PLATFORM_ACCOUNTS_NOTIFICATIONS_SETTING]: {
    name: RouteName.CS_PLATFORM_ACCOUNTS_NOTIFICATIONS_SETTING,
    path: [SETTINGS_NOTIFICATIONS_ACCOUNT],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsNotificationsAccountsView,
  },
  [RouteName.CS_PLATFORM_NOTIFICATION_LIST]: {
    name: RouteName.CS_PLATFORM_NOTIFICATION_LIST,
    path: [FLEET_NOTIFICATIONS],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    authorizedPerms: [AlertPermission.READ],
    secure: true,
    component: Notifications,
  },
  [RouteName.CS_PLATFORM_ACCOUNT_PREFERENCES]: {
    name: RouteName.CS_PLATFORM_ACCOUNT_PREFERENCES,
    path: ['account/profile/preferences'],
    authorizedPerms: [ProfilePermission.READ, ProfilePermission.UPDATE],
    secure: true,
    component: CSPlatformAccountPreferencesView,
  },

  [RouteName.ACCOUNT_PROFILE_PERSONAL]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [ACCOUNT_PROFILE_PERSONAL_PATH],
    authorizedPerms: [ProfilePermission.READ],
    secure: true,
    component: AccountProfileView,
  },
  [RouteName.ACCOUNT_PREFERENCES_LANGUAGE_REGION]: {
    name: RouteName.CS_PLATFORM_SETTINGS,
    path: [ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH],
    authorizedPerms: [],
    secure: true,
    component: CSPlatformAccountPreferencesView,
  },

  [RouteName.CS_PLATFORM_ACCOUNT_ORG_DETAILS]: {
    name: RouteName.CS_PLATFORM_ACCOUNT_ORG_DETAILS,
    path: [ACCOUNT_ORG_DETAILS],
    authorizedPerms: [],
    excludedRoles: [
      Role.OPERATOR,
      Role.POC_GROCERYMANAGER,
      Role.POC_ROUTEMANAGER,
      Role.VIEWER,
    ],
    secure: true,
    component: CSPlatformOrgDetailsView,
    requiredFeatureFlags: [ORG_DETAILS_FLAG],
  },
  [RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS]: {
    name: RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS,
    path: [ACCOUNT_FLEET_PROGRAMS],
    authorizedPerms: [],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformFleetPurchaseProgramsView,
    featureFlagComponent: CSPlatformFleetPurchaseProgramsView,
    requiredFeatureFlags: [CAPS_FLAG_NAME],
  },
  [RouteName.CS_PLATFORM_SETTINGS_CAMERAS]: {
    name: RouteName.CS_PLATFORM_SETTINGS_CAMERAS,
    path: [ACCOUNT_SETTINGS_CAMERA_VIEW_PATH],
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSettingsNotificationsCamerasView,
    featureFlagComponent: CSPlatformSettingsNotificationsCamerasView,
    requiredFeatureFlags: [SETTINGS_AND_NOTIFICATIONS_DUAL_CAM],
  },
  [RouteName.CS_PLATFORM_USER_LIST]: {
    name: RouteName.CS_PLATFORM_USER_LIST,
    path: [ACCOUNT_USER_LIST_PATH],
    authorizedPerms: [],
    excludedRoles: [
      Role.OPERATOR,
      Role.POC_GROCERYMANAGER,
      Role.POC_ROUTEMANAGER,
      Role.VIEWER,
      //System roles do not need to be able to view this User List,
      //as those roles will have access to the Advisor User List View
      Role.SYSTEM_READER,
      Role.SYSTEM_MANAGER,
    ],
    secure: true,
    component: CSPlatformUserList,
    requiredFeatureFlags: [USER_LIST_V05_FLAG],
  },
  [RouteName.CS_PLATFORM_ADD_VEHICLES]: {
    name: RouteName.CS_PLATFORM_ADD_VEHICLES,
    path: [FLEET_ADD_VEHICLES_LIST],
    authorizedPerms: [],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: AddVehiclesListView,
    requiredFeatureFlags: [ADD_VEHICLE_MODAL_V05_FLAG],
  },
  [RouteName.PLANS_LIST_VIEW]: {
    name: RouteName.PLANS_LIST_VIEW,
    path: [ACCOUNT_PLAN_LIST_VIEW_PATH],
    // TODO: Undo permissions when api supports,
    // authorizedPerms: [LicensePermission.GET, EntitlementPermission.GET],
    authorizedPerms: [],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: PlanListView,
    featureFlagComponent: PlanListView,
    requiredFeatureFlags: [MIXED_PLAN_FLAG],
  },
  [RouteName.PLANS_DETAIL_VIEW]: {
    name: RouteName.PLANS_DETAIL_VIEW,
    path: [ACCOUNT_PLAN_DETAIL_VIEW_PATH],
    // TODO: Undo permissions when api supports,
    // authorizedPerms: [LicensePermission.GET, EntitlementPermission.GET],
    authorizedPerms: [],
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: PlanDetailView,
    featureFlagComponent: PlanDetailView,
    requiredFeatureFlags: [MIXED_PLAN_FLAG],
  },
  [RouteName.CS_PLATFORM_SAFETY_AND_SECURITY_NOTIFICATIONS_SETTINGS]: {
    name: RouteName.CS_PLATFORM_SAFETY_AND_SECURITY_NOTIFICATIONS_SETTINGS,
    path: [SETTINGS_NOTIFICATIONS_SAFETY_AND_SECURITY],
    authorizedPerms: [],
    authorizedRoles: NOTIFICATIONS_REQUIRED_ROLES,
    excludedRoles: [Role.OPERATOR],
    secure: true,
    component: CSPlatformSettingsNotificationsSafetyAndSecurityView,
    featureFlagComponent: CSPlatformSettingsNotificationsSafetyAndSecurityView,
    requiredFeatureFlags: [SETTINGS_AND_NOTIFICATIONS_DUAL_CAM],
  },
};

export const Routes: RouteConfig[] = Object.values(APP_ROUTES_CONFIG_MAPPING);

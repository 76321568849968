import { makeStyles } from 'tss-react/mui';

export const VIEW_AS_BANNER_HEIGHT = 60;

const useStyles = makeStyles()((theme) => ({
  viewAsContainer: {
    zIndex: '400',
    backgroundColor: theme.new.color.brand[100],
    height: `${VIEW_AS_BANNER_HEIGHT}px`,
    position: 'fixed',
    borderBottom: `1px solid ${theme.new.color.brandPrimary}`,
  },
  viewAsMessageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  infoIcon: {
    path: {
      stroke: theme.new.color.brandPrimary,
    },
  },
}));

export default useStyles;

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { getAuthToken, getBaseUrl } from '..';

const rawBaseQuery = fetchBaseQuery({
  baseUrl: '', // placeholder, dynamically replaced
  prepareHeaders: (headers) => {
    const token = getAuthToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('accept', 'application/json, text/plain, */*');
    headers.set('content-type', 'application/json');
    return headers;
  },
});

// Adapted from https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = getBaseUrl();
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = `${baseUrl}/entitlement${urlEnd}`;

  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
  return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const initialApi = createApi({
  reducerPath: 'entitlementsApi',
  refetchOnMountOrArgChange: 60,
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}), // injected in generated-api.ts
});

import { MRT_Cell, MRT_Row } from '@brightdrop/table-ui';
import { User } from '@gm-commercial/profile-model';
import { Box } from '@mui/material';

import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';

import useStyles from './userListCellStyles';

interface UserInvitationStatusColumnCellProps {
  cell: MRT_Cell<User, string>;
  row: MRT_Row<User>;
}

const UserInvitationStatusColumnCell = ({
  cell,
}: UserInvitationStatusColumnCellProps) => {
  const status = cell.getValue();
  const { classes } = useStyles();
  const visibleText =
    cell.getValue() !== DEFAULT_EMPTY_VALUE
      ? translateText(`user:InvitationStatuses.${status}`)
      : DEFAULT_EMPTY_VALUE;
  return (
    <Box display="flex" data-testid="user-list-invitation-status-column">
      <Box
        className={`${status}-invitationStatus ${classes.invitationcellWrapper}`}
      >
        {visibleText}
      </Box>
    </Box>
  );
};

export default UserInvitationStatusColumnCell;

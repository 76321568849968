import { SvgIcon, Typography } from '@mui/material';

import gmEnvolveLogo from '~/assets/images/login/gmEnvolveLogoV2.svg?react';

import useStyles from './InvalidInviteTemplate.style';

export interface InvalidInviteTemplateProps {
  header: string;
  message: string;
}

const InvalidInviteTemplate = (
  props: InvalidInviteTemplateProps
): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.templateWrapper}>
      <div className={classes.templateLeftCol} />
      <div className={classes.templateRightCol}>
        <div className={classes.templateContent}>
          <div className={classes.companyLogo}>
            <SvgIcon component={gmEnvolveLogo} viewBox="0 0 199 48"></SvgIcon>
          </div>
          <div className={classes.messageContainer}>
            <Typography
              variant="header1_medium"
              className={classes.messageHeader}
            >
              {props.header}
            </Typography>
            <Typography variant={'body_regular'}>{props.message}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidInviteTemplate;

import { BDContainer, BDPageSpinner } from '@brightdrop/bd-ui';
import { Box, Container } from '@mui/material';
import { lazy, Suspense } from 'react';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { WarningExcludedPaths } from '~/app/appContent/AppContent.constants';
import { AlertMessage } from '~/common/components';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { Routes } from '~/common/configs/route.config';
import { AppNames } from '~/common/models/appNames.model';
import { AppRoutePaths } from '~/common/models/route.model';
import { getNavDisabledPaths } from '~/common/utils/route/route.utils';
import InactiveUserCountdownModalWrapper from '~/features/inactivityTracker/InactiveUserCountdownModalWrapper';
import CancelledInvitePage from '~/features/invalidInvitePages/CancelledInvitePage';
import ExpiredInvitePage from '~/features/invalidInvitePages/ExpiredInvitePage';
import { getAppNameFromPath } from '~/features/navigationDrawer/config/navDrawer.config';
import ProtectYourAccountModalWrapper from '~/features/protectYourAccount/ProtectYourAccountModalWrapper';
import { DEFAULT_AUTHENTICATION_TYPE } from '~/features/session/model/authentication.model';

import SecureRoute from '../../features/secureRoute/SecureRoute';
import AuthTokenWrapper from '../../features/session/AuthTokenWrapper';
import InboundRedirect from '../../features/session/login/InboundRedirect';
import RouteErrorBoundary from '../errorFallback/RouteErrorBoundary';

const Auth = lazy(() => import('../../features/session/Auth'));

const Support = lazy(() => import('mf-support/SupportView'));
const BusinessEnrollmentApp = lazy(
  () => import('mf-business-enrollment/BusinessEnrollmentApp')
);

const AppContent = (): JSX.Element => {
  const { pathname, search } = useLocation();

  const appName = getAppNameFromPath(pathname);
  const onAuthPage = appName === AppNames.AUTH;

  // Certain content-only or flow-specific views should not display global nav components
  const matchUnsupportedRoute = matchPath(pathname, {
    path: getNavDisabledPaths(),
  });
  const suppressNavigation = !!matchUnsupportedRoute;

  //pages to exclude showing the Protect Your Account Warning
  const onWarningExcludedPage =
    Object.values(WarningExcludedPaths).some(
      (AppRoutePath) => AppRoutePath === pathname
    ) ||
    pathname === '' ||
    onAuthPage;

  return (
    <BDContainer id="fleet-management">
      {!onAuthPage && <AuthTokenWrapper />}
      <Container maxWidth={false} className="contentContainer">
        <Box
          flex="1"
          className={`${suppressNavigation ? '' : 'contentWrapper'} ${onAuthPage ? 'noPagePadding' : ''}`}
          position="relative"
        >
          <Suspense fallback={<BDPageSpinner />}>
            <RouteErrorBoundary>
              <Switch>
                <Route exact path="/" component={InboundRedirect} />
                <Route exact path={AppRoutePaths.LOGIN}>
                  <Redirect
                    to={`/auth/${DEFAULT_AUTHENTICATION_TYPE}/login/${search}`}
                  />
                </Route>
                {/*Adding simple redirect from sso to the auth/sso/login page*/}
                {/* Adding login to below url will result auth/sso being added to the url twice due to redirect above*/}
                <Route exact path="/sso">
                  <Redirect to={'auth/sso'} />
                </Route>
                <Route path="/auth" component={Auth} />
                {Routes.map((route, index) =>
                  route.secure ? (
                    <SecureRoute key={index} exact {...route} />
                  ) : (
                    <Route key={index} exact {...route} />
                  )
                )}
                <SecureRoute
                  exact
                  path={AppRoutePaths.MOBILE_APP_REQUIRED}
                  authorizedPerms={[]}
                  render={() => (
                    <AlertMessage
                      header={translateText(
                        'common:alertMsg.messageView.mobileAppRequired.header'
                      )}
                      body={translateText(
                        'common:alertMsg.messageView.mobileAppRequired.body'
                      )}
                      showGraphic
                      topic="mobileAppRequired"
                      id="mobile-app-required"
                    />
                  )}
                ></SecureRoute>
                <Route path={AppRoutePaths.LOGOUT}>
                  <Redirect
                    to={`/auth/${DEFAULT_AUTHENTICATION_TYPE}/logout`}
                  />
                </Route>
                <Route
                  exact
                  path={AppRoutePaths.PAGE_NOT_FOUND}
                  render={() => (
                    <AlertMessage
                      header={translateText(
                        'common:alertMsg.messageView.pageNotFound.header'
                      )}
                      linkBtn={translateText(
                        'common:alertMsg.messageView.pageNotFound.button'
                      )}
                      topic="pageNotFound"
                      id="page-not-found"
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={AppRoutePaths.UNAUTHORIZED}
                  render={() => (
                    <AlertMessage
                      header={translateText(
                        'common:alertMsg.messageView.unauthorized.header'
                      )}
                      body={translateText(
                        'common:alertMsg.messageView.unauthorized.body'
                      )}
                      linkBtn={translateText(
                        'common:alertMsg.messageView.unauthorized.button'
                      )}
                      topic="unauthorizedError"
                      id="unauthorized-access"
                    />
                  )}
                ></Route>

                <Route
                  exact
                  path={AppRoutePaths.SERVICE_UNAVAILABLE}
                  render={() => (
                    <AlertMessage
                      header={translateText(
                        'common:alertMsg.messageView.serviceUnavailable.header'
                      )}
                      body={translateText(
                        'common:alertMsg.messageView.serviceUnavailable.body'
                      )}
                      linkBtn={translateText(
                        'common:alertMsg.messageView.serviceUnavailable.button'
                      )}
                      topic="serviceUnavailable"
                      id="service-unavailable"
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={AppRoutePaths.CANCELLED_INVITE}
                  component={CancelledInvitePage}
                />
                <Route
                  exact
                  path={AppRoutePaths.EXPIRED_INVITE}
                  component={ExpiredInvitePage}
                />
                <Route path={AppRoutePaths.SUPPORT} component={Support} />
                <Route
                  path={AppRoutePaths.BUSINESS_ENROLLMENT}
                  component={BusinessEnrollmentApp}
                />
                <Redirect to={AppRoutePaths.PAGE_NOT_FOUND} />
              </Switch>
              {!onWarningExcludedPage && <ProtectYourAccountModalWrapper />}
              <InactiveUserCountdownModalWrapper />
            </RouteErrorBoundary>
          </Suspense>
        </Box>
      </Container>
    </BDContainer>
  );
};
export default AppContent;

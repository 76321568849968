import { AppNames } from '~/common/models/appNames.model';
import { FLEET_VEHICLE_LIST } from '~/common/models/pages/fleetPages.model';
import { AppRoutePaths, RouteName } from '~/common/models/route.model';
import { findRoute } from '~/common/utils/route/route.utils';

// Apps where FleetSwitcher is visible
export const SUPPORTED_APP_NAMES = [AppNames.FLEET];
type SupportedAppNames = (typeof SUPPORTED_APP_NAMES)[number];

// Routes where FleetSwitcher is enabled
export const ENABLED_ROUTES: Partial<Record<SupportedAppNames, RouteName[]>> = {
  [AppNames.FLEET]: [
    RouteName.ASSET_LIST_REPORT,
    RouteName.CS_PLATFORM_DASHBOARD,
  ],
};

// Exclude Advisor-only paths only when search string is empty
const ADVISOR_PATHS = [AppRoutePaths.ASSET_LIST, FLEET_VEHICLE_LIST];

export const getFleetSwitcherSupportedRoutes = (search: string): string[] => {
  const hasOrgParam = new URLSearchParams(search).has('organizationsId');
  return Object.values(ENABLED_ROUTES)
    .flat()
    .map((routeName) => findRoute(routeName))
    .reduce((paths, route) => {
      if (hasOrgParam) {
        /*
         * FleetSwitcher should theoretically be able to support
         * any route with an organizationsId param, but separate logic
         * will need to ensure the param is used to fetch the org children
         */
        return paths.concat(route.path || []);
      }
      if (
        typeof route.path === 'string' &&
        !ADVISOR_PATHS.includes(route.path)
      ) {
        return paths.concat(route.path || []);
      }
      if (typeof route.path === 'object' && route.path.filter) {
        return paths.concat(
          route.path.filter(
            (path) => typeof path === 'string' && !ADVISOR_PATHS.includes(path)
          )
        );
      }
      return paths;
    }, new Array<string>());
};

import {
  ActionButtonConfig,
  ActionButtons,
  BDAlert,
  BDAlerts,
  DialogResultStatus,
  Flex,
  IconButton,
} from '@brightdrop/bd-ui';
import { cx } from '@emotion/css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, useMediaQuery } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

import useStyles from './BDDialog.styles';

export interface DialogTitleProps {
  id: string;
  title: ReactNode | string;
  onClose: () => void;
  labels?: {
    close?: string;
  };
  disableClose?: boolean;
  extendedMobile?: boolean;
}

const DialogTitle = (props: DialogTitleProps) => {
  const { onClose, title, disableClose, extendedMobile, ...other } = props;
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();
  return (
    <MuiDialogTitle {...other} variant="header1_medium">
      {extendedMobile ? (
        <Grid container>
          {isMobileView && !disableClose && (
            <Grid item xs={1.5}>
              <IconButton
                aria-label={props.labels?.close}
                className={classes.dialogClose}
                onClick={onClose}
                size="medium"
                data-testid={`${props.id}-close-button`}
                icon={<ArrowBackIcon data-testid="close-dialog-arrow" />}
                variant="fill"
              />
            </Grid>
          )}

          <Grid item xs={disableClose ? 12 : 9} md={10.5}>
            <Flex center={isMobileView} className={classes.dialogTitle}>
              {title}
            </Flex>
          </Grid>

          {!isMobileView && !disableClose && (
            <Grid item xs={1.5}>
              <Flex className={classes.rightClose}>
                {!disableClose && (
                  <IconButton
                    aria-label={props.labels?.close}
                    className={classes.dialogClose}
                    onClick={onClose}
                    size="medium"
                    data-testid={`${props.id}-close-button`}
                    icon={<CloseIcon data-testid="x-close-icon" />}
                    variant="fill"
                  />
                )}
              </Flex>
            </Grid>
          )}
        </Grid>
      ) : (
        <>
          <div className={classes.dialogTitle}>{title}</div>
          <div className={classes.closeWrapper}>
            {!disableClose ? (
              <IconButton
                aria-label={props.labels?.close}
                className={classes.dialogClose}
                onClick={onClose}
                size="medium"
                data-testid={`${props.id}-close-button`}
                icon={<CloseIcon data-testid="x-close-icon" />}
                variant="fill"
              />
            ) : null}
          </div>
        </>
      )}
    </MuiDialogTitle>
  );
};
export interface DialogMessage {
  message: string;
  severity: AlertColor;
}

export interface DialogResult<T = never> {
  status: DialogResultStatus;
  entity?: T;
}

export interface DialogComponentProps<T = never> {
  onCloseModal: (isSuccess: boolean) => void;
  setAlerts: (
    alerts: BDAlert[] | ((existingAlerts: BDAlert[]) => BDAlert[]),
    replace?: boolean
  ) => void;
  disableSubmit?: boolean;
  entity?: T;
  entityId?: string;
  onResult?: (result: DialogResult<T>) => void;
  useLegacyAssign?: boolean;
  disableEntityFields?: boolean;
}

export interface DialogWrapperProps<T> {
  openModal: boolean;
  onCloseModal: (isSuccess: boolean) => void;
  component: React.ComponentType<DialogComponentProps<T>>;
  dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  disableSubmit?: boolean;
  disableClose?: boolean;
  entity?: T;
  entityId?: string;
  title?: ReactNode | string;
  onResult?: (result: DialogResult<T>) => void;
  buttonsConfig?: ActionButtonConfig[];
  useLegacyAssign?: boolean;
  disableEntityFields?: boolean;
  labels?: {
    aria?: string;
    close?: string;
  };
  extendedMobileView?: boolean;
  className?: string;
}

const BDDialog = <T,>({
  openModal,
  onCloseModal,
  component: DialogComponent,
  dialogSize,
  disableSubmit,
  disableClose,
  entity,
  entityId,
  title,
  onResult,
  buttonsConfig,
  useLegacyAssign,
  disableEntityFields,
  labels,
  extendedMobileView,
  className,
}: DialogWrapperProps<T>): JSX.Element => {
  const { classes } = useStyles();
  const errorScrollViewRef = useRef<HTMLInputElement>(null);
  const [dialogAlerts, setDialogAlerts] = useState<BDAlert[]>([]);

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const closeModal = useCallback(
    (success: boolean) => {
      setDialogAlerts([]);
      onCloseModal(success);
    },
    [onCloseModal]
  );

  const handleSetAlerts = useCallback(
    (
      newAlerts: BDAlert[] | ((existingAlerts: BDAlert[]) => BDAlert[]),
      replace = true
    ) => {
      setDialogAlerts((dialogAlerts) => {
        const alerts =
          typeof newAlerts === 'function' ? newAlerts(dialogAlerts) : newAlerts;
        return replace ? [...alerts] : [...dialogAlerts, ...alerts];
      });
      if (errorScrollViewRef.current) {
        errorScrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [setDialogAlerts]
  );

  return (
    <>
      <Dialog
        id="dialog-form"
        open={openModal}
        aria-label={labels?.aria}
        className={cx(
          { [classes.extendedMobileView]: extendedMobileView },
          className
        )}
        fullWidth={true}
        fullScreen={extendedMobileView && isMobileView}
        maxWidth={dialogSize || 'md'}
        scroll="paper"
        onClose={(event, reason) => {
          if (reason === 'escapeKeyDown') closeModal(false);
          if (reason === 'backdropClick') closeModal(false);
        }}
      >
        <div id="errorScrollView" ref={errorScrollViewRef}></div>
        {title && (
          <DialogTitle
            id="dialog-title"
            title={title}
            onClose={() => closeModal(false)}
            data-testid="dialog-title"
            disableClose={disableClose}
            labels={labels}
            extendedMobile={extendedMobileView}
          />
        )}
        <DialogContent className={classes.dialogContent}>
          {dialogAlerts && dialogAlerts.length > 0 && (
            <BDAlerts alerts={dialogAlerts} />
          )}

          <DialogComponent
            onCloseModal={closeModal}
            entity={entity}
            entityId={entityId}
            disableSubmit={disableSubmit}
            setAlerts={handleSetAlerts}
            onResult={onResult}
            useLegacyAssign={useLegacyAssign}
            disableEntityFields={disableEntityFields}
          />
        </DialogContent>
        {buttonsConfig && (
          <DialogActions className={classes.dialogActions}>
            <ActionButtons buttons={buttonsConfig} />
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default BDDialog;

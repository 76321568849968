import { makeStyles } from 'tss-react/mui';

import loginBackground from '~/assets/images/login/bgImageV3.jpg';

const useStyles = makeStyles()((theme) => ({
  templateWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    margin: '0 auto',
  },

  templateLeftCol: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      height: '100vh',
      display: 'block',
      backgroundImage: `url("${loginBackground}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
    },
  },

  templateRightCol: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      paddingInlineEnd: '20px',
      width: '50%',
    },
  },

  templateContent: {
    padding: '24px',
    height: '100%',
    maxWidth: '500px',
    width: '100%',
  },

  companyLogo: {
    padding: '0 0 4px',
    '& svg': {
      width: '200px',
      height: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: '8px',
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
    '@media (max-height: 400px)': {
      display: 'none',
    },
  },

  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      height: '60%',
    },
  },

  messageHeader: {
    paddingBottom: '16px',
  },
}));

export default useStyles;

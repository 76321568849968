import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/app/rootReducer';
import { Trip, TripEvent } from '~/common/models/trip.model';

export const selectTripHistoryState = (state: RootState) => state.tripHistory;

export const selectTrips = createSelector(
  selectTripHistoryState,
  (state) => state.trips
);

export const selectSelectedTripId = createSelector(
  selectTripHistoryState,
  (state) => state.selectedTripId
);

export const selectSelectedTrip = createSelector(
  [selectTrips, selectSelectedTripId],
  (trips, selectedTripId) =>
    selectedTripId
      ? trips.find((trip: Trip) => trip.id === selectedTripId)
      : null
);

export const selectSelectedEventId = createSelector(
  selectTripHistoryState,
  (state) => state.selectedEventId
);

export const selectSelectedEvent = createSelector(
  [selectSelectedTrip, selectSelectedEventId],
  (selectedTrip, selectedEventId) => {
    if (!selectedTrip) return null;
    return selectedTrip.events.find(
      (event: TripEvent) => event.eventId === selectedEventId
    );
  }
);

export const selectTripOperationStatus = createSelector(
  selectTripHistoryState,
  (state) => state.operationStatus
);

export const selectTripById = createSelector(
  [selectTrips, (_: RootState, tripId?: string) => tripId],
  (trips, tripId) => trips.find((trip: Trip) => trip.id === tripId)
);

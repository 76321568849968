import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  SafetyEventType,
  Trip,
  TripEvent,
  TripEventsApiResponse,
  TripsApiResponse,
} from '~/common/models/trip.model';

import { X_COORDINATE, Y_COORDINATE } from '../constants/tripHistory.constants';

export const mapTripDetails = (response: TripsApiResponse): Trip[] => {
  const { data: details } = response;

  const trips: Trip[] = details.map((detail) => {
    const startLocation = detail.trip.tripStartLocation.split(' ').map(Number);
    const endLocation = detail.trip.tripEndLocation.split(' ').map(Number);

    return {
      id: detail.trip.tripId.toString(),
      vin: detail.vehicle.vin,
      vehicleName: detail.vehicle.vehicleNumber,
      startTime: detail.trip.tripStartTime,
      endTime: detail.trip.tripEndTime,
      startLocation: {
        coordinates: [startLocation[Y_COORDINATE], startLocation[X_COORDINATE]], // Mapbox coordinates format is [longitude, latitude]
        address: DEFAULT_EMPTY_VALUE, // No address returned from Netradyne API Get Trip Details endpoint
      },
      endLocation: {
        coordinates: [endLocation[Y_COORDINATE], endLocation[X_COORDINATE]], // Mapbox coordinates format is [longitude, latitude]
        address: DEFAULT_EMPTY_VALUE, // No address returned from Netradyne API Get Trip Details endpoint
      },
      duration: detail.trip.drivingDuration,
      distance: detail.trip.milesDriven,
      idleTime: detail.trip.idleDuration,
      events: [],
      safetyEventCount: 0,
    };
  });

  return trips;
};

export const mapTripEvents = (response: TripEventsApiResponse): TripEvent[] => {
  const { alerts: events } = response.data;

  const tripEvents: TripEvent[] = events.map((event) => {
    return {
      eventId: event.id.toString(),
      eventType: event.details.typeDescription as SafetyEventType,
      timestamp: event.timestamp?.toString(),
      vin: event.vehicle.vin,
      location: {
        coordinates: [event.gpsData[0].longitude, event.gpsData[0].latitude], // Mapbox coordinates format is [longitude, latitude]
        address: event.details.location?.address, // No address returned from Netradyne API Get Trip Events endpoint
      },
      speed: event.speedData?.speed,
      duration: event.duration?.toString(),
      videoStatus: event.videos.map((video) => {
        return {
          position: video.position,
        };
      }),
    };
  });

  return tripEvents;
};

import { lazy } from 'react';

import { LEGAL_PAGE_FLAG } from '~/common/models/featureFlags.model';
import { ACCOUNT_LEGAL_VIEW_PATH } from '~/common/models/pages/accountPages.model';
import { RouteName } from '~/common/models/route.model';

const LegalView = lazy(() => import('~/features/legal/LegalView'));

export const ACCOUNT_ROUTES = {
  [RouteName.LEGAL]: {
    name: RouteName.LEGAL,
    path: [ACCOUNT_LEGAL_VIEW_PATH],
    authorizedPerms: [],
    secure: true,
    component: LegalView,
    requiredFeatureFlags: [LEGAL_PAGE_FLAG],
  },
};

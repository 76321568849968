import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
  'Asset',
  'asset-controller',
  'admin-controller',
  'vehicle-boarding-workflow-session-controller',
  'batch-boarding-vehicles-controller',
  'asset-view-controller',
  'vehicle-field-action-view-controller',
  'vehicles-ownership-controller',
  'asset-history-controller',
  'utilization-controller',
  'event-subscriptions-controller',
  'azure-ai-search-controller',
  'remote-command-controller',
  'vehicle-boarding-profile-controller',
  'asset-location-history-controller',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      get20211115ById: build.query<
        Get20211115ByIdApiResponse,
        Get20211115ByIdApiArg
      >({
        query: (queryArg) => ({ url: `/2021-11-15/${queryArg.id}` }),
        providesTags: ['Asset'],
      }),
      updateAsset: build.mutation<UpdateAssetApiResponse, UpdateAssetApiArg>({
        query: (queryArg) => ({
          url: `/2021-11-15/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['asset-controller'],
      }),
      deleteAsset: build.mutation<DeleteAssetApiResponse, DeleteAssetApiArg>({
        query: (queryArg) => ({
          url: `/2021-11-15/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['asset-controller'],
      }),
      enrollAssetInDataTelemetry: build.mutation<
        EnrollAssetInDataTelemetryApiResponse,
        EnrollAssetInDataTelemetryApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/admin/telemetry/${queryArg.pathAssetId}`,
          method: 'PUT',
          headers: { assetId: queryArg.headerAssetId },
        }),
        invalidatesTags: ['admin-controller'],
      }),
      terminateVehicleBoardingSession: build.mutation<
        TerminateVehicleBoardingSessionApiResponse,
        TerminateVehicleBoardingSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-03-26/boarding/vehicles/${queryArg.vin}/sessions/${queryArg.sessionId}/terminate`,
          method: 'POST',
        }),
        invalidatesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      batchOffBoardVehicles: build.mutation<
        BatchOffBoardVehiclesApiResponse,
        BatchOffBoardVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-03-11/boarding/vehicles/offboard`,
          method: 'POST',
          body: queryArg.body,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
          },
          params: {
            remoteProvider: queryArg.remoteProvider,
            telemetryProvider: queryArg.telemetryProvider,
          },
        }),
        invalidatesTags: ['batch-boarding-vehicles-controller'],
      }),
      refreshVehicleData: build.mutation<
        RefreshVehicleDataApiResponse,
        RefreshVehicleDataApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-25/admin/vehicles/refresh`,
          method: 'POST',
          body: queryArg.vehicleDataRefreshRequest,
        }),
        invalidatesTags: ['admin-controller'],
      }),
      triggerAddCapabilitiesEvent: build.mutation<
        TriggerAddCapabilitiesEventApiResponse,
        TriggerAddCapabilitiesEventApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-25/admin/triggerAssetCapabilitiesEvent`,
          method: 'POST',
          body: queryArg.body,
          headers: {
            organizationId: queryArg.organizationId,
            'cs-organization': queryArg['cs-organization'],
          },
          params: {
            assetIds: queryArg.assetIds,
            batchSize: queryArg.batchSize,
          },
        }),
        invalidatesTags: ['admin-controller'],
      }),
      getAssetBatteryReadinessV20250211: build.mutation<
        GetAssetBatteryReadinessV20250211ApiResponse,
        GetAssetBatteryReadinessV20250211ApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-11/views/batteryReadinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
          headers: {
            organizationId: queryArg.organizationId,
            hubId: queryArg.hubId,
            fleetId: queryArg.fleetId,
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-hub': queryArg['x-bd-hub'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
          },
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      exportAssetReadinessCsv: build.mutation<
        ExportAssetReadinessCsvApiResponse,
        ExportAssetReadinessCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-28/views/readinessReport/export`,
          method: 'POST',
          body: queryArg.bdPageRequest,
          headers: {
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-hub': queryArg['x-bd-hub'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
          },
          params: { requestBody: queryArg.requestBody },
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      exportAssetFluidReadinessCsv: build.mutation<
        ExportAssetFluidReadinessCsvApiResponse,
        ExportAssetFluidReadinessCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-28/views/fluidReadinessReport/export`,
          method: 'POST',
          body: queryArg.bdPageRequest,
          headers: {
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-hub': queryArg['x-bd-hub'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
          },
          params: { requestBody: queryArg.requestBody },
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      exportAssetBatteryReadinessCsv: build.mutation<
        ExportAssetBatteryReadinessCsvApiResponse,
        ExportAssetBatteryReadinessCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-28/views/batteryReadinessReport/export`,
          method: 'POST',
          body: queryArg.bdPageRequest,
          headers: {
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-hub': queryArg['x-bd-hub'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
          },
          params: { requestBody: queryArg.requestBody },
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      batchOnboardVehiclesV2: build.mutation<
        BatchOnboardVehiclesV2ApiResponse,
        BatchOnboardVehiclesV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-14/boarding/vehicles/onboard`,
          method: 'POST',
          body: queryArg.body,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
          },
          params: {
            remoteProvider: queryArg.remoteProvider,
            telemetryProvider: queryArg.telemetryProvider,
          },
        }),
        invalidatesTags: ['batch-boarding-vehicles-controller'],
      }),
      triggerEvent: build.mutation<TriggerEventApiResponse, TriggerEventApiArg>(
        {
          query: (queryArg) => ({
            url: `/2025-01-01/triggerAssetCreationEvent`,
            method: 'POST',
            body: queryArg.body,
            params: { assetIds: queryArg.assetIds },
          }),
          invalidatesTags: ['asset-controller'],
        }
      ),
      getActiveFieldActionVehicleSummary: build.mutation<
        GetActiveFieldActionVehicleSummaryApiResponse,
        GetActiveFieldActionVehicleSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-12-04/fieldactions/vehicleSummary`,
          method: 'POST',
          body: queryArg.body,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: {
            vehicleFieldActionSummaryRequest:
              queryArg.vehicleFieldActionSummaryRequest,
          },
        }),
        invalidatesTags: ['vehicle-field-action-view-controller'],
      }),
      getAssetReadinessV2: build.mutation<
        GetAssetReadinessV2ApiResponse,
        GetAssetReadinessV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-12-03/views/readinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
          headers: {
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-hub': queryArg['x-bd-hub'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
          },
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      rejectVehicleProofOfOwnership: build.mutation<
        RejectVehicleProofOfOwnershipApiResponse,
        RejectVehicleProofOfOwnershipApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-11-11/onboarding/vehicles/ownership/reject`,
          method: 'POST',
          body: queryArg.vehicleOwnershipOperationBatchRequest,
        }),
        invalidatesTags: ['vehicles-ownership-controller'],
      }),
      confirmVehicleProofOfOwnership: build.mutation<
        ConfirmVehicleProofOfOwnershipApiResponse,
        ConfirmVehicleProofOfOwnershipApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-11-11/onboarding/vehicles/ownership/confirm`,
          method: 'POST',
          body: queryArg.vehicleOwnershipOperationBatchRequest,
        }),
        invalidatesTags: ['vehicles-ownership-controller'],
      }),
      getFieldActionSummaryReport: build.mutation<
        GetFieldActionSummaryReportApiResponse,
        GetFieldActionSummaryReportApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-10-23/fieldactions/vehicleFieldActionReport`,
          method: 'POST',
          body: queryArg.vehicleFieldActionReportRequest,
          headers: {
            'x-bd-organization': queryArg['x-bd-organization'],
            'x-bd-fleet': queryArg['x-bd-fleet'],
            'x-bd-hub': queryArg['x-bd-hub'],
          },
        }),
        invalidatesTags: ['vehicle-field-action-view-controller'],
      }),
      enrollAssetsInTelemetry: build.mutation<
        EnrollAssetsInTelemetryApiResponse,
        EnrollAssetsInTelemetryApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-10-22/admin/telemetry`,
          method: 'POST',
          body: queryArg.body,
          params: { csvVins: queryArg.csvVins },
        }),
        invalidatesTags: ['admin-controller'],
      }),
      getAssetWithEnhancedDiagnosticReadiness: build.mutation<
        GetAssetWithEnhancedDiagnosticReadinessApiResponse,
        GetAssetWithEnhancedDiagnosticReadinessApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-10-08/views/readinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      batchOnboardVehicles: build.mutation<
        BatchOnboardVehiclesApiResponse,
        BatchOnboardVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-10/boarding/vehicles/onboard`,
          method: 'POST',
          body: queryArg.body,
          params: {
            remoteProvider: queryArg.remoteProvider,
            telemetryProvider: queryArg.telemetryProvider,
            organizationId: queryArg.organizationId,
          },
        }),
        invalidatesTags: ['batch-boarding-vehicles-controller'],
      }),
      getAssetsByPage: build.mutation<
        GetAssetsByPageApiResponse,
        GetAssetsByPageApiArg
      >({
        query: (queryArg) => ({
          url: `/2023-03-14/views/enhancements`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      getAssetHistoryByPage: build.mutation<
        GetAssetHistoryByPageApiResponse,
        GetAssetHistoryByPageApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-05-24/${queryArg.assetId}/history`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-history-controller'],
      }),
      getUtilizationV2: build.mutation<
        GetUtilizationV2ApiResponse,
        GetUtilizationV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-05-24/utilizations`,
          method: 'POST',
          body: queryArg.utilizationRequest,
        }),
        invalidatesTags: ['utilization-controller'],
      }),
      getUtilizationDisaggregatedByAsset: build.mutation<
        GetUtilizationDisaggregatedByAssetApiResponse,
        GetUtilizationDisaggregatedByAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-05-24/utilizations/disaggregatedByAsset`,
          method: 'POST',
          body: queryArg.utilizationRequest,
        }),
        invalidatesTags: ['utilization-controller'],
      }),
      doSubscribeV2: build.mutation<
        DoSubscribeV2ApiResponse,
        DoSubscribeV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-05-24/eventsubscriptions`,
          method: 'POST',
          body: queryArg.eventSubscriptionRequestV2,
        }),
        invalidatesTags: ['event-subscriptions-controller'],
      }),
      getAllAssets: build.query<GetAllAssetsApiResponse, GetAllAssetsApiArg>({
        query: (queryArg) => ({
          url: `/2021-11-15`,
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-controller'],
      }),
      createAsset: build.mutation<CreateAssetApiResponse, CreateAssetApiArg>({
        query: (queryArg) => ({
          url: `/2021-11-15`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['asset-controller'],
      }),
      transitionState: build.mutation<
        TransitionStateApiResponse,
        TransitionStateApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/${queryArg.id}/states/${queryArg.state}`,
          method: 'POST',
          body: queryArg.changeStateRequestJson,
        }),
        invalidatesTags: ['asset-controller'],
      }),
      getAssetWithTireReadiness: build.mutation<
        GetAssetWithTireReadinessApiResponse,
        GetAssetWithTireReadinessApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/tireReadinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      getAssetWithReadiness: build.mutation<
        GetAssetWithReadinessApiResponse,
        GetAssetWithReadinessApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/readinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      getComplianceReport: build.mutation<
        GetComplianceReportApiResponse,
        GetComplianceReportApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/complianceReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      getAssetWithBatteryReadiness: build.mutation<
        GetAssetWithBatteryReadinessApiResponse,
        GetAssetWithBatteryReadinessApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/batteryReadinessReport`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        invalidatesTags: ['asset-view-controller'],
      }),
      searchAssets: build.mutation<SearchAssetsApiResponse, SearchAssetsApiArg>(
        {
          query: (queryArg) => ({
            url: `/2021-11-15/searches`,
            method: 'POST',
            body: queryArg.bdPageRequest,
          }),
          invalidatesTags: ['asset-controller'],
        }
      ),
      updateProtectedFieldsForAsset: build.mutation<
        UpdateProtectedFieldsForAssetApiResponse,
        UpdateProtectedFieldsForAssetApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/admin/${queryArg.pathAssetId}`,
          method: 'POST',
          body: queryArg.protectedFieldsUpdateRequest,
          headers: { assetId: queryArg.headerAssetId },
          params: { request: queryArg.request },
        }),
        invalidatesTags: ['admin-controller'],
      }),
      getVehiclesBasicDetails: build.query<
        GetVehiclesBasicDetailsApiResponse,
        GetVehiclesBasicDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-04-08/views/vehiclesBasicDetails`,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
            'cs-continuation-token': queryArg['cs-continuation-token'],
          },
          params: {
            size: queryArg.size,
            vins: queryArg.vins,
            name: queryArg.name,
            licensePlate: queryArg.licensePlate,
            sortBy: queryArg.sortBy,
            sortOrder: queryArg.sortOrder,
          },
        }),
        providesTags: ['asset-view-controller'],
      }),
      listPendingConnectionVehicles: build.query<
        ListPendingConnectionVehiclesApiResponse,
        ListPendingConnectionVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-03-25/views/pendingConnectionVehicles`,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
            'cs-continuation-token': queryArg['cs-continuation-token'],
          },
          params: { size: queryArg.size, vin: queryArg.vin },
        }),
        providesTags: ['asset-view-controller'],
      }),
      searchAsset: build.query<SearchAssetApiResponse, SearchAssetApiArg>({
        query: (queryArg) => ({
          url: `/2025-03-11-BETA/search`,
          params: {
            searchText: queryArg.searchText,
            top: queryArg.top,
            skip: queryArg.skip,
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
          },
        }),
        providesTags: ['azure-ai-search-controller'],
      }),
      listIncompleteOnboardingVehicles: build.query<
        ListIncompleteOnboardingVehiclesApiResponse,
        ListIncompleteOnboardingVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-25/boarding/vehicles/incomplete`,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
            'cs-continuation-token': queryArg['cs-continuation-token'],
          },
          params: {
            size: queryArg.size,
            vin: queryArg.vin,
            reason: queryArg.reason,
          },
        }),
        providesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      getAssetsList: build.query<GetAssetsListApiResponse, GetAssetsListApiArg>(
        {
          query: (queryArg) => ({
            url: `/2025-02-11/views/vehicleList`,
            headers: {
              'cs-organization': queryArg['cs-organization'],
              'cs-hub': queryArg['cs-hub'],
              'cs-fleet': queryArg['cs-fleet'],
              'cs-continuation-token': queryArg['cs-continuation-token'],
            },
            params: {
              size: queryArg.size,
              propulsionTypes: queryArg.propulsionTypes,
              vin: queryArg.vin,
              name: queryArg.name,
              licensePlate: queryArg.licensePlate,
            },
          }),
          providesTags: ['asset-view-controller'],
        }
      ),
      getVehicleIgnitionStatus: build.query<
        GetVehicleIgnitionStatusApiResponse,
        GetVehicleIgnitionStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-11/views/vehicleIgnitionStatus/${queryArg.vin}`,
        }),
        providesTags: ['asset-view-controller'],
      }),
      getAssetStatusWithFiltering: build.query<
        GetAssetStatusWithFilteringApiResponse,
        GetAssetStatusWithFilteringApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-11/views/status/${queryArg.id}`,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-fleet': queryArg['cs-fleet'],
            'cs-hub': queryArg['cs-hub'],
          },
          params: { statuses: queryArg.statuses },
        }),
        providesTags: ['asset-view-controller'],
      }),
      listPendingOnboardingVehicles: build.query<
        ListPendingOnboardingVehiclesApiResponse,
        ListPendingOnboardingVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-02-11/boarding/vehicles/pending`,
          headers: {
            'cs-organization': queryArg['cs-organization'],
            'cs-hub': queryArg['cs-hub'],
            'cs-fleet': queryArg['cs-fleet'],
            'cs-continuation-token': queryArg['cs-continuation-token'],
          },
          params: {
            size: queryArg.size,
            status: queryArg.status,
            vin: queryArg.vin,
          },
        }),
        providesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      executeRemoteCommands: build.query<
        ExecuteRemoteCommandsApiResponse,
        ExecuteRemoteCommandsApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-14/remotecommands/${queryArg.commandType}/${queryArg.command}/${queryArg.vin}`,
        }),
        providesTags: ['remote-command-controller'],
      }),
      getChargeLevelDashboardSummary: build.query<
        GetChargeLevelDashboardSummaryApiResponse,
        GetChargeLevelDashboardSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-11-06/views/chargeLevelDashboardSummary`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getOilLifeDashboardSummary: build.query<
        GetOilLifeDashboardSummaryApiResponse,
        GetOilLifeDashboardSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-10-09/views/oilLifeDashboardSummary`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getFieldActionsForVin: build.query<
        GetFieldActionsForVinApiResponse,
        GetFieldActionsForVinApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-09-11/fieldactions/lookup/${queryArg.vin}`,
        }),
        providesTags: ['vehicle-field-action-view-controller'],
      }),
      getVehicleBoardingProfile: build.query<
        GetVehicleBoardingProfileApiResponse,
        GetVehicleBoardingProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-10/boarding/vehicles/${queryArg.vin}`,
        }),
        providesTags: ['vehicle-boarding-profile-controller'],
      }),
      getCurrentVehicleBoardingSession: build.query<
        GetCurrentVehicleBoardingSessionApiResponse,
        GetCurrentVehicleBoardingSessionApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-10/boarding/vehicles/${queryArg.vin}/sessions/current`,
        }),
        providesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      listVehicleBoardingSessions: build.query<
        ListVehicleBoardingSessionsApiResponse,
        ListVehicleBoardingSessionsApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-10/boarding/vehicleBoardingSessions`,
          params: {
            batchId: queryArg.batchId,
            token: queryArg.token,
            organizationId: queryArg.organizationId,
            size: queryArg.size,
            sessionType: queryArg.sessionType,
            vin: queryArg.vin,
          },
        }),
        providesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      getVehicleBoardingSessionBySessionId: build.query<
        GetVehicleBoardingSessionBySessionIdApiResponse,
        GetVehicleBoardingSessionBySessionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-10/boarding/vehicleBoardingSessions/${queryArg.sessionId}`,
        }),
        providesTags: ['vehicle-boarding-workflow-session-controller'],
      }),
      getAssetHistoryLimitedResponse: build.query<
        GetAssetHistoryLimitedResponseApiResponse,
        GetAssetHistoryLimitedResponseApiArg
      >({
        query: (queryArg) => ({ url: `/2023-12-08/${queryArg.id}/history` }),
        providesTags: ['asset-history-controller'],
      }),
      getOrganizationAssetCountSummary: build.query<
        GetOrganizationAssetCountSummaryApiResponse,
        GetOrganizationAssetCountSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/2023-11-22/views/assetCountSummary`,
          params: {
            organizationId: queryArg.organizationId,
            hubId: queryArg.hubId,
          },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getAssetByIdWithFan: build.query<
        GetAssetByIdWithFanApiResponse,
        GetAssetByIdWithFanApiArg
      >({
        query: (queryArg) => ({ url: `/2023-11-21/${queryArg.id}` }),
        providesTags: ['asset-controller'],
      }),
      getAssetHardwareIdWithFan: build.query<
        GetAssetHardwareIdWithFanApiResponse,
        GetAssetHardwareIdWithFanApiArg
      >({
        query: (queryArg) => ({
          url: `/2023-11-21/hardware/${queryArg.hardwareId}`,
        }),
        providesTags: ['asset-controller'],
      }),
      getAssetLocationHistoryGeoJson20231107: build.query<
        GetAssetLocationHistoryGeoJson20231107ApiResponse,
        GetAssetLocationHistoryGeoJson20231107ApiArg
      >({
        query: (queryArg) => ({
          url: `/2023-11-07/${queryArg.id}/location/geojson`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['asset-location-history-controller'],
      }),
      getComplianceReportV2: build.query<
        GetComplianceReportV2ApiResponse,
        GetComplianceReportV2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2023-03-14/views/complianceReport`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { params: queryArg.params },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getAssetLocationHistoryV20220524: build.query<
        GetAssetLocationHistoryV20220524ApiResponse,
        GetAssetLocationHistoryV20220524ApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-05-24/${queryArg.id}/location`,
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            page: queryArg.page,
            size: queryArg.size,
          },
        }),
        providesTags: ['asset-location-history-controller'],
      }),
      getAssetStatus: build.query<
        GetAssetStatusApiResponse,
        GetAssetStatusApiArg
      >({
        query: (queryArg) => ({ url: `/2021-11-15/${queryArg.id}/status` }),
        providesTags: ['asset-controller'],
      }),
      getAssetUtilization: build.query<
        GetAssetUtilizationApiResponse,
        GetAssetUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/${queryArg.assetId}/utilizations`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            timePeriod: queryArg.timePeriod,
            featureIdentifier: queryArg.featureIdentifier,
          },
        }),
        providesTags: ['utilization-controller'],
      }),
      getComplianceSummary: build.query<
        GetComplianceSummaryApiResponse,
        GetComplianceSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/safetyCompliance`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { hubId: queryArg.hubId, fleetId: queryArg.fleetId },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getDashboardMetrics: build.query<
        GetDashboardMetricsApiResponse,
        GetDashboardMetricsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/orgDashboard`,
          headers: {
            orgId: queryArg.orgId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getMapListItems: build.query<
        GetMapListItemsApiResponse,
        GetMapListItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/maplistitems`,
          headers: { continuationToken: queryArg.continuationToken },
          params: { filters: queryArg.filters, size: queryArg.size },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getAssetWithi8NDetails: build.query<
        GetAssetWithi8NDetailsApiResponse,
        GetAssetWithi8NDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/enhancements/${queryArg.id}`,
        }),
        providesTags: ['asset-view-controller'],
      }),
      getDiagnosticDashboardSummaryMetrics: build.query<
        GetDiagnosticDashboardSummaryMetricsApiResponse,
        GetDiagnosticDashboardSummaryMetricsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/diagnosticDashboardSummary`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getConnectivityStatusDashboardSummaryMetrics: build.query<
        GetConnectivityStatusDashboardSummaryMetricsApiResponse,
        GetConnectivityStatusDashboardSummaryMetricsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/views/connectivityStatusDashboardSummary`,
          headers: {
            orgId: queryArg.orgId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: { filters: queryArg.filters },
        }),
        providesTags: ['asset-view-controller'],
      }),
      getOrganizationUtilization: build.query<
        GetOrganizationUtilizationApiResponse,
        GetOrganizationUtilizationApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/utilizations`,
          headers: {
            organizationId: queryArg.organizationId,
            'x-bd-organization': queryArg['x-bd-organization'],
          },
          params: {
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            timePeriod: queryArg.timePeriod,
            vehicleListSize: queryArg.vehicleListSize,
            featureIdentifier: queryArg.featureIdentifier,
          },
        }),
        providesTags: ['utilization-controller'],
      }),
      getAssetHardwareId: build.query<
        GetAssetHardwareIdApiResponse,
        GetAssetHardwareIdApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/hardware/${queryArg.hardwareId}`,
        }),
        providesTags: ['asset-controller'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as assetsApi };
export type Get20211115ByIdApiResponse =
  /** status 200 OK */ GmResponseCommonAssetByIdDetailResponseJson;
export type Get20211115ByIdApiArg = {
  /** Asset's identification. This is typically the VIN for vehicles. */
  id: string;
};
export type UpdateAssetApiResponse =
  /** status 200 OK */ GmResponseCommonAssetResponseJson;
export type UpdateAssetApiArg = {
  /** Asset's identification. */
  id: string;
  body: UpdateVehicleJson | UpsertVehicleJson;
};
export type DeleteAssetApiResponse = /** status 200 OK */ GmResponseCommonEmpty;
export type DeleteAssetApiArg = {
  /** Asset's identification. */
  id: string;
};
export type EnrollAssetInDataTelemetryApiResponse =
  /** status 202 Success */ GmResponseCommonEmpty;
export type EnrollAssetInDataTelemetryApiArg = {
  /** Asset ID. */
  headerAssetId?: any;
  pathAssetId: string;
};
export type TerminateVehicleBoardingSessionApiResponse =
  /** status 200 OK */ GmResponseCommonBoolean;
export type TerminateVehicleBoardingSessionApiArg = {
  /** Boarding session ID */
  sessionId: string;
  vin: string;
};
export type BatchOffBoardVehiclesApiResponse =
  /** status 200 OK */ GmResponseCommonBatchBoardingResponse;
export type BatchOffBoardVehiclesApiArg = {
  /** The id of the organization. */
  'cs-organization': string;
  /** The ID of the hub. */
  'cs-hub': string;
  /** The ID of the fleet. */
  'cs-fleet': string;
  /** The provider of the asset remote commands. */
  remoteProvider?: string;
  /** The provider of the asset telemetry data. */
  telemetryProvider?: string;
  body: {
    file: Blob;
  };
};
export type RefreshVehicleDataApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleDataRefreshResponse;
export type RefreshVehicleDataApiArg = {
  vehicleDataRefreshRequest: VehicleDataRefreshRequest;
};
export type TriggerAddCapabilitiesEventApiResponse =
  /** status 200 OK */ GmResponseCommonEmpty;
export type TriggerAddCapabilitiesEventApiArg = {
  /** The organization ID. */
  organizationId?: any;
  /** List of asset ids to trigger add capability event */
  assetIds?: any;
  /** Batch size for processing the asset ids */
  batchSize?: number;
  'cs-organization': string;
  body: string[];
};
export type GetAssetBatteryReadinessV20250211ApiResponse =
  /** status 200 OK */ GmResponseCommonBatteryReadinessItemsResponsePageV20250211;
export type GetAssetBatteryReadinessV20250211ApiArg = {
  /** The organization ID. */
  organizationId?: any;
  /** The hub ID. */
  hubId?: any;
  /** The fleet ID. */
  fleetId?: any;
  'x-bd-organization': string;
  'x-bd-hub': string;
  'x-bd-fleet': string;
  bdPageRequest: BdPageRequest;
};
export type ExportAssetReadinessCsvApiResponse = /** status 200 OK */ string;
export type ExportAssetReadinessCsvApiArg = {
  /** The organization ID. */
  'x-bd-organization': string;
  /** The hub ID. */
  'x-bd-hub': string;
  /** The fleet ID. */
  'x-bd-fleet': string;
  /** The request body containing the filter criteria  */
  requestBody?: any;
  bdPageRequest: BdPageRequest;
};
export type ExportAssetFluidReadinessCsvApiResponse =
  /** status 200 OK */ string;
export type ExportAssetFluidReadinessCsvApiArg = {
  /** The organization ID. */
  'x-bd-organization': string;
  /** The hub ID. */
  'x-bd-hub': string;
  /** The fleet ID. */
  'x-bd-fleet': string;
  /** The request body containing the filter criteria  */
  requestBody?: any;
  bdPageRequest: BdPageRequest;
};
export type ExportAssetBatteryReadinessCsvApiResponse =
  /** status 200 OK */ string;
export type ExportAssetBatteryReadinessCsvApiArg = {
  /** The organization ID. */
  'x-bd-organization': string;
  /** The hub ID. */
  'x-bd-hub': string;
  /** The fleet ID. */
  'x-bd-fleet': string;
  /** The request body containing the filter criteria  */
  requestBody?: any;
  bdPageRequest: BdPageRequest;
};
export type BatchOnboardVehiclesV2ApiResponse =
  /** status 200 OK */ GmResponseCommonBatchBoardingResponse;
export type BatchOnboardVehiclesV2ApiArg = {
  /** The id of the organization. */
  'cs-organization': string;
  /** The ID of the hub. */
  'cs-hub': string;
  /** The ID of the fleet. */
  'cs-fleet': string;
  /** The provider of the asset remote commands. */
  remoteProvider?: string;
  /** The provider of the asset telemetry data. */
  telemetryProvider?: string;
  body: {
    file: Blob;
  };
};
export type TriggerEventApiResponse =
  /** status 200 OK */ GmResponseCommonEmpty;
export type TriggerEventApiArg = {
  /** List of asset ids to trigger asset creation event */
  assetIds?: any;
  body: string[];
};
export type GetActiveFieldActionVehicleSummaryApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleFieldActionSummaryResponse20241204;
export type GetActiveFieldActionVehicleSummaryApiArg = {
  /** Expected organization id for vehicle field action. */
  organizationId?: any;
  /** Request containing fleet id. */
  vehicleFieldActionSummaryRequest?: any;
  'x-bd-organization': string;
  body: VehicleFieldActionSummaryRequest;
};
export type GetAssetReadinessV2ApiResponse =
  /** status 200 OK */ GmResponseCommonAssetReadinessEnhancedDiagnosticsItemResponsePageV2;
export type GetAssetReadinessV2ApiArg = {
  'x-bd-organization': string;
  'x-bd-hub': string;
  'x-bd-fleet': string;
  bdPageRequest: BdPageRequest;
};
export type RejectVehicleProofOfOwnershipApiResponse =
  /** status 202 Accepted */ GmResponseCommonVehicleOwnershipOperationBatchResponse;
export type RejectVehicleProofOfOwnershipApiArg = {
  /** JSON payload containing a list of vehicle entries for which proof
    of ownership needs confirmation.
     */
  vehicleOwnershipOperationBatchRequest: VehicleOwnershipOperationBatchRequest;
};
export type ConfirmVehicleProofOfOwnershipApiResponse =
  /** status 202 Accepted */ GmResponseCommonVehicleOwnershipOperationBatchResponse;
export type ConfirmVehicleProofOfOwnershipApiArg = {
  /** JSON payload containing a list of vehicle entries for which proof
    of ownership needs confirmation.
     */
  vehicleOwnershipOperationBatchRequest: VehicleOwnershipOperationBatchRequest;
};
export type GetFieldActionSummaryReportApiResponse =
  /** status 200 OK */ GmResponseCommonPaginatedDataResponseVehicleFieldActionReportItem;
export type GetFieldActionSummaryReportApiArg = {
  'x-bd-organization': string;
  'x-bd-fleet': string;
  'x-bd-hub': string;
  vehicleFieldActionReportRequest: VehicleFieldActionReportRequest;
};
export type EnrollAssetsInTelemetryApiResponse =
  /** status 202 Accepted */ GmResponseCommonListString;
export type EnrollAssetsInTelemetryApiArg = {
  /** A CSV-formatted string containing VINs of the assets to be enrolled in telemetry. */
  csvVins?: any;
  body: string;
};
export type GetAssetWithEnhancedDiagnosticReadinessApiResponse =
  /** status 200 OK */ GmResponseCommonAssetReadinessEnhancedDiagnosticsItemResponsePage;
export type GetAssetWithEnhancedDiagnosticReadinessApiArg = {
  bdPageRequest: BdPageRequest;
};
export type BatchOnboardVehiclesApiResponse =
  /** status 200 OK */ GmResponseCommonBatchBoardingResponse;
export type BatchOnboardVehiclesApiArg = {
  /** The provider of the asset remote commands. */
  remoteProvider?: string;
  /** The provider of the asset telemetry data. */
  telemetryProvider?: string;
  organizationId: string;
  body: {
    file: Blob;
  };
};
export type GetAssetsByPageApiResponse =
  /** status 200 OK */ GmResponseCommonAssetDetailResponseViewPage;
export type GetAssetsByPageApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetAssetHistoryByPageApiResponse =
  /** status 200 OK */ GmResponseCommonAssetHistoriesPage;
export type GetAssetHistoryByPageApiArg = {
  /** Asset's identification. */
  assetId: string;
  bdPageRequest: BdPageRequest;
};
export type GetUtilizationV2ApiResponse =
  /** status 200 OK */ GmResponseCommonUtilizationResponse;
export type GetUtilizationV2ApiArg = {
  utilizationRequest: UtilizationRequest;
};
export type GetUtilizationDisaggregatedByAssetApiResponse =
  /** status 200 OK */ GmResponseCommonDisaggregatedUtilizationResponse;
export type GetUtilizationDisaggregatedByAssetApiArg = {
  utilizationRequest: UtilizationRequest;
};
export type DoSubscribeV2ApiResponse = /** status 201 Created */ Empty;
export type DoSubscribeV2ApiArg = {
  eventSubscriptionRequestV2: EventSubscriptionRequestV2;
};
export type GetAllAssetsApiResponse =
  /** status 200 OK */ GmResponseCommonListAssetDetailResponseJson;
export type GetAllAssetsApiArg = {
  /** Data filters based on org/hub/fleet. */
  filters?: any;
};
export type CreateAssetApiResponse =
  /** status 201 Created */ GmResponseCommonAssetCreationResponseJson;
export type CreateAssetApiArg = {
  body: VehicleJson;
};
export type TransitionStateApiResponse =
  /** status 200 OK */ GmResponseCommonEmpty;
export type TransitionStateApiArg = {
  /** Asset's identification. */
  id: string;
  /** State to transition. */
  state: string;
  changeStateRequestJson: ChangeStateRequestJson;
};
export type GetAssetWithTireReadinessApiResponse =
  /** status 200 OK */ GmResponseCommonTireReadinessItemsResponsePage;
export type GetAssetWithTireReadinessApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetAssetWithReadinessApiResponse =
  /** status 200 OK */ GmResponseCommonAssetReadinessItemsResponsePage;
export type GetAssetWithReadinessApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetComplianceReportApiResponse =
  /** status 200 OK */ GmResponseCommonComplianceReportPage;
export type GetComplianceReportApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetAssetWithBatteryReadinessApiResponse =
  /** status 200 OK */ GmResponseCommonBatteryReadinessItemsResponsePage;
export type GetAssetWithBatteryReadinessApiArg = {
  bdPageRequest: BdPageRequest;
};
export type SearchAssetsApiResponse =
  /** status 200 OK */ GmResponseCommonAssetDetailResponsePage;
export type SearchAssetsApiArg = {
  bdPageRequest: BdPageRequest;
};
export type UpdateProtectedFieldsForAssetApiResponse =
  /** status 202 Success */ GmResponseCommonAsset;
export type UpdateProtectedFieldsForAssetApiArg = {
  /** Asset ID. */
  headerAssetId?: any;
  /** Request containing the protected fields to update. */
  request?: any;
  pathAssetId: string;
  protectedFieldsUpdateRequest: ProtectedFieldsUpdateRequest;
};
export type GetVehiclesBasicDetailsApiResponse =
  /** status 200 OK */ GmResponseCommonPaginatedDataResponseBasicVehicleDetails;
export type GetVehiclesBasicDetailsApiArg = {
  /** The number of items to return. Default is 10. */
  size?: number;
  /** List of vehicle identification numbers. */
  vins?: string[];
  /** The vehicle name. */
  name?: string;
  /** The vehicle license plate. */
  licensePlate?: string;
  /** The organization Id. */
  'cs-organization': string;
  /** The hub Id. */
  'cs-hub'?: string;
  /** The fleet Id. */
  'cs-fleet'?: string;
  /** The continuation token. */
  'cs-continuation-token'?: string;
  sortBy?: string;
  sortOrder?: string;
};
export type ListPendingConnectionVehiclesApiResponse =
  /** status 200 OK */ GmResponseCommonGmPagePendingConnectionVehicleItem;
export type ListPendingConnectionVehiclesApiArg = {
  /** The ID of the organization. */
  'cs-organization': string;
  /** The ID of the hub. */
  'cs-hub'?: string;
  /** The ID of the fleet. */
  'cs-fleet'?: string;
  /** Number of items per page. */
  size?: number;
  /** Filter by (partial) VIN. */
  vin?: string;
  /** Used for next set of results */
  'cs-continuation-token'?: string;
};
export type SearchAssetApiResponse =
  /** status 200 Successfully searched organizations. */ GmResponseCommonAzureAiSearchResponseAsset;
export type SearchAssetApiArg = {
  /** Search text to search for assets. */
  searchText?: string;
  /** Number of items to return. */
  top?: number;
  /** Number of items to skip. */
  skip?: number;
  'cs-organization'?: string;
  'cs-hub'?: string;
  'cs-fleet'?: string;
};
export type ListIncompleteOnboardingVehiclesApiResponse =
  /** status 200 OK */ GmResponseCommonIncompleteOnboardingVehiclesResponse;
export type ListIncompleteOnboardingVehiclesApiArg = {
  /** The ID of the organization. */
  'cs-organization': string;
  /** The ID of the hub. */
  'cs-hub'?: string;
  /** The ID of the fleet. */
  'cs-fleet'?: string;
  /** Number of items per page. */
  size?: number;
  /** Filter by (partial) VIN. */
  vin?: string;
  /** Filter by reason. */
  reason?:
    | 'UNEXPECTED_FAILURE'
    | 'VEHICLE_REJECTED_ACTIVELY_ONBOARDING'
    | 'VEHICLE_REJECTED_ACTIVELY_OFFBOARDING'
    | 'VEHICLE_REJECTED_CLAIMED_BY_OTHER'
    | 'VEHICLE_REJECTED_CLAIMED_BY_OWNER'
    | 'VEHICLE_REJECTED_NO_INFORMATION_FOUND'
    | 'VEHICLE_MANUAL_PROOF_OF_OWNERSHIP_REJECTED'
    | 'VEHICLE_REJECTED_NOT_SUPPORTED';
  /** Used for next set of results */
  'cs-continuation-token'?: string;
};
export type GetAssetsListApiResponse =
  /** status 200 OK */ GmResponseCommonPaginatedDataResponseAssetListItem;
export type GetAssetsListApiArg = {
  /** The number of items to return. Default is 10. */
  size?: number;
  /** The propulsion types filter. */
  propulsionTypes?: ('EV' | 'ICE' | 'HEV' | 'PHEV')[];
  /** The vehicle identification number. */
  vin?: string;
  /** The vehicle name. */
  name?: string;
  /** The vehicle license plate. */
  licensePlate?: string;
  /** The organization Id. */
  'cs-organization': string;
  /** The hub Id. */
  'cs-hub': string;
  /** The fleet Id. */
  'cs-fleet': string;
  /** The continuation token. */
  'cs-continuation-token'?: string;
};
export type GetVehicleIgnitionStatusApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleIgnitionStatusView;
export type GetVehicleIgnitionStatusApiArg = {
  /** Vehicle Identification Number of the vehicle. */
  vin: string;
};
export type GetAssetStatusWithFilteringApiResponse =
  /** status 200 OK */ GmResponseCommonAssetStatusView;
export type GetAssetStatusWithFilteringApiArg = {
  /** Asset's identification. */
  id: string;
  /** An optional list of statuses to filter the results. */
  statuses?: string[];
  'cs-organization': string;
  'cs-fleet'?: string;
  'cs-hub'?: string;
};
export type ListPendingOnboardingVehiclesApiResponse =
  /** status 200 OK */ GmResponseCommonPendingOnboardingVehiclesResponse;
export type ListPendingOnboardingVehiclesApiArg = {
  /** The ID of the organization. */
  'cs-organization': string;
  /** The ID of the hub. */
  'cs-hub'?: string;
  /** The ID of the fleet. */
  'cs-fleet'?: string;
  /** Number of items per page. */
  size?: number;
  /** Filter by status. */
  status?: 'PREPARING' | 'REVIEWING_OWNERSHIP';
  /** Filter by (partial) VIN. */
  vin?: string;
  /** Used for next set of results */
  'cs-continuation-token'?: string;
};
export type ExecuteRemoteCommandsApiResponse =
  /** status 200 OK */ GmResponseCommonOptionalRemoteCommandResponse;
export type ExecuteRemoteCommandsApiArg = {
  /** The type of command to be executed. Permissible values are: engine, door, alert, ignition. */
  commandType: 'engine' | 'door' | 'alert' | 'ignition';
  /** The specific action to be performed. Permissible values are: engine - start, cancel; door - lock, unlock; alert - start, cancel; ignition - enable, disable. */
  command: 'start' | 'cancel' | 'lock' | 'unlock' | 'enable' | 'disable';
  /** VIN of the vehicle on which the command is to be executed. */
  vin: string;
};
export type GetChargeLevelDashboardSummaryApiResponse =
  /** status 200 OK */ GmResponseCommonChargeLevelDashboardSummaryResponse;
export type GetChargeLevelDashboardSummaryApiArg = {
  /** Optional hubId and fleetId. */
  filters?: any;
  /** The the organization ID. */
  organizationId?: any;
  'x-bd-organization': string;
};
export type GetOilLifeDashboardSummaryApiResponse =
  /** status 200 OK */ GmResponseCommonOilLifeDashboardSummaryResponse;
export type GetOilLifeDashboardSummaryApiArg = {
  /** Optional fleetId and hubId for the data. */
  filters?: any;
  /** The the organization ID. */
  organizationId?: any;
  'x-bd-organization': string;
};
export type GetFieldActionsForVinApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleFieldActionDetailsResponse;
export type GetFieldActionsForVinApiArg = {
  vin: string;
};
export type GetVehicleBoardingProfileApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleBoardingProfileDetail;
export type GetVehicleBoardingProfileApiArg = {
  /** The vehicle's identification number. */
  vin: string;
};
export type GetCurrentVehicleBoardingSessionApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleBoardingWorkflowSessionDetail;
export type GetCurrentVehicleBoardingSessionApiArg = {
  /** The vehicle's identification number. */
  vin: string;
};
export type ListVehicleBoardingSessionsApiResponse =
  /** status 200 OK */ GmResponseCommonBdPageVehicleBoardingWorkflowSessionDetail;
export type ListVehicleBoardingSessionsApiArg = {
  /** The boarding session's batch identification number. */
  batchId?: string;
  /** The continuation token for the next set of sessions. */
  token?: string;
  /** The organization id associated with the session. */
  organizationId: string;
  /** The number of items to return for the request page. */
  size?: number;
  /** The vehicle boarding session type. */
  sessionType?: 'ONBOARDING' | 'OFFBOARDING';
  /** The vehicle's identification number. */
  vin?: string;
};
export type GetVehicleBoardingSessionBySessionIdApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleBoardingWorkflowSessionDetail;
export type GetVehicleBoardingSessionBySessionIdApiArg = {
  /** ID number for a boarding session */
  sessionId: string;
};
export type GetAssetHistoryLimitedResponseApiResponse =
  /** status 200 OK */ GmResponseCommonPaginatedDataResponseAssetHistoryResponseJson;
export type GetAssetHistoryLimitedResponseApiArg = {
  id: string;
};
export type GetOrganizationAssetCountSummaryApiResponse =
  /** status 200 OK */ GmResponseCommonOrganizationAssetCountSummaryItem;
export type GetOrganizationAssetCountSummaryApiArg = {
  organizationId: string;
  hubId?: string;
};
export type GetAssetByIdWithFanApiResponse =
  /** status 200 OK */ GmResponseCommonAssetByIdDetailResponseV20231121;
export type GetAssetByIdWithFanApiArg = {
  /** Asset's identification. */
  id: string;
};
export type GetAssetHardwareIdWithFanApiResponse =
  /** status 200 OK */ GmResponseCommonAssetByIdDetailResponseV20231121;
export type GetAssetHardwareIdWithFanApiArg = {
  /** Asset's hardware id. */
  hardwareId: string;
};
export type GetAssetLocationHistoryGeoJson20231107ApiResponse =
  /** status 200 OK */ GmResponseCommonAssetLocationHistoryResponseGeoJson;
export type GetAssetLocationHistoryGeoJson20231107ApiArg = {
  /** Asset's identification. */
  id: string;
  /** Start of history to return. */
  startDate: string;
  /** End of history to return. */
  endDate: string;
  /** Page of results to return. */
  page?: any;
  /** Size of pages to return. */
  size?: any;
};
export type GetComplianceReportV2ApiResponse =
  /** status 200 OK */ GmResponseCommonComplianceReportPage;
export type GetComplianceReportV2ApiArg = {
  /** Org/Hub/Fleet ids */
  params?: any;
  /** Optional orgId filter. */
  organizationId?: any;
  'x-bd-organization'?: string;
};
export type GetAssetLocationHistoryV20220524ApiResponse =
  /** status 200 OK */ GmResponseCommonAssetLocationHistoryResponseJson;
export type GetAssetLocationHistoryV20220524ApiArg = {
  /** Asset's identification. */
  id: string;
  /** Start of history to return. */
  startDate: string;
  /** End of history to return. */
  endDate: string;
  /** Page of results to return. */
  page?: number;
  /** Size of pages to return. */
  size?: number;
};
export type GetAssetStatusApiResponse =
  /** status 200 OK */ GmResponseCommonMapStringStatusValueResponseJson;
export type GetAssetStatusApiArg = {
  /** Asset's identification. */
  id: string;
};
export type GetAssetUtilizationApiResponse =
  /** status 200 OK */ GmResponseCommonVehicleDayResponse;
export type GetAssetUtilizationApiArg = {
  /** Start date. */
  startDate: string;
  /** End date. */
  endDate: string;
  /** Asset's identification. */
  assetId: string;
  /** Unused. */
  timePeriod: string;
  /** Optional feature identifier */
  featureIdentifier?: string;
  /** OrgId. */
  organizationId?: any;
  'x-bd-organization': string;
};
export type GetComplianceSummaryApiResponse =
  /** status 200 OK */ GmResponseCommonComplianceSummaryResult;
export type GetComplianceSummaryApiArg = {
  /** Optional hubId filter. */
  hubId?: string;
  /** Optional fleetId filter. */
  fleetId?: string;
  /** Optional organizationId filter. */
  organizationId?: any;
  'x-bd-organization'?: string;
};
export type GetDashboardMetricsApiResponse =
  /** status 200 OK */ GmResponseCommonDashboardSummaryWrapper;
export type GetDashboardMetricsApiArg = {
  /** Filters of orgId/hubId/fleetId. */
  filters?: any;
  /** Optional filter for orgId instead of in filters. */
  orgId?: any;
  'x-bd-organization': string;
};
export type GetMapListItemsApiResponse =
  /** status 200 OK */ GmResponseCommonPaginatedDataResponseViewMapListItem;
export type GetMapListItemsApiArg = {
  /** Filters of orgId/hubId/fleetId. */
  filters?: any;
  /** number of items to return */
  size?: number;
  /** continuation token for the next set of assets */
  continuationToken?: string;
};
export type GetAssetWithi8NDetailsApiResponse =
  /** status 200 OK */ GmResponseCommonAssetDetailResponseViewJson;
export type GetAssetWithi8NDetailsApiArg = {
  /** Asset's identification. */
  id: string;
};
export type GetDiagnosticDashboardSummaryMetricsApiResponse =
  /** status 200 OK */ GmResponseCommonDiagnosticDashboardSummaryItem;
export type GetDiagnosticDashboardSummaryMetricsApiArg = {
  /** Optional fleetId and hubId for the data. */
  filters?: any;
  /** The the organization ID. */
  organizationId?: any;
  'x-bd-organization': string;
};
export type GetConnectivityStatusDashboardSummaryMetricsApiResponse =
  /** status 200 OK */ GmResponseCommonConnectivityStatusDashboardSummaryItem;
export type GetConnectivityStatusDashboardSummaryMetricsApiArg = {
  /** A map containing optional filters for the data. */
  filters?: any;
  /** The ID of the organization for which the connectivity status summary metrics is to be fetched. */
  orgId?: any;
  'x-bd-organization': string;
};
export type GetOrganizationUtilizationApiResponse =
  /** status 200 OK */ GmResponseCommonOrganizationSummaryResponse;
export type GetOrganizationUtilizationApiArg = {
  /** Start date. */
  startDate: string;
  /** End date. */
  endDate: string;
  /** Unused. */
  timePeriod: string;
  /** Maximum size of returned list. Maybe. */
  vehicleListSize?: number;
  /** Optional feature identifier */
  featureIdentifier?: string;
  /** OrgId. */
  organizationId?: any;
  'x-bd-organization': string;
};
export type GetAssetHardwareIdApiResponse =
  /** status 200 OK */ GmResponseCommonAssetByIdDetailResponseJson;
export type GetAssetHardwareIdApiArg = {
  /** Asset's hardware id. */
  hardwareId: string;
};
export type FleetResponseJson = {
  /** Fleet id */
  id?: string;
  /** Fleet name */
  name?: string;
};
export type HubResponseJson = {
  /** Hub id */
  id?: string;
  /** Hub name */
  name?: string;
};
export type OrganizationResponseJson = {
  /** Organization id */
  id?: string;
  /** Organization name */
  name?: string;
};
export type StateResponseJson = {
  connectivity?: string;
  diagnostic?: string;
  availability?: string;
  safetyCompliant?: boolean;
  mil?: string;
  provisioning?: string;
  homeLocationCompliant?: boolean;
  chargingScheduleCompliant?: boolean;
  chargeState?: string;
};
export type StatusValueResponseJson = {
  value?: object;
  deviceTimestamp?: string;
};
export type VehicleType = {
  /** Manufacturer of the Vehicle. */
  make?: string;
  /** Trim level, package, and option code. */
  merchandisingModel?: string;
  /** Model name of the Vehicle. */
  model?: string;
  /** Platform, Engine, and Gearbox code. */
  peg?: string;
  /** Engine type of the vehicle. */
  propulsion?: 'EV' | 'ICE' | 'HEV' | 'PHEV';
  /** List of option codes. */
  rpoCodes?: string[];
  /** Model year of the Vehicle. */
  year?: string;
};
export type BatteryInfo = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  soc?: number;
  targetSoc?: number;
  chargeState?: string;
  chargePowerType?: 'AC' | 'DC' | 'unknown';
  estimatedRangedInKM?: number;
  estimatedTimeRemainingToTargetSOC?: number;
  chargingEstimatedCompletionDateTime?: string;
  chargingPowerInput?: number;
};
export type DiagnosticSubsystem = {
  type?:
    | 'airbag_deployment'
    | 'antilock_brake'
    | 'auxiliary_transmission_pump'
    | 'battery_conditioning'
    | 'charging_cable'
    | 'electric_all_wheel_drive'
    | 'engine_cooling'
    | 'engine_electrical'
    | 'evaporative_emissions'
    | 'exhaust_emissions'
    | 'hybrid_battery'
    | 'hybrid_powertrain'
    | 'lighting'
    | 'motor_control'
    | 'onboard_charging'
    | 'peripheral'
    | 'safety_belt_pretensioner'
    | 'stabilitrak'
    | 'traction_control'
    | 'wireless_communications';
};
export type DiagnosticSystem = {
  type?:
    | 'airbag'
    | 'antilock_braking'
    | 'electric_drive'
    | 'electric_lamps'
    | 'emissions'
    | 'engine_and_transmission'
    | 'high_voltage_battery'
    | 'onstar'
    | 'stability_control';
  subsystems?: DiagnosticSubsystem[];
};
export type DiagnosticsValueResponseJson = {
  value?: boolean;
  severity?: string;
  mil?: boolean;
  deviceTimestamp?: string;
  systems?: DiagnosticSystem[];
};
export type AssetByIdDetailResponseJson = {
  /** Asset id */
  id?: string;
  /** Asset name */
  name?: string;
  /** Asset's hardware id such as a VIN. */
  hardwareId?: string;
  /** Asset's license plate. */
  licensePlate?: string;
  /** Fleet that an asset is associated with. */
  fleet?: FleetResponseJson;
  /** Hub that an asset is associated with. */
  hub?: HubResponseJson;
  /** Organization that an asset is associated with. */
  organization?: OrganizationResponseJson;
  /** Asset state */
  state?: StateResponseJson;
  /** Asset status */
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  /** Asset configuration */
  configuration?: {
    [key: string]: object;
  };
  /** Asset capabilities */
  capabilities?: {
    [key: string]: string;
  };
  /** Asset timezone. This is inherited from the fleet. */
  timeZone?: string;
  /** Vehicle information such as make, model, year, propulsion */
  vehicleType?: VehicleType;
  /** Asset battery and charging information */
  hvBatteryInfo?: BatteryInfo;
  /** Asset tcps accepted information */
  tcpsAccepted?: boolean;
  /** Vehicle organization primary ownership status */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Asset creation date */
  creationDate?: string;
  /** Asset last updated time */
  lastUpdated?: string;
  /** Asset readiness */
  ready?: 'ready' | 'not_ready' | 'unknown';
  /** Asset fault count */
  faultCount?: number;
  /** Asset parent */
  parent?: string;
  /** Asset host */
  host?: string;
  /** Commands available for the Vehicle */
  availableCommands?: (
    | 'ALERT'
    | 'CANCEL_ALERT'
    | 'START'
    | 'CANCEL_START'
    | 'ENABLE_IGNITION'
    | 'DISABLE_IGNITION'
    | 'LOCK_DOOR'
    | 'UNLOCK_DOOR'
  )[];
  diagnostics?: {
    [key: string]: DiagnosticsValueResponseJson;
  };
  offlineAccessToken?: string;
};
export type GmResponseError = {
  errorType: 'GENERIC' | 'VALIDATION';
  message: string;
  errorCode: string;
};
export type GmResponseCommonAssetByIdDetailResponseJson = {
  result?: AssetByIdDetailResponseJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type Empty = object;
export type GmResponseCommonEmpty = {
  result?: Empty;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetResponseJsonBase = {
  type: string;
  /** Asset id */
  id?: string;
  fleetId?: string;
  hubId?: string;
  organizationId?: string;
  name?: string;
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  diagnostics?: {
    [key: string]: DiagnosticsValueResponseJson;
  };
  state?: StateResponseJson;
  availabilityState?: string;
  fan?: string;
  vehicleAccountType?: 'FL' | 'RN' | 'PN' | 'BN' | 'CV' | 'DD' | 'DH';
  creationDateTime?: string;
};
export type PropulsionModelBase = {
  vehiclePropulsion: string;
};
export type HvBattery = {
  batteryCapacityKwh?: number;
};
export type Ev = {
  vehiclePropulsion: 'Ev';
} & PropulsionModelBase & {
    hvBattery?: HvBattery;
  };
export type FuelTank = {
  fuelTankCapacityL?: number;
  fuelType?: 'GASOLINE' | 'DIESEL';
};
export type Hev = {
  vehiclePropulsion: 'Hev';
} & PropulsionModelBase & {
    fuelTank?: FuelTank;
  };
export type Ice = {
  vehiclePropulsion: 'Ice';
} & PropulsionModelBase & {
    fuelTank?: FuelTank;
  };
export type Phev = {
  vehiclePropulsion: 'Phev';
} & PropulsionModelBase & {
    hvBattery?: HvBattery;
    fuelTank?: FuelTank;
  };
export type OnStar = {
  subscriberAccountNumber?: string;
  unitGenerationId?: string;
  unitState?:
    | 'UNKNOWN'
    | 'NOT_EQUIPPED'
    | 'NOT_CONFIGURED'
    | 'DISABLED'
    | 'ENABLED'
    | 'CONNECTED';
  tcpsAccepted?: boolean;
  vduState?:
    | 'UNKNOWN'
    | 'NOT_EQUIPPED'
    | 'NOT_CONFIGURED'
    | 'DISABLED'
    | 'ENABLED'
    | 'CONNECTED';
  accountOwnerProfileId?: string;
  accountOwnerEmailId?: string;
  accountStatus?: string;
};
export type VehicleResponseJson = {
  type: 'VehicleResponseJson';
} & AssetResponseJsonBase & {
    /** Vehicle identification number. */
    vin?: string;
    /** License plate of the vehicle. */
    licensePlate?: string;
    /** Manufacturer of the Vehicle. */
    vehicleMake?: string;
    /** Model name of the Vehicle. */
    vehicleModel?: string;
    /** Model year of the Vehicle. */
    vehicleYear?: string;
    /** Platform, Engine, and Gearbox code. */
    peg?: string;
    /** Engine type of the vehicle. */
    propulsionModel?: Ev | Hev | Ice | Phev;
    /** Onstar related data such as account number for the vehicle. */
    onStar?: OnStar;
    /** List of option codes. */
    rpoCodes?: string[];
    /** Trim level, package, and option code. */
    merchandisingModel?: string;
    /** Time value of when the vehicle was delivered. */
    deliveryDate?: string;
  };
export type GmResponseCommonAssetResponseJson = {
  result?: VehicleResponseJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ChangeStateRequestJson = {
  /** Current state of the asset */
  current: string;
  /** Desired state of the asset */
  desired: string;
};
export type UpdateAssetJsonBase = {
  type: string;
  id?: string;
  /** Fleet ID for fleet the asset belongs to */
  fleetId?: string;
  /** Hub ID for hub the asset belongs to */
  hubId?: string;
  /** Organization ID for organization the asset belongs to */
  organizationId?: string;
  /** Asset name */
  name?: string;
  /** Hardware ID of the asset */
  hardwareId?: string;
  /** Host */
  host?: string;
  /** Organization primary ownership status */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Vehicle account type */
  vehicleAccountType?: 'FL' | 'RN' | 'PN' | 'BN' | 'CV' | 'DD' | 'DH';
  changeAvailabilityStateRequest?: ChangeStateRequestJson;
};
export type UpdateVehicleJson = {
  type: 'UpdateVehicleJson';
} & UpdateAssetJsonBase & {
    /** Vehicle Identification Number */
    vin?: string;
    /** Vehicle license plate number */
    licensePlate?: string;
    /** Vehicle manufacturer */
    vehicleMake?: string;
    /** Vehicle model name */
    vehicleModel?: string;
    /** Vehicle model year */
    vehicleYear?: string;
    /** Vehicle propulsion system type */
    propulsionModel?: Ev | Hev | Ice | Phev;
    /** OnStar service configuration */
    onStar?: OnStar;
  };
export type UpsertVehicleJson = {
  type: 'UpsertVehicleJson';
} & UpdateAssetJsonBase & {
    /** Vehicle Identification Number */
    vin?: string;
    /** Vehicle license plate number */
    licensePlate?: string;
    /** Vehicle manufacturer */
    vehicleMake?: string;
    /** Vehicle model name */
    vehicleModel?: string;
    /** Vehicle model year */
    vehicleYear?: string;
    /** Vehicle propulsion system type */
    propulsionModel?: Ev | Hev | Ice | Phev;
    /** OnStar service configuration */
    onStar?: OnStar;
    /** Fleet Account Number */
    fan?: string;
    /** Provider for telemetry services */
    telemetryProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
    /** Provider for remote services */
    remoteProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
    /** Type of vehicle account */
    accountType?: 'FL' | 'RN' | 'PN' | 'BN' | 'CV' | 'DD' | 'DH';
    /** Platform, Engine, and Gearbox code */
    peg?: string;
    /** List of RPO codes */
    rpoCodes?: string[];
    /** Vehicle merchandising model code */
    merchandisingModelCode?: string;
    /** Vehicle delivery date */
    deliveryDate?: string;
  };
export type GmResponseCommonBoolean = {
  result?: boolean;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type BatchBoardingResponse = {
  batchId?: string;
};
export type GmResponseCommonBatchBoardingResponse = {
  result?: BatchBoardingResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleDataRefreshResponse = {
  acceptedEntries?: string[];
  rejectedEntries?: string[];
};
export type GmResponseCommonVehicleDataRefreshResponse = {
  result?: VehicleDataRefreshResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleDataRefreshRequest = {
  vins?: string[];
};
export type LastCommunicated = {
  deviceTimestamp?: string;
  status?: 'online' | 'offline';
};
export type BatteryReadinessItem = {
  id?: string;
  name?: string;
  licensePlate?: string;
  organization?: OrganizationResponseJson;
  hub?: HubResponseJson;
  fleet?: FleetResponseJson;
  lastCommunicated?: LastCommunicated;
  battery?: BatteryInfo;
  vehicleType?: VehicleType;
};
export type BatteryReadinessItemsResponsePageV20250211 = {
  items?: BatteryReadinessItem[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonBatteryReadinessItemsResponsePageV20250211 = {
  result?: BatteryReadinessItemsResponsePageV20250211;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type SortObject = {
  empty?: boolean;
  unsorted?: boolean;
  sorted?: boolean;
};
export type BdQuery = {
  qualifier:
    | 'ALL'
    | 'IS_EQUAL'
    | 'OR'
    | 'AND'
    | 'NOT'
    | 'BEFORE'
    | 'AFTER'
    | 'IN'
    | 'NOT_IN'
    | 'IS_NULL'
    | 'IS_NOT_NULL'
    | 'LESS_THAN'
    | 'LESS_THAN_EQUAL'
    | 'GREATER_THAN'
    | 'GREATER_THAN_EQUAL'
    | 'CONTAINING'
    | 'ENDS_WITH'
    | 'STARTS_WITH'
    | 'TRUE'
    | 'FALSE'
    | 'BETWEEN'
    | 'ARRAY_CONTAINS'
    | 'STRING_EQUALS'
    | 'NOT_CONTAINING';
  field?: string;
  values?: object[];
  leftQuery?: BdQuery;
  rightQuery?: BdQuery;
};
export type BdPageRequest = {
  page: number;
  size: number;
  /** Direct has been deprecated in favor of query. */
  direct?: boolean;
  sort: SortObject[];
  token?: string;
  query?: BdQuery;
};
export type VehicleFieldActionSummaryResponse20241204 = {
  /** Organization ID */
  organizationId?: string;
  /** Total number of vehicles with active field actions. */
  activeFieldActionVehicleCount?: number;
};
export type GmResponseCommonVehicleFieldActionSummaryResponse20241204 = {
  result?: VehicleFieldActionSummaryResponse20241204;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleFieldActionSummaryRequest = {
  fleetId?: string;
};
export type StateDiagnostic = {
  state?: string;
  type?: string;
  deviceTimestamp?: string;
};
export type BatteryInfoJson = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  soc?: number;
  chargeState?: string;
  estimatedRangedInKM?: number;
};
export type FuelLevelJson = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  fuelLevel?: number;
};
export type OilLifeJson = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  oilLife?: number;
};
export type BrakePadJson = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  brakePadLife?: number;
};
export type EngineAirFilterJson = {
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  engineAirFilter?: number;
  engineAirFilterStatus?:
    | 'replace_now'
    | 'inactive'
    | 'indeterminate'
    | 'missing'
    | 'blocked'
    | 'replace_next_time'
    | 'no_fault'
    | 'unknown';
};
export type AssetReadinessItemEnhancedDiagnostics = {
  /** Asset id */
  id?: string;
  name?: string;
  organization?: OrganizationResponseJson;
  fleet?: FleetResponseJson;
  licensePlate?: string;
  lastCommunicated?: LastCommunicated;
  vehicleType?: VehicleType;
  provisioning?: string;
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  diagnosticState?: 'clear' | 'impaired' | 'critical';
  diagnosticsCritical?: boolean;
  diagnosticsImpaired?: boolean;
  availabilityState?: 'in_service' | 'out_of_service';
  diagnostics?: StateDiagnostic[];
  battery?: BatteryInfoJson;
  fuelLevel?: FuelLevelJson;
  oilLife?: OilLifeJson;
  brakePadFront?: BrakePadJson;
  brakePadRear?: BrakePadJson;
  engineAirFilter?: EngineAirFilterJson;
  tires?: 'good' | 'impaired' | 'critical' | 'no_data';
  brakeFluid?: 'good' | 'critical' | 'no_data';
  washerFluid?: 'good' | 'impaired' | 'no_data';
  milState?: string;
};
export type AssetReadinessEnhancedDiagnosticsItemResponsePageV2 = {
  items?: AssetReadinessItemEnhancedDiagnostics[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonAssetReadinessEnhancedDiagnosticsItemResponsePageV2 =
  {
    result?: AssetReadinessEnhancedDiagnosticsItemResponsePageV2;
    targetUrl: string;
    __bd: boolean;
    success: boolean;
    unauthorizedRequest: boolean;
    errors: GmResponseError[];
    traceId?: string;
  };
export type CompletedOperationEntry = {
  vin?: string;
};
export type OperationError = {
  errorCode?: string;
  message?: string;
};
export type FailedOperationEntry = {
  vin?: string;
  error?: OperationError;
};
export type VehicleOwnershipOperationBatchResponse = {
  completedEntries?: CompletedOperationEntry[];
  failedEntries?: FailedOperationEntry[];
};
export type GmResponseCommonVehicleOwnershipOperationBatchResponse = {
  result?: VehicleOwnershipOperationBatchResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleOwnershipOperation = {
  vin: string;
  note?: string;
};
export type VehicleOwnershipOperationBatchRequest = {
  entries: VehicleOwnershipOperation[];
};
export type VehicleFieldActionReportItem = {
  id?: string;
  name?: string;
  licensePlate?: string;
  organization?: OrganizationResponseJson;
  fleet?: FleetResponseJson;
  hub?: HubResponseJson;
  availabilityState?: 'in_service' | 'out_of_service';
  vehicleType?: VehicleType;
  fieldActionSummary?: {
    [key: string]: string[];
  };
};
export type PaginatedDataResponseVehicleFieldActionReportItem = {
  items?: VehicleFieldActionReportItem[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonPaginatedDataResponseVehicleFieldActionReportItem =
  {
    result?: PaginatedDataResponseVehicleFieldActionReportItem;
    targetUrl: string;
    __bd: boolean;
    success: boolean;
    unauthorizedRequest: boolean;
    errors: GmResponseError[];
    traceId?: string;
  };
export type SearchCriteria = {
  /** Asset id */
  id?: string;
  /** Asset name */
  name?: string;
  /** Asset license plate */
  licensePlate?: string;
};
export type FilterCriteria = {
  /** Field action types (ZPSR, ZPER, ZNCR, ZCSP) */
  fieldActionTypes?: ('ZPSR' | 'ZPER' | 'ZNCR' | 'ZCSP')[];
};
export type VehicleFieldActionReportRequest = {
  /** Page size */
  size?: number;
  searchCriteria?: SearchCriteria;
  filterCriteria?: FilterCriteria;
  /** Continuation token */
  token?: string;
};
export type GmResponseCommonListString = {
  result?: string[];
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetReadinessEnhancedDiagnosticsItemResponsePage = {
  items: AssetReadinessItemEnhancedDiagnostics[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonAssetReadinessEnhancedDiagnosticsItemResponsePage =
  {
    result?: AssetReadinessEnhancedDiagnosticsItemResponsePage;
    targetUrl: string;
    __bd: boolean;
    success: boolean;
    unauthorizedRequest: boolean;
    errors: GmResponseError[];
    traceId?: string;
  };
export type DiagnosticsValueResponseViewJson = {
  value?: boolean;
  severity?: string;
  mil?: boolean;
  deviceTimestamp?: string;
  systems?: DiagnosticSystem[];
  name?: string;
  text?: string;
  recommendation?: string[];
};
export type AssetDetailResponseViewJson = {
  /** Asset id */
  id?: string;
  /** Asset name */
  name?: string;
  /** Asset's hardware id such as a VIN. */
  hardwareId?: string;
  /** Asset's license plate. */
  licensePlate?: string;
  /** Fleet that an asset is associated with. */
  fleet?: FleetResponseJson;
  /** Hub that an asset is associated with. */
  hub?: HubResponseJson;
  /** Organization that an asset is associated with. */
  organization?: OrganizationResponseJson;
  /** Asset state */
  state?: StateResponseJson;
  /** Asset status */
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  /** Asset configuration */
  configuration?: {
    [key: string]: object;
  };
  /** Asset capabilities */
  capabilities?: {
    [key: string]: string;
  };
  /** Asset timezone. This is inherited from the fleet. */
  timeZone?: string;
  /** Vehicle information such as make, model, year, propulsion */
  vehicleType?: VehicleType;
  /** Asset battery and charging information */
  hvBatteryInfo?: BatteryInfo;
  /** Asset tcps accepted information */
  tcpsAccepted?: boolean;
  /** Vehicle organization primary ownership status */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Asset creation date */
  creationDate?: string;
  /** Asset last updated time */
  lastUpdated?: string;
  /** Asset readiness */
  ready?: 'ready' | 'not_ready' | 'unknown';
  /** Asset fault count */
  faultCount?: number;
  /** Asset parent */
  parent?: string;
  /** Asset host */
  host?: string;
  /** Commands available for the Vehicle */
  availableCommands?: (
    | 'ALERT'
    | 'CANCEL_ALERT'
    | 'START'
    | 'CANCEL_START'
    | 'ENABLE_IGNITION'
    | 'DISABLE_IGNITION'
    | 'LOCK_DOOR'
    | 'UNLOCK_DOOR'
  )[];
  /** Asset diagnostics */
  diagnostics?: {
    [key: string]: DiagnosticsValueResponseViewJson;
  };
};
export type AssetDetailResponseViewPage = {
  items: AssetDetailResponseViewJson[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonAssetDetailResponseViewPage = {
  result?: AssetDetailResponseViewPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type Action = {
  type?: string;
  value?: object;
};
export type Actor = {
  id?: string;
  name?: string;
  method?: string;
};
export type StatusHistoryItem = {
  name?: string;
  value?: object;
  deviceTimestamp?: string;
  explicit?: boolean;
};
export type AssetHistory = {
  id?: string;
  assetId?: string;
  organizationId?: string;
  action?: Action;
  actor?: Actor;
  timestamp?: string;
  createDateTime?: string;
  statuses?: {
    [key: string]: StatusHistoryItem;
  };
  type?: string;
  get_etag?: string;
  correlationId?: string;
};
export type AssetHistoriesPage = {
  items?: AssetHistory[];
  token?: string;
  total_items?: number;
};
export type GmResponseCommonAssetHistoriesPage = {
  result?: AssetHistoriesPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type DaySummaryForEntitiesOrgFleetHubDayByDay = {
  /** Date */
  day?: string;
  /** Battery start in % state of charge */
  batteryStart?: number;
  /** Battery end in % state of charge */
  batteryEnd?: number;
  /** Difference between battery start and end in % state of charge (start - end) */
  batteryStartEndDiff?: number;
  /** Estimated range start in km */
  estimatedRangeStart?: number;
  /** Estimated range end in km */
  estimatedRangeEnd?: number;
  /** Difference between start and end estimated range in km (start - end) */
  estimatedRangeStartEndDiff?: number;
  /** Odometer start in km */
  odometerStart?: number;
  /** Odometer end in km */
  odometerEnd?: number;
  /** Difference between the start and end odometer */
  odometerStartEndDiff?: number;
  /** Initial powered-on timestamp */
  ignitionStart?: string;
  /** Final powered-off timestamp */
  ignitionEnd?: string;
  /** Difference between initial powered-on and final powered-off in s (start - end) */
  ignitionStartEndDiff?: number;
  /** Number of vehicles */
  numberOfVehicles?: number;
  /** Days driven */
  daysDriven?: number;
  /** Drive time in s */
  driveTime?: number;
  /** Distance driven in km */
  distanceDriven?: number;
  /** Number of trips completed */
  trips?: number;
  /** Energy used in kWh */
  energyUsed?: number;
  /** Energy consumption rate in kWh/km */
  energyUsedRate?: number;
  /** Charge time in seconds */
  chargeTime?: number;
  /** Number of seatbelt violations */
  seatbeltViolations?: number;
  /** Number of acceleration or brake violations */
  accelerationOrBrakeViolations?: number;
  /** Powered-on time in s */
  ignitionOnTime?: number;
  /** Powered-off time in s */
  ignitionOffTime?: number;
  /** Time Zone */
  timeZone?: string;
};
export type MonthSummaryForEntitiesOrgFleetHubMonthByMonth = {
  /** Month */
  month?: string;
  /** Number of days in the month that had driving activity */
  daysDriven?: number;
  /** Number of vehicles */
  numberOfVehicles?: number;
  /** Drive time in s */
  driveTime?: number;
  /** Distance driven in km */
  distanceDriven?: number;
  /** Number of trips completed */
  trips?: number;
  /** Energy used in kWh */
  energyUsed?: number;
  /** Energy consumption rate in kWh/km */
  energyUsedRate?: number;
  /** Charge time in seconds */
  chargeTime?: number;
  /** Number of seatbelt violations */
  seatbeltViolations?: number;
  /** Number of acceleration or brake violations */
  accelerationOrBrakeViolations?: number;
  /** Powered-on time in s */
  ignitionOnTime?: number;
  /** Powered-off time in s */
  ignitionOffTime?: number;
  /** Time Zone */
  timeZone?: string;
};
export type VehicleTotalsSummary = {
  /** VIN */
  id?: string;
  /** Sum of days driven */
  daysDriven?: number;
  /** Sum of drive time in seconds */
  driveTime?: number;
  /** Sum of kilometers driven */
  distanceDriven?: number;
  /** Sum of trips taken */
  trips?: number;
  /** Energy Used */
  energyUsed?: number;
  /** Charge Time */
  chargeTime?: number;
  /** Time Zone */
  timeZone?: string;
};
export type UtilizationSummaries = {
  /** Summary by day for the given date range */
  days?: DaySummaryForEntitiesOrgFleetHubDayByDay[];
  /** Summary by month for the given date range */
  months?: MonthSummaryForEntitiesOrgFleetHubMonthByMonth[];
  /** Summary by asset across the whole date range */
  assets?: VehicleTotalsSummary[];
};
export type GmResponseCommonUtilizationResponse = {
  result?: UtilizationSummaries;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type UtilizationRequest = {
  assetIds?: string[];
  organizationId?: string;
  fleetIds?: string[];
  hubIds?: string[];
  endDate?: string;
  startDate?: string;
  timePeriod?: 'day' | 'month';
  featureIdentifier?: string;
};
export type DaySummaryForAVehicle = {
  /** VIN */
  vehicleId?: string;
  /** Organization ID */
  organizationId?: string;
  /** Fleet ID */
  fleetId?: string;
  /** Hub ID */
  hubId?: string;
  /** Date */
  day?: string;
  /** Drive time in s */
  driveTime?: number;
  /** Distance driven in km */
  distanceDriven?: number;
  /** Days driven */
  daysDriven?: number;
  /** Number of trips completed */
  trips?: number;
  /** Energy used in kWh */
  energyUsed?: number;
  /** Energy consumption rate in kWh/km */
  energyUsedRate?: number;
  /** Charge time in seconds */
  chargeTime?: number;
  /** Number of seatbelt violations */
  seatbeltViolations?: number;
  /** Number of acceleration or brake violations */
  accelerationOrBrakeViolations?: number;
  /** Battery start in % state of charge */
  batteryStart?: number;
  /** Battery end in % state of charge */
  batteryEnd?: number;
  /** Difference between battery start and end in % state of charge (start - end) */
  batteryStartEndDiff?: number;
  /** Estimated range start in km */
  estimatedRangeStart?: number;
  /** Estimated range end in km */
  estimatedRangeEnd?: number;
  /** Difference between start and end estimated range in km (start - end) */
  estimatedRangeStartEndDiff?: number;
  /** Odometer start in km */
  odometerStart?: number;
  /** Odometer end in km */
  odometerEnd?: number;
  /** Difference between the start and end odometer */
  odometerStartEndDiff?: number;
  /** Initial powered-on timestamp */
  ignitionStart?: string;
  /** Final powered-off timestamp */
  ignitionEnd?: string;
  /** Difference between initial powered-on and final powered-off in s (start - end) */
  ignitionStartEndDiff?: number;
  /** Powered-on time in s */
  ignitionOnTime?: number;
  /** Powered-off time in s */
  ignitionOffTime?: number;
  /** Time Zone */
  timeZone?: string;
};
export type DisaggregatedUtilizationResponse = {
  /** Per-day summary for vehicles */
  days?: DaySummaryForAVehicle[];
};
export type GmResponseCommonDisaggregatedUtilizationResponse = {
  result?: DisaggregatedUtilizationResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type EventSubscriptionRequestV2 = {
  type: 'ORG' | 'ASSET_ID';
  id: string;
  organizationId: string;
  cleanFromOthers?: boolean;
};
export type AssetDetailResponseJson = {
  /** Asset id */
  id?: string;
  /** Asset name */
  name?: string;
  /** Asset's hardware id such as a VIN. */
  hardwareId?: string;
  /** Asset's license plate. */
  licensePlate?: string;
  /** Fleet that an asset is associated with. */
  fleet?: FleetResponseJson;
  /** Hub that an asset is associated with. */
  hub?: HubResponseJson;
  /** Organization that an asset is associated with. */
  organization?: OrganizationResponseJson;
  /** Asset state */
  state?: StateResponseJson;
  /** Asset status */
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  /** Asset configuration */
  configuration?: {
    [key: string]: object;
  };
  /** Asset capabilities */
  capabilities?: {
    [key: string]: string;
  };
  /** Asset timezone. This is inherited from the fleet. */
  timeZone?: string;
  /** Vehicle information such as make, model, year, propulsion */
  vehicleType?: VehicleType;
  /** Asset battery and charging information */
  hvBatteryInfo?: BatteryInfo;
  /** Asset tcps accepted information */
  tcpsAccepted?: boolean;
  /** Vehicle organization primary ownership status */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Asset creation date */
  creationDate?: string;
  /** Asset last updated time */
  lastUpdated?: string;
  /** Asset readiness */
  ready?: 'ready' | 'not_ready' | 'unknown';
  /** Asset fault count */
  faultCount?: number;
  /** Asset parent */
  parent?: string;
  /** Asset host */
  host?: string;
  /** Commands available for the Vehicle */
  availableCommands?: (
    | 'ALERT'
    | 'CANCEL_ALERT'
    | 'START'
    | 'CANCEL_START'
    | 'ENABLE_IGNITION'
    | 'DISABLE_IGNITION'
    | 'LOCK_DOOR'
    | 'UNLOCK_DOOR'
  )[];
  diagnostics?: {
    [key: string]: DiagnosticsValueResponseJson;
  };
};
export type GmResponseCommonListAssetDetailResponseJson = {
  result?: AssetDetailResponseJson[];
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetCreationResponseJsonBase = {
  type: string;
  id?: string;
  fleetId?: string;
  hubId?: string;
  organizationId?: string;
  name?: string;
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  capabilities?: {
    [key: string]: string;
  };
};
export type VehicleCreationResponseJson = {
  type: 'VehicleCreationResponseJson';
} & AssetCreationResponseJsonBase & {
    vin?: string;
    licensePlate?: string;
    vehicleMake?: string;
    vehicleModel?: string;
    vehicleYear?: string;
    propulsionModel?: Ev | Hev | Ice | Phev;
    onStar?: OnStar;
  };
export type GmResponseCommonAssetCreationResponseJson = {
  result?: VehicleCreationResponseJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetJsonBase = {
  type: string;
  /** Asset ID */
  id?: string;
  /** Fleet ID for fleet the asset belongs to */
  fleetId?: string;
  /** Hub ID for hub the asset belongs to */
  hubId?: string;
  /** Organization ID for organization the asset belongs to */
  organizationId?: string;
  /** Asset name */
  name?: string;
  /** Status of the asset with respect to ownership */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Hardware ID of the asset */
  hardwareId?: string;
  /** Six digit FAN of the asset */
  fan?: string;
  /** Asset capabilities as a name-value pair */
  capabilities?: {
    [key: string]: string;
  };
};
export type VehicleJson = {
  type: 'VehicleJson';
} & AssetJsonBase & {
    /** Vehicle Identification Number */
    vin?: string;
    /** Vehicle license plate number */
    licensePlate?: string;
    /** Vehicle manufacturer */
    vehicleMake?: string;
    /** Vehicle model name */
    vehicleModel?: string;
    /** Vehicle model year */
    vehicleYear?: string;
    /** Vehicle propulsion system type */
    propulsionModel?: Ev | Hev | Ice | Phev;
    /** OnStar service configuration */
    onStar?: OnStar;
  };
export type TireStatus = {
  pressureInPSI?: number;
  state?: 'good' | 'impaired' | 'critical' | 'no_data';
  deviceTimestamp?: string;
};
export type TireInfoJson = {
  leftFront?: TireStatus;
  rightFront?: TireStatus;
  leftRear?: TireStatus;
  rightRear?: TireStatus;
  overallStatus?: 'good' | 'impaired' | 'critical' | 'no_data';
};
export type TireReadinessItem = {
  id?: string;
  name?: string;
  licensePlate?: string;
  organization?: OrganizationResponseJson;
  hub?: HubResponseJson;
  fleet?: FleetResponseJson;
  ready?: 'ready' | 'not_ready' | 'unknown';
  lastCommunicated?: LastCommunicated;
  tires?: TireInfoJson;
  vehicleType?: VehicleType;
  availabilityState?: 'in_service' | 'out_of_service';
};
export type TireReadinessItemsResponsePage = {
  items: TireReadinessItem[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonTireReadinessItemsResponsePage = {
  result?: TireReadinessItemsResponsePage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetReadinessItem = {
  /** Asset id */
  id?: string;
  name?: string;
  organization?: OrganizationResponseJson;
  hub?: HubResponseJson;
  fleet?: FleetResponseJson;
  ready?: 'ready' | 'not_ready' | 'unknown';
  faultCount?: number;
  battery?: BatteryInfoJson;
  fuelLevel?: FuelLevelJson;
  oilLife?: OilLifeJson;
  brakePadFront?: BrakePadJson;
  brakePadRear?: BrakePadJson;
  engineAirFilter?: EngineAirFilterJson;
  cubbyTemp?: 'good' | 'impaired' | 'critical';
  tires?: 'good' | 'impaired' | 'critical' | 'no_data';
  brakeFluid?: 'good' | 'critical' | 'no_data';
  washerFluid?: 'good' | 'impaired' | 'no_data';
  safetyCompliant?: boolean;
  licensePlate?: string;
  lastCommunicated?: LastCommunicated;
  vehicleType?: VehicleType;
  provisioning?: string;
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  diagnosticState?: 'clear' | 'impaired' | 'critical';
  availabilityState?: 'in_service' | 'out_of_service';
  milState?: string;
};
export type AssetReadinessItemsResponsePage = {
  items: AssetReadinessItem[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonAssetReadinessItemsResponsePage = {
  result?: AssetReadinessItemsResponsePage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type SafetyComplianceInfo = {
  value?: boolean;
  lastUpdated?: string;
  complianceStatus?: {
    [key: string]: boolean;
  };
};
export type ChargingComplianceStatus = {
  chargingScheduleCompliant?: boolean;
  homeLocationCompliant?: boolean;
};
export type ChargingComplianceInfo = {
  value?: boolean;
  complianceStatus?: ChargingComplianceStatus;
};
export type ComplianceItem = {
  id?: string;
  name?: string;
  organization?: OrganizationResponseJson;
  hub?: HubResponseJson;
  fleet?: FleetResponseJson;
  safetyCompliance?: SafetyComplianceInfo;
  chargingCompliance?: ChargingComplianceInfo;
};
export type ComplianceReportPage = {
  items: ComplianceItem[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonComplianceReportPage = {
  result?: ComplianceReportPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type BatteryReadinessItemsResponsePage = {
  items: BatteryReadinessItem[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonBatteryReadinessItemsResponsePage = {
  result?: BatteryReadinessItemsResponsePage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetDetailResponsePage = {
  items: AssetDetailResponseJson[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonAssetDetailResponsePage = {
  result?: AssetDetailResponsePage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type StatusItemValueObject = {
  name?: string;
  value?: object;
  deviceTimestamp?: string;
  receivedDateTime?: string;
};
export type DiagnosticsItemValue = {
  name?: string;
  value?: boolean;
  severity?: string;
  mil?: boolean;
  deviceTimestamp?: string;
  systems?: DiagnosticSystem[];
};
export type Value = {
  assetId?: string;
  fleetId?: string;
  hubId?: string;
  organizationId?: string;
};
export type ConfigurationValue = {
  lastUpdated?: string;
  value?: Value;
};
export type Configuration = {
  desiredConfigurationValue?: ConfigurationValue;
  currentConfigurationValue?: ConfigurationValue;
};
export type Properties = object;
export type State = {
  brightdrop?: 'assigned' | 'available' | 'building' | 'unavailable';
  connectivity?: 'online' | 'offline';
  diagnostics?: 'clear' | 'impaired' | 'critical';
  diagnosticsCritical?: boolean;
  diagnosticsImpaired?: boolean;
  availability?: 'in_service' | 'out_of_service';
  safetyCompliant?: boolean;
  provisioning?: 'in_progress' | 'failed' | 'completed';
  mil?: '30_clear' | '10_critical' | '20_impaired';
  homeLocationCompliant?: boolean;
  chargingScheduleCompliant?: boolean;
  chargeState?:
    | 'unplugged'
    | 'plugged'
    | 'charging'
    | 'completed'
    | 'aborted'
    | 'idle';
};
export type AssetBase = {
  type?: string;
  assetClass: string;
  id?: string;
  assetId?: string;
  fleetId?: string;
  hubId?: string;
  organizationId?: string;
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  name?: string;
  offlineAccessToken?: string;
  fan?: string;
  hardwareId?: string;
  dataCommunicationActivityStatus?:
    | 'NOT_PERMITTED'
    | 'PENDING'
    | 'PREPARING'
    | 'ACTIVATING'
    | 'ACTIVATED';
  telemetryProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
  remoteProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
  statuses?: {
    [key: string]: StatusItemValueObject;
  };
  diagnostics?: {
    [key: string]: DiagnosticsItemValue;
  };
  capabilities?: {
    [key: string]: string;
  };
  configuration?: Configuration;
  properties?: Properties;
  state?: State;
  createDateTime?: string;
  createdBy?: string;
  updateDate?: string;
  updatedBy?: string;
  ready?: 'ready' | 'not_ready' | 'unknown';
  faultCount?: number;
  lastCommunicated?: string;
  parent?: string;
  host?: string;
  get_etag?: string;
};
export type Vehicle = {
  assetClass: 'Vehicle';
} & AssetBase & {
    vin?: string;
    licensePlate?: string;
    vehicleMake?: string;
    vehicleModel?: string;
    vehicleYear?: string;
    onStar?: OnStar;
    peg?: string;
    vehiclePropulsionModel?: Ev | Hev | Ice | Phev;
    vehicleAccountType?: 'FL' | 'RN' | 'PN' | 'BN' | 'CV' | 'DD' | 'DH';
    vehicleRpoCodes?: string[];
    vehicleMerchandisingModel?: string;
    vehicleDeliveryDate?: string;
    availableCommands?: (
      | 'ALERT'
      | 'CANCEL_ALERT'
      | 'START'
      | 'CANCEL_START'
      | 'ENABLE_IGNITION'
      | 'DISABLE_IGNITION'
      | 'LOCK_DOOR'
      | 'UNLOCK_DOOR'
    )[];
  };
export type Asset = Vehicle;
export type GmResponseCommonAsset = {
  result?: Asset | Vehicle;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ProtectedFieldsUpdateRequest = {
  /** Six digit FAN of the asset */
  fan?: string;
};
export type BasicVehicleDetails = {
  /** id also known as vin. */
  id?: string;
  /** the vehicle name. */
  name?: string;
  /** license plate associated with vehicle. */
  licensePlate?: string;
  /** the id of the organization this vehicle is associated with. */
  organizationId?: string;
  /** the id of the fleet this vehicle is associated with. */
  fleetId?: string;
  /** vehicle type. */
  vehicleType?: VehicleType;
};
export type PaginatedDataResponseBasicVehicleDetails = {
  items?: BasicVehicleDetails[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonPaginatedDataResponseBasicVehicleDetails = {
  result?: PaginatedDataResponseBasicVehicleDetails;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type PendingConnectionVehicleItem = {
  vin?: string;
  name?: string;
  licensePlate?: string;
  provisioning?: string;
  fleetId?: string;
  organizationId?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
  vehicleImageSrc?: string;
  createdDate?: string;
  onStarTcpsAccepted?: boolean;
  onStarAccountStatus?: string;
};
export type GmPagePendingConnectionVehicleItem = {
  items: PendingConnectionVehicleItem[];
  total_items?: number;
  continuation_token?: string;
};
export type GmResponseCommonGmPagePendingConnectionVehicleItem = {
  result?: GmPagePendingConnectionVehicleItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AzureAiSearchResponseAsset = {
  /** List of items returns from search */
  items?: (Asset | Vehicle)[];
  /** Total number of items found */
  totalItems?: number;
};
export type GmResponseCommonAzureAiSearchResponseAsset = {
  result?: AzureAiSearchResponseAsset;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type IncompleteOnboardingVehicleListItem = {
  sessionId?: string;
  vin?: string;
  organizationId?: string;
  fleetId?: string;
  reason?:
    | 'UNEXPECTED_FAILURE'
    | 'VEHICLE_REJECTED_ACTIVELY_ONBOARDING'
    | 'VEHICLE_REJECTED_ACTIVELY_OFFBOARDING'
    | 'VEHICLE_REJECTED_CLAIMED_BY_OTHER'
    | 'VEHICLE_REJECTED_CLAIMED_BY_OWNER'
    | 'VEHICLE_REJECTED_NO_INFORMATION_FOUND'
    | 'VEHICLE_MANUAL_PROOF_OF_OWNERSHIP_REJECTED'
    | 'VEHICLE_REJECTED_NOT_SUPPORTED';
  createdOn?: string;
  createdBy?: string;
  vehicleImageSrc?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
};
export type IncompleteOnboardingVehiclesResponse = {
  items?: IncompleteOnboardingVehicleListItem[];
  totalItems?: number;
  token?: string;
};
export type GmResponseCommonIncompleteOnboardingVehiclesResponse = {
  result?: IncompleteOnboardingVehiclesResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetListItem = {
  /** Asset id. */
  id?: string;
  /** Asset name. */
  name?: string;
  /** Asset license plate. */
  licensePlate?: string;
  /** Asset propulsion type. */
  vehicleType?: VehicleType;
  /** Fan number. */
  fan?: string;
  /** Organization primary ownership status. */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Organization representation for organization id and name. */
  organization?: OrganizationResponseJson;
  /** Fleet representation for fleet id and name. */
  fleet?: FleetResponseJson;
  /** Provisioning state. */
  provisioning?: string;
};
export type PaginatedDataResponseAssetListItem = {
  items?: AssetListItem[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonPaginatedDataResponseAssetListItem = {
  result?: PaginatedDataResponseAssetListItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleIgnitionStatusView = {
  ignitionStatus?: 'ENABLED' | 'DISABLED' | 'UNKNOWN';
};
export type GmResponseCommonVehicleIgnitionStatusView = {
  result?: VehicleIgnitionStatusView;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetMinimalStatusObject = {
  value?: object;
  deviceTimestamp?: string;
};
export type AssetStatusView = {
  statuses?: {
    [key: string]: AssetMinimalStatusObject;
  };
};
export type GmResponseCommonAssetStatusView = {
  result?: AssetStatusView;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type PendingOnboardingVehicleListItem = {
  sessionId?: string;
  vin?: string;
  organizationId?: string;
  fleetId?: string;
  status?: 'PREPARING' | 'REVIEWING_OWNERSHIP';
  createdOn?: string;
  vehicleImageSrc?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleYear?: string;
};
export type PendingOnboardingVehiclesResponse = {
  items?: PendingOnboardingVehicleListItem[];
  totalItems?: number;
  token?: string;
};
export type GmResponseCommonPendingOnboardingVehiclesResponse = {
  result?: PendingOnboardingVehiclesResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type RemoteCommandResponse = {
  requestTime?: string;
  completionTime?: string;
  url?: string;
  status?: string;
  type?: string;
  requestId?: string;
  error?: string;
  body?: object;
  payload?: object;
};
export type GmResponseCommonOptionalRemoteCommandResponse = {
  result?: RemoteCommandResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ChargeLevelSummary = {
  charging?: number;
  criticalCount?: number;
  lowCount?: number;
  goodCount?: number;
  highCount?: number;
};
export type ChargeLevelDashboardSummaryResponse = {
  organizationId?: string;
  fleetId?: string;
  totalEVCount?: number;
  chargeLevelSummary?: ChargeLevelSummary;
};
export type GmResponseCommonChargeLevelDashboardSummaryResponse = {
  result?: ChargeLevelDashboardSummaryResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type OilLifeStatusSummary = {
  criticalCount?: number;
  lowCount?: number;
  goodCount?: number;
};
export type OilLifeDashboardSummaryResponse = {
  organizationId?: string;
  fleetId?: string;
  totalIceCount?: number;
  oilLifeStatusSummary?: OilLifeStatusSummary;
};
export type GmResponseCommonOilLifeDashboardSummaryResponse = {
  result?: OilLifeDashboardSummaryResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleFieldActionDetails = {
  title?: string;
  releaseDate?: string;
  type?: 'ZPSR' | 'ZPER' | 'ZNCR' | 'ZCSP';
};
export type VehicleFieldActionDetailsResponse = {
  id?: string;
  fieldActions?: VehicleFieldActionDetails[];
};
export type GmResponseCommonVehicleFieldActionDetailsResponse = {
  result?: VehicleFieldActionDetailsResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleBoardingProfileDetail = {
  id?: string;
  vin?: string;
  docType?: string;
  status?: 'INACTIVE' | 'OFFBOARDING' | 'ONBOARDING';
  organizationId?: string;
  createdOn?: string;
  updatedOn?: string;
  lastCompletedBoardingSessionId?: string;
  lastAbortedBoardingSessionId?: string;
  currentBoardingSessionId?: string;
};
export type GmResponseCommonVehicleBoardingProfileDetail = {
  result?: VehicleBoardingProfileDetail;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type WorkflowEventData = {
  result?: string;
  context?: string;
};
export type WorkflowEvent = {
  createdOn?: string;
  data?: WorkflowEventData;
  eventType?:
    | 'SESSION_STARTED'
    | 'SESSION_COMPLETED'
    | 'SESSION_FAILED'
    | 'SESSION_TERMINATED'
    | 'ACTIVITY_SCHEDULED'
    | 'ACTIVITY_COMPLETED'
    | 'ACTIVITY_FAILED'
    | 'WAIT_SCHEDULED'
    | 'WAIT_COMPLETED'
    | 'WAIT_FAILED';
  name?: string;
};
export type VehicleProofOfOwnership = {
  status?: 'PENDING' | 'REVIEW' | 'CONFIRMED' | 'REJECTED';
  requestReviewDate?: string;
  requestReviewReason?:
    | 'ONSTAR_ACCOUNT_NO_OWNER_EMAIL'
    | 'ONSTAR_ACCOUNT_NOT_ACTIVE'
    | 'ONSTAR_ACCOUNT_NOT_FOUND'
    | 'ONSTAR_NOT_AVAILABLE'
    | 'NO_MATCHING_ONSTAR_ACCOUNT_EMAIL'
    | 'NO_MATCHING_FAN'
    | 'ONSTAR_GEN_NOT_SUPPORTED'
    | 'VEHICLE_POWERTRAIN_NOT_SUPPORTED'
    | 'VEHICLE_ACCOUNT_TYPE_NOT_SUPPORTED'
    | 'UNEXPECTED';
  confirmedDate?: string;
  confirmedBy?: string;
  confirmedNote?: string;
  confirmedApproach?: 'AUTO' | 'MANUAL';
  rejectedDate?: string;
  rejectedBy?: string;
  rejectedNote?: string;
};
export type VehicleBoardingParameters = {
  fleetAccountNumber?: string;
  onstarAccountNumber?: string;
  onstarTcpsAccepted?: boolean;
  fleetId?: string;
};
export type VehicleBoardingWorkflowSessionDetail = {
  id?: string;
  vin?: string;
  docType?: string;
  batchId?: string;
  createdBy?: string;
  createdOn?: string;
  events?: WorkflowEvent[];
  organizationId?: string;
  ownership?: VehicleProofOfOwnership;
  remoteProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
  sessionType?: 'ONBOARDING' | 'OFFBOARDING';
  status?: 'ACTIVE' | 'COMPLETED' | 'FAILED' | 'TERMINATED';
  telemetryProvider?: 'Particle' | 'Particle-Sim' | 'OnStar' | 'OnStar-Sim';
  vehicleBoardingParameters?: VehicleBoardingParameters;
  vehicleImageSrc?: string;
  vehicleModel?: string;
  vehicleMake?: string;
  vehicleYear?: string;
};
export type GmResponseCommonVehicleBoardingWorkflowSessionDetail = {
  result?: VehicleBoardingWorkflowSessionDetail;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type BdPageVehicleBoardingWorkflowSessionDetail = {
  items: VehicleBoardingWorkflowSessionDetail[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonBdPageVehicleBoardingWorkflowSessionDetail = {
  result?: BdPageVehicleBoardingWorkflowSessionDetail;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ActionResponseJson = {
  /** Type of action */
  type?: string;
  /** Action information */
  value?: object;
};
export type ActorResponseJson = {
  /** Actor id */
  id?: string;
  /** Actor name */
  name?: string;
  /** Method of acting */
  method?: string;
};
export type AssetHistoryResponseJson = {
  /** Asset id */
  id?: string;
  /** Asset id */
  assetId?: string;
  /** Time of action */
  timestamp?: string;
  /** Action that occurred */
  action?: ActionResponseJson;
  /** Actor that caused action */
  actor?: ActorResponseJson;
  /** Asset state */
  state?: {
    [key: string]: object;
  };
};
export type PaginatedDataResponseAssetHistoryResponseJson = {
  items?: AssetHistoryResponseJson[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonPaginatedDataResponseAssetHistoryResponseJson = {
  result?: PaginatedDataResponseAssetHistoryResponseJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type FleetAssetCountSummaryItem = {
  fleetId?: string;
  numberOfAssets?: number;
};
export type HubAssetCountSummaryItem = {
  hubId?: string;
  numberOfAssets?: number;
  fleets?: {
    [key: string]: FleetAssetCountSummaryItem;
  };
};
export type OrganizationAssetCountSummaryItem = {
  organizationId?: string;
  numberOfAssets?: number;
  hubs?: {
    [key: string]: HubAssetCountSummaryItem;
  };
};
export type GmResponseCommonOrganizationAssetCountSummaryItem = {
  result?: OrganizationAssetCountSummaryItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AssetByIdDetailResponseV20231121 = {
  /** Asset id */
  id?: string;
  /** Asset name */
  name?: string;
  /** Asset's hardware id such as a VIN. */
  hardwareId?: string;
  /** Asset's license plate. */
  licensePlate?: string;
  /** Fleet that an asset is associated with. */
  fleet?: FleetResponseJson;
  /** Hub that an asset is associated with. */
  hub?: HubResponseJson;
  /** Organization that an asset is associated with. */
  organization?: OrganizationResponseJson;
  /** Asset state */
  state?: StateResponseJson;
  /** Asset status */
  status?: {
    [key: string]: StatusValueResponseJson;
  };
  /** Asset configuration */
  configuration?: {
    [key: string]: object;
  };
  /** Asset capabilities */
  capabilities?: {
    [key: string]: string;
  };
  /** Asset timezone. This is inherited from the fleet. */
  timeZone?: string;
  /** Vehicle information such as make, model, year, propulsion */
  vehicleType?: VehicleType;
  /** Asset battery and charging information */
  hvBatteryInfo?: BatteryInfo;
  /** Asset tcps accepted information */
  tcpsAccepted?: boolean;
  /** Vehicle organization primary ownership status */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** Asset creation date */
  creationDate?: string;
  /** Asset last updated time */
  lastUpdated?: string;
  /** Asset readiness */
  ready?: 'ready' | 'not_ready' | 'unknown';
  /** Asset fault count */
  faultCount?: number;
  /** Asset parent */
  parent?: string;
  /** Asset host */
  host?: string;
  /** Commands available for the Vehicle */
  availableCommands?: (
    | 'ALERT'
    | 'CANCEL_ALERT'
    | 'START'
    | 'CANCEL_START'
    | 'ENABLE_IGNITION'
    | 'DISABLE_IGNITION'
    | 'LOCK_DOOR'
    | 'UNLOCK_DOOR'
  )[];
  diagnostics?: {
    [key: string]: DiagnosticsValueResponseJson;
  };
  offlineAccessToken?: string;
  fan?: string;
};
export type GmResponseCommonAssetByIdDetailResponseV20231121 = {
  result?: AssetByIdDetailResponseV20231121;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type GeoJsonGeometry = {
  type?: string;
  coordinates?: number[][];
};
export type GeoJsonProperties = {
  ts?: string[];
  dir?: number[];
  spd?: number[];
};
export type GeoJsonResponse = {
  type?: string;
  geometry?: GeoJsonGeometry;
  properties?: GeoJsonProperties;
};
export type AssetLocationHistoryResponseGeoJson = {
  geojson?: GeoJsonResponse;
  token?: string;
  total_items?: number;
};
export type GmResponseCommonAssetLocationHistoryResponseGeoJson = {
  result?: AssetLocationHistoryResponseGeoJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type LocationHistoryMetadata = {
  ts?: string;
  dir?: number;
  spd?: number;
};
export type AssetLocationHistoryResponseJson = {
  metadata?: LocationHistoryMetadata[];
  coordinates?: number[][];
  token?: string;
  total_items?: number;
};
export type GmResponseCommonAssetLocationHistoryResponseJson = {
  result?: AssetLocationHistoryResponseJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type GmResponseCommonMapStringStatusValueResponseJson = {
  result?: {
    [key: string]: StatusValueResponseJson;
  };
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type VehicleDaySummary = {
  /** Date */
  day?: string;
  /** Drive time in s */
  driveTime?: number;
  /** Distance driven in km */
  distanceDriven?: number;
  /** Days driven */
  daysDriven?: number;
  /** Number of trips completed */
  trips?: number;
  /** Energy used in kWh */
  energyUsed?: number;
  /** Energy consumption rate in kWh/km */
  energyUsedRate?: number;
  /** Charge time in seconds */
  chargeTime?: number;
  /** Number of seatbelt violations */
  seatbeltViolations?: number;
  /** Number of acceleration or brake violations */
  accelerationOrBrakeViolations?: number;
  /** Efficiency */
  efficiency?: number;
  /** Battery start in % state of charge */
  batteryStart?: number;
  /** Battery end in % state of charge */
  batteryEnd?: number;
  /** Difference between battery start and end in % state of charge (start - end) */
  batteryStartEndDiff?: number;
  /** Estimated range start in km */
  estimatedRangeStart?: number;
  /** Estimated range end in km */
  estimatedRangeEnd?: number;
  /** Difference between start and end estimated range in km (start - end) */
  estimatedRangeStartEndDiff?: number;
  /** Odometer start in km */
  odometerStart?: number;
  /** Odometer end in km */
  odometerEnd?: number;
  /** Difference between the start and end odometer */
  odometerStartEndDiff?: number;
  /** Initial powered-on timestamp */
  ignitionStart?: string;
  /** Final powered-off timestamp */
  ignitionEnd?: string;
  /** Difference between initial powered-on and final powered-off in s (start - end) */
  ignitionStartEndDiff?: number;
  /** Powered-on time in s */
  ignitionOnTime?: number;
  /** Powered-off time in s */
  ignitionOffTime?: number;
  /** Time Zone */
  timeZone?: string;
};
export type WrapperForListOfVehicleDayItems = {
  /** Vehicle Day Summary */
  days?: VehicleDaySummary[];
};
export type GmResponseCommonVehicleDayResponse = {
  result?: WrapperForListOfVehicleDayItems;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ComplianceSummaryModel = {
  compliant?: number;
  nonCompliant?: number;
  unknown?: number;
};
export type ComplianceSummaryResult = {
  models?: {
    [key: string]: ComplianceSummaryModel;
  };
};
export type GmResponseCommonComplianceSummaryResult = {
  result?: ComplianceSummaryResult;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type BatterySummaryItem = {
  critical?: number;
  impaired?: number;
  info0?: number;
  info1?: number;
  no_data?: number;
};
export type ChargeSummaryItem = {
  charging?: number;
  no_data?: number;
};
export type DiagnosticSummaryItem = {
  tire_low?: number;
  tire_flat?: number;
  fluid_low_brake?: number;
  fluid_low_washer?: number;
  mil_critical?: number;
  mil_impaired?: number;
};
export type ConnectivitySummaryItem = {
  online?: number;
  offline?: number;
  no_data?: number;
};
export type AvailabilitySummaryItem = {
  in_service?: number;
  out_of_service?: number;
};
export type DashboardSummaryItem = {
  type?: string;
  total?: number;
  ready?: number;
  battery?: BatterySummaryItem;
  charge?: ChargeSummaryItem;
  diagnostics?: DiagnosticSummaryItem;
  connectivity?: ConnectivitySummaryItem;
  availability?: AvailabilitySummaryItem;
  not_ready?: number;
};
export type BatterySettings = {
  thresholds?: {
    [key: string]: number;
  };
};
export type DashboardSettings = {
  battery?: BatterySettings;
};
export type DashboardSummaryWrapper = {
  summaries?: DashboardSummaryItem[];
  settings?: DashboardSettings;
};
export type GmResponseCommonDashboardSummaryWrapper = {
  result?: DashboardSummaryWrapper;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type AddressJson = {
  /** Address line 1 */
  line1?: string;
  /** Address line 2 */
  line2?: string;
  /** Address city */
  city?: string;
  /** Address state or province */
  state?: string;
  /** Address postal code */
  postalCode?: string;
  /** Address country */
  country?: string;
};
export type LocationResponseJson = {
  lon?: number;
  lat?: number;
  address?: AddressJson;
};
export type OperationalStatus = {
  primary?: 'OFFLINE' | 'STOPPED' | 'MOVING' | 'UNKNOWN';
  speed?: number;
  direction?: number;
  lastUpdated?: string;
};
export type ViewMapListItem = {
  id?: string;
  name?: string;
  battery?: BatteryInfo;
  location?: LocationResponseJson;
  operational?: OperationalStatus;
  connectivity?: 'online' | 'offline';
  brakeFluid?: 'good' | 'critical' | 'no_data';
  washerFluidState?: 'good' | 'impaired' | 'no_data';
  tires?: 'good' | 'impaired' | 'critical' | 'no_data';
  ready?: boolean;
  faultCount?: number;
  milState?: string;
  availability?: 'in_service' | 'out_of_service';
  cubbyTempState?: 'good' | 'impaired' | 'critical';
  lastCommunicated?: string;
};
export type PaginatedDataResponseViewMapListItem = {
  items?: ViewMapListItem[];
  continuation_token?: string;
  total_items?: number;
};
export type GmResponseCommonPaginatedDataResponseViewMapListItem = {
  result?: PaginatedDataResponseViewMapListItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type GmResponseCommonAssetDetailResponseViewJson = {
  result?: AssetDetailResponseViewJson;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type DiagnosticsSummaryItem = {
  criticalCount?: number;
  warningCount?: number;
  okCount?: number;
  totalCount?: number;
};
export type DiagnosticDashboardSummaryItem = {
  organizationId?: string;
  fleetId?: string;
  totalVehicleCount?: number;
  diagnostics?: DiagnosticsSummaryItem;
};
export type GmResponseCommonDiagnosticDashboardSummaryItem = {
  result?: DiagnosticDashboardSummaryItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ConnectivityStatusItem = {
  /** The primary ownership status of the organization. */
  organizationPrimaryOwnershipStatus?:
    | 'AVAILABLE'
    | 'CLAIMING'
    | 'CLAIMED'
    | 'RELEASING';
  /** The provisioning state of the asset. */
  provisioningState?: 'in_progress' | 'failed' | 'completed';
};
export type ConnectivityStatusDashboardSummaryItem = {
  /** The ID of the organization. */
  organizationId?: string;
  /** The total count of vehicles. */
  totalVehicleCount?: number;
  /** A list of connectivity status items. */
  items?: ConnectivityStatusItem[];
};
export type GmResponseCommonConnectivityStatusDashboardSummaryItem = {
  result?: ConnectivityStatusDashboardSummaryItem;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type OrganizationSummaryResponse = {
  /** Day summary for entities day by day */
  days?: DaySummaryForEntitiesOrgFleetHubDayByDay[];
  /** Vehicle totals summary for most utilized vehicles */
  mostUtilized?: VehicleTotalsSummary[];
  /** Vehicle totals summary for least utilized vehicles */
  leastUtilized?: VehicleTotalsSummary[];
};
export type GmResponseCommonOrganizationSummaryResponse = {
  result?: OrganizationSummaryResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export const {
  useGet20211115ByIdQuery,
  useUpdateAssetMutation,
  useDeleteAssetMutation,
  useEnrollAssetInDataTelemetryMutation,
  useTerminateVehicleBoardingSessionMutation,
  useBatchOffBoardVehiclesMutation,
  useRefreshVehicleDataMutation,
  useTriggerAddCapabilitiesEventMutation,
  useGetAssetBatteryReadinessV20250211Mutation,
  useExportAssetReadinessCsvMutation,
  useExportAssetFluidReadinessCsvMutation,
  useExportAssetBatteryReadinessCsvMutation,
  useBatchOnboardVehiclesV2Mutation,
  useTriggerEventMutation,
  useGetActiveFieldActionVehicleSummaryMutation,
  useGetAssetReadinessV2Mutation,
  useRejectVehicleProofOfOwnershipMutation,
  useConfirmVehicleProofOfOwnershipMutation,
  useGetFieldActionSummaryReportMutation,
  useEnrollAssetsInTelemetryMutation,
  useGetAssetWithEnhancedDiagnosticReadinessMutation,
  useBatchOnboardVehiclesMutation,
  useGetAssetsByPageMutation,
  useGetAssetHistoryByPageMutation,
  useGetUtilizationV2Mutation,
  useGetUtilizationDisaggregatedByAssetMutation,
  useDoSubscribeV2Mutation,
  useGetAllAssetsQuery,
  useCreateAssetMutation,
  useTransitionStateMutation,
  useGetAssetWithTireReadinessMutation,
  useGetAssetWithReadinessMutation,
  useGetComplianceReportMutation,
  useGetAssetWithBatteryReadinessMutation,
  useSearchAssetsMutation,
  useUpdateProtectedFieldsForAssetMutation,
  useGetVehiclesBasicDetailsQuery,
  useListPendingConnectionVehiclesQuery,
  useSearchAssetQuery,
  useListIncompleteOnboardingVehiclesQuery,
  useGetAssetsListQuery,
  useGetVehicleIgnitionStatusQuery,
  useGetAssetStatusWithFilteringQuery,
  useListPendingOnboardingVehiclesQuery,
  useExecuteRemoteCommandsQuery,
  useGetChargeLevelDashboardSummaryQuery,
  useGetOilLifeDashboardSummaryQuery,
  useGetFieldActionsForVinQuery,
  useGetVehicleBoardingProfileQuery,
  useGetCurrentVehicleBoardingSessionQuery,
  useListVehicleBoardingSessionsQuery,
  useGetVehicleBoardingSessionBySessionIdQuery,
  useGetAssetHistoryLimitedResponseQuery,
  useGetOrganizationAssetCountSummaryQuery,
  useGetAssetByIdWithFanQuery,
  useGetAssetHardwareIdWithFanQuery,
  useGetAssetLocationHistoryGeoJson20231107Query,
  useGetComplianceReportV2Query,
  useGetAssetLocationHistoryV20220524Query,
  useGetAssetStatusQuery,
  useGetAssetUtilizationQuery,
  useGetComplianceSummaryQuery,
  useGetDashboardMetricsQuery,
  useGetMapListItemsQuery,
  useGetAssetWithi8NDetailsQuery,
  useGetDiagnosticDashboardSummaryMetricsQuery,
  useGetConnectivityStatusDashboardSummaryMetricsQuery,
  useGetOrganizationUtilizationQuery,
  useGetAssetHardwareIdQuery,
} = injectedRtkApi;

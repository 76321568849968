import {
  API_VERSION_DEFAULTS,
  BATCH_VEHICLES_API_BASE_CONFIG,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { DEFAULT_PAGINATION_ROW_PER_PAGE } from '~/common/constants/common.constant';
import {
  AddVehiclesApiRequest,
  AddVehiclesApiResponse,
  GetIncompleteVehiclesListParams,
  GetPendingConnectionVehiclesListParams,
  GetPendingVerificationVehiclesListParams,
  IncompleteVehicleDetailsApiResponse,
  PendingConnectionVehicleDetailsApiResponse,
  PendingVerificationVehicleDetailsApiResponse,
} from '~/common/models/addVehicles.model';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { PagedResultWithErrors } from '~/common/models/common.model';
import { BDError } from '~/common/models/error.model';
import {
  addCSContinuationTokenHeader,
  addCSFleetIdHeader,
  addCSHubIdHeader,
  addCSOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { SELECT_ALL_MAX_COUNT } from './addVehiclesSlice.constants';

export enum AddVehiclesActionType {
  ADD_VEHICLES = 'addVehicles/addVehicles',
  GET_PENDING_VERIFICATION = 'addVehicles/getPendingVerificationVehicles',
  GET_PENDING_VERIFICATION_EXPORT = 'addVehicles/getPendingVerificationVehiclesExport',
  GET_PENDING_CONNECTION = 'addVehicles/getPendingConnectionVehicles',
  GET_PENDING_CONNECTION_EXPORT = 'addVehicles/getPendingConnectionVehiclesExport',
  GET_INCOMPLETE = 'addVehicles/getIncompleteVehicles',
  GET_INCOMPLETE_EXPORT = 'addVehicles/getIncompleteVehiclesExport',
}

const addVehicles = makeThunk(
  AddVehiclesActionType.ADD_VEHICLES,
  makePostPayloadCreator<
    ApiResponse<AddVehiclesApiResponse>,
    AddVehiclesApiRequest
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.batchOnboardAPI}/boarding/vehicles/onboard`,
    axiosOptions: ({ organizationsId, hubsId, fleetId }) =>
      addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            BATCH_VEHICLES_API_BASE_CONFIG,
            organizationsId
          ),
          hubsId
        ),
        fleetId
      ),
    argAdapter: (params) => ({
      requestBody: { file: params.vinData },
    }),
    responseAdapter: (response: any) => {
      if (!!response && hasApiResult<any>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Add Vehicles response', {
        data: response,
      });
    },
  })
);

const getPendingVerificationVehicles = makeThunk(
  AddVehiclesActionType.GET_PENDING_VERIFICATION,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<PendingVerificationVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetPendingVerificationVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.pendingVerificationVehiclesAPI}/boarding/vehicles/pending`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      const filterType = args?.status;
      const size = args.rowsPerPage
        ? String(args.rowsPerPage)
        : String(DEFAULT_PAGINATION_ROW_PER_PAGE);
      return {
        requestParams: {
          size: size,
          vin: args?.vin,
          status: filterType, //right now the API only accepts one filter value
          fleetId: args?.fleetsId,
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetPendingVerificationVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(
        `Unexpected GET Pending Verification Vehicles response: `,
        {
          data: { ...args, ...response },
        }
      );
    },
  })
);

const getPendingConnectionVehicles = makeThunk(
  AddVehiclesActionType.GET_PENDING_CONNECTION,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<PendingConnectionVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetPendingConnectionVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.pendingConnectionVehiclesAPI}/views/pendingConnectionVehicles`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      const size = args.rowsPerPage
        ? String(args.rowsPerPage)
        : String(DEFAULT_PAGINATION_ROW_PER_PAGE);
      return {
        requestParams: {
          size: size,
          vin: args?.vin,
          fleetId: args?.fleetsId,
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetPendingConnectionVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(
        `Unexpected GET Pending Connection Vehicles response: `,
        {
          data: { ...args, ...response },
        }
      );
    },
  })
);

const getPendingVerificationVehiclesExport = makeThunk(
  AddVehiclesActionType.GET_PENDING_VERIFICATION_EXPORT,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<PendingVerificationVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetPendingVerificationVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.pendingVerificationVehiclesAPI}/boarding/vehicles/pending`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      const filterType = args?.status;
      return {
        requestParams: {
          size: String(SELECT_ALL_MAX_COUNT),
          vin: args?.vin,
          status: filterType, //right now the API only accepts one filter value
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetPendingVerificationVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(`Unexpected GET Pending Vehicles Export response: `, {
        data: { ...args, ...response },
      });
    },
  })
);

const getPendingConnectionVehiclesExport = makeThunk(
  AddVehiclesActionType.GET_PENDING_CONNECTION_EXPORT,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<PendingConnectionVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetPendingConnectionVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.pendingConnectionVehiclesAPI}/views/pendingConnectionVehicles`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      return {
        requestParams: {
          size: String(SELECT_ALL_MAX_COUNT),
          vin: args?.vin,
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetPendingConnectionVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(
        `Unexpected GET Pending Connection Vehicles Export response: `,
        {
          data: { ...args, ...response },
        }
      );
    },
  })
);

const getIncompleteVehicles = makeThunk(
  AddVehiclesActionType.GET_INCOMPLETE,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<IncompleteVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetIncompleteVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.incompleteVehiclesAPI}/boarding/vehicles/incomplete`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      const filterType = args?.reason;
      const size = args.rowsPerPage
        ? String(args.rowsPerPage)
        : String(DEFAULT_PAGINATION_ROW_PER_PAGE);
      return {
        requestParams: {
          size: size,
          vin: args?.vin,
          reason: filterType, //right now the API only accepts one filter value
          fleetId: args?.fleetsId,
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetIncompleteVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(`Unexpected GET Incomplete Vehicles response: `, {
        data: { ...args, ...response },
      });
    },
  })
);

const getIncompleteVehiclesExport = makeThunk(
  AddVehiclesActionType.GET_INCOMPLETE_EXPORT,
  makeGetPayloadCreator<
    ApiResponse<
      PagedResultWithErrors<IncompleteVehicleDetailsApiResponse> & {
        totalItems: number;
        token: string;
      }
    >,
    GetIncompleteVehiclesListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/assets/${API_VERSION_DEFAULTS.incompleteVehiclesAPI}/boarding/vehicles/incomplete`,
    axiosOptions: ({
      organizationsId,
      hubsId,
      fleetsId,
      continuationToken,
    }) => {
      return addCSFleetIdHeader(
        addCSHubIdHeader(
          addCSOrganizationIdHeader(
            addCSContinuationTokenHeader(DEFAULT_API_CONFIG, continuationToken),
            organizationsId
          ),
          hubsId
        ),
        fleetsId
      );
    },
    argAdapter: (args) => {
      const filterType = args?.reason;
      return {
        requestParams: {
          size: String(SELECT_ALL_MAX_COUNT),
          vin: args?.vin,
          status: filterType,
        },
      };
    },
    responseAdapter: (
      response: any,
      _,
      args: GetIncompleteVehiclesListParams
    ) => {
      if (hasApiResult<any>(response)) {
        return { ...args, ...response };
      }
      throw new BDError(
        `Unexpected GET Incomplete Vehicles Export response: `,
        {
          data: { ...args, ...response },
        }
      );
    },
  })
);

export const ADD_VEHICLES_ACTIONS = {
  addVehicles,
  getPendingVerificationVehicles,
  getPendingVerificationVehiclesExport,
  getPendingConnectionVehicles,
  getPendingConnectionVehiclesExport,
  getIncompleteVehicles,
  getIncompleteVehiclesExport,
};

import { createSelector, createSlice, EntityState } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

import { assetsApi } from '~/api';
import { RootState } from '~/app/rootReducer';
import { RouteParams } from '~/common/configs/route.config';
import {
  SearchConfigType,
  SessionConfigType,
} from '~/common/constants/common.constant';
import {
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ApiAssetType,
  Asset,
  AssetComplianceDetail,
  AssetConnectivityStatus,
  AssetEvent,
  AssetEventAction,
  AssetFault,
  AssetModel,
  type AssetRecall,
  AssetReportType,
  AssetServiceStatus,
  AssetTab,
  AssetType,
  LockStatus,
  UpdateApiAssetType,
  WithPartialTelemetry,
} from '~/common/models/asset.model';
import {
  AdvisorVehicleListItem,
  AssetReportItem,
  BatteryChargingStatus,
  BatterySocStatus,
  BrakePadStatus,
  FuelStatus,
  LastCommunicatedStatus,
  OilLifeStatus,
  PendingVehicleItem,
  PowerType,
  ReadyStatus,
  ReportDiagnosticState,
  SafetyCompliance,
  VehiclePropulsion,
} from '~/common/models/asset-report.model';
import {
  AssetRawLocation,
  ConnectivityStatusType,
  ContinuationCache,
  FormPayload,
  FormSubmitResponse,
  ListViewSession,
  SearchCriteria,
  SkippableStateUpdate,
} from '~/common/models/common.model';
import { FieldActionType } from '~/common/models/recalls.model';
import { NavBarSections } from '~/common/models/sections.model';
import { IDLE_OPERATION_STATUS } from '~/common/utils/store/selector.helper';
import { AssetRemoteCommandRequestType } from '~/features/assets/details/remoteCommands/remoteCommands.constants';
import { NotificationSettingsConfigType } from '~/features/settings/settings.constant';

import { PeripheralVehiclesConfig } from '../peripherals/config/constants/state';
import { EventType } from './details/history/assetEventFilter/AssetEventFilter';
import { ValidEditVehicleForm } from './form/EditVehicleForm';
import { ASSETS_ACTIONS } from './state/assetsSlice.actions';
import {
  advisorVehicleListAdapter,
  assetComplianceAdapter,
  assetEventsAdapter,
  assetLocationsAdapter,
  assetPeripheralVehicleAdapter,
  assetReportItemsAdapter,
  assetsAdapter,
  assetSearchAdapter,
  assetVehicleNotificationAdapter,
} from './state/assetsSlice.adapters';
import { INITIAL_ASSETS_STATE } from './state/assetsSlice.constants';
import {
  ASSETS_EXTRA_REDUCER_BUILDERS,
  ASSETS_REDUCERS,
} from './state/assetsSlice.reducers';

export enum AssetRequestType {
  GET_ASSET_EPALLET_VERSIONS = 'Get Asset ePallet versions',
  GET_ASSET_HISTORY = 'Get Asset History',
  GET_ASSET_LOCATION = 'Get Asset Location',
  LOCK_UNLOCK_ASSEST = 'Lock/Unlock Asset',
  GET_NEXT_EPALLET_SERIAL_NUMBER = 'Get Next ePallet Serial Number',
  GET_TRACKER_SERIALS = 'Get Tracker Serials',
  SEARCH_ASSETS = 'Search Assets',
  SEARCH_NOTIFICATION_SETTINGS_ASSETS = 'Vehicle Notification Assets',
  GET_PENDING_VEHICLES = 'Get Pending Vehicles',
  GET_ASSET_IGNITION_STATUS = 'Get Asset Ignition Status',
}

export const FIRST_PAGE = 0;
export const DEFAULT_ITEMS_PER_PAGE = 10;

export interface AssetEventListSession {
  selectedTab: AssetTab;
  selectedType: EventType;
  selectedPage: number;
  recalls: {
    operationStatus: OperationStatus;
    recallItems?: AssetRecall[] | null;
  };
  rowsPerPage: number;
  selectedEvent: string;
  selectedDate: string;
  selectedStartTime: string;
  selectedEndTime: string;
  expandedRows: number[];
  selectedTripId?: string;
  activeSection?: NavBarSections;
  faults?: AssetFault[];
  selectedStartDate?: string;
  selectedEndDate?: string;
  assetStatus: {
    operationStatus: OperationStatus;
  };
}

export interface AssetListView {
  filters: Record<keyof WithPartialTelemetry<Asset>, string>;
  list: ListViewSession;
  selectedIndices: number[];
}
export type SelectedVehicleIds = {
  [key: string]: boolean;
};
export enum AssetReportConfigType {
  REPORT_VIEW = 'Report View',
  COMPLIANCE_VIEW = 'Compliance View',
  PENDING_VIEW = 'Pending View',
}

export interface AssetReportTab {
  activeTab: AssetType;
}

export interface AssetReportDays {
  days: number;
}

export type AssetListViewSession = {
  [id in AssetType]: AssetListView;
};

export type AssetReportSessionState = {
  [id in AssetType]?: AssetListView;
} & AssetReportTab &
  AssetReportDays;

export type AssetReportViewSession = {
  [id in AssetReportType]: AssetReportSessionState;
};

export interface AssetListSession
  extends AssetListViewSession,
    ListViewSession {
  activeTab: AssetType;
  searchCriteria: SearchCriteria | undefined;
  filterType: AssetListFilterCriteria;
  operationStatus: OperationStatus;
  activeReport?: AssetReportType;
  withoutAssignment?: boolean;
  selectedAssets?: EntityState<AssetReportItem>; //updated
  withoutOrganizationAssignment?: boolean;
  filterModalOpen?: boolean;
  selectedVehicleIds?: SelectedVehicleIds;
  continuationCache?: ContinuationCache<AssetReportItem[]>;
  pendingVehiclesContinuationCache?: ContinuationCache<PendingVehicleItem[]>;
  continuationAdvisorVehicleCache?: ContinuationCache<AdvisorVehicleListItem[]>;
  exportVehicleIds?: string[];
}

export interface AssetComplianceFilterCriteria {
  selectedAssetTypes?: ApiAssetType[];
  selectedSafetyComplianceStatus?: SafetyCompliance;
}
export interface AssetComplianceSession extends ListViewSession {
  searchCriteria: SearchCriteria;
  filterCriteria: AssetComplianceFilterCriteria;
  operationStatus: OperationStatus;
}

export interface AssetListFilterCriteria {
  assetType?: ApiAssetType[];
  readyStatus?: ReadyStatus[];
  connectivityStatus?: AssetConnectivityStatus[];
  brakeFluidStatus?: ReportDiagnosticState[];
  washerFluidStatus?: ReportDiagnosticState[];
  tireStatus?: ReportDiagnosticState[];
  batterySocStatus?: BatterySocStatus[];
  batteryChargingStatus?: BatteryChargingStatus[];
  safetyComplianceStatus?: SafetyCompliance[];
  faultStatus?: ReportDiagnosticState[];
  diagnosticsHealthStatus?: ReportDiagnosticState[];
  availabilityStatus?: AssetServiceStatus[];
  powerType?: PowerType[];
  fuelStatus?: FuelStatus[];
  oilLifeStatus?: OilLifeStatus[];
  brakePadFrontStatus?: BrakePadStatus[];
  brakePadRearStatus?: BrakePadStatus[];
  engineAirFilterStatus?: ReportDiagnosticState[];
  connectivityBoardingStatus?: ConnectivityStatusType[];
  lastCommunicatedStatus?: LastCommunicatedStatus[];
  fieldActionTypeStatus?: FieldActionType[];
  dieselExhaustFluidStatus?: ReportDiagnosticState[];
  advisorFilterType?: VehiclePropulsion[];
}

export const ASSET_REPORT_FILTER_EMPTY_STATE = {
  assetType: [],
  readyStatus: [],
  connectivityStatus: [],
  brakeFluidStatus: [],
  washerFluidStatus: [],
  tireStatus: [],
  batterySocStatus: [],
  batteryChargingStatus: [],
  safetyComplianceStatus: [],
  faultStatus: [],
  diagnosticsHealthStatus: [],
  availabilityStatus: [],
  powerType: [],
  fuelStatus: [],
  oilLifeStatus: [],
  brakePadFrontStatus: [],
  brakePadRearStatus: [],
  engineAirFilterStatus: [],
  connectivityBoardingStatus: [],
  lastCommunicatedStatus: [],
  fieldActionTypeStatus: [],
  dieselExhaustFluidStatus: [],
  advisorFilterType: [],
} as AssetListFilterCriteria;

export enum AssetListFilterKey {
  ASSET_TYPE = 'assetType',
  READY_STATUS = 'readyStatus',
  CONNECTIVITY_STATUS = 'connectivityStatus',
  BRAKE_FLUID_STATUS = 'brakeFluidStatus',
  WASHER_FLUID_STATUS = 'washerFluidStatus',
  TIRE_STATUS = 'tireStatus',
  BATTERY_SOC_STATUS = 'batterySocStatus',
  BATTERY_CHARGING_STATUS = 'batteryChargingStatus',
  SAFETY_COMPLIANCE_STATUS = 'safetyComplianceStatus',
  SELECTED_ASSET_TYPES = 'selectedAssetTypes',
  FAULT_STATUS = 'faultStatus',
  DIAGNOSTICS_HEALTH_STATUS = 'diagnosticsHealthStatus',
  AVAILABILITY_STATUS = 'availabilityStatus',
  POWER_TYPE = 'powerType',
  FUEL_STATUS = 'fuelStatus',
  OIL_LIFE_STATUS = 'oilLifeStatus',
  BRAKE_PAD_FRONT_STATUS = 'brakePadFrontStatus',
  BRAKE_PAD_REAR_STATUS = 'brakePadRearStatus',
  ENGINE_AIR_FILTER_STATUS = 'engineAirFilterStatus',
  CONNECTIVITY_BOARDING_STATUS = 'connectivityBoardingStatus',
  LAST_COMMUNICATED_STATUS = 'lastCommunicatedStatus',
  DIESEL_EXHAUST_FLUID = 'dieselExhaustFluidStatus',
  RECALL_STATUS = 'fieldActionTypeStatus',
  VEHICLE_TYPE = 'advisorFilterType',
}

export interface AssetReportSession extends AssetReportViewSession {
  activeSession: boolean;
}

/**
 * AssetSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface AssetSessionConfigType
 * @property {SessionConfigType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing, E.g.
 * id of assetDetails is assetId, id for asset list under fleet is fleetId
 */
export interface AssetSessionConfigType {
  [SessionConfigType.DETAIL_VIEW]?: {
    [id: string]: Partial<AssetEventListSession>;
  };
  [SessionConfigType.LIST_VIEW]?: {
    [id: string]: Partial<AssetListSession> | undefined;
  };
  [AssetReportConfigType.REPORT_VIEW]?: {
    [id: string]: Partial<AssetReportSession> | undefined;
  };
  [NotificationSettingsConfigType.ADD_VIEW]?: {
    [id: string]: Partial<AssetListSession> | undefined;
  };
  [PeripheralVehiclesConfig.LIST_VIEW]?: {
    [id: string]: Partial<AssetListSession> | undefined;
  };
  [AssetReportConfigType.COMPLIANCE_VIEW]?: {
    [id: string]: Partial<AssetComplianceSession> | undefined;
  };
  [AssetReportConfigType.PENDING_VIEW]?: {
    [id: string]: Partial<AssetListSession> | undefined;
  };
  [SearchConfigType.SEARCH_VIEW]?: {
    [id: string]: Partial<AssetListSession> | undefined;
  };
}

type AssetOperations =
  | AssetRequestType
  | BDRequestType
  | AssetRemoteCommandRequestType;

type AssetOperationStatus = {
  [key in AssetOperations]?: OperationStatus;
};

export interface AssetsState {
  nextEPalletSerial: string;
  trackerSerials: string[];
  ePalletVersions: AssetModel[];
  operations: AssetOperationStatus;
  assetEvents: EntityState<AssetEvent>;
  assetLocations: EntityState<AssetRawLocation>;
  sessionConfigs: {
    [k in
      | SessionConfigType
      | NotificationSettingsConfigType
      | PeripheralVehiclesConfig
      | AssetReportConfigType
      | SearchConfigType]?: AssetSessionConfigType[k];
  };
  startDate: string;
  endDate: string;
  pendingAssetOperation: LockStatus | undefined;
  pendingVehiclesList: EntityState<PendingVehicleItem>;
  selectedMarkerId: string;
  currentEntityContexts?: RouteParams;
  assetList: EntityState<Asset>;
  assetReportItems: EntityState<AssetReportItem>;
  assetVehicleNotificationList: EntityState<AssetReportItem>;
  assetPeripheralVehicleList: EntityState<AssetReportItem>;
  assetComplianceList: EntityState<AssetComplianceDetail>;
  advisorVehiclesList: EntityState<AdvisorVehicleListItem>;
}

export type AssetRouteParams = Pick<RouteParams, 'assetsId'>;

export type AssetHistoryParams = Required<Pick<RouteParams, 'assetsId'>> & {
  startDate: string;
  endDate: string;
  actions?: AssetEventAction[];
  locationUpgradeFlagActive?: boolean;
};

export type UpdateAssetFormPayload = FormPayload<
  ValidEditVehicleForm & {
    type: UpdateApiAssetType;
  },
  'assetsId'
>;

export type AssetFormSubmitResponse = FormSubmitResponse<Asset>;

export type AssetParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
> & {
  sessionId: string;
};

type PendingVehicleParams = {
  organizationsId: string;
  sessionId: string;
  searchTerm?: string;
};

//TODO:Update with PaginationContinuationInfo ones the report endpoints are updated with continuation token
export interface VehicleReportPaginationInfo {
  rowsPerPage: number;
  page?: number;
  continuationToken?: string | null;
}
export interface SearchFilterInfo {
  searchCriteria?: SearchCriteria;
  filterCriteria?: AssetListFilterCriteria;
}
export type AssetPaginatedListParams = AssetParams &
  VehicleReportPaginationInfo &
  SkippableStateUpdate;

export type PendingVehiclePaginatedListParams = PendingVehicleParams &
  VehicleReportPaginationInfo &
  SkippableStateUpdate;

export interface PendingVehicleResponse {
  totalItems: number;
  token: string | null;
  items: PendingVehicleItem[];
}

export const {
  getAssets,
  getReadinessReport,
  getVehicleListOverviewExport,
  getVehicleListBatteryExport,
  getVehicleListFluidsExport,
  getTiresReport,
  getBatteryReport,
  getPendingVehicles,
  getRecalls,
  getAssetsCompliance,
  searchAssets,
  getAssetDetails,
  getAssetRecalls,
  getAssetHistory,
  getAssetLocation,
  addAsset,
  updateAsset,
  lockAsset,
  updateServiceAvailability,
  deleteAsset,
  getNextEPalletSerial,
  getTrackerSerials,
  getEPalletVersions,
  reProvisionAsset,
  saveAssetProtectedFields,
  executeRemoteCommand,
  getAssetStatuses,
  getAssetIgnition,
  getAdvisorVehicleList,
  approveVehicle,
  rejectVehicle,
} = ASSETS_ACTIONS;

const {
  reduceSetAssetSessionConfig,
  reduceClearAssetListReportFilterSessionConfig,
  reduceResetAssetListReportSessionConfig,
  reduceResetEventDate,
  reduceSetPendingAssetOperation,
  reduceSetAssetLocationAddress,
  reduceClearOperations,
  reduceClearCertainAssetOperations,
  reduceClearAssetEvents,
  reduceClearAssetLocations,
  reduceSetSelectedMarkerId,
  reduceHandleAssetSubscriptionEvent,
  reduceSetSelectedAssets,
  reduceRecallContinuationCache,
  reducePendingVehiclesContinuationCache,
  reduceAdvisorVehicleListContinuationCache,
} = ASSETS_REDUCERS;

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: INITIAL_ASSETS_STATE,
  reducers: {
    setAssetSessionConfig: reduceSetAssetSessionConfig,
    clearAssetListReportFilterSessionConfig:
      reduceClearAssetListReportFilterSessionConfig,
    resetAssetListReportSessionConfig: reduceResetAssetListReportSessionConfig,
    resetEventDate: reduceResetEventDate,
    setPendingAssetOperation: reduceSetPendingAssetOperation,
    setAssetLocationAddress: reduceSetAssetLocationAddress,
    clearOperations: reduceClearOperations,
    clearCertainAssetOperations: reduceClearCertainAssetOperations,
    clearAssetEvents: reduceClearAssetEvents,
    clearAssetLocations: reduceClearAssetLocations,
    setSelectedMarkerId: reduceSetSelectedMarkerId,
    handleAssetSubscriptionEvent: reduceHandleAssetSubscriptionEvent,
    setSelectedAssets: reduceSetSelectedAssets,
    resetRecallContinuationCache: reduceRecallContinuationCache,
    resetPendingVehiclelListContinuationCache:
      reducePendingVehiclesContinuationCache,
    resetAdvisorVehicleListContinuationCache:
      reduceAdvisorVehicleListContinuationCache,
  },
  extraReducers: (builder) => {
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetsActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetDetailsActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetRecallsActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetHistoryActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetLocationActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildAddAssetActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildUpdateAssetActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildDeleteAssetActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildLockAssetActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildUpdateServiceAvailabilityActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetNextEPalletSerialActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetTrackerSerialsActionReducers(builder);
    // ASSETS_EXTRA_REDUCER_BUILDERS.buildGetSettingsNotificationsActionReducers(
    //   builder
    // );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetEPalletVersionsActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetReadinessReportActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetTiresReportActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetBatteryReportActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAsseComplianceListActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildSearchAssetsActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetRecallsActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildExecuteRemoteCommandActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetPeripheralVehiclesActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetPendingVehiclesActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetStatusActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetVehicleListOverviewExportReducer(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetVehicleListBatteryExportReducer(
      builder
    );

    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetVehicleListFluidsExportReducer(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAssetIgnitionActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildGetAdvisorVehicleListActionReducers(
      builder
    );
    ASSETS_EXTRA_REDUCER_BUILDERS.buildConfirmVehicleActionReducers(builder);
    ASSETS_EXTRA_REDUCER_BUILDERS.buildRejectVehicleActionReducers(builder);
    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = INITIAL_ASSETS_STATE;
    });
  },
});

export const {
  setAssetSessionConfig,
  resetAssetListReportSessionConfig,
  clearAssetListReportFilterSessionConfig,
  resetEventDate,
  setPendingAssetOperation,
  clearOperations,
  clearCertainAssetOperations,
  clearAssetEvents,
  clearAssetLocations,
  setAssetLocationAddress,
  setSelectedMarkerId,
  handleAssetSubscriptionEvent,
  setSelectedAssets,
  resetRecallContinuationCache,
  resetPendingVehiclelListContinuationCache,
  resetAdvisorVehicleListContinuationCache,
} = assetsSlice.actions;

export const {
  selectAll: selectAllAssets,
  selectById: selectAssetById,
  selectIds: selectAssetIds,
} = assetsAdapter.getSelectors<RootState>((state: RootState) => state.assets);

export const {
  selectAll: selectAllAssetEvents,
  selectById: selectAssetEventById,
  selectIds: selectAssetEventIds,
} = assetEventsAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetEvents
);

export const {
  selectAll: selectAllAssetLocations,
  selectById: selectAssetLocationById,
  selectIds: selectAssetLocationIds,
} = assetLocationsAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetLocations
);

export const {
  selectAll: selectAllAssetReportItems,
  selectById: selectAssetReportItemById,
  selectIds: selectAssetReportItemIds,
} = assetReportItemsAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetReportItems
);
export const {
  selectAll: selectAdvisorVehicleListItems,
  selectById: selectAdvisorVehicleListItemById,
  selectIds: selectAdvisorVehicleListItemIds,
} = advisorVehicleListAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.advisorVehiclesList
);
export const {
  selectAll: selectAllVehicleNotificationItems,
  selectById: selectVehicleNotificationItemById,
  selectIds: selectVehicleNotificationItemIds,
} = assetVehicleNotificationAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetVehicleNotificationList
);
export const {
  selectAll: selectAllPeripheralVehicleItems,
  selectById: selectPeripheralVehicleItemById,
  selectIds: selectPeripheralVehicleIds,
} = assetPeripheralVehicleAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetPeripheralVehicleList
);

// Selectors require entity state, not root state
export const {
  selectAll: selectAllSelectedAssets,
  selectById: selectSelectedAssetById,
  selectIds: selectSelectedAssetsIds,
} = assetReportItemsAdapter.getSelectors();

export const {
  selectAll: selectAssetSearchItems,
  selectById: selectAssetSearchItemById,
  selectIds: selectAssetSearchItemIds,
} = assetSearchAdapter.getSelectors<RootState>(
  (state: RootState) => state.assets.assetList
);

export const {
  selectAll: selectAssetComplianceList,
  selectById: selectAssetComplianceById,
  selectIds: selectAssetComplianceIds,
} = assetComplianceAdapter.getSelectors(
  (state: RootState) => state.assets.assetComplianceList
);
export const {
  selectAll: selectAllAdvisorSelectedvehicles,
  selectById: selectAdvisorSelectedVehicleById,
  selectIds: selectAdvisorSelectedVehicleIds,
} = advisorVehicleListAdapter.getSelectors();
export const selectAssetsState = (state: RootState): RootState['assets'] =>
  state.assets;

export const selectLastAssetEvent = createSelector(
  (state: RootState) => selectAllAssetEvents(state)[0],
  (lastEvent) => lastEvent?.timestamp
);

export const getAssetOperationStatus = (
  {
    operations,
  }: {
    operations: { [key in AssetRequestType]?: OperationStatus };
  },
  expected: AssetRequestType
): OperationStatus =>
  operations
    ? operations[expected] || IDLE_OPERATION_STATUS
    : IDLE_OPERATION_STATUS;

export const assetsReducer = assetsSlice.reducer;

export const { useGetVehiclesBasicDetailsQuery } = assetsApi;

import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile, Role } from '@gm-commercial/profile-model';

import BankIcon from '~/assets/icons/new/bank-icon.svg?react';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { ADMIN_ROLES } from '~/common/constants/common.constant';
import { FeatureFlagValueType } from '~/common/models/featureFlags.model';
import {
  ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
  ACCOUNT_PROFILE_PERSONAL_PATH,
} from '~/common/models/pages/accountPages.model';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import GearIcon from '../../../assets/icons/new/gearIcon.svg?react';
import ProfileIcon from '../../../assets/icons/new/profile.svg?react';
import UserIcon from '../../../assets/icons/new/user.svg?react';
import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

export const getAccountNavBarConfig = ({
  messages,
  profileParams,
  findFlagScope,
  hasRole,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.accountMenu;
  profileParams: Profile['permissionsContextIds'];
  hasRole: (authorizedRoles: Role[]) => boolean;
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };

  return {
    title: messages['common:account'],
    homePath: '/',
    items: [
      {
        label: messages['account:profile'],
        testId: 'account-profile',
        icon: <ProfileIcon className="noFill" />,
        path: ACCOUNT_PROFILE_PERSONAL_PATH,
      },
      ...(hasRole(ADMIN_ROLES)
        ? []
        : [
            {
              label: messages['account:organization.nav.title'],
              testId: 'account-organization',
              icon: <BankIcon className="noFill" />,
              path: makeRoutePath(
                findRoute(RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS),
                context,
                true,
                true,
                findFlagScope
              ),
              children: [
                {
                  label: messages['organization:orgDetails'],
                  testId: 'organization-details',
                  path: makeRoutePath(
                    findRoute(RouteName.CS_PLATFORM_ACCOUNT_ORG_DETAILS),
                    {},
                    false,
                    false,
                    findFlagScope
                  ),
                },
                {
                  label: translateText('entitlements:title.plans'),
                  testId: 'plan-list-view',
                  path: makeRoutePath(
                    findRoute(RouteName.PLANS_LIST_VIEW),
                    {},
                    true,
                    true,
                    findFlagScope
                  ),
                },
                {
                  label: messages['organization:fleetPrograms'],
                  testId: 'fleet-programs',
                  path: makeRoutePath(
                    findRoute(RouteName.CS_PLATFORM_ACCOUNT_FLEET_PROGRAMS),
                    context,
                    true,
                    true,
                    findFlagScope
                  ),
                },
                {
                  label: messages['account:organization.nav.legal.title'],
                  testId: 'legal-view',
                  path: makeRoutePath(
                    findRoute(RouteName.LEGAL),
                    context,
                    true,
                    true,
                    findFlagScope
                  ),
                },
              ],
            },
            {
              label: messages['common:users'],
              testId: 'account-user-list',
              icon: <UserIcon className="noFill" />,
              path: makeRoutePath(
                findRoute(RouteName.CS_PLATFORM_USER_LIST),
                context,
                true,
                false,
                findFlagScope
              ),
            },
          ]),
      {
        label: messages['account:preferences'],
        testId: 'account-preferences',
        icon: <GearIcon className="noFill" />,
        path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
        children: [
          {
            label: messages['account:languageAndRegion'],
            testId: 'account-preferences-language-region',
            path: ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH,
          },
        ],
      },
      {
        label: messages['account:settings'],
        testId: 'account-settings',
        icon: <GearIcon className="noFill" />,
        path: makeRoutePath(
          findRoute(RouteName.CS_PLATFORM_SETTINGS_CAMERAS),
          context,
          true,
          false,
          findFlagScope
        ),
        children: [
          {
            label: messages['account:cameras'],
            testId: 'account-settings-cameras',
            path: makeRoutePath(
              findRoute(RouteName.CS_PLATFORM_SETTINGS_CAMERAS),
              context,
              true,
              false,
              findFlagScope
            ),
          },
        ],
      },
    ],
  };
};

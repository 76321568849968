import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import InvalidInviteTemplate from '~/features/invalidInvitePages/InvalidInviteTemplate';

const ExpiredInvitePage = (): JSX.Element => {
  return (
    <InvalidInviteTemplate
      header={translateText('common:inviteExpired')}
      message={translateText('common:invitationExpiredMessage')}
    />
  );
};

export default ExpiredInvitePage;

import { BDDialog } from '@brightdrop/bd-ui';
import { useFeatureFlags } from '@brightdrop/feature-flags-client';

import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { INACTIVITY_TRACKER_MODAL_FLAG } from '~/common/models/featureFlags.model';
import InactiveUserCountdownModal from '~/features/inactivityTracker/InactiveUserCountdownModal';
import useInactivityTracker from '~/features/inactivityTracker/useInactivityTracker';

const InactiveUserCountdownModalWrapper = (): JSX.Element => {
  const { getFlag } = useFeatureFlags();
  const { isUserInactive, resetAllTimers } = useInactivityTracker();
  const isFeatureFlagEnabled = getFlag(INACTIVITY_TRACKER_MODAL_FLAG);

  //extends session in all tabs and dismisses the modal in other tabs
  const handleCloseInactiveUser = (isSuccess: boolean) => {
    if (isSuccess) {
      resetAllTimers();
    }
  };

  return (
    <BDDialog
      openModal={isUserInactive && !!isFeatureFlagEnabled}
      title={translateText('common:inactivity:modal:header')}
      onCloseModal={handleCloseInactiveUser}
      entity={undefined}
      component={(props) => <InactiveUserCountdownModal {...props} />}
      disableClose={true}
    />
  );
};

export default InactiveUserCountdownModalWrapper;

import { AppNames } from '../appNames.model';

export const ACCOUNT_PROFILE_PERSONAL_PATH = `/${AppNames.ACCOUNT}/profile/personal`;
export const ACCOUNT_ORG_DETAILS = `/${AppNames.ACCOUNT}/organization/details`;
export const ACCOUNT_FLEET_PROGRAMS = `/${AppNames.ACCOUNT}/organization/fleet-programs`;
export const ACCOUNT_PREFERENCES_LANGUAGE_REGION_PATH = `/${AppNames.ACCOUNT}/preferences/language-region`;
export const ACCOUNT_USER_LIST_PATH = `/${AppNames.ACCOUNT}/users`;
export const ACCOUNT_PLAN_LIST_VIEW_PATH = `/${AppNames.ACCOUNT}/organization/plans`;
export const ACCOUNT_PLAN_DETAIL_VIEW_PATH = `/${AppNames.ACCOUNT}/organization/plans/detail`;
export const ACCOUNT_SETTINGS_CAMERA_VIEW_PATH = `/${AppNames.ACCOUNT}/settings/cameras`;
export const ACCOUNT_LEGAL_VIEW_PATH = `/${AppNames.ACCOUNT}/organization/legal`;

export enum InactivityEvents {
  inactiveUser = 'csp-inactive-user',
}

export enum InactivityStorageKeys {
  userSignedOut = 'csp.inactivity.sign.out',
  userActive = 'csp.inactivity.user.active',
  userInactive = 'csp.inactivity.user.inactive',
  userExtendSession = 'csp.inactivity.user.extendSession',
}

//5 minutes for countdown in modal
export const modalCountdownTimeout = 300000;

//15 minutes as a default for inactivity tracker
export const inactivityDefaultTimeout = 900000;

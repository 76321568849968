import { Address } from '@gm-commercial/location-model';
import { User } from '@gm-commercial/profile-model';

import { ApiAddressFields } from '../mappers/common.mappers';
import {
  ActivationStatus,
  Entity,
  Location,
  PartialEntity,
  SummaryCounts,
} from './common.model';

export enum OrgActivationStatus {
  ACTIVE = 'ACTIVE',
  ENROLLING = 'ENROLLING',
  ABORTED = 'ABORTED',
}

export enum Industry {
  AGRICULTURE = 'AGRICULTURE',
  MINING = 'MINING',
  UTILITIES = 'UTILITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  MANUFACTURING = 'MANUFACTURING',
  WHOLESALE = 'WHOLESALE',
  RETAIL = 'RETAIL',
  TRANSPORTATION = 'TRANSPORTATION',
  INFORMATION = 'INFORMATION',
  FINANCE = 'FINANCE',
  REAL_ESTATE = 'REAL_ESTATE',
  PROFESSIONAL = 'PROFESSIONAL',
  MANAGEMENT = 'MANAGEMENT',
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  EDUCATIONAL = 'EDUCATIONAL',
  HEALTH = 'HEALTH',
  ARTS = 'ARTS',
  FOOD = 'FOOD',
  OTHER = 'OTHER',
  PUBLIC = 'PUBLIC',
}

export enum DeclineReason {
  NOT_QUALIFIED = 'NOT_QUALIFIED',
  OTHER = 'OTHER',
}

export type OrgDeclineForm = {
  reasonCode: DeclineReason.NOT_QUALIFIED | DeclineReason.OTHER | null;
  reasonNote: string | null;
};

export type OrganizationTerms = {
  accepted: boolean;
  url: {
    termsAndConditions: string;
  };
  acceptedDate: string;
};

export interface Organization extends Entity {
  phoneNumber: string;
  email: string;
  status: ActivationStatus;
  address?: Address;
  creationDate?: string;
  activationDate?: string;
  primaryContact?: PartialEntity<User>;
  location?: Location;
  summaryCounts?: SummaryCounts;
  orgType?: OrganizationType;
  orgMasterNumber?: string;
}

export interface OrganizationItem extends Entity {
  address: ApiAddressFields;
  industry: Industry;
  emailAddress: string;
  status: OrgActivationStatus;
  creationDate?: string;
  activationDate?: string;
  primaryContact?: PartialEntity<User>;
  organizationId?: string;
  preferredName?: string;
  website?: string;
  fan?: string;
  defaultFleet?: string;
  accountId?: string;
  terms?: OrganizationTerms;
}
export type OrganizationTenant = Entity;

export type ApiOrganization = Partial<
  Omit<Organization, 'address' | 'status' | 'primaryContact'>
> & {
  address: ApiAddressFields | null;
  primaryContact: PartialEntity<User> | null;
} & Record<string, unknown>;

export enum OrganizationType {
  ASSET = 'ASSET',
  SERVICE = 'SERVICE',
}

export type OrgFormFields = {
  name: string | null;
  preferredName: string | null;
  website: string | null;
  industry: Industry | null;
  fan: string | null;
  address: Address | null;
  primaryContact: PartialEntity<User> | null;
  onBehalfOfEmail: string | null;
};
export type OrgFormErrors = { [k in keyof OrgFormFields]: string };

export type OrgForm = {
  id?: string;
} & OrgFormFields & { errors: OrgFormErrors };

type CountSummary = {
  numberOfAssets?: number;
};

type FleetAssetCountSummary = {
  fleetId?: string;
} & CountSummary;

type HubAssetCountSummary = {
  hubId?: string;
  fleets?: {
    [key: string]: FleetAssetCountSummary;
  };
} & CountSummary;

type AssetCountSummaryCommonAttributes = {
  organizationId: string;
  numberOfAssets?: number;
  hubs?: {
    [key: string]: HubAssetCountSummary;
  };
};

export type ApiAssetCountSummary = AssetCountSummaryCommonAttributes;
export type AssetCountSummary = AssetCountSummaryCommonAttributes;

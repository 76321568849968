import {
  ActionReducerMapBuilder,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import { set } from 'lodash';

import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_ROW_PER_PAGE,
  DEFAULT_SESSION_CONFIG_KEY,
} from '~/common/constants/common.constant';
import {
  AddVehiclesListSessionType,
  AddVehiclesSessionConfig,
  AddVehiclesSessionViewType,
  AddVehiclesState,
  VehiclesIncompleteListSession,
  VehiclesPendingConnectionListSession,
  VehiclesPendingVerificationListSession,
} from '~/common/models/addVehicles.model';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import { ADD_VEHICLES_ACTIONS } from './addVehiclesSlice.actions';
import {
  incompleteVehiclesAdapter,
  pendingConnectionVehiclesAdapter,
  pendingVerificationVehiclesAdapter,
} from './addVehiclesSlice.adapters';
import { INITIAL_ADD_VEHICLES_STATE } from './addVehiclesSlice.constants';

const createDefaultPendingVerificationSession =
  (): VehiclesPendingVerificationListSession => ({
    vehiclesPendingVerification:
      pendingVerificationVehiclesAdapter.getInitialState(),
    continuationToken: null,
    operationStatus: { status: BDRequestStatus.IDLE },
    count: 0,
    sortOrder: { name: 'createdOn', direction: 'asc' },
    page: DEFAULT_PAGINATION_PAGE,
    rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
    continuationCache: {},
  });

const createDefaultPendingConnectionSession =
  (): VehiclesPendingConnectionListSession => ({
    vehiclesPendingConnection:
      pendingConnectionVehiclesAdapter.getInitialState(),
    continuationToken: null,
    operationStatus: { status: BDRequestStatus.IDLE },
    count: 0,
    sortOrder: { name: 'createdOn', direction: 'asc' },
    page: DEFAULT_PAGINATION_PAGE,
    rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
    continuationCache: {},
  });

const createDefaultIncompleteSession = (): VehiclesIncompleteListSession => ({
  incompleteVehicles: incompleteVehiclesAdapter.getInitialState(),
  continuationToken: null,
  operationStatus: { status: BDRequestStatus.IDLE },
  count: 0,
  sortOrder: { name: 'createdOn', direction: 'asc' },
  page: DEFAULT_PAGINATION_PAGE,
  rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
  continuationCache: {},
});

const reducerSetAddVehiclesSessionConfig: CaseReducer<
  typeof INITIAL_ADD_VEHICLES_STATE,
  PayloadAction<AddVehiclesSessionConfig>
> = (state, action) => {
  Object.keys(action.payload).forEach((scopeKey) => {
    const scope = scopeKey as AddVehiclesSessionViewType;
    const payloadForScope = action.payload[scope] || {};
    const currentScope =
      state.sessionConfigs[scope] ?? (state.sessionConfigs[scope] = {});

    Object.keys(payloadForScope).forEach((id) => {
      currentScope[id] = {
        ...currentScope[id],
        ...payloadForScope[id],
      };
    });
  });
};

const reducerSetAddVehiclesActiveList: CaseReducer<
  typeof INITIAL_ADD_VEHICLES_STATE,
  PayloadAction<AddVehiclesListSessionType>
> = (state, action) => {
  state.activeList = action.payload;
};

const reducerResetAddVehicleContinuationCacheByView: CaseReducer<
  typeof INITIAL_ADD_VEHICLES_STATE,
  PayloadAction<{
    viewType: AddVehiclesListSessionType;
    sessionId: string;
  }>
> = (
  state,
  action: PayloadAction<{
    viewType: AddVehiclesListSessionType;
    sessionId: string;
  }>
) => {
  const { viewType, sessionId } = action.payload;
  const scope = state.sessionConfigs?.[viewType];

  if (scope && sessionId in scope) {
    Object.assign(scope[sessionId], {
      continuationToken: null,
      continuationCache: undefined,
    });
  }
};

const buildAddVehiclesReducer = (
  builder: ActionReducerMapBuilder<AddVehiclesState>
) => {
  builder.addCase(ADD_VEHICLES_ACTIONS.addVehicles.pending, (state) => {
    const session =
      state?.sessionConfigs?.[AddVehiclesSessionViewType.VEHICLES_ADD]?.[
        DEFAULT_SESSION_CONFIG_KEY
      ];
    if (session)
      session.operationStatus = {
        status: BDRequestStatus.PENDING,
      };
  });

  builder.addCase(
    ADD_VEHICLES_ACTIONS.addVehicles.fulfilled,
    (state, action) => {
      const result = action.payload.result;
      const session =
        state?.sessionConfigs?.[AddVehiclesSessionViewType.VEHICLES_ADD]?.[
          DEFAULT_SESSION_CONFIG_KEY
        ];

      if (session) {
        session.recentBatches?.push(result);

        session.operationStatus = { status: BDRequestStatus.SUCCEEDED };
      }
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.addVehicles.rejected,
    (state, action) => {
      const session =
        state?.sessionConfigs?.[AddVehiclesSessionViewType.VEHICLES_ADD]?.[
          DEFAULT_SESSION_CONFIG_KEY
        ];

      if (session) {
        session.operationStatus = {
          status: BDRequestStatus.FAILED,
          errors: [
            {
              type: BDAppErrorType.API,
              ...(action.payload || (action.error as BDError)),
            },
          ],
        };
      }
    }
  );
};

const buildGetPendingVerificationVehiclesReducer = (
  builder: ActionReducerMapBuilder<AddVehiclesState>
) => {
  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingVerificationVehicles.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingVerificationSession();
      }

      // Now update the session.
      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.PENDING };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingVerificationVehicles.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, continuationToken } =
        action.meta.arg;
      const { items, totalItems, token: resultToken } = action.payload.result;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingVerificationSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];

      // Ensure vehiclesPendingVerification is initialized
      if (!session.vehiclesPendingVerification) {
        session.vehiclesPendingVerification =
          pendingVerificationVehiclesAdapter.getInitialState();
      }

      session.count = totalItems;
      session.page = page || 0;
      session.rowsPerPage = rowsPerPage;
      session.continuationToken = continuationToken;
      session.continuationCache = {
        ...session.continuationCache,
        [Number(page)]: {
          data: items,
          continuationToken: resultToken,
        },
      };

      pendingVerificationVehiclesAdapter.setAll(
        session.vehiclesPendingVerification,
        items ?? []
      );
      if (session.allSelected) {
        let selectedVins = items && [...new Set(items.map((item) => item.vin))]; // ensuring no duplicate vins are stored
        if (session.unselectedVins) {
          selectedVins = selectedVins.filter(
            (selectedVin) => !session.unselectedVins?.includes(selectedVin)
          );
        }

        session.count = totalItems;
        if (session.selectedVins) {
          session.selectedVins = [...session.selectedVins, ...selectedVins];
        }
      }

      session.operationStatus = { status: BDRequestStatus.SUCCEEDED };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingVerificationVehicles.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingVerificationSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.FAILED };
    }
  );
};

const buildGetPendingConnectionVehiclesReducer = (
  builder: ActionReducerMapBuilder<AddVehiclesState>
) => {
  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingConnectionVehicles.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingConnectionSession();
      }

      // Now update the session.
      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.PENDING };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingConnectionVehicles.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, continuationToken } =
        action.meta.arg;
      const { items, total_items, token: resultToken } = action.payload.result;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingConnectionSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];

      // Ensure vehiclesPendingConnection is initialized
      if (!session.vehiclesPendingConnection) {
        session.vehiclesPendingConnection =
          pendingConnectionVehiclesAdapter.getInitialState();
      }

      session.count = total_items;
      session.page = page || 0;
      session.rowsPerPage = rowsPerPage;
      session.continuationToken = continuationToken;
      session.continuationCache = {
        ...session.continuationCache,
        [Number(page)]: {
          data: items,
          continuationToken: resultToken,
        },
      };

      pendingConnectionVehiclesAdapter.setAll(
        session.vehiclesPendingConnection,
        items ?? []
      );
      if (session.allSelected) {
        let selectedVins = items && [...new Set(items.map((item) => item.vin))]; // ensuring no duplicate vins are stored
        if (session.unselectedVins) {
          selectedVins = selectedVins.filter(
            (selectedVin) => !session.unselectedVins?.includes(selectedVin)
          );
        }

        session.count = total_items;
        if (session.selectedVins) {
          session.selectedVins = [...session.selectedVins, ...selectedVins];
        }
      }

      session.operationStatus = { status: BDRequestStatus.SUCCEEDED };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getPendingConnectionVehicles.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType =
        AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultPendingConnectionSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.FAILED };
    }
  );
};

const buildGetIncompleteVehiclesReducer = (
  builder: ActionReducerMapBuilder<AddVehiclesState>
) => {
  builder.addCase(
    ADD_VEHICLES_ACTIONS.getIncompleteVehicles.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType = AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultIncompleteSession();
      }

      // Now update the session.
      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.PENDING };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getIncompleteVehicles.fulfilled,
    (state, action) => {
      const { sessionId, page, rowsPerPage, continuationToken } =
        action.meta.arg;
      const { items, totalItems, token: resultToken } = action.payload.result;
      const viewType = AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultIncompleteSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];

      // Ensure incompleteVehicles is initialized
      if (!session.incompleteVehicles) {
        session.incompleteVehicles =
          incompleteVehiclesAdapter.getInitialState();
      }

      session.count = totalItems;
      session.page = page || 0;
      session.rowsPerPage = rowsPerPage;
      session.continuationToken = continuationToken;
      session.continuationCache = {
        ...session.continuationCache,
        [Number(page)]: {
          data: items,
          continuationToken: resultToken,
        },
      };

      incompleteVehiclesAdapter.setAll(session.incompleteVehicles, items ?? []);
      if (session.allSelected) {
        let selectedVins = items && [...new Set(items.map((item) => item.vin))]; // ensuring no duplicate vins are stored
        if (session.unselectedVins) {
          selectedVins = selectedVins.filter(
            (selectedVin) => !session.unselectedVins?.includes(selectedVin)
          );
        }

        session.count = totalItems;
        if (session.selectedVins) {
          session.selectedVins = [...session.selectedVins, ...selectedVins];
        }
      }

      session.operationStatus = { status: BDRequestStatus.SUCCEEDED };
    }
  );

  builder.addCase(
    ADD_VEHICLES_ACTIONS.getIncompleteVehicles.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      const viewType = AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST;

      // Ensure the scope exists
      if (!state.sessionConfigs[viewType]) {
        state.sessionConfigs[viewType] = {};
      }

      // Ensure the session exists; if not, initialize it.
      if (!state.sessionConfigs[viewType][sessionId]) {
        state.sessionConfigs[viewType][sessionId] =
          createDefaultIncompleteSession();
      }

      const session = state.sessionConfigs[viewType][sessionId];
      session.operationStatus = { status: BDRequestStatus.FAILED };
    }
  );
};

const reducerSetAddVehicleModalOpen: CaseReducer<
  typeof INITIAL_ADD_VEHICLES_STATE,
  PayloadAction<boolean>
> = (state, action) => {
  const { payload } = action;
  set(state, 'isAddVehiclesModalOpen', payload);
};

export const ADD_VEHICLES_REDUCER_BUILDERS = {
  buildAddVehiclesReducer,
  buildGetPendingVerificationVehiclesReducer,
  buildGetPendingConnectionVehiclesReducer,
  buildGetIncompleteVehiclesReducer,
};

export const ADD_VEHICLES_RECUCERS = {
  reducerSetAddVehiclesSessionConfig,
  reducerResetAddVehicleContinuationCacheByView,
  reducerSetAddVehiclesActiveList,
  reducerSetAddVehicleModalOpen,
};

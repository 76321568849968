import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import InvalidInviteTemplate from '~/features/invalidInvitePages/InvalidInviteTemplate';

const CancelledInvitePage = (): JSX.Element => {
  return (
    <InvalidInviteTemplate
      header={translateText('common:inviteCancelled')}
      message={translateText('common:invitationCancelledMessage')}
    />
  );
};

export default CancelledInvitePage;

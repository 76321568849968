import { NavBarConfig } from '@gm-commercial/navbar-model';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'; // Correct icon that matches

import SupportIcon from '~/assets/icons/new/help-circle.svg?react';
import { type FeatureFlagValueType } from '~/common/models/featureFlags.model';
import { SUPPORT_CONTACT_PATH } from '~/common/models/pages/supportPages.model';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';

export const getSupportNavBarConfig = ({
  messages,
  findFlagScope,
}: {
  messages: typeof NAV_DRAWER_MESSAGES.supportMenu;
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => ({
  title: messages['account:support.nav.title'],
  homePath: '/support',
  items: [
    {
      label: messages['account:support.nav.faqs'],
      testId: 'support-faqs',
      icon: <SupportIcon />,
      path: makeRoutePath({
        pathInfo: findRoute(RouteName.FAQS),
        exact: true,
        findFlagScope,
      }),
    },
    {
      label: messages['account:support.nav.contact'],
      testId: 'support-contact',
      icon: <ChatBubbleOutlineIcon fontSize="small" />,
      path: SUPPORT_CONTACT_PATH,
    },
  ],
});

import { EntityState } from '@reduxjs/toolkit';

import { ProvisioningStatus } from './asset.model';
import { VehicleDetailsType } from './asset-report.model';
import {
  ContinuationCache,
  Entity,
  ListViewSession,
  NonNullablePick,
  OperationSession,
  SearchCriteria,
} from './common.model';

export type VehicleDetailsBase = {
  vin: string;
};

export enum AddVehiclesSessionViewType {
  VEHICLES_PENDING_CONNECTION_LIST = 'vehiclesPendingConnectionList',
  VEHICLES_PENDING_VERIFICATION_LIST = 'vehiclesPendingVerificationList',
  VEHICLES_INCOMPLETE_LIST = 'incompleteVehiclesList',
  VEHICLES_ADD = 'addVehicles',
}

// Filter models
export interface AddVehiclesFilterCriteria {
  [AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST]?: PendingVerificationVehicleStatus[];
  [AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST]?: ProvisioningStatus[];
  [AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST]?: IncompleteVehicleReason[];
}

export const ADD_VEHICLES_FILTER_EMPTY_STATE: AddVehiclesFilterCriteria = {
  [AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST]: [],
  [AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST]: [],
  [AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST]: [],
};

export type AddVehiclesFilterKey = keyof AddVehiclesFilterCriteria;

// Add Vehicles
export type AddVehiclesFormFields = {
  vinData: File | VehicleDetailsBase[];
  fleetId: string;
  legalTermsAgreement?: boolean;
};

export type ValidAddVehiclesFormFields = NonNullablePick<
  AddVehiclesFormFields,
  'fleetId'
> &
  Pick<AddVehiclesFormFields, 'vinData'>;

export type AddVehiclesApiRequest = {
  organizationsId: string;
  hubsId: string;
  fleetId: string;
  vinData: File;
};

export type AddVehiclesApiResponse = {
  batchId: string;
};

export enum AddVehiclesFieldNames {
  VIN_DATA = 'vinData',
  FLEET_ID = 'fleetId',
  LEGAL_TERMS_AGREEMENT = 'legalTermsAgreement',
}

export interface AddVehicleReportItem extends Entity {
  action?: string;
  added?: string;
  vehicleType?: VehicleDetailsType;
}

export interface AddVehiclesSession extends OperationSession {
  recentBatches: AddVehiclesApiResponse[];
}

// Pending Verification List
export enum PendingVerificationVehicleStatus {
  PREPARING = 'PREPARING',
  REVIEWING_OWNERSHIP = 'REVIEWING_OWNERSHIP',
}

export type PendingVerificationVehicleDetailsApiResponse =
  VehicleDetailsBase & {
    sessionId: string;
    organizationId: string;
    status: PendingVerificationVehicleStatus;
    createdOn: string;
    vehicleMake: string;
    vehicleModel: string;
    vehicleYear: string;
    vehicleImageUrl: string;
  };

export type GetPendingVerificationVehiclesListParams = {
  organizationsId: string;
  sessionId: string;
  hubsId?: string;
  fleetsId?: string;
  rowsPerPage?: number;
  page?: number;
  status?: string;
  vin?: string;
  continuationToken?: string | null;
};

export interface PendingVerificationVehicleReportItem
  extends VehicleDetailsBase {
  action?: JSX.Element;
  added?: JSX.Element;
  vehicleType?: JSX.Element;
}

export interface VehiclesPendingVerificationListSession
  extends ListViewSession,
    OperationSession {
  searchCriteria?: SearchCriteria;
  filterType?: AddVehiclesFilterCriteria;
  filterModalOpen?: boolean;
  selectedVins?: string[];
  unselectedVins?: string[];
  selectedVehicles?: PendingVerificationVehicleReportItem[];
  vehiclesPendingVerification: EntityState<PendingVerificationVehicleDetailsApiResponse>;
  continuationToken: string | null;
  continuationCache?: ContinuationCache<
    PendingVerificationVehicleDetailsApiResponse[]
  >;
}

// Incomplete List
export enum IncompleteVehicleReason {
  UNEXPECTED_FAILURE = 'UNEXPECTED_FAILURE',
  VEHICLE_REJECTED_ACTIVELY_ONBOARDING = 'VEHICLE_REJECTED_ACTIVELY_ONBOARDING',
  VEHICLE_REJECTED_ACTIVELY_OFFBOARDING = 'VEHICLE_REJECTED_ACTIVELY_OFFBOARDING',
  VEHICLE_REJECTED_CLAIMED_BY_OTHER = 'VEHICLE_REJECTED_CLAIMED_BY_OTHER',
  VEHICLE_REJECTED_CLAIMED_BY_OWNER = 'VEHICLE_REJECTED_CLAIMED_BY_OWNER',
  VEHICLE_REJECTED_NO_INFORMATION_FOUND = 'VEHICLE_REJECTED_NO_INFORMATION_FOUND',
  VEHICLE_MANUAL_PROOF_OF_OWNERSHIP_REJECTED = 'VEHICLE_MANUAL_PROOF_OF_OWNERSHIP_REJECTED',
  VEHICLE_REJECTED_NOT_SUPPORTED = 'VEHICLE_REJECTED_NOT_SUPPORTED',
}

export type IncompleteVehicleDetailsApiResponse = VehicleDetailsBase & {
  sessionId: string;
  organizationId: string;
  reason: IncompleteVehicleReason;
  createdOn: string;
  createdBy: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleImageUrl: string;
};

export type GetIncompleteVehiclesListParams = {
  organizationsId: string;
  sessionId: string;
  fleetsId?: string;
  hubsId?: string;
  rowsPerPage?: number;
  page?: number;
  reason?: string;
  vin?: string;
  continuationToken?: string | null;
};

export interface IncompleteVehicleReportItem extends VehicleDetailsBase {
  description?: JSX.Element;
  added?: JSX.Element;
  vehicleType?: JSX.Element;
}

export interface VehiclesIncompleteListSession
  extends ListViewSession,
    OperationSession {
  searchCriteria?: SearchCriteria;
  filterType?: AddVehiclesFilterCriteria;
  filterModalOpen?: boolean;
  selectedVins?: string[];
  unselectedVins?: string[];
  selectedVehicles?: IncompleteVehicleReportItem[];
  incompleteVehicles: EntityState<IncompleteVehicleDetailsApiResponse>;
  continuationToken: string | null;
  continuationCache?: ContinuationCache<IncompleteVehicleDetailsApiResponse[]>;
}

// Pending Connection List
// API in progress. Some of the fields will likely change, but for the
// most part it will be very similar to the models for the other data types.

export type PendingConnectionVehicleDetailsApiResponse = VehicleDetailsBase & {
  sessionId: string;
  organizationId: string;
  name: string;
  licensePlate: string;
  fleetId: string;
  provisioning: ProvisioningStatus;
  createdDate: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleImageSrc: string;
  onStarTcpsAccepted: boolean;
  onStarAccountStatus: string;
};

export type GetPendingConnectionVehiclesListParams = {
  organizationsId: string;
  hubsId?: string;
  fleetsId?: string;
  rowsPerPage?: number;
  page?: number;
  status?: string;
  vin?: string;
  continuationToken?: string | null;
  sessionId: string;
};

export interface PendingConnectionVehicleReportItem extends VehicleDetailsBase {
  action?: JSX.Element;
  added?: JSX.Element;
  vehicleName?: JSX.Element;
}

export interface VehiclesPendingConnectionListSession
  extends ListViewSession,
    OperationSession {
  searchCriteria?: SearchCriteria;
  filterType?: AddVehiclesFilterCriteria;
  filterModalOpen?: boolean;
  selectedVins?: string[];
  unselectedVins?: string[];
  selectedVehicles?: PendingConnectionVehicleReportItem[];
  vehiclesPendingConnection: EntityState<PendingConnectionVehicleDetailsApiResponse>;
  continuationToken: string | null;
  continuationCache?: ContinuationCache<
    PendingConnectionVehicleDetailsApiResponse[]
  >;
}

export type AddVehicleTableReportItem =
  | PendingVerificationVehicleReportItem
  | PendingConnectionVehicleReportItem
  | IncompleteVehicleReportItem;

export type AddVehicleTableData =
  | PendingVerificationVehicleDetailsApiResponse[]
  | PendingConnectionVehicleDetailsApiResponse[]
  | IncompleteVehicleDetailsApiResponse[];

// Session configs
export type AddVehiclesListSessionType =
  | AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST
  | AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST
  | AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST;

export type AddVehiclesSessionConfig = {
  [AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST]?: {
    [key: string]: Partial<VehiclesPendingConnectionListSession>;
  };
  [AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST]?: {
    [key: string]: Partial<VehiclesPendingVerificationListSession>;
  };
  [AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST]?: {
    [key: string]: Partial<VehiclesIncompleteListSession>;
  };
  [AddVehiclesSessionViewType.VEHICLES_ADD]?: {
    [key: string]: Partial<AddVehiclesSession>;
  };
};

export interface AddVehiclesState {
  sessionConfigs: AddVehiclesSessionConfig;
  activeList: AddVehiclesListSessionType;
  isAddVehiclesModalOpen: boolean;
}

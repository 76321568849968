import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../../app/rootReducer';
import { ADD_VEHICLES_ACTIONS } from './state/addVehiclesSlice.actions';
import { addVehiclesAdapter } from './state/addVehiclesSlice.adapters';
import { INITIAL_ADD_VEHICLES_STATE } from './state/addVehiclesSlice.constants';
import {
  ADD_VEHICLES_RECUCERS,
  ADD_VEHICLES_REDUCER_BUILDERS,
} from './state/addVehiclesSlice.reducers';

const {
  reducerSetAddVehiclesSessionConfig,
  reducerResetAddVehicleContinuationCacheByView,
  reducerSetAddVehiclesActiveList,
  reducerSetAddVehicleModalOpen,
} = ADD_VEHICLES_RECUCERS;

export const addVehiclesSlice = createSlice({
  name: 'addVehicles',
  initialState: INITIAL_ADD_VEHICLES_STATE,
  reducers: {
    setAddVehiclesSessionConfig: reducerSetAddVehiclesSessionConfig,
    resetAddVehicleContinuationCache:
      reducerResetAddVehicleContinuationCacheByView,
    setAddVehiclesActiveList: reducerSetAddVehiclesActiveList,
    setAddVehicleModalOpen: reducerSetAddVehicleModalOpen,
  },
  extraReducers: (builder) => {
    ADD_VEHICLES_REDUCER_BUILDERS.buildAddVehiclesReducer(builder);
    ADD_VEHICLES_REDUCER_BUILDERS.buildGetPendingVerificationVehiclesReducer(
      builder
    );
    ADD_VEHICLES_REDUCER_BUILDERS.buildGetPendingConnectionVehiclesReducer(
      builder
    );
    ADD_VEHICLES_REDUCER_BUILDERS.buildGetIncompleteVehiclesReducer(builder);
  },
});

export const {
  addVehicles,
  getPendingVerificationVehicles,
  getPendingVerificationVehiclesExport,
  getPendingConnectionVehicles,
  getPendingConnectionVehiclesExport,
  getIncompleteVehicles,
  getIncompleteVehiclesExport,
} = ADD_VEHICLES_ACTIONS;
export const {
  setAddVehiclesSessionConfig,
  resetAddVehicleContinuationCache,
  setAddVehiclesActiveList,
  setAddVehicleModalOpen,
} = addVehiclesSlice.actions;

export const {
  selectAll: selectAllAddVehicles,
  selectById: selectAddVehiclesById,
  selectIds: selectAddVehiclesIds,
} = addVehiclesAdapter.getSelectors<RootState>(
  (state: RootState) => state.addVehicles
);

export const selectAddVehiclesState = (
  state: RootState
): RootState['addVehicles'] => state.addVehicles;

export const addVehiclesReducer = addVehiclesSlice.reducer;

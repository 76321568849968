import { useTranslations } from '@brightdrop/localization-client';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '~/app/store';
import CheckSquareIcon from '~/assets/icons/new/checkSquareIcon.svg?react';
import SettingsIcon from '~/assets/icons/new/settings.svg?react';
import CSPlatformSettingsMenu from '~/common/components/SettingsMenu/CSPlatformSettingsMenu';
import { SETTINGS_NOTIFICATIONS_VEHICLES } from '~/common/models/pages/settingsPages.model';
import {
  setAllRead,
  setPopUp,
  useUpdateAllAlertsToReadAsyncMutation,
} from '~/features/alerts/alertsSlice';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

const NotificationSettings = () => {
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [handleMarkAllAsRead] = useUpdateAllAlertsToReadAsyncMutation();

  const getFeedNotificationSettingsMainMenuItems = () => {
    return [
      {
        text: translations['settings:notifications.markAllRead'],
        icon: (
          <CheckSquareIcon data-testid="header-notifications-feed-options-mark-all-read" />
        ),
        onClick: () => {
          dispatch(setAllRead());
          handleMarkAllAsRead();
        },
      },
      {
        text: translations['settings:settings'],
        icon: (
          <SettingsIcon data-testid="header-notifications-feed-options-settings" />
        ),
        onClick: () => {
          dispatch(setPopUp(false));
          history.push(SETTINGS_NOTIFICATIONS_VEHICLES);
        },
      },
    ];
  };

  return (
    <Box>
      <CSPlatformSettingsMenu
        data-testid="CSPlatformSettingsMenu"
        options={getFeedNotificationSettingsMainMenuItems()}
      />
    </Box>
  );
};

export default NotificationSettings;

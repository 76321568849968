import { Box, Skeleton } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/app/store';
import {
  ContextItem,
  setSelectedContext,
} from '~/features/profile/profileSlice';
import {
  selectActingPermissionsContextIds,
  selectSelectedContext,
} from '~/features/profile/profileSlice.selectors';

import FleetSwitcher, { StaticFleetSwitcher } from './FleetSwitcher';
import { useAllowedContexts } from './hooks/useAllowedContexts';
import {
  transformAllowedContexts,
  validateAllowedContexts,
} from './utils/fleetSwitcher.utils';

/**
 * Handles data and shared app state related to the FleetSwitcher
 */
const FleetSwitcherContainer = ({
  navExpanded,
  disabled = false,
}: {
  navExpanded: boolean;
  disabled?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { allowedContexts, isLoading, isFailed } = useAllowedContexts();
  const actingPermissionsContextIds = useSelector(
    selectActingPermissionsContextIds
  );
  const selectedContext = useSelector(selectSelectedContext);

  const hasProfileContexts = useMemo(() => {
    return Boolean(
      actingPermissionsContextIds?.fleetsId ||
        actingPermissionsContextIds?.hubsId ||
        actingPermissionsContextIds?.organizationsId
    );
  }, [actingPermissionsContextIds]);

  const hasValidAllowedContexts = useMemo(() => {
    if (!actingPermissionsContextIds || isLoading) {
      // either profile or allowed contexts are not yet loaded
      return undefined;
    }
    return validateAllowedContexts({
      allowedContexts,
      actingPermissionsContextIds,
    });
  }, [actingPermissionsContextIds, allowedContexts, isLoading]);

  const transformedContexts = useMemo(() => {
    return transformAllowedContexts({
      allowedContexts,
      actingPermissionsContextIds,
    });
  }, [allowedContexts, actingPermissionsContextIds]);

  /**
   * Ensures that the selected context is valid and updates it if necessary
   */
  useEffect(() => {
    if (!selectedContext && transformedContexts?.root) {
      dispatch(setSelectedContext(transformedContexts.root));
      return;
    }
    if (selectedContext?.id && transformedContexts?.root) {
      const matchesRoot = selectedContext.id === transformedContexts.root.id;
      if (matchesRoot) return;

      const matchesSubContext = transformedContexts.subContexts.some(
        (subContext) => subContext.id === selectedContext.id
      );
      if (matchesSubContext) return;

      dispatch(setSelectedContext(transformedContexts.root));
    }
  }, [dispatch, selectedContext, transformedContexts]);

  const handleContextSelection = (item: ContextItem) => {
    dispatch(setSelectedContext(item));
  };

  if (!hasProfileContexts) {
    // switcher only visibile to scoped users
    return null;
  }

  if (isFailed) {
    // TODO: improve error case handling
    return null;
  }

  const pendingSelection =
    !selectedContext && hasValidAllowedContexts !== false;
  if (isLoading || pendingSelection) {
    return (
      <Box
        sx={{
          width: '100%',
          margin: '16px 0px',
          padding: '0px 8px',
          justifySelf: 'center',
        }}
      >
        <Skeleton
          data-testid="fleet-switcher-skeleton"
          variant="rounded"
          sx={{ height: '56px', width: '100%' }}
        />
      </Box>
    );
  }

  if (
    hasValidAllowedContexts === false ||
    !transformedContexts ||
    !selectedContext
  ) {
    // conflicting contexts, or no valid contexts
    return null;
  }

  if (
    transformedContexts.root &&
    (!transformedContexts.subContexts ||
      transformedContexts.subContexts.length === 0)
  ) {
    return (
      <StaticFleetSwitcher
        navExpanded={navExpanded}
        selectedContext={selectedContext}
      />
    );
  }

  return (
    <FleetSwitcher
      contexts={{
        root: transformedContexts.root,
        subContexts: transformedContexts.subContexts,
      }}
      selectedContext={selectedContext}
      onContextSelect={handleContextSelection}
      navExpanded={navExpanded}
      disabled={disabled}
    />
  );
};

export default FleetSwitcherContainer;

import {
  ActionReducerMapBuilder,
  CaseReducer,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';

import { DEFAULT_SESSION_CONFIG_KEY } from '~/common/constants/common.constant';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { SeverityLevel } from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';

import { INITIAL_PERIPHERALS_STATE } from '../config/constants/state';
import {
  mapApiPeripheralMoveFleetSummary,
  mapPeripheralsOnboardResponseToPeripherals,
  mapPeripheralsOnboardStatusResponseToPeripherals,
} from '../mappers';
import { PeripheralRequestType } from './peripheralsSlice';
import { PERIPHERALS_ACTIONS } from './peripheralsSlice.actions';
import { peripheralsAdapter } from './peripheralsSlice.adapters';
import {
  PeripheralsSessionConfig,
  PeripheralsSessionViewType,
  PeripheralsState,
} from './peripheralsSlice.model';

const reduceSetPeripheralsSessionConfig: CaseReducer<
  PeripheralsState,
  PayloadAction<Partial<PeripheralsSessionConfig>>
> = (state, action) => {
  Object.keys(action.payload).forEach((key) => {
    const scope = key as PeripheralsSessionViewType;
    const payload = action.payload[scope] || {};
    Object.keys(payload).forEach((id) => {
      state.sessionConfigs[scope] = {
        ...state.sessionConfigs[scope],
        [id]: {
          ...state.sessionConfigs[scope]?.[id],
          ...payload[id],
        },
      };
    });
  });
};

const reduceResetPeripheralsSessionConfig: CaseReducer<
  typeof INITIAL_PERIPHERALS_STATE,
  PayloadAction<Partial<PeripheralsSessionConfig>>
> = (state, action) => {
  state.sessionConfigs = merge(state.sessionConfigs, {
    [PeripheralsSessionViewType.LIST_VIEW]: {
      [DEFAULT_SESSION_CONFIG_KEY]: {
        importModalOpen: false,
        onboardState: undefined,
        onboardStateStatus: undefined,
        errorSnackbarOpen: undefined,
      },
    },
  });
};

const buildGetPeripheralsListReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripherals.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(state.sessionConfigs, {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [sessionId]: {
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      });
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripherals.fulfilled,
    (state, action) => {
      const { sessionId, pageIndex } = action.meta.arg;
      const { items, total_items, continuation_token } = action.payload.result;
      if (pageIndex !== undefined) {
        peripheralsAdapter.setAll(state.items, items);
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [PeripheralsSessionViewType.LIST_VIEW]: {
              [sessionId]: {
                count: total_items,
                continuationToken: continuation_token,
                continuationCache: {
                  [pageIndex]: {
                    data: items,
                    continuationToken: continuation_token,
                  },
                },
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      } else {
        const selectedIds = items.map((item) => item.id);
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [PeripheralsSessionViewType.LIST_VIEW]: {
              [sessionId]: {
                count: total_items,
                selectedIds,
                allSelectedItems: items,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripherals.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
              errors: [
                {
                  type: BDAppErrorType.API,
                  ...(action.payload || (action.error as BDError)),
                },
              ],
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildOnboardPeripheralsReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.pending,
    (state, action) => {
      state.sessionConfigs = merge(state.sessionConfigs, {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            onboardState: {},
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      });
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              onboardState: mapPeripheralsOnboardResponseToPeripherals(
                action.payload.result
              ),
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postPeripheralsOnboard.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
              onboardState: {
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildOnboardPeripheralsStatusReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.pending,
    (state, action) => {
      state.sessionConfigs = merge(state.sessionConfigs, {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            onboardStateStatus: {},
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      });
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.fulfilled,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              onboardStateStatus:
                mapPeripheralsOnboardStatusResponseToPeripherals(
                  action.payload.result
                ),
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.getPeripheralsOnboardStatus.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
              },
              onboardState: {
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildPostLinkVehicleReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(PERIPHERALS_ACTIONS.postLinkVehicle.pending, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.PENDING,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(PERIPHERALS_ACTIONS.postLinkVehicle.fulfilled, (state) => {
    state.sessionConfigs = merge(
      state.sessionConfigs,
      {
        [PeripheralsSessionViewType.LIST_VIEW]: {
          [DEFAULT_SESSION_CONFIG_KEY]: {
            operationStatus: {
              status: BDRequestStatus.SUCCEEDED,
            },
          },
        },
      },
      {
        arrayMerge: (_, sourceArray) => sourceArray,
      }
    );
  });
  builder.addCase(
    PERIPHERALS_ACTIONS.postLinkVehicle.rejected,
    (state, action) => {
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [DEFAULT_SESSION_CONFIG_KEY]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildPostUnlinkVehicleReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(
    PERIPHERALS_ACTIONS.postUnlinkVehicle.pending,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.PENDING,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postUnlinkVehicle.fulfilled,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.SUCCEEDED,
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postUnlinkVehicle.rejected,
    (state, action) => {
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [sessionId]: {
              operationStatus: {
                status: BDRequestStatus.FAILED,
                errors: [
                  {
                    type: BDAppErrorType.API,
                    ...(action.payload || (action.error as BDError)),
                  },
                ],
              },
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
};

const buildPostMoveFleetReducer = (
  builder: ActionReducerMapBuilder<PeripheralsState>
) => {
  builder.addCase(PERIPHERALS_ACTIONS.postMoveFleet.pending, (state) => {
    state.operations[PeripheralRequestType.POST_MOVE_FLEET] = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    PERIPHERALS_ACTIONS.postMoveFleet.fulfilled,
    (state, action) => {
      state.operations[PeripheralRequestType.POST_MOVE_FLEET] = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
      const { sessionId } = action.meta.arg;
      state.sessionConfigs = merge(
        state.sessionConfigs,
        {
          [PeripheralsSessionViewType.LIST_VIEW]: {
            [sessionId]: {
              moveFleetSummary: mapApiPeripheralMoveFleetSummary(
                action.payload.result
              ),
            },
          },
        },
        {
          arrayMerge: (_, sourceArray) => sourceArray,
        }
      );
    }
  );
  builder.addCase(
    PERIPHERALS_ACTIONS.postMoveFleet.rejected,
    (state, action) => {
      state.operations[PeripheralRequestType.POST_MOVE_FLEET] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            severity: SeverityLevel.ERROR,
          },
        ],
      };
    }
  );
};

export const PERIPHERALS_REDUCERS = {
  reduceSetPeripheralsSessionConfig,
  reduceResetPeripheralsSessionConfig,
};

export const PERIPHERALS_EXTRA_REDUCER_BUILDERS = {
  buildGetPeripheralsListReducer,
  buildPostLinkVehicleReducer,
  buildPostUnlinkVehicleReducer,
  buildOnboardPeripheralsReducer,
  buildOnboardPeripheralsStatusReducer,
  buildPostMoveFleetReducer,
};

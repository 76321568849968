import capitalize from 'lodash/capitalize';
import forEach from 'lodash/forEach';
import { DateTime } from 'luxon';

import { isValidLocation } from '~/common/components/map/utils/map.utils';
import {
  DEFAULT_EMPTY_VALUE,
  KNOWN_DIAGNOSTIC_FLAGS,
  LOCALE_FORMAT_DATE,
} from '~/common/constants/common.constant';
import { mapApiResponseToAddress } from '~/common/mappers/common.mappers';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import {
  ActionModel,
  ActorModel,
  ApiActionModel,
  ApiActorModel,
  ApiAsset,
  ApiAssetEvent,
  ApiAssetLocation,
  ApiAssetMapItem,
  ApiAssetTelemetry,
  ApiAssetType,
  ApiGeoJSON,
  ApiToAssetTypeMap,
  Asset,
  AssetComplianceDetail,
  AssetDiagnostics,
  AssetEvent,
  AssetEventAction,
  AssetExceptionStatus,
  AssetFault,
  AssetFaultDisplay,
  AssetFaultStatus,
  AssetFlags,
  AssetGearStateApi,
  AssetIgnitionStateApi,
  AssetMapItem,
  AssetModel,
  AssetOperationalStatus,
  AssetProductModel,
  AssetRecall,
  AssetRemoteCommandExecuteStatus,
  AssetServiceStatus,
  AssetState,
  AssetTelemetry,
  AssetType,
  AssetTypeToModelMap,
  BatteryRange,
  BatteryStatus,
  ChargingSchedule,
  DiagnosticFlag,
  DiagnosticSeverity,
  DiagnosticState,
  DistanceUnit,
  EstimatedRange,
  OrganizationOwnershipStatus,
  PeriCompartments,
  ProvisioningStatus,
  TelemetryStatus,
  UpdateApiAssetType,
} from '~/common/models/asset.model';
import {
  BatteryChargingStatus,
  VehiclePropulsion,
} from '~/common/models/asset-report.model';
import {
  AssetRawLocation,
  ConnectivityStatusType,
  Location,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import { LocationWithId } from '~/common/models/map/map.model';
import {
  MAX_VALID_SOC_VALUE,
  MIN_VALID_SOC_VALUE,
} from '~/common/utils/battery.utils';
import { notEmpty, uppercaseLiteral } from '~/common/utils/common.utils';
import { toDateStringWithTimezone } from '~/common/utils/date-time.utils';
import {
  BATTERY_RANGE_MAPPING,
  buildVehicleImageURL,
} from '~/features/assets/report/mappers/asset-report.mappers';

import { UpdateAssetFormPayload } from '../assetsSlice';
import {
  AssetRemoteCommandRequestType,
  RemoteCommand,
  RemoteCommandGearMode,
  RemoteCommandPowerIgnitionStatus,
} from '../details/remoteCommands/remoteCommands.constants';
import { getOperationalStatusDurationDate } from '../details/utils/assetDetails.utils';

type DiagnosticFaultCode =
  | '0x83'
  | '0x82'
  | '0x81'
  | '0x80'
  | '0x69'
  | '0x68'
  | '0x60'
  | '0x31'
  | '0x15'
  | '0x14'
  | '0x12'
  | '0x09'
  | '0x08'
  | '0x07'
  | '0x05';

const diagnosticFaultCodesTable: Record<string, DiagnosticFaultCode> = {
  '2': '0x83',
  '3': '0x82',
  '4': '0x81',
  '5': '0x80',
  '9': '0x69',
  '10': '0x68',
  '11': '0x60',
  '13': '0x31',
  '14': '0x15',
  '15': '0x14',
  '16': '0x12',
  '19': '0x09',
  '20': '0x08',
  '21': '0x07',
  '22': '0x05',
};

/**
 * List of diagnostic flags related to conditions not currently mapped directly from statuses
 */
export const DiagnosticMap: {
  [k in DiagnosticFlag]: keyof Omit<AssetFlags, 'diagnostics' | 'ready'>;
} = {
  [DiagnosticFlag.FLUID_BRAKE_GREEN]: 'brakeFluidGreen',
  [DiagnosticFlag.FLUID_LOW_BRAKE]: 'lowBrakeFluid',

  [DiagnosticFlag.FLUID_WASHER_GREEN]: 'washerFluidGreen',
  [DiagnosticFlag.FLUID_LOW_WASHER]: 'lowWasherFluid',

  [DiagnosticFlag.BATTERY_GREEN]: 'okBattery',
  [DiagnosticFlag.BATTERY_RED]: 'lowBattery',
  [DiagnosticFlag.BATTERY_YELLOW]: 'lowBattery', // TODO: Find the correct string for this

  [DiagnosticFlag.BRAKE_PAD_LIFE_FRONT_GREEN]: 'brakePadLifeFrontGreen',
  [DiagnosticFlag.BRAKE_PAD_LIFE_FRONT_YELLOW]: 'brakePadLifeFrontYellow',
  [DiagnosticFlag.BRAKE_PAD_LIFE_FRONT_RED]: 'brakePadLifeFrontRed',
  [DiagnosticFlag.BRAKE_PAD_LIFE_REAR_GREEN]: 'brakePadLifeRearGreen',
  [DiagnosticFlag.BRAKE_PAD_LIFE_REAR_YELLOW]: 'brakePadLifeRearYellow',
  [DiagnosticFlag.BRAKE_PAD_LIFE_REAR_RED]: 'brakePadLifeRearRed',

  [DiagnosticFlag.ENGINE_AIR_FILTER_GREEN]: 'engineAirFilterGreen',
  [DiagnosticFlag.ENGINE_AIR_FILTER_YELLOW]: 'engineAirFilterYellow',
  [DiagnosticFlag.ENGINE_AIR_FILTER_RED]: 'engineAirFilterRed',

  [DiagnosticFlag.TIRE_LEFT_FRONT_GREEN]: 'tireLeftFrontGreen',
  [DiagnosticFlag.TIRE_LEFT_FRONT_YELLOW]: 'tireLeftFrontYellow',
  [DiagnosticFlag.TIRE_LEFT_FRONT_RED]: 'tireLeftFrontRed',
  [DiagnosticFlag.TIRE_LEFT_REAR_GREEN]: 'tireLeftRearGreen',
  [DiagnosticFlag.TIRE_LEFT_REAR_YELLOW]: 'tireLeftRearYellow',
  [DiagnosticFlag.TIRE_LEFT_REAR_RED]: 'tireLeftRearRed',
  [DiagnosticFlag.TIRE_RIGHT_FRONT_GREEN]: 'tireRightFrontGreen',
  [DiagnosticFlag.TIRE_RIGHT_FRONT_YELLOW]: 'tireRightFrontYellow',
  [DiagnosticFlag.TIRE_RIGHT_FRONT_RED]: 'tireRightFrontRed',
  [DiagnosticFlag.TIRE_RIGHT_REAR_GREEN]: 'tireRightRearGreen',
  [DiagnosticFlag.TIRE_RIGHT_REAR_YELLOW]: 'tireRightRearYellow',
  [DiagnosticFlag.TIRE_RIGHT_REAR_RED]: 'tireRightRearRed',
  [DiagnosticFlag.TIRE_FLAT]: 'flatTire',
  [DiagnosticFlag.TIRE_LOW]: 'lowTire',

  [DiagnosticFlag.OIL_LIFE_GREEN]: 'oilLifeGreen',
  [DiagnosticFlag.OIL_LIFE_YELLOW]: 'oilLifeYellow',
  [DiagnosticFlag.OIL_LIFE_RED]: 'oilLifeRed',

  [DiagnosticFlag.DIESEL_EXHAUST_FLUID_GREEN]: 'dieselExhaustFluidGreen',
  [DiagnosticFlag.DIESEL_EXHAUST_FLUID_YELLOW]: 'dieselExhaustFluidYellow',
  [DiagnosticFlag.DIESEL_EXHAUST_FLUID_RED]: 'dieselExhaustFluidRed',

  // DEPRECATED
  [DiagnosticFlag.TEMP_CUBBY1_RED]: 'tempCubby1Red',
  [DiagnosticFlag.TEMP_CUBBY2_RED]: 'tempCubby2Red',
  [DiagnosticFlag.TEMP_CUBBY3_RED]: 'tempCubby3Red',
  [DiagnosticFlag.TEMP_CUBBY4_RED]: 'tempCubby4Red',
  [DiagnosticFlag.TEMP_CUBBY5_RED]: 'tempCubby5Red',
  [DiagnosticFlag.TEMP_CUBBY6_RED]: 'tempCubby6Red',
  [DiagnosticFlag.TEMP_CUBBY7_RED]: 'tempCubby7Red',
  [DiagnosticFlag.TEMP_CUBBY8_RED]: 'tempCubby8Red',
  [DiagnosticFlag.TEMP_CUBBY9_RED]: 'tempCubby9Red',
  [DiagnosticFlag.TEMP_CUBBY1_YELLOW]: 'tempCubby1Yellow',
  [DiagnosticFlag.TEMP_CUBBY2_YELLOW]: 'tempCubby2Yellow',
  [DiagnosticFlag.TEMP_CUBBY3_YELLOW]: 'tempCubby3Yellow',
  [DiagnosticFlag.TEMP_CUBBY4_YELLOW]: 'tempCubby4Yellow',
  [DiagnosticFlag.TEMP_CUBBY5_YELLOW]: 'tempCubby5Yellow',
  [DiagnosticFlag.TEMP_CUBBY6_YELLOW]: 'tempCubby6Yellow',
  [DiagnosticFlag.TEMP_CUBBY7_YELLOW]: 'tempCubby7Yellow',
  [DiagnosticFlag.TEMP_CUBBY8_YELLOW]: 'tempCubby8Yellow',
  [DiagnosticFlag.TEMP_CUBBY9_YELLOW]: 'tempCubby9Yellow',
} as const;

export const getAssetType = (model?: string): AssetType | undefined =>
  (typeof model === 'string' && ApiToAssetTypeMap[model as ApiAssetType]) ||
  undefined;
export const getAssetTypeVersion = (
  assetTypeVersion: string | undefined | null
): string => {
  const regex = /^(ev|elcv)/i;
  return notEmpty(assetTypeVersion)
    ? regex.test(assetTypeVersion)
      ? assetTypeVersion.replace(regex, '')
      : assetTypeVersion
    : '';
};

export const mapAssetModel = (
  assetType?: AssetType
): AssetProductModel | undefined =>
  (!!assetType && AssetTypeToModelMap[assetType]) || undefined;

export const mapApiResponseToType = (
  apiAssetType?: ApiAsset['type']
): AssetModel => {
  const assetType = getAssetType(apiAssetType?.model);
  const version = getAssetTypeVersion(apiAssetType?.version);
  return {
    model: mapAssetModel(assetType) || '',
    version,
    class: assetType,
    apiType: apiAssetType?.model as ApiAssetType,
  };
};

// export const mapApiPowerType = (status: Partial<ApiAssetTelemetry>) => {
//   return (status &&
//     status.charging_power_type &&
//     status.charging_power_type.value &&
//     status.charging_session?.value !== 'interrupted') ||
//     status.charging_session?.value !== 'interrupted'
//     ? {
//         value: status.charging_power_type.value,
//         deviceTimestamp: status.charging_power_type.deviceTimestamp,
//       }
//     : undefined;
// };
export const parseDiagnosticCodes = (hex: string): string | undefined => {
  const convertedHex = parseInt(hex, 16);
  const collectedIndices: string[] = [];

  if (convertedHex === 0) {
    return undefined;
  }

  const convertedHexArr = convertedHex.toString(2).split('').reverse();

  convertedHexArr.forEach((element, index) => {
    if (element === '1') {
      collectedIndices.push(index.toString());
    }
  });

  const codes = collectedIndices
    .map((item) => diagnosticFaultCodesTable[item])
    .filter((index) => !!index);

  return codes.join(',');
};

/**
 * Sets the fleet readiness flag in asset.flags.
 */
export const mapVehicleFleetReadiness = (
  apiAsset?: Partial<ApiAsset>
): boolean | undefined => {
  const isClear = apiAsset?.state?.diagnostic === DiagnosticState.CLEAR;
  if (!isClear) {
    return false;
  }

  const actualTires = {
    leftFront: apiAsset.status?.tire_driver_front_act?.value,
    rightFront: apiAsset.status?.tire_pass_front_act?.value,
    leftRear: apiAsset.status?.tire_driver_rear_act?.value,
    rightRear: apiAsset.status?.tire_pass_rear_act?.value,
  };
  const expectedTires = {
    front: apiAsset.status?.tires_front_exp?.value,
    rear: apiAsset.status?.tires_rear_exp,
  };

  const hasUnDefinedActual = Object.values(actualTires).some(
    (value) => value === undefined
  );
  const hasUndefinedExpected = Object.values(expectedTires).some(
    (value) => value === undefined
  );
  const batteryRange = mapBatteryRangeStatus(
    apiAsset.status?.hv_battery_soc_in_pct,
    apiAsset.diagnostics
  );
  const isElectricVehicle =
    apiAsset.vehicleType?.propulsion === VehiclePropulsion.EV ||
    apiAsset.vehicleType?.propulsion === VehiclePropulsion.PHEV;

  // return undefined if there are undefined tire actual, tire expected, or if unknown battery
  return (isElectricVehicle && batteryRange === BatteryRange.UNKNOWN) ||
    hasUnDefinedActual ||
    hasUndefinedExpected ||
    apiAsset.diagnostics === undefined
    ? undefined
    : isClear;
};

export const mapDiagnosticFlags = (
  apiAsset?: Partial<ApiAsset>
): AssetFlags | undefined => {
  const mapped = {
    diagnostics: apiAsset?.diagnostics ?? {},
    ready: mapVehicleFleetReadiness(apiAsset),
  } as AssetFlags;

  Object.keys(mapped.diagnostics).forEach((flag) => {
    const constFlag = flag as keyof AssetDiagnostics;
    const mappedFlag = DiagnosticMap[constFlag];
    if (mappedFlag && mapped.diagnostics[constFlag]) {
      mapped[mappedFlag] = true;
    }
  });

  return mapped;
};

export const mapPeriCompartments = (
  apiAsset: Partial<ApiAsset>
): PeriCompartments | undefined => {
  const temperatureStatus = Object.assign(
    {},
    ...[...Array(9)].map((_: undefined, index: number) => {
      const cubbyStatus =
        apiAsset.status?.[`temp_cubby${index + 1}` as keyof ApiAssetTelemetry];
      const cubbyDiagRed =
        apiAsset.diagnostics?.[
          `temp_cubby${index + 1}_red` as keyof AssetDiagnostics
        ];
      const cubbyDiaYellow =
        apiAsset.diagnostics?.[
          `temp_cubby${index + 1}_yellow` as keyof AssetDiagnostics
        ];
      if (cubbyStatus) {
        return {
          [`cubby${index + 1}`]: {
            ...cubbyStatus,
            severity: cubbyDiagRed
              ? DiagnosticSeverity.CRITICAL
              : cubbyDiaYellow
                ? DiagnosticSeverity.IMPAIRED
                : DiagnosticSeverity.INFO,
          },
        };
      } else return undefined;
    })
  );
  return Object.keys(temperatureStatus).length > 0
    ? {
        temperatureStatus,
      }
    : undefined;
};

export const checkDiagnosticFlags = (
  diagnostics: AssetDiagnostics,
  flags: DiagnosticFlag[]
): boolean => {
  for (const name of flags) {
    if (name in diagnostics) {
      return true;
    }
  }
  return false;
};

// Faults defined as diagnostics flags outside of known list
export const countAssetFaults = (
  apiAsset: Partial<ApiAsset>
): AssetFaultDisplay => {
  let faultCount = 0;
  let criticalCount = 0;
  let hasCriticalFault = false;
  let hasImpairedFault = false;
  if (apiAsset.diagnostics) {
    const diagnostics = apiAsset.diagnostics;
    Object.keys(apiAsset.diagnostics).forEach((item) => {
      const id = item as keyof AssetDiagnostics;
      if (
        KNOWN_DIAGNOSTIC_FLAGS.every((flag) => flag !== id) &&
        id.match(/^dtc_/)
      ) {
        if (diagnostics[id]?.severity === DiagnosticSeverity.CRITICAL) {
          hasCriticalFault = true;
          criticalCount++;
        }
        if (diagnostics[id]?.severity === DiagnosticSeverity.IMPAIRED) {
          hasImpairedFault = true;
        }
        faultCount++;
      }
    });
  }

  const faultCodes = apiAsset.status?.c_diag?.value
    ? parseDiagnosticCodes(apiAsset.status?.c_diag?.value)
    : undefined;
  if (faultCodes) {
    faultCount += faultCodes.split(',').length;
  }

  return {
    numberOfFaults: faultCount,
    numberOfCriticalFaults: criticalCount,
    hasCriticalFault,
    hasImpairedFault,
  };
};

export const mapAssetFaults = (
  diagnostics?: ApiAsset['diagnostics']
): AssetFault[] | undefined => {
  if (!diagnostics) return undefined;

  const faults: AssetFault[] = [];
  forEach(diagnostics, (fault, key) => {
    if (
      !KNOWN_DIAGNOSTIC_FLAGS.includes(key as DiagnosticFlag) &&
      key.match(/^dtc_/) &&
      fault?.systems?.length
    ) {
      faults.push({
        id: key,
        code: key.slice(4),
        severity: fault.severity ?? DiagnosticSeverity.UNKNOWN,
        deviceTimestamp: fault.deviceTimestamp || DEFAULT_EMPTY_VALUE,
        status: AssetFaultStatus.ACTIVE,
        mil: fault.mil || false,
        name: fault.name || DEFAULT_EMPTY_VALUE,
        text: fault.text,
        recommendation: fault.recommendation,
        system: fault?.systems?.[0]?.type,
      });
    }
  });
  return faults;
};

export const mapApiResponseToAction = (
  apiAction: ApiActionModel
): ActionModel | undefined => {
  switch (apiAction.type) {
    case AssetEventAction.DOOR:
      return {
        type: 'Door',
        value: apiAction.value ? 'OPEN' : 'CLOSED',
      };
    case AssetEventAction.LOCK:
      return {
        type: 'Lock',
        value: apiAction.value ? 'LOCKED' : 'UNLOCKED',
      };
    case AssetEventAction.POWER_ON:
      return {
        type: 'Power',
        value: 'ON',
      };
    case AssetEventAction.POWER_OFF:
      return {
        type: 'Power',
        value: 'OFF',
      };
    default:
      return undefined;
  }
};

export const mapApiResponseToActor = (apiActor: ApiActorModel): ActorModel =>
  apiActor.method === 'A'
    ? {
        id: null,
        method: 'A',
        name: 'Autolock',
      }
    : {
        id: apiActor.id || null,
        method: apiActor.method || null,
        name: apiActor.name || DEFAULT_EMPTY_VALUE,
      };

export type AssetApiRequestPayload = {
  name: string;
  organizationId: string | null;
  hubId: string | null;
  fleetId: string | null;
  type: UpdateApiAssetType.VEHICLE;
  hardwareId?: string;
  licensePlate: string | null;
  changeAvailabilityStateRequest?: {
    current: AssetServiceStatus | null;
    desired: AssetServiceStatus | null;
  };
};

export const mapEventApiResponseToEvent = (
  apiAssetEvents: ApiAssetEvent[]
): AssetEvent[] =>
  apiAssetEvents.map(({ id, assetId, action, actor, timestamp, state }) => ({
    id,
    assetId,
    action: mapApiResponseToAction(action),
    actor: actor ? mapApiResponseToActor(actor) : undefined,
    timestamp: toDateStringWithTimezone(timestamp),
    state: {
      ...state,
      location: state?.location
        ? {
            latitude: state.location.lat,
            longitude: state.location.lon,
          }
        : undefined,
      address: state?.location?.address
        ? mapApiResponseToAddress(state?.location?.address)
        : undefined,
      lock: notEmpty(state?.locked)
        ? state?.locked
          ? 'LOCKED'
          : 'UNLOCKED'
        : undefined,
      door: notEmpty(state?.door)
        ? state?.door
          ? 'OPEN'
          : 'CLOSED'
        : undefined,
      charge:
        state && notEmpty(state?.charge_active)
          ? state.charge_active
          : undefined,
      battery:
        typeof state?.battery === 'number' &&
        state.battery >= 0 &&
        state.battery <= 100
          ? Math.round(state.battery)
          : undefined,
      diagnostics: state?.c_diag,
      tripId: state?.tripId,
      startEventId: id?.split(/;power_off;|;power_on;/i)[0],
      endEventId: id?.split(/;power_off;|;power_on;/i)[1],
      tripEndOdometer:
        typeof state?.tripEndOdometer === 'number'
          ? state.tripEndOdometer
          : undefined,
      tripStartOdometer:
        typeof state?.tripStartOdometer === 'number'
          ? state.tripStartOdometer
          : undefined,
    },
  }));

export const mapLocationApiResponseToLocation = (
  apiAssetLocation: ApiAssetLocation
): Location[] => {
  const assetLocations: AssetRawLocation[] = [];
  apiAssetLocation.metadata.forEach((meta, i) => {
    assetLocations.push({
      longitude: apiAssetLocation.coordinates[i][0],
      latitude: apiAssetLocation.coordinates[i][1],
      timestamp: meta.ts,
    });
  });
  return assetLocations;
};
/**
 * @param apiGeoJSON: ApiGeoJSON
 * @returns Location
 * Mapping for geoLocation API
 * Contract: https://dev.azure.com/BrightDrop/BrightDrop/_git/apicontracts?path=/asset-service/asset-apis.md&anchor=get-asset-location-history-%28geojson%29:-get-/api/asset/%7bassetid%7d/location/geojson?%5b%26startdate%3D%7bstartdate%2a%7d%26enddate%3D%7benddate%2a%7d%5d&_a=preview
 */
export const mapGeoJSONApiResponseToLocation = (
  apiGeoJSON: ApiGeoJSON
): Location[] => {
  const assetLocations: AssetRawLocation[] = [];
  apiGeoJSON.geometry.coordinates.forEach((lonLat, i) => {
    assetLocations.push({
      longitude: lonLat[0],
      latitude: lonLat[1],
      timestamp: apiGeoJSON.properties.ts[i],
    });
  });
  return assetLocations;
};

/**
 * Maps estimated range only if "unit of measure" (`uom`) from API response is a supported `DistanceUnit`
 *
 * Backwards-compatibility with API responses that do not include `uom` should be removed when format
 * of API asset detail is stable and consistent
 */
export const mapApiEstimatedRange = ({
  vehicleType,
  apiChargeEstimatedRange,
  apiFuelEstimatedRange,
}: {
  vehicleType?: VehiclePropulsion;
  apiChargeEstimatedRange?: ApiAssetTelemetry['estimated_range_in_km'];
  apiFuelEstimatedRange?: ApiAssetTelemetry['fuel_range_in_km'];
}): EstimatedRange | undefined => {
  if (
    apiChargeEstimatedRange &&
    notEmpty(apiChargeEstimatedRange?.value) &&
    vehicleType === VehiclePropulsion.EV
  ) {
    const deviceTimestamp = apiChargeEstimatedRange.deviceTimestamp
      ? toDateStringWithTimezone(apiChargeEstimatedRange.deviceTimestamp)
      : '';
    if (typeof apiChargeEstimatedRange.value === 'number') {
      return {
        value: apiChargeEstimatedRange.value,
        deviceTimestamp,
        unitOfMeasure: DistanceUnit.KM,
      };
    } else if (
      (apiChargeEstimatedRange.value.uom === DistanceUnit.KM ||
        apiChargeEstimatedRange.value.uom === DistanceUnit.MI) &&
      typeof apiChargeEstimatedRange.value.value === 'number'
    ) {
      return {
        value: apiChargeEstimatedRange.value.value,
        deviceTimestamp,
        unitOfMeasure: apiChargeEstimatedRange.value.uom,
      };
    }
  }
  if (
    apiFuelEstimatedRange &&
    notEmpty(apiFuelEstimatedRange?.value) &&
    vehicleType === VehiclePropulsion.ICE
  ) {
    const deviceTimestamp = apiFuelEstimatedRange.deviceTimestamp
      ? toDateStringWithTimezone(apiFuelEstimatedRange.deviceTimestamp)
      : '';
    if (typeof apiFuelEstimatedRange.value === 'number') {
      return {
        value: apiFuelEstimatedRange.value,
        deviceTimestamp,
        unitOfMeasure: DistanceUnit.KM,
      };
    }
  }
  return undefined;
};

export const mapApiSpeed = (
  apiSpeed?: TelemetryStatus<number>,
  apiType?: AssetType | undefined
): TelemetryStatus<number> | undefined => {
  if (
    typeof apiSpeed?.value === 'number' &&
    notEmpty(apiSpeed?.value) &&
    (apiType === AssetType.VEHICLE || apiType === AssetType.EP)
  ) {
    return {
      value: apiSpeed.value,
      deviceTimestamp: apiSpeed.deviceTimestamp
        ? toDateStringWithTimezone(apiSpeed.deviceTimestamp)
        : '',
    };
  }
  return undefined;
};

export const mapOperationalStatus = ({
  connectivity,
  speed,
  direction,
}: {
  connectivity?: string;
  speed?: number;
  direction?: number;
}): AssetOperationalStatus => {
  if (connectivity && uppercaseLiteral(connectivity) !== 'OFFLINE') {
    if (typeof speed === 'number') {
      if (speed > 0) {
        if (
          typeof direction === 'number' &&
          direction >= 0 &&
          direction <= 360
        ) {
          //moving with direction
          return AssetOperationalStatus.MOVING;
        } else {
          //moving without direction
          return AssetOperationalStatus.MOVING_NO_DIRECTION;
        }
      } else if (speed === 0) {
        return AssetOperationalStatus.STOPPED;
      }
    }
  }
  return AssetOperationalStatus.OFFLINE;
};

export const mapLockedStatus = (
  lockedStatus?: ApiAssetTelemetry['locked']
): AssetTelemetry['lock'] | undefined => {
  return (
    (lockedStatus &&
      notEmpty(lockedStatus.value) && {
        value: lockedStatus.value ? ('LOCKED' as const) : ('UNLOCKED' as const),
        deviceTimestamp: lockedStatus.deviceTimestamp
          ? toDateStringWithTimezone(lockedStatus.deviceTimestamp)
          : '',
      }) ||
    undefined
  );
};

export const mapDoorStatus = (
  doorStatus?: ApiAssetTelemetry['door']
): AssetTelemetry['door'] | undefined => {
  return (
    (doorStatus &&
      notEmpty(doorStatus.value) && {
        value: doorStatus.value ? ('OPEN' as const) : ('CLOSED' as const),
        deviceTimestamp: doorStatus?.deviceTimestamp
          ? toDateStringWithTimezone(doorStatus.deviceTimestamp)
          : '',
      }) ||
    undefined
  );
};

export const mapLocationStatus = (
  locationStatus?: ApiAssetTelemetry['location']
): AssetTelemetry['location'] | undefined => {
  return locationStatus &&
    isValidLocation({
      latitude: locationStatus.value?.lat,
      longitude: locationStatus.value?.lon,
    })
    ? {
        value: {
          latitude: locationStatus.value?.lat,
          longitude: locationStatus.value?.lon,
        },
        deviceTimestamp: toDateStringWithTimezone(
          locationStatus.deviceTimestamp
        ),
      }
    : undefined;
};

export const mapBatteryLevelStatus = (
  batteryStatus?: ApiAssetTelemetry['hv_battery_soc_in_pct']
): BatteryStatus['battery'] | undefined => {
  return typeof batteryStatus?.value === 'number' &&
    batteryStatus?.value >= 0 &&
    batteryStatus?.value <= 100
    ? {
        value: batteryStatus.value,
        deviceTimestamp: batteryStatus?.deviceTimestamp
          ? toDateStringWithTimezone(batteryStatus?.deviceTimestamp)
          : '',
      }
    : undefined;
};

export const mapBatteryChargingStatus = (
  chargingStatus?: ApiAssetTelemetry['charge_active']
): BatteryStatus['charge'] | undefined => {
  return chargingStatus && notEmpty(chargingStatus?.value)
    ? {
        value: chargingStatus.value,
        deviceTimestamp: chargingStatus.deviceTimestamp
          ? toDateStringWithTimezone(chargingStatus.deviceTimestamp)
          : '',
      }
    : undefined;
};

export const mapBatteryRangeStatus = (
  batteryStatus?: ApiAssetTelemetry['hv_battery_soc_in_pct'],
  diagnostics?: ApiAsset['diagnostics']
): BatteryStatus['batteryRange'] => {
  if (
    typeof batteryStatus?.value === 'number' &&
    batteryStatus?.value >= MIN_VALID_SOC_VALUE &&
    batteryStatus?.value <= MAX_VALID_SOC_VALUE
  ) {
    if (diagnostics?.battery_red) {
      return BatteryRange.LOW;
    }
    if (diagnostics?.battery_yellow) {
      return BatteryRange.MEDIUM;
    }
    return BatteryRange.HIGH;
  }
  return BatteryRange.UNKNOWN;
};

export const mapTelemetryStatusFlags = <T>(
  vehiclePartStatus?: TelemetryStatus<T>,
  greenFlag?: TelemetryStatus<boolean>,
  yellowFlag?: TelemetryStatus<boolean>,
  redFlag?: TelemetryStatus<boolean>
): TelemetryStatus<T> | undefined => {
  if (vehiclePartStatus?.value == null) {
    return undefined;
  }

  let severity = DiagnosticSeverity.UNKNOWN;
  if (greenFlag) {
    severity = DiagnosticSeverity.INFO;
  }
  if (yellowFlag) {
    severity = DiagnosticSeverity.IMPAIRED;
  }
  if (redFlag) {
    severity = DiagnosticSeverity.CRITICAL;
  }

  return {
    value: vehiclePartStatus.value,
    severity,
    deviceTimestamp: vehiclePartStatus.deviceTimestamp
      ? toDateStringWithTimezone(vehiclePartStatus.deviceTimestamp)
      : '',
  };
};

export const mapOdometerStatus = (
  odometerStatus?: ApiAssetTelemetry['odometer_in_km']
): AssetTelemetry['odometer'] | undefined => {
  return odometerStatus && notEmpty(odometerStatus?.value)
    ? {
        value: odometerStatus.value,
        deviceTimestamp: odometerStatus.deviceTimestamp
          ? toDateStringWithTimezone(odometerStatus.deviceTimestamp)
          : '',
        unitOfMeasure: DistanceUnit.KM,
      }
    : undefined;
};

export const mapLegacyDiagnosticStatus = (
  cDiag?: ApiAssetTelemetry['c_diag']
): AssetTelemetry['diagnostics'] | undefined => {
  return notEmpty(cDiag?.value)
    ? {
        value: parseDiagnosticCodes(cDiag?.value as string) as string,
        deviceTimestamp: cDiag?.deviceTimestamp
          ? toDateStringWithTimezone(cDiag?.deviceTimestamp)
          : '',
      }
    : undefined;
};

export const mapApiState = (apiState?: ApiAsset['state']): AssetState => {
  return apiState
    ? {
        ...apiState,
        provisioning: apiState?.provisioning ?? null,
        connectivity: apiState.connectivity
          ? uppercaseLiteral(apiState.connectivity)
          : 'OFFLINE',
        availability: apiState.availability,
      }
    : ({} as AssetState);
};

export const mapTargetSocStatus = (
  status?: ApiAssetTelemetry['charging_target_soc_in_pct']
): AssetTelemetry['targetSoc'] | undefined => {
  return (
    (status &&
      notEmpty(status.value) && {
        value: status.value,
        deviceTimestamp: status?.deviceTimestamp
          ? toDateStringWithTimezone(status.deviceTimestamp)
          : '',
      }) ||
    undefined
  );
};

export const mapAssetApiResponseToAsset = (
  apiAsset: Partial<ApiAsset>
): Asset => {
  if (apiAsset.id && apiAsset.name) {
    // preserve original API asset to merge as needed with incoming pubsub event payloads
    const originalApiAsset: ApiAsset = JSON.parse(JSON.stringify(apiAsset));

    const {
      organization,
      hub,
      fleet,
      creationDate,
      lastUpdated,
      licensePlate,
      vehicleType,
      tcpsAccepted,
    } = apiAsset;

    const mapped: Asset = {
      raw: originalApiAsset,
      id: apiAsset.id,
      organization,
      hub,
      fleet,
      creationDate,
      lastUpdated,
      licensePlate,
      organizationPrimaryOwnershipStatus:
        apiAsset.organizationPrimaryOwnershipStatus ?? null,
      name: apiAsset.name,
      type: mapApiResponseToType(apiAsset.type),
      vehicleType,
      tcpsAccepted: tcpsAccepted ?? null,
      hvBatteryInfo: {
        chargeState: apiAsset?.hvBatteryInfo?.chargeState
          ? apiAsset?.hvBatteryInfo?.chargeState
          : undefined,
        targetSoc: apiAsset?.hvBatteryInfo?.targetSoc
          ? apiAsset?.hvBatteryInfo?.targetSoc
          : undefined,
        chargePowerType: apiAsset?.hvBatteryInfo?.chargePowerType,
      },
      status: {
        fuelLevel: apiAsset.status?.fuel_level_in_pct,
        location: mapLocationStatus(apiAsset.status?.location),
        lock: mapLockedStatus(apiAsset.status?.locked),
        door: mapDoorStatus(apiAsset.status?.door),
        brakeFluidStatus: mapTelemetryStatusFlags(
          apiAsset.diagnostics?.fluid_low_brake ||
            apiAsset.diagnostics?.fluid_brake_green,
          apiAsset.diagnostics?.fluid_brake_green,
          apiAsset.diagnostics?.fluid_low_brake
        ),
        washerFluidStatus: mapTelemetryStatusFlags(
          apiAsset.diagnostics?.fluid_low_washer ||
            apiAsset.diagnostics?.fluid_washer_green,
          apiAsset.diagnostics?.fluid_washer_green,
          apiAsset.diagnostics?.fluid_low_washer
        ),
        batteryStatus: {
          battery: mapBatteryLevelStatus(
            apiAsset.status?.hv_battery_soc_in_pct
          ),
          charge: mapBatteryChargingStatus(apiAsset.status?.charge_active),
          batteryRange: mapBatteryRangeStatus(
            apiAsset.status?.hv_battery_soc_in_pct,
            apiAsset.diagnostics
          ),
        },
        brakePadStatuses: {
          front: mapTelemetryStatusFlags(
            apiAsset.status?.brake_pad_life_front_in_pct,
            apiAsset.diagnostics?.brake_pad_life_front_green,
            apiAsset.diagnostics?.brake_pad_life_front_yellow,
            apiAsset.diagnostics?.brake_pad_life_front_red
          ),
          rear: mapTelemetryStatusFlags(
            apiAsset.status?.brake_pad_life_rear_in_pct,
            apiAsset.diagnostics?.brake_pad_life_rear_green,
            apiAsset.diagnostics?.brake_pad_life_rear_yellow,
            apiAsset.diagnostics?.brake_pad_life_rear_red
          ),
        },
        engineAirFilterStatus: mapTelemetryStatusFlags(
          apiAsset.status?.engine_air_filter_life_in_pct,
          apiAsset.diagnostics?.engine_air_filter_green,
          apiAsset.diagnostics?.engine_air_filter_yellow,
          apiAsset.diagnostics?.engine_air_filter_red
        ),
        oilLifeStatus: mapTelemetryStatusFlags(
          apiAsset.status?.oil_life_in_pct,
          apiAsset.diagnostics?.oil_life_green,
          apiAsset.diagnostics?.oil_life_yellow,
          apiAsset.diagnostics?.oil_life_red
        ),
        dieselExhaustFluidStatus: mapTelemetryStatusFlags(
          apiAsset.diagnostics?.diesel_exhaust_green ||
            apiAsset.diagnostics?.diesel_exhaust_yellow ||
            apiAsset.diagnostics?.diesel_exhaust_red,
          apiAsset.diagnostics?.diesel_exhaust_green,
          apiAsset.diagnostics?.diesel_exhaust_yellow,
          apiAsset.diagnostics?.diesel_exhaust_red
        ),
        diagnostics: mapLegacyDiagnosticStatus(apiAsset.status?.c_diag),
        tirePressures: {
          leftFront: mapTelemetryStatusFlags(
            apiAsset.status?.tire_driver_front_act,
            apiAsset.diagnostics?.tire_left_front_green,
            apiAsset.diagnostics?.tire_left_front_yellow,
            apiAsset.diagnostics?.tire_left_front_red
          ),
          leftRear: mapTelemetryStatusFlags(
            apiAsset.status?.tire_driver_rear_act,
            apiAsset.diagnostics?.tire_left_rear_green,
            apiAsset.diagnostics?.tire_left_rear_yellow,
            apiAsset.diagnostics?.tire_left_rear_red
          ),
          rightFront: mapTelemetryStatusFlags(
            apiAsset.status?.tire_pass_front_act,
            apiAsset.diagnostics?.tire_right_front_green,
            apiAsset.diagnostics?.tire_right_front_yellow,
            apiAsset.diagnostics?.tire_right_front_red
          ),
          rightRear: mapTelemetryStatusFlags(
            apiAsset.status?.tire_pass_rear_act,
            apiAsset.diagnostics?.tire_right_rear_green,
            apiAsset.diagnostics?.tire_right_rear_yellow,
            apiAsset.diagnostics?.tire_right_rear_red
          ),
        },
        expectedTirePressures: {
          front: apiAsset.status?.tires_front_exp,
          rear: apiAsset.status?.tires_rear_exp,
        },
        odometer: mapOdometerStatus(apiAsset.status?.odometer_in_km),
        estimatedRange: mapApiEstimatedRange({
          vehicleType: apiAsset.vehicleType?.propulsion,
          apiChargeEstimatedRange: apiAsset.status?.estimated_range_in_km,
          apiFuelEstimatedRange: apiAsset.status?.fuel_range_in_km,
        }),
        speedStatus: mapApiSpeed(
          apiAsset.status?.speed_in_kph,
          getAssetType(apiAsset?.type?.model)
        ),
        direction: apiAsset?.status?.direction_in_deg,
        compartments: mapPeriCompartments(apiAsset),
        chargingStatus: apiAsset?.status?.charging_session,
        powerType: apiAsset?.status?.charging_power_type,
        minutesToTargetSoc: apiAsset?.status?.charging_time_remaining_in_min,
        targetSoc: mapTargetSocStatus(
          apiAsset.status?.charging_target_soc_in_pct
        ),
        engineRunTime: apiAsset.status?.engine_run_time_total_in_sec,
        lifetimeFuelEconomy: apiAsset.status?.lifetime_fuel_economy_in_kmpl,
        powerState: mapPowerState(apiAsset.status?.power_state),
        gearState: mapGearState(apiAsset.status?.gear_state),
      },
      state: mapApiState(apiAsset.state),
      configuration: {
        autolockTimeout: apiAsset.configuration?.autolockTimeout
          ? typeof apiAsset.configuration.autolockTimeout === 'string'
            ? parseInt(apiAsset.configuration?.autolockTimeout)
            : typeof apiAsset.configuration.autolockTimeout === 'number'
              ? apiAsset.configuration.autolockTimeout
              : undefined
          : undefined,
        autolockEnabled:
          typeof apiAsset.configuration?.autolockEnabled === 'string'
            ? apiAsset.configuration?.autolockEnabled === 'true'
            : typeof apiAsset.configuration?.autolockEnabled === 'boolean'
              ? apiAsset.configuration?.autolockEnabled
              : undefined,
        proximityUnlockEnabled:
          typeof apiAsset.configuration?.proximityUnlockEnabled === 'boolean'
            ? apiAsset.configuration?.proximityUnlockEnabled
            : undefined,
        chargingScheduleEnabled: apiAsset.configuration
          ?.chargingScheduleTargetChargeLevel
          ? ChargingSchedule.ON
          : ChargingSchedule.OFF,
      },
      flags: mapDiagnosticFlags(apiAsset),
      faults: countAssetFaults(apiAsset),
      faultsData: mapAssetFaults(apiAsset.diagnostics),
      operationalStatus: mapOperationalStatus({
        connectivity: apiAsset?.state?.connectivity,
        speed: apiAsset?.status?.speed_in_kph?.value,
        direction: apiAsset?.status?.direction_in_deg?.value,
      }),
      availableCommands: mapAvailableRemoteCommands(
        apiAsset?.availableCommands
      ),
      imageUrl: buildVehicleImageURL(vehicleType),
    };

    return mapped;
  }

  throw new BDError('Unexpected asset response', {
    type: BDAppErrorType.VALIDATION,
    data: apiAsset,
  });
};

export const mapPowerState = (
  powerState: TelemetryStatus<AssetIgnitionStateApi> | null | undefined
): TelemetryStatus<RemoteCommandPowerIgnitionStatus> | undefined => {
  if (!powerState) {
    return undefined;
  }
  if (!(powerState.value in RemoteCommandPowerIgnitionStatus)) {
    throw new Error(`Unknown power state: ${powerState.value}`);
  }

  return {
    ...powerState,
    value: RemoteCommandPowerIgnitionStatus[powerState.value],
    deviceTimestamp: powerState.deviceTimestamp,
  };
};

// list of possible values for gear state: https://dev.azure.com/BrightDrop/BrightDrop/_workitems/edit/55114#25236822
export const mapGearState = (
  gearState: TelemetryStatus<AssetGearStateApi> | null | undefined
): TelemetryStatus<RemoteCommandGearMode> | undefined => {
  if (!gearState) return undefined;

  const gearModeMap: Record<string, RemoteCommandGearMode> = {
    [AssetGearStateApi.DRIVE]: RemoteCommandGearMode.DRIVE,
    [AssetGearStateApi.NEUTRAL]: RemoteCommandGearMode.NEUTRAL,
    [AssetGearStateApi.REVERSE]: RemoteCommandGearMode.REVERSE,
    [AssetGearStateApi.PARK]: RemoteCommandGearMode.PARK,
  };

  // Check if the value starts with 'drive' (handling DRIVE as a prefix case)
  if (gearState.value.startsWith(AssetGearStateApi.DRIVE)) {
    return {
      ...gearState,
      value: RemoteCommandGearMode.DRIVE,
      deviceTimestamp: gearState.deviceTimestamp,
    };
  }
  // Check for other gear states in the map
  const transmissionMode: RemoteCommandGearMode = gearModeMap[gearState.value];

  if (transmissionMode) {
    return {
      ...gearState,
      value: transmissionMode,
      deviceTimestamp: gearState.deviceTimestamp,
    };
  }

  throw new Error(`Unknown gear state: ${gearState.value}`);
};

export const mapAssetFormToApiRequest = (
  payload: UpdateAssetFormPayload
): AssetApiRequestPayload => {
  const commonPayload = {
    name: payload.formFields.name.trim(),
    type: UpdateApiAssetType.VEHICLE,
    organizationId: payload.formFields.organization?.id || null,
    hubId: payload.formFields.hub?.id || null,
    fleetId: payload.formFields.fleet?.id || null,
    licensePlate: payload.formFields.licensePlate,
  };
  if (payload.formFields.currentAvailability) {
    return {
      ...commonPayload,
      changeAvailabilityStateRequest: {
        current: payload.formFields.currentAvailability,
        desired: payload.formFields.desiredAvailability,
      },
    };
  } else return commonPayload;
};

export const checkDiagnosticsError = (asset: Asset): AssetType | undefined => {
  if (asset.status?.diagnostics?.value) {
    return AssetType.EP;
  } else {
    return undefined;
  }
};

export const mapAssetToLocationWithId = (
  asset?: Asset
): LocationWithId | undefined => {
  return asset
    ? asset?.status?.location?.value
      ? ({
          id: asset.id,
          type: 'Feature',
          properties: { id: asset.id },
          geometry: {
            type: 'Point',
            coordinates: [
              asset?.status?.location?.value?.longitude,
              asset?.status?.location?.value?.latitude,
            ],
          },
        } as LocationWithId)
      : ({
          id: asset.id,
          type: 'Feature',
          properties: { id: asset.id },
        } as LocationWithId)
    : undefined;
};

export const mapAssetMapItemToLocationWithId = (
  item: AssetMapItem
): LocationWithId => {
  return item?.id && item?.location && isValidLocation(item?.location)
    ? ({
        id: item.id,
        type: 'Feature',
        properties: { id: item.id },
        geometry: {
          type: 'Point',
          coordinates: [item?.location?.longitude, item?.location?.latitude],
        },
      } as LocationWithId)
    : ({
        id: item?.id || '',
        type: 'Feature',
        properties: { id: item?.id || '' },
      } as LocationWithId);
};

export const mapAssetToMapItem = (asset?: Partial<Asset>): AssetMapItem => {
  return {
    id: asset?.id,
    name: asset?.name,
    model: asset?.type?.model,
    version: asset?.type?.version,
    operationalStatus: asset?.operationalStatus,
    statusTime: getOperationalStatusDurationDate({
      lastUpdated: asset?.lastUpdated,
      operationalStatus: asset?.operationalStatus,
      telemetry: asset?.status,
      flags: asset?.flags,
    })?.toISOString(),
    speed:
      asset?.type?.class === AssetType.VEHICLE
        ? asset?.status?.speedStatus?.value
        : undefined,
    ready: asset?.flags?.ready,
    estimatedRange: asset?.status?.estimatedRange?.value,
    battery: {
      isCharging: asset?.status?.batteryStatus?.charge?.value,
      range: asset?.status?.batteryStatus?.batteryRange,
      value: asset?.status?.batteryStatus?.battery?.value,
      chargingStatus: asset?.status?.chargingStatus?.value,
    },
    exceptions: getWarningFlags({
      model: asset?.type?.model,
      mil: asset?.state?.mil,
      ...asset?.faults,

      thermalStatus: asset?.status?.thermalStatus,
      tireStatus: asset?.flags?.flatTire
        ? DiagnosticSeverity.CRITICAL
        : asset?.flags?.lowTire
          ? DiagnosticSeverity.IMPAIRED
          : undefined,
      brakeFluidStatus: asset?.flags?.lowBrakeFluid
        ? DiagnosticSeverity.CRITICAL
        : undefined,
      washerFluidStatus: asset?.flags?.lowWasherFluid
        ? DiagnosticSeverity.CRITICAL
        : undefined,
    }),
    location: asset?.status?.location?.value,
    direction: asset?.status?.direction?.value,
    availability: asset?.state?.availability,
  } as AssetMapItem;
};

export const mapApiAssetMapItemToMapItem = (
  apiAssetMapItem?: Partial<ApiAssetMapItem>
): AssetMapItem => {
  const model = mapAssetModel(getAssetType(apiAssetMapItem?.type?.model));
  const version = getAssetTypeVersion(apiAssetMapItem?.type?.version);
  return {
    id: apiAssetMapItem?.id,
    name: apiAssetMapItem?.name,
    model,
    version,
    operationalStatus: mapOperationalStatus({
      connectivity: apiAssetMapItem?.connectivity,
      speed: apiAssetMapItem?.operational?.speed,
      direction: apiAssetMapItem?.operational?.direction,
    }),
    statusTime: apiAssetMapItem?.lastCommunicated,
    speed: apiAssetMapItem?.operational?.speed,
    ready: apiAssetMapItem?.ready,
    estimatedRange: apiAssetMapItem?.battery?.estimatedRangedInKM,

    battery: {
      isCharging:
        apiAssetMapItem?.battery?.chargeState ===
        BatteryChargingStatus.CHARGING,
      range: apiAssetMapItem?.battery?.state
        ? BATTERY_RANGE_MAPPING[apiAssetMapItem?.battery?.state] ||
          BatteryRange.UNKNOWN
        : undefined,
      value:
        typeof apiAssetMapItem?.battery?.soc === 'number' &&
        apiAssetMapItem?.battery?.soc >= MIN_VALID_SOC_VALUE &&
        apiAssetMapItem?.battery?.soc <= MAX_VALID_SOC_VALUE
          ? apiAssetMapItem?.battery?.soc
          : undefined,
      chargingStatus: apiAssetMapItem?.battery?.chargeState,
    },
    exceptions: getWarningFlags({
      model,
      mil: apiAssetMapItem?.milState,
      hasImpairedFault: !!apiAssetMapItem?.faultCount,
      thermalStatus: apiAssetMapItem?.cubbyTempState,
      tireStatus: apiAssetMapItem?.tires,
      brakeFluidStatus: apiAssetMapItem?.brakeFluid,
      washerFluidStatus: apiAssetMapItem?.washerFluidState,
    }),
    location: {
      longitude: apiAssetMapItem?.location?.lon,
      latitude: apiAssetMapItem?.location?.lat,
    },
    direction: apiAssetMapItem?.operational?.direction,
    availability: apiAssetMapItem?.availability,
  } as AssetMapItem;
};

const getWarningFlags = ({
  model,
  mil,
  hasCriticalFault,
  hasImpairedFault,
  thermalStatus,
  tireStatus,
  brakeFluidStatus,
  washerFluidStatus,
}: {
  model?: string;
  mil?: string;
  hasCriticalFault?: boolean;
  hasImpairedFault?: boolean;
  thermalStatus?: string;
  tireStatus?: string;
  brakeFluidStatus?: string;
  washerFluidStatus?: string;
}) => {
  const result = [];
  if (model === AssetProductModel.ZEVO) {
    if (mil === DiagnosticState.CRITICAL) {
      result.push(AssetExceptionStatus.SEVERE_FAULT);
    } else if (mil === DiagnosticState.IMPAIRED) {
      result.push(AssetExceptionStatus.IMPAIRED_FAULT);
    }
    if (mil === DiagnosticState.CRITICAL || mil === DiagnosticState.IMPAIRED) {
      result.push(AssetExceptionStatus.FAULTS);
    }
  } else {
    if (hasCriticalFault) {
      result.push(AssetExceptionStatus.SEVERE_FAULT);
    } else if (hasImpairedFault) {
      result.push(AssetExceptionStatus.IMPAIRED_FAULT);
    }
    if (hasImpairedFault || hasCriticalFault) {
      result.push(AssetExceptionStatus.FAULTS);
    }
  }

  if (thermalStatus === DiagnosticSeverity.CRITICAL) {
    result.push(AssetExceptionStatus.TEMP_CRITICAL);
  } else if (thermalStatus === DiagnosticSeverity.IMPAIRED) {
    result.push(AssetExceptionStatus.TEMP_IMPAIRED);
  }
  if (tireStatus === DiagnosticSeverity.IMPAIRED) {
    result.push(AssetExceptionStatus.LOW_TIRE);
  }
  if (tireStatus === DiagnosticSeverity.CRITICAL) {
    result.push(AssetExceptionStatus.FLAT_TIRE);
  }
  if (brakeFluidStatus === DiagnosticSeverity.CRITICAL) {
    result.push(AssetExceptionStatus.LOW_BRAKE_FLUID);
  }
  if (
    washerFluidStatus === DiagnosticSeverity.CRITICAL ||
    washerFluidStatus === DiagnosticSeverity.IMPAIRED
  ) {
    result.push(AssetExceptionStatus.LOW_WASHER_FLUID);
  }
  return result;
};

export const mapApiAssetComplianceToAssetComplianceDetail = ({
  type,
  ...apiCompliance
}: Partial<AssetComplianceDetail>): AssetComplianceDetail => {
  return {
    ...apiCompliance,
    type: type ? mapApiResponseToType(type) : undefined,
  } as AssetComplianceDetail;
};

/**
 * Maps connectivity status type based off the asset's OrganizationOwnershipStatus & ProvisioningStatus field
 * Reference: https://generalmotors.sharepoint.com/:x:/r/sites/msteams_552ef449feb9/Shared%20Documents/Engineering/Software%20Engineering/Web/Mapping%20For%20Vehicle%20Status.xlsx?d=wa89a3931ae5f4ffca9b7d1decefe5b85&csf=1&web=1&e=gTrlFk
 */
export const mapConnectivityStatusType = (
  organizationOwnershipStatus?: OrganizationOwnershipStatus | null,
  provisioning?: ProvisioningStatus | null
): ConnectivityStatusType | undefined => {
  if (organizationOwnershipStatus === OrganizationOwnershipStatus.CLAIMING)
    return ConnectivityStatusType.VERIFICATION_PENDING;

  if (organizationOwnershipStatus !== OrganizationOwnershipStatus.CLAIMED)
    return undefined;

  if (!provisioning) {
    return ConnectivityStatusType.VERIFIED;
  }

  const provisioningMap: Record<ProvisioningStatus, ConnectivityStatusType> = {
    [ProvisioningStatus.IN_PROGRESS]: ConnectivityStatusType.CONNECTION_PENDING,
    [ProvisioningStatus.COMPLETED]: ConnectivityStatusType.CONNECTED,
    [ProvisioningStatus.FAILED]: ConnectivityStatusType.CONNECTION_PENDING,
  };

  return provisioningMap[provisioning];
};

export const mapVehicleDetailsRecalls = (
  recall: AssetRecall[],
  locale: string
): AssetRecall[] => {
  const formatDateToUTC = (dateString: string) => {
    const dateTime = DateTime.fromISO(dateString, { zone: 'utc' });
    return dateTime.toFormat(
      LOCALE_FORMAT_DATE[locale as keyof typeof LOCALE_FORMAT_DATE]
    );
  };

  const recalls = recall.map((recall: AssetRecall) => {
    return {
      ...recall,
      // Reduce all caps to title case
      title: recall.title.split(' ').map(capitalize).join(' '),
      releaseDate: formatDateToUTC(recall.releaseDate),
    };
  });

  // Sort the dates by Year (YYYY), then Month (MM), then Day (dd)
  const sortedByDates = recalls.sort((a: AssetRecall, b: AssetRecall) => {
    const dateA = DateTime.fromFormat(
      a.releaseDate,
      LOCALE_FORMAT_DATE[locale as keyof typeof LOCALE_FORMAT_DATE]
    );
    const dateB = DateTime.fromFormat(
      b.releaseDate,
      LOCALE_FORMAT_DATE[locale as keyof typeof LOCALE_FORMAT_DATE]
    );
    return dateB.valueOf() - dateA.valueOf();
  });

  return sortedByDates;
};

export const mapAssetRemoteCommandRequestType = (
  command: RemoteCommand
): AssetRemoteCommandRequestType => {
  switch (command) {
    case RemoteCommand.ALERT_CANCEL:
      return AssetRemoteCommandRequestType.EXECUTE_CANCEL_ALERT;
    case RemoteCommand.ALERT_START:
      return AssetRemoteCommandRequestType.EXECUTE_START_ALERT;
    case RemoteCommand.ENGINE_START:
      return AssetRemoteCommandRequestType.EXECUTE_START_ENGINE;
    case RemoteCommand.ENGINE_CANCEL:
      return AssetRemoteCommandRequestType.EXECUTE_CANCEL_ENGINE;
    case RemoteCommand.DOOR_LOCK:
      return AssetRemoteCommandRequestType.EXECUTE_DOOR_LOCK;
    case RemoteCommand.DOOR_UNLOCK:
      return AssetRemoteCommandRequestType.EXECUTE_DOOR_UNLOCK;
    case RemoteCommand.IGNITION_ENABLE:
      return AssetRemoteCommandRequestType.EXECUTE_ENABLE_IGNITION;
    case RemoteCommand.IGNITION_DISABLE:
      return AssetRemoteCommandRequestType.EXECUTE_DISABLE_IGNITION;
  }
};

export const mapAssetRemoteCommandExecuteStatus = (
  status: AssetRemoteCommandExecuteStatus
): BDRequestStatus => {
  switch (status) {
    case 'SUCCESS':
      return BDRequestStatus.SUCCEEDED;
    case 'FAILURE':
      return BDRequestStatus.FAILED;
    default:
      //Current default until additional testing is completed
      return BDRequestStatus.FAILED;
  }
};

export const mapAvailableRemoteCommands = (
  commands?: string[]
): RemoteCommand[] => {
  const commandMap: Record<string, RemoteCommand> = {
    ALERT: RemoteCommand.ALERT_START,
    CANCEL_ALERT: RemoteCommand.ALERT_CANCEL,
    START: RemoteCommand.ENGINE_START,
    CANCEL_START: RemoteCommand.ENGINE_CANCEL,
    ENABLE_IGNITION: RemoteCommand.IGNITION_ENABLE,
    DISABLE_IGNITION: RemoteCommand.IGNITION_DISABLE,
    LOCK_DOOR: RemoteCommand.DOOR_LOCK,
    UNLOCK_DOOR: RemoteCommand.DOOR_UNLOCK,
  };
  return (
    commands
      ?.map((command) => commandMap[command.toUpperCase()])
      ?.filter((cmd): cmd is RemoteCommand => cmd !== undefined) ?? []
  );
};

import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { BDError } from '~/common/models/error.model';
import { TripEvent } from '~/common/models/trip.model';

import { TRIP_HISTORY_ACTIONS } from './tripHistorySlice.actions';
import { TripHistoryState } from './tripHistorySlice.model';

export const TRIP_HISTORY_REDUCERS = {
  selectTrip: (state: TripHistoryState, action: PayloadAction<string>) => {
    state.selectedTripId = action.payload;
  },
  selectEvent: (
    state: TripHistoryState,
    action: PayloadAction<string | null>
  ) => {
    state.selectedEventId = action.payload;
  },
};

export const buildFetchTokenReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchToken.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchToken.fulfilled, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchToken.rejected, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.FAILED,
        errors: [action.payload as BDError],
      };
    });
};

export const buildFetchTripsReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchTrips.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchTrips.fulfilled, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
      state.trips = action.payload;
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchTrips.rejected, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.FAILED,
        errors: [action.payload as BDError],
      };
    });
};

export const buildFetchEventsReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchEvents.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchEvents.fulfilled, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
      state.trips = state.trips.map((trip) => {
        const events: TripEvent[] = action.payload.filter(
          (event) => event.vin === trip.vin
        );
        return {
          ...trip,
          events,
          safetyEventCount: events.length,
        };
      });
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchEvents.rejected, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.FAILED,
        errors: [action.payload as BDError],
      };
    });
};

export const buildFetchVideoRequestIdReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoRequestId.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoRequestId.fulfilled, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
    })
    .addCase(
      TRIP_HISTORY_ACTIONS.fetchVideoRequestId.rejected,
      (state, action) => {
        state.operationStatus = {
          status: BDRequestStatus.FAILED,
          errors: [action.payload as BDError],
        };
      }
    );
};

export const buildFetchVideoRequestStatusReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoRequestStatus.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(
      TRIP_HISTORY_ACTIONS.fetchVideoRequestStatus.fulfilled,
      (state) => {
        state.operationStatus = {
          status: BDRequestStatus.SUCCEEDED,
          errors: [],
        };
      }
    )
    .addCase(
      TRIP_HISTORY_ACTIONS.fetchVideoRequestStatus.rejected,
      (state, action) => {
        state.operationStatus = {
          status: BDRequestStatus.FAILED,
          errors: [action.payload as BDError],
        };
      }
    );
};

export const buildFetchVideoUrlReducer = (
  builder: ActionReducerMapBuilder<TripHistoryState>
) => {
  builder
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoUrl.pending, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoUrl.fulfilled, (state) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
    })
    .addCase(TRIP_HISTORY_ACTIONS.fetchVideoUrl.rejected, (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.FAILED,
        errors: [action.payload as BDError],
      };
    });
};

export const TRIP_HISTORY_EXTRA_REDUCER_BUILDERS = {
  buildFetchTripsReducer,
  buildFetchEventsReducer,
  buildFetchTokenReducer,
  buildFetchVideoRequestIdReducer,
  buildFetchVideoRequestStatusReducer,
  buildFetchVideoUrlReducer,
};

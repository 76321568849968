import { throttle } from 'lodash';

import {
  InactivityEvents,
  InactivityStorageKeys,
} from '~/features/inactivityTracker/InactivityTracker.constants';

export class InactivityTracker {
  private _timer: number;
  private _timerId: number | undefined;
  private _eventHandler: () => void;
  private _throttledEventHandler: () => void;
  private _trackedEvents: string[] = [
    'load',
    'mousemove',
    'mousedown',
    'scroll',
    'keydown',
    'click',
  ];
  constructor(timeout: number) {
    this._timer = timeout;
    //Event handlers need to be bound, in order to properly set up and remove event listeners
    this._eventHandler = this.syncTimer.bind(this);
    this._eventHandler = this._eventHandler.bind(this);
    this._throttledEventHandler = throttle(this._eventHandler, 1000);

    this.tracker();
  }

  /**
   * reset Timer function
   */
  resetTimer(): void {
    clearTimeout(this._timerId);
    this._timerId = window.setTimeout(this.dispatchUserInactive, this._timer);
  }

  /**
   * Sync timer function
   */
  syncTimer(): void {
    this.emitActiveUser();
    this.resetTimer();
  }

  /**
   * Dispatch storage event
   */
  emitActiveUser(): void {
    localStorage.setItem(InactivityStorageKeys.userActive, 'true');
    localStorage.removeItem(InactivityStorageKeys.userActive);
  }

  /**
   * dispatch event when the timeout has been reached
   */
  dispatchUserInactive(): void {
    window.dispatchEvent(new CustomEvent(InactivityEvents.inactiveUser));
  }

  /**
   * Sets up events to track in order to measure inactivity
   */
  tracker(): void {
    for (const event of this._trackedEvents) {
      window.addEventListener(event, this._throttledEventHandler);
    }
  }

  /**
   * remove event listeners so that app no longer tracks inactivity
   */
  removeTrackers(): void {
    for (const event of this._trackedEvents) {
      window.removeEventListener(event, this._throttledEventHandler);
    }
  }
}

import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  Asset,
  AssetComplianceDetail,
  AssetEvent,
} from '~/common/models/asset.model';
import {
  AdvisorVehicleListItem,
  AssetReportItem,
  PendingVehicleItem,
} from '~/common/models/asset-report.model';
import { AssetRawLocation } from '~/common/models/common.model';
import { isBefore } from '~/common/utils/date-time.utils';

export const assetsAdapter = createEntityAdapter<Asset>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (asset: Asset) => asset.id,
});

export const assetEventsAdapter = createEntityAdapter<AssetEvent>({
  selectId: (assetEvent: AssetEvent) => assetEvent.id,
  sortComparer: (a, b) => {
    if (a.timestamp === b.timestamp) {
      return a.id.localeCompare(b.id);
    }
    return isBefore(new Date(a.timestamp), new Date(b.timestamp)) ? 1 : -1;
  },
});

export const assetReportItemsAdapter = createEntityAdapter<AssetReportItem>({
  selectId: (reportItem: AssetReportItem) => reportItem.id,
});

export const assetVehicleNotificationAdapter =
  createEntityAdapter<AssetReportItem>({
    selectId: (reportItem: AssetReportItem) => reportItem.id,
  });

export const assetPeripheralVehicleAdapter =
  createEntityAdapter<AssetReportItem>({
    selectId: (reportItem: AssetReportItem) => reportItem.id,
  });

export const assetLocationsAdapter = createEntityAdapter<AssetRawLocation>({
  selectId: (assetLocation: AssetRawLocation) =>
    assetLocation.timestamp as string,
});

export const assetSearchAdapter = createEntityAdapter<Asset>({
  selectId: (asset: Asset) => asset.id,
});

export const pendingVehicleAdapter = createEntityAdapter<PendingVehicleItem>({
  selectId: (pendingVehicle: PendingVehicleItem) => pendingVehicle.sessionId,
});

export const assetComplianceAdapter =
  createEntityAdapter<AssetComplianceDetail>({
    selectId: (assetCompliance: AssetComplianceDetail) => assetCompliance.id,
  });

export const advisorVehicleListAdapter =
  createEntityAdapter<AdvisorVehicleListItem>({
    selectId: (vehicleItem: AdvisorVehicleListItem) => vehicleItem.id,
  });

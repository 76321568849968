import { NavBarConfig } from '@gm-commercial/navbar-model';

import {
  SETTINGS_NOTIFICATIONS_ACCOUNT,
  SETTINGS_NOTIFICATIONS_USERS,
  SETTINGS_NOTIFICATIONS_VEHICLES,
} from '~/common/models/pages/settingsPages.model';
import { RouteName } from '~/common/models/route.model';
import { SettingsNavBarConfigParams } from '~/common/models/settingsNavigation.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import NotificationIcon from '../../../assets/icons/new/notification.svg?react';

//TODO: Make this dynamic once rules are defined for S&N
export const getSettingsNavBarConfig = ({
  messages,
  profileParams,
  findFlagScope,
}: SettingsNavBarConfigParams): NavBarConfig => {
  const context = {
    fleetsId: profileParams?.fleetsId || undefined,
    hubsId: profileParams?.hubsId || undefined,
    organizationsId: profileParams?.organizationsId || undefined,
  };

  return {
    title: messages['settings:settings'],
    homePath: '/',
    items: [
      {
        label: messages['settings:notifications.title'],
        icon: <NotificationIcon className="noFill" />,
        testId: 'notifications',
        path: SETTINGS_NOTIFICATIONS_VEHICLES,
        children: [
          {
            label: messages['common:vehicles'],
            testId: 'vehicles',
            path: SETTINGS_NOTIFICATIONS_VEHICLES,
          },
          {
            label: messages['common:users'],
            testId: 'users',
            path: SETTINGS_NOTIFICATIONS_USERS,
          },
          {
            label: messages['common:account'],
            testId: 'account',
            path: SETTINGS_NOTIFICATIONS_ACCOUNT,
          },
          {
            label: messages['common:safetyAndSecurity'],
            testId: 'safetyAndSecurity',
            path: makeRoutePath(
              findRoute(
                RouteName.CS_PLATFORM_SAFETY_AND_SECURITY_NOTIFICATIONS_SETTINGS
              ),
              context,
              true,
              true,
              findFlagScope
            ),
          },
        ],
      },
    ],
  };
};

import { emptyApi as api } from './emptyApi';
export const addTagTypes = [
  'subscriptions-controller',
  'alerts-controller',
  'registrations-controller',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      updateSubscriptionByIdVersion2: build.mutation<
        UpdateSubscriptionByIdVersion2ApiResponse,
        UpdateSubscriptionByIdVersion2ApiArg
      >({
        query: (queryArg) => ({
          url: `/2022-07-01/subscriptions/me/${queryArg.subscriptionId}`,
          method: 'PUT',
          body: queryArg.subscriptionUpdateDto,
        }),
        invalidatesTags: ['subscriptions-controller'],
      }),
      updateAllAlertsToReadAsync: build.mutation<
        UpdateAllAlertsToReadAsyncApiResponse,
        UpdateAllAlertsToReadAsyncApiArg
      >({
        query: () => ({ url: `/2025-03-11/me/read`, method: 'POST' }),
        invalidatesTags: ['alerts-controller'],
      }),
      addSubscriptionsToUser: build.mutation<
        AddSubscriptionsToUserApiResponse,
        AddSubscriptionsToUserApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/subscriptions`,
          method: 'POST',
          body: queryArg.subscriptionDto,
        }),
        invalidatesTags: ['subscriptions-controller'],
      }),
      getAllUserSubscriptions: build.query<
        GetAllUserSubscriptionsApiResponse,
        GetAllUserSubscriptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/subscriptions/me`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        providesTags: ['subscriptions-controller'],
      }),
      getRegistrationsForUser: build.query<
        GetRegistrationsForUserApiResponse,
        GetRegistrationsForUserApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/registrations`,
          method: 'POST',
          body: queryArg.bdPageRequest,
        }),
        providesTags: ['registrations-controller'],
      }),
      updateAlertToUnRead: build.mutation<
        UpdateAlertToUnReadApiResponse,
        UpdateAlertToUnReadApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/me/${queryArg.id}/unread`,
          method: 'POST',
        }),
        invalidatesTags: ['alerts-controller'],
      }),
      updateAlertToRead: build.mutation<
        UpdateAlertToReadApiResponse,
        UpdateAlertToReadApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/me/${queryArg.id}/read`,
          method: 'POST',
        }),
        invalidatesTags: ['alerts-controller'],
      }),
      updateAllAlertsToRead: build.mutation<
        UpdateAllAlertsToReadApiResponse,
        UpdateAllAlertsToReadApiArg
      >({
        query: () => ({ url: `/2021-11-15/me/read`, method: 'POST' }),
        invalidatesTags: ['alerts-controller'],
      }),
      getAlertsForCurrentUser: build.query<
        GetAlertsForCurrentUserApiResponse,
        GetAlertsForCurrentUserApiArg
      >({
        query: (queryArg) => ({
          url: `/2025-01-28/me`,
          headers: {
            'cs-continuation-token': queryArg['cs-continuation-token'],
          },
          params: { size: queryArg.size },
        }),
        providesTags: ['alerts-controller'],
      }),
      getAlertById: build.query<GetAlertByIdApiResponse, GetAlertByIdApiArg>({
        query: (queryArg) => ({ url: `/2021-11-15/me/${queryArg.id}` }),
        providesTags: ['alerts-controller'],
      }),
      deleteSubscription: build.mutation<
        DeleteSubscriptionApiResponse,
        DeleteSubscriptionApiArg
      >({
        query: (queryArg) => ({
          url: `/2021-11-15/subscriptions/me/${queryArg.subscriptionId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['subscriptions-controller'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as alertsApi };
export type UpdateSubscriptionByIdVersion2ApiResponse =
  /** status 200 OK */ GmResponseCommonSubscriptionDto;
export type UpdateSubscriptionByIdVersion2ApiArg = {
  /** Unique identifying subscription ID. */
  subscriptionId: string;
  subscriptionUpdateDto: SubscriptionUpdateDto;
};
export type UpdateAllAlertsToReadAsyncApiResponse =
  /** status 200 Successfully marked all alerts as read. */ GmResponseCommonString;
export type UpdateAllAlertsToReadAsyncApiArg = void;
export type AddSubscriptionsToUserApiResponse =
  /** status 200 OK */ GmResponseCommonSubscriptionDto;
export type AddSubscriptionsToUserApiArg = {
  subscriptionDto: SubscriptionDto;
};
export type GetAllUserSubscriptionsApiResponse =
  /** status 200 OK */ GmResponseCommonBdSubscriptionPage;
export type GetAllUserSubscriptionsApiArg = {
  bdPageRequest: BdPageRequest;
};
export type GetRegistrationsForUserApiResponse =
  /** status 200 OK */ GmResponseCommonBdRegistrationPage;
export type GetRegistrationsForUserApiArg = {
  bdPageRequest: BdPageRequest;
};
export type UpdateAlertToUnReadApiResponse =
  /** status 200 OK */ GmResponseCommonAlert;
export type UpdateAlertToUnReadApiArg = {
  /** Id of alert to update */
  id: string;
};
export type UpdateAlertToReadApiResponse =
  /** status 200 OK */ GmResponseCommonAlert;
export type UpdateAlertToReadApiArg = {
  /** Id of alert to update */
  id: string;
};
export type UpdateAllAlertsToReadApiResponse =
  /** status 200 Successfully marked all alerts as read. */ GmResponseCommon;
export type UpdateAllAlertsToReadApiArg = void;
export type GetAlertsForCurrentUserApiResponse =
  /** status 200 OK */ GmResponseCommonAlertsPaginatedResponse;
export type GetAlertsForCurrentUserApiArg = {
  /** Number of alerts to return */
  size?: number;
  /** Continuation token to be used to navigate to the next page */
  'cs-continuation-token'?: string;
};
export type GetAlertByIdApiResponse =
  /** status 200 OK */ GmResponseCommonAlert;
export type GetAlertByIdApiArg = {
  /** Unique generated identifying ID. */
  id: string;
};
export type DeleteSubscriptionApiResponse =
  /** status 200 Successfully deleted subscription. */ GmResponseCommon;
export type DeleteSubscriptionApiArg = {
  /** A unique identifying subscription ID. */
  subscriptionId: string;
};
export type RegistrationItem = {
  category: string;
  event: string;
};
export type SubscriptionDto = {
  /** Unique identifying subscription ID. */
  id?: string;
  organizationId: string;
  /** List of registrations for this subscription */
  registrations: RegistrationItem[];
  /** Whether subscription is active. */
  active: boolean;
  /** Threshold For Noisy Messages In Minutes */
  noiseThresholdInMinutes?: number;
  profileId?: string;
  /** The notification receipt preferences */
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  /** The notification preferred name */
  preferredName?: string;
  /** List of assetIds for this subscription */
  assets?: string[];
};
export type GmResponseError = {
  errorType: 'GENERIC' | 'VALIDATION';
  message: string;
  errorCode: string;
};
export type GmResponseCommonSubscriptionDto = {
  result?: SubscriptionDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type GmResponseCommon = {
  result?: object;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type SubscriptionUpdateDto = {
  /** Whether subscription is active. */
  active: boolean;
  /** Threshold For Noisy Messages In Minutes */
  noiseThresholdInMinutes?: number;
  /** The notification receipt preferences */
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  /** The notification preferred name */
  preferredName?: string;
  /** List of registrations for this subscription */
  registrations?: RegistrationItem[];
  /** List of assetIds for this subscription */
  assets?: string[];
};
export type GmResponseCommonString = {
  result?: string;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type Subscription = {
  id?: string;
  organizationId?: string;
  profileId?: string;
  registrations: RegistrationItem[];
  active: boolean;
  noiseThresholdInMinutes?: number;
  createDateTime?: string;
  updateDateTime?: string;
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  preferredName?: string;
  assets?: string[];
};
export type SortObject = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  pageNumber?: number;
  pageSize?: number;
  unpaged?: boolean;
};
export type PageSubscription = {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: Subscription[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  first?: boolean;
  numberOfElements?: number;
  last?: boolean;
  empty?: boolean;
};
export type BdSubscriptionPage = {
  page?: PageSubscription;
  items: SubscriptionDto[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonBdSubscriptionPage = {
  result?: BdSubscriptionPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type BdQuery = {
  qualifier:
    | 'ALL'
    | 'IS_EQUAL'
    | 'OR'
    | 'AND'
    | 'NOT'
    | 'BEFORE'
    | 'AFTER'
    | 'IN'
    | 'NOT_IN'
    | 'IS_NULL'
    | 'IS_NOT_NULL'
    | 'LESS_THAN'
    | 'LESS_THAN_EQUAL'
    | 'GREATER_THAN'
    | 'GREATER_THAN_EQUAL'
    | 'CONTAINING'
    | 'ENDS_WITH'
    | 'STARTS_WITH'
    | 'TRUE'
    | 'FALSE'
    | 'BETWEEN'
    | 'ARRAY_CONTAINS'
    | 'STRING_EQUALS'
    | 'NOT_CONTAINING';
  field: string;
  values: object[];
  leftQuery?: BdQuery;
  rightQuery?: BdQuery;
};
export type BdPageRequest = {
  /** Page index. Pages are 0-index based. */
  page: number;
  /** Number of elements per page. */
  size: number;
  /** Direct has been deprecated in favor of query. */
  direct?: boolean;
  /** Sort parameters. */
  sort?: SortObject[];
  token?: string;
  /**
                Query details for requested information. See
                https://dev.azure.com/BrightDrop/BrightDrop/_wiki/wikis/BrightDrop.wiki/1374/Search-Filter-API-for-CosmosDB
                for more details.
                 */
  query?: BdQuery;
};
export type EmailSettings = {
  templates?: {
    [key: string]: string;
  };
};
export type ContentSettings = {
  email?: EmailSettings;
};
export type Registration = {
  id?: string;
  applicationId?: string;
  sourceType: string;
  category: string;
  event: string;
  createDateTime?: string;
  timestamp?: string;
  contentSettings?: ContentSettings;
};
export type PageRegistration = {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: Registration[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  first?: boolean;
  numberOfElements?: number;
  last?: boolean;
  empty?: boolean;
};
export type RegistrationDto = {
  id?: string;
  applicationId?: string;
  /** Source type for the registration. */
  sourceType: string;
  /** Category type for the registration. */
  category: string;
  /** Event type for the registration. */
  event: string;
  contentSettings?: ContentSettings;
};
export type BdRegistrationPage = {
  page?: PageRegistration;
  items: RegistrationDto[];
  total_items?: number;
  token?: string;
};
export type GmResponseCommonBdRegistrationPage = {
  result?: BdRegistrationPage;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type Event = {
  sourceId: string;
  sourceType: string;
  applicationId: string;
  category: string;
  event: string;
  eventTimestamp: string;
  contentVariableMap?: {
    [key: string]: object;
  };
  returnUrl?: string;
};
export type Alert = {
  id?: string;
  profileId: string;
  subscriptionId: string;
  event: Event;
  organizationId: string;
  createDateTime: string;
  receiptPreferences?: ('EMAIL' | 'FEED')[];
  read: boolean;
  sourceType: string;
  sourceId: string;
  eventTimestamp: string;
  eventName: string;
};
export type GmResponseCommonAlert = {
  result?: Alert;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export type AlertDto = {
  /** Unique generated identifying ID. */
  id?: string;
  profileId: string;
  /** ID of base subscription for alert. */
  subscriptionId: string;
  /**
                Primary identifier to denote the action that raised the alert.
                See https://dev.azure.com/BrightDrop/BrightDrop/_wiki/wikis/BrightDrop.wiki/1260/0012a-Alerts
                for information about well know events.
                 */
  event: Event;
  organizationId: string;
  createDateTime: string;
  /** If the alert is read. */
  read: boolean;
  /** The notification receipt preferences */
  receiptPreferences?: ('EMAIL' | 'FEED')[];
};
export type AlertsPaginatedResponse = {
  /** The list of alerts in the current page */
  items: AlertDto[];
  /** The total number of alerts for the current user, not just the current page */
  total_items?: number;
  /** The continuation token to be used to navigate to the next page */
  continuation_token?: string;
};
export type GmResponseCommonAlertsPaginatedResponse = {
  result?: AlertsPaginatedResponse;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
};
export const {
  useUpdateSubscriptionByIdVersion2Mutation,
  useUpdateAllAlertsToReadAsyncMutation,
  useAddSubscriptionsToUserMutation,
  useGetAllUserSubscriptionsQuery,
  useGetRegistrationsForUserQuery,
  useUpdateAlertToUnReadMutation,
  useUpdateAlertToReadMutation,
  useUpdateAllAlertsToReadMutation,
  useGetAlertsForCurrentUserQuery,
  useGetAlertByIdQuery,
  useDeleteSubscriptionMutation,
} = injectedRtkApi;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from '~/app/rootReducer';

const defaultUrlForTesting = `http://localhost:3000`;

export const emptyApi = createApi({
  refetchOnMountOrArgChange: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: `${
      globalThis.appConfig.apiBaseUrl || defaultUrlForTesting
    }/assets`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.authToken?.value;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('accept', 'application/json, text/plain, */*');
      headers.set('content-type', 'application/json');

      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'assetsApi',
});

import { useTranslations } from '@brightdrop/localization-client';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '~/app/store';
import { FLEET_NOTIFICATIONS } from '~/common/models/pages/fleetPages.model';
import { setPopUp } from '~/features/alerts/alertsSlice';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

import { useStyles } from './SeeAllButton.styles';

const SeeAllButton = () => {
  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const { classes } = useStyles();

  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <Box className={classes['rt--notifications--cmp--buttons--see-all-box']}>
      <Button
        className={classes['rt--notifications--cmp--buttons--see-all-button']}
        onClick={() => {
          dispatch(setPopUp(false));
          history.push(FLEET_NOTIFICATIONS);
        }}
      >
        {translations['settings:notifications.seeAll']}
      </Button>
    </Box>
  );
};

export default SeeAllButton;

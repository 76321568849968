import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_ROW_PER_PAGE,
  DEFAULT_SESSION_CONFIG_KEY,
} from '~/common/constants/common.constant';
import {
  AddVehiclesSessionViewType,
  AddVehiclesState,
} from '~/common/models/addVehicles.model';
import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';

import {
  addVehiclesAdapter,
  incompleteVehiclesAdapter,
  pendingConnectionVehiclesAdapter,
  pendingVerificationVehiclesAdapter,
} from './addVehiclesSlice.adapters';

export const SELECT_ALL_MAX_COUNT = 50;
export const UNIQUE_TABLE_ID = 'UNIQUE_TABLE_ID';

export const INITIAL_VEHICLES_PENDING_VERIFICATION_LIST_STATE = {
  vehiclesPendingVerification:
    pendingVerificationVehiclesAdapter.getInitialState(),
  continuationToken: null,
  operationStatus: { status: BDRequestStatus.IDLE },
  count: 0,
  page: DEFAULT_PAGINATION_PAGE,
  rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
};

export const INITIAL_VEHICLES_PENDING_CONNECTION_LIST_STATE = {
  vehiclesPendingConnection: pendingConnectionVehiclesAdapter.getInitialState(),
  continuationToken: null,
  operationStatus: { status: BDRequestStatus.IDLE },
  count: 0,
  page: DEFAULT_PAGINATION_PAGE,
  rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
};

export const INITIAL_VEHICLES_INCOMPLETE_LIST_STATE = {
  incompleteVehicles: incompleteVehiclesAdapter.getInitialState(),
  continuationToken: null,
  operationStatus: { status: BDRequestStatus.IDLE },
  count: 0,
  page: DEFAULT_PAGINATION_PAGE,
  rowsPerPage: DEFAULT_PAGINATION_ROW_PER_PAGE,
};

export const INITIAL_ADD_VEHICLES_STATE =
  addVehiclesAdapter.getInitialState<AddVehiclesState>({
    sessionConfigs: {
      [AddVehiclesSessionViewType.VEHICLES_ADD]: {
        // Inital Add Vehicles
        [DEFAULT_SESSION_CONFIG_KEY]: {
          recentBatches: [],
          operationStatus: {
            status: BDRequestStatus.IDLE,
          },
        },
      },
      [AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST]: {
        // Initial Vehicles Pending Verification
        [DEFAULT_SESSION_CONFIG_KEY]:
          INITIAL_VEHICLES_PENDING_VERIFICATION_LIST_STATE,
      },
      [AddVehiclesSessionViewType.VEHICLES_PENDING_CONNECTION_LIST]: {
        // Initial Vehicles Pending Connection
        [DEFAULT_SESSION_CONFIG_KEY]:
          INITIAL_VEHICLES_PENDING_CONNECTION_LIST_STATE,
      },
      [AddVehiclesSessionViewType.VEHICLES_INCOMPLETE_LIST]: {
        // Initial Incomplete
        [DEFAULT_SESSION_CONFIG_KEY]: INITIAL_VEHICLES_INCOMPLETE_LIST_STATE,
      },
    },
    activeList: AddVehiclesSessionViewType.VEHICLES_PENDING_VERIFICATION_LIST, // Initialize to pending verification
    isAddVehiclesModalOpen: false,
  });

import MUIButton from '@mui/material/Button';
import { Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import styled from 'styled-components';

import LoadingIcon from '../../assets/loading.svg?react';
import { ButtonProps } from './Button.model';
import { generateButtonCss } from './Button.styles';

/* Component function to generate a MUI Button with custom styling. */
const StyledButton = styled(MUIButton).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'destructive',
      'isLoading',
      'iconColorRule',
      'noPadding',
      'customIconColors',
    ].includes(prop),
})<ButtonProps>`
  ${({ theme, ...props }) => generateButtonCss(theme, props)}
`;

/* BD Design System Button Component */
const Button: React.FC<ButtonProps> = ({
  children,
  size = 'large',
  variant = 'primary',
  customIconColors = false,
  iconColorRule = 'fill',
  removePadding = false,
  isLoading = false,
  ...props
}) => {
  const theme = useTheme<Theme>();

  /* Remove variant from props as MUI doesn't know what our variants are. */
  const convertedProps = { ...props, variant: undefined };

  /* Helper function to clean up style class name generation */
  const generateButtonClassName = (): string => {
    const variantClass = variant;
    const sizeClass = size;
    const customIconColorsClass = customIconColors ? 'custom-icon-colors' : '';
    const startIconClass = props.startIcon ? 'icon-left' : '';
    const endIconClass = props.endIcon ? 'icon-right' : '';
    const iconColorRuleClass =
      startIconClass || endIconClass ? `${iconColorRule}-icon` : '';
    const loadingClass = isLoading ? 'loading' : '';

    //TODO: Remove this once we have a design component for link buttons
    const noPaddingClass = removePadding ? 'no-padding' : '';

    return [
      props.className ?? '',
      variantClass,
      sizeClass,
      customIconColorsClass,
      iconColorRuleClass,
      startIconClass,
      endIconClass,
      loadingClass,
      noPaddingClass,
    ]
      .join(' ')
      .trim();
  };

  return (
    <StyledButton
      {...convertedProps}
      disableRipple
      className={generateButtonClassName()}
      theme={theme}
      disabled={props.disabled || isLoading}
      endIcon={isLoading ? undefined : props.endIcon}
      startIcon={isLoading ? undefined : props.startIcon}
    >
      {isLoading ? <LoadingIcon aria-label={'loading'} /> : children}
    </StyledButton>
  );
};

export default Button;

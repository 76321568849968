import { User } from '@gm-commercial/profile-model';
import { UserTabType } from '@gm-commercial/profile-model/src/models/user.model';
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import { PURGE } from 'redux-persist';

import { RootState } from '~/app/rootReducer';
import {
  API_VERSION_DEFAULTS,
  CS_CONTINUATION_TOKEN_HEADER_NAME,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import {
  ApiResponse,
  BDRequestStatus,
  BDRequestType,
  OperationStatus,
} from '~/common/models/apis/apiResponse.model';
import {
  ContinuationCache,
  FormPayload,
  ListViewSession,
  NonNullablePick,
  PagedResult,
  PagedResultWithErrors,
  PaginationContinuationInfo,
  PaginationInfo,
  SearchCriteria,
  SeverityLevel,
} from '~/common/models/common.model';
import { BDAppErrorType, BDError } from '~/common/models/error.model';
import {
  InviteUsersRequestPayload,
  InviteUsersResponsePayload,
} from '~/common/models/inviteFleetUserForm.model';
import {
  UserFormFields,
  UserListFilterCriteria,
  UserListFilterCriteriaKeys,
  UserSearchFilterInfo,
} from '~/common/models/user.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  addHubIdHeader,
  addOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeDeletePayloadCreator,
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makePutPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';
import { buildUserListQueryParams } from '~/features/users/utils/userList.utils';

import {
  mapApiResponseToUser,
  mapUserFormToApiRequest,
  mapUserRoleAndInvite,
} from './list/mappers/users.mappers';

export interface UserListSession
  extends Record<keyof User, string>,
    ListViewSession {
  operationStatus: OperationStatus;
  activeUserTab?: UserTabType;
  continuationCache?: ContinuationCache<User[]>;
  filterCriteria?: UserListFilterCriteria;
  searchCriteria?: SearchCriteria;
}

enum UsersActionType {
  GET_USERS = 'users/fetchUsers',
  GET_USER_LIST = 'users/fetchUserList',
}

// If views are mutually exclusive (e.g. Account User List View and Advisor User List View)
// only 1 configuration is needed for both views
export enum UserSessionViewType {
  USER_LIST_VIEW = 'User List View',
  ACCOUNT_DETAIL_VIEW = 'Account Detail View',
  ADVISOR_DETAIL_VIEW = 'Advisor Detail View',
}

/**
 * UserSessionConfigType represent an object where session is scoped by the view and entity id
 *
 * @interface UserSessionConfigType
 * @property {UserSessionViewType} key The view that we are storing sessions.
 * @property {string} id The id of the entity you are viewing.
 */

export interface UserSessionConfigType {
  [UserSessionViewType.USER_LIST_VIEW]?: {
    [id: string]: Partial<UserListSession> | undefined;
  };
  [UserSessionViewType.ACCOUNT_DETAIL_VIEW]?: {
    [id: string]: Partial<UserListSession> | undefined;
  };
  [UserSessionViewType.ADVISOR_DETAIL_VIEW]?: {
    [id: string]: Partial<UserListSession> | undefined;
  };
}

export type UserRouteParams = Pick<
  RouteParams,
  'usersId' | 'organizationsId' | 'hubsId' | 'fleetsId'
>;

export type UserParams = UserRouteParams & { sessionId?: string };
export type UserListParams = UserRouteParams &
  PaginationContinuationInfo &
  UserSearchFilterInfo &
  PaginationInfo & { sessionId?: string };

export type AddUserFormPayload = FormPayload<
  NonNullablePick<UserFormFields, 'email' | 'role' | 'firstName' | 'lastName'> &
    Pick<UserFormFields, 'hub' | 'fleet' | 'phoneNumber'> & {
      status: null;
    },
  'organizationsId'
>;
export type UpdateUserFormPayload = FormPayload<
  NonNullablePick<
    UserFormFields,
    'role' | 'status' | 'firstName' | 'lastName'
  > &
    Pick<
      UserFormFields,
      'hub' | 'fleet' | 'phoneNumber' | 'locale' | 'timeZone'
    > & {
      email: null;
    },
  'usersId',
  'organizationsId'
>;

export type ActivateUserPayload = {
  formFields: {
    urls: { termsAndConditions: string; privacyPolicy: string };
    accepted: boolean;
  };
};

export type ValidateSsoEmailPayload = {
  email: string;
};

export type InviteUserPayload = {
  requestPayload: InviteUsersRequestPayload;
  hubId?: string;
};

export enum UserRequestType {
  SUBMIT_TERMS_AND_CONDITIONS = 'Submit User terms and conditions',
}

const usersAdapter = createEntityAdapter<User>({
  // redundant, but makes obvious the key used for fetching records
  selectId: (user: User) => user.id,
});

//TODO: remove userListItems from UsersState, once one version of list of users is chosen
const userListAdapter = createEntityAdapter<User>({
  selectId: (user: User) => user.id,
});

// Union type of both key types
interface UsersState {
  selectedIndices: number[];
  selectedReverseMap: { [key: string]: number };
  operations: {
    [key in BDRequestType | UserRequestType]?: OperationStatus;
  };
  sessionConfigs: {
    [k in UserSessionViewType]?: UserSessionConfigType[k];
  };
  userListItems: EntityState<User>;
}

const initialState = usersAdapter.getInitialState<UsersState>({
  selectedIndices: [],
  selectedReverseMap: {},
  operations: {},
  sessionConfigs: {},
  userListItems: userListAdapter.getInitialState(),
});

const CONFIG = DEFAULT_API_CONFIG;

export const getUsers = makeThunk(
  UsersActionType.GET_USERS,
  makeGetPayloadCreator<ApiResponse<User[]>, void | UserParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (params) => {
      if (params) {
        return {
          requestParams: {
            organizationId: params.organizationsId || '',
            hubId: params.hubsId || '',
            fleetId: params.fleetsId || '',
          },
        };
      }
      return {};
    },
    responseAdapter: (rawResponse: unknown) => {
      if (
        rawResponse &&
        typeof rawResponse === 'object' &&
        Object.prototype.hasOwnProperty.call(rawResponse, '__bd')
      ) {
        const apiResponse = rawResponse as ApiResponse<unknown>;
        return {
          ...apiResponse,
          result:
            apiResponse && Array.isArray(apiResponse.result)
              ? apiResponse.result
                  .filter((data) => data && typeof data === 'object')
                  .map((data) => {
                    return mapApiResponseToUser(data);
                  })
              : [],
        };
      }
      throw new BDError('Unexpected user list response', { data: rawResponse });
    },
  })
);

// TODO: consolidate getUsers and getUserList once all consuming components use continuation token
export const getUserList = makeThunk(
  UsersActionType.GET_USER_LIST,
  makeGetPayloadCreator<
    ApiResponse<PagedResultWithErrors<User>>,
    UserListParams
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.userListAPIv2}`,
    axiosOptions: ({ continuationToken }, state) => {
      const commonHeaders = addAcceptLanguageHeader(
        CONFIG,
        state.profile.currentLocale
      );
      return continuationToken
        ? addHeader(
            commonHeaders,
            CS_CONTINUATION_TOKEN_HEADER_NAME,
            continuationToken
          )
        : commonHeaders;
    },

    argAdapter: (params) => {
      if (params) {
        const queryParams = buildUserListQueryParams({
          search: params.searchCriteria,
          filter: params.filterCriteria,
          orgId: params.organizationsId,
          hubId: params.hubsId,
        });
        //Default sort order with below is by creation date in descending order, (i.e. newest first)
        return {
          requestParams: {
            ...queryParams,
            sortBy: 'CREATION_DATE',
            sortOrder: 'DESC',
            size: params.rowsPerPage?.toString() || '10',
          },
        };
      }
      return {};
    },
    responseAdapter: (rawResponse: unknown | ApiResponse<unknown>) => {
      if (!!rawResponse && hasApiResult<PagedResult<User>>(rawResponse)) {
        const { items, total_items, continuation_token } = rawResponse.result;
        return {
          ...rawResponse,
          result: {
            total_items,
            continuation_token,
            items: items.length
              ? items
                  .filter((data) => data && typeof data === 'object')
                  .map((data) => {
                    return mapUserRoleAndInvite(data);
                  })
              : [],
          },
        };
      }
      throw new BDError('Unexpected user list response', { data: rawResponse });
    },
  })
);
export const getUserDetails = makeThunk(
  'users/fetchDetails',
  makeGetPayloadCreator<
    ApiResponse<User>,
    Required<Pick<UserRouteParams, 'usersId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/:usersId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: ({ usersId }) => ({
      requestParams: { usersId },
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToUser(response?.result),
    }),
  })
);

export const addUser = makeThunk(
  'users/addNewUser',
  makePostPayloadCreator<ApiResponse<User>, AddUserFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.addUserAPI}/admin`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: mapUserFormToApiRequest(payload),
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToUser(response?.result),
    }),
  })
);

export const submitUserTCs = makeThunk(
  'users/submitUserTCs',
  makePostPayloadCreator<ApiResponse<User>, ActivateUserPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.acceptInviteAPI}/invite/accept`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: payload.formFields,
    }),
  })
);

export const updateUser = makeThunk(
  'users/updateUser',
  makePutPayloadCreator<ApiResponse<User>, UpdateUserFormPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/:usersId`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestParams: {
        usersId: payload.params.usersId,
      },
      requestBody: mapUserFormToApiRequest(payload),
    }),
    responseAdapter: (response: any) => ({
      ...response,
      result: mapApiResponseToUser(response?.result),
    }),
  })
);

export const deleteUser = makeThunk(
  'users/deleteUser',
  makeDeletePayloadCreator<
    ApiResponse,
    Required<Pick<UserRouteParams, 'usersId'>>
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.default}/:usersId`,
    argAdapter: ({ usersId }) => ({
      requestParams: { usersId },
    }),
  })
);

export const validateSsoEmail = makeThunk(
  'users/validateSsoEmail',
  makePostPayloadCreator<ApiResponse, ValidateSsoEmailPayload>({
    url: `${globalThis.appConfig.apiBaseUrl}/users/sso/${API_VERSION_DEFAULTS.validateSsoEmailAPI}/validateDomain`,
    axiosOptions: (_, state) =>
      addAcceptLanguageHeader(CONFIG, state.profile.currentLocale),
    argAdapter: (payload) => ({
      requestBody: { email: payload.email },
    }),
  })
);

//10/21/2024 - current POST format for Invite User
export const inviteUsers = makeThunk(
  'users/invite',
  makePostPayloadCreator<
    ApiResponse<InviteUsersResponsePayload>,
    InviteUserPayload
  >({
    url: `${globalThis.appConfig.apiBaseUrl}/users/${API_VERSION_DEFAULTS.inviteAPI}/invites`,
    axiosOptions: (payload, state) =>
      addAcceptLanguageHeader(
        addOrganizationIdHeader(
          addHubIdHeader(CONFIG, payload.hubId),
          state.profile.actingProfile?.organization?.id
        ),
        state.profile.currentLocale
      ),
    argAdapter: (payload) => ({
      requestBody: payload.requestPayload,
    }),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (response && hasApiResult<InviteUsersResponsePayload>(response)) {
        return {
          ...response,
          result: response.result,
        };
      }
      throw new BDError('Unexpected Invite User response', {
        data: response,
      });
    },
  })
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedIndices: (state, action: PayloadAction<number[]>) => {
      state.selectedIndices = action.payload;

      // map associating each selected user id with its index in the selectedIndices array
      // used to speed up removal of selected users in assign dialog
      state.selectedReverseMap = action.payload.reduce(
        (reverseMap, selectedIndex, arrayIndex) => {
          if (state.ids.length > selectedIndex) {
            reverseMap[state.ids[selectedIndex]] = arrayIndex;
          }
          return reverseMap;
        },
        {} as { [key: string]: number }
      );
    },
    setUserSessionConfig: (
      state,
      action: PayloadAction<UserSessionConfigType>
    ) => {
      const mergeSelectedFilters = (_existing: string[], incoming: string[]) =>
        incoming;
      Object.keys(action.payload).forEach((key) => {
        const scope = key as UserSessionViewType;
        const payload = action.payload[scope] || {};
        Object.keys(payload).forEach((id) => {
          state.sessionConfigs = merge(
            state.sessionConfigs,
            {
              [scope]: {
                [id]: payload[id] && {
                  ...payload[id],
                },
              },
            },
            {
              customMerge: (key) => {
                if (Object.values(UserListFilterCriteriaKeys).includes(key)) {
                  return mergeSelectedFilters;
                }
              },
            }
          );
        });
      });
    },
    setUserListItems: (state, action: PayloadAction<User[]>) => {
      userListAdapter.setAll(state.userListItems, action.payload);
    },
    resetUserListContinuationCache: (
      state,
      action: PayloadAction<{
        viewType: UserSessionViewType;
        sessionId: string;
      }>
    ) => {
      const { viewType, sessionId } = action.payload;
      state.sessionConfigs = merge(state.sessionConfigs, {
        [viewType]: {
          [sessionId]: {
            continuationToken: null,
            continuationCache: undefined,
          },
        },
      });
    },
  },
  extraReducers: (builder) => {
    // user list
    builder.addCase(getUsers.pending, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.selectedIndices = [];
      usersAdapter.removeMany(state, state.ids); // ensure no records when fetching
      if (action.meta.arg?.sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [action.meta.arg?.sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      usersAdapter.upsertMany(state, action.payload.result);
      if (action.meta.arg?.sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [action.meta.arg?.sessionId]: {
                count: action.payload.result.length || 0,
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };

      if (action.meta.arg?.sessionId) {
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [action.meta.arg?.sessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });

    // user details
    builder.addCase(getUserDetails.pending, (state) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      usersAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(getUserDetails.rejected, (state, action) => {
      state.operations[BDRequestType.GET_BY_ID] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_BY_ID,
          },
        ],
      };
    });

    // add user
    builder.addCase(addUser.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // submit user t&c
    builder.addCase(submitUserTCs.pending, (state) => {
      state.operations[UserRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(submitUserTCs.fulfilled, (state) => {
      state.operations[UserRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(submitUserTCs.rejected, (state, action) => {
      state.operations[UserRequestType.SUBMIT_TERMS_AND_CONDITIONS] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // update user
    builder.addCase(updateUser.pending, (state) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      usersAdapter.upsertOne(state, action.payload.result);
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.operations[BDRequestType.UPDATE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.UPDATE,
          },
        ],
      };
    });

    // delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.operations[BDRequestType.DELETE] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.DELETE,
          },
        ],
      };
    });

    //validate Email domain for SSO
    builder.addCase(validateSsoEmail.pending, (state) => {
      state.operations[BDRequestType.VALIDATE_SSO_EMAIL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(validateSsoEmail.fulfilled, (state) => {
      state.operations[BDRequestType.VALIDATE_SSO_EMAIL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(validateSsoEmail.rejected, (state, action) => {
      state.operations[BDRequestType.VALIDATE_SSO_EMAIL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.VALIDATE_SSO_EMAIL,
          },
        ],
      };
    });

    //Invite Users for User Boarding - V0.5
    builder.addCase(inviteUsers.pending, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
    });
    builder.addCase(inviteUsers.fulfilled, (state) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.SUCCEEDED,
      };
    });
    builder.addCase(inviteUsers.rejected, (state, action) => {
      state.operations[BDRequestType.ADD] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.ADD,
          },
        ],
      };
    });

    // user list V2
    builder.addCase(getUserList.pending, (state, action) => {
      const { sessionId } = action.meta.arg;
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.PENDING,
        errors: [],
      };
      state.selectedIndices = [];
      userListAdapter.removeMany(state.userListItems, state.ids); // ensure no records when fetching
      if (sessionId) {
        const validSessionId = '' + sessionId;
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [validSessionId]: {
                operationStatus: {
                  status: BDRequestStatus.PENDING,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      const { items, total_items, continuation_token } = action.payload.result;
      const { page, sessionId } = action.meta.arg;
      const existingConfigs =
        state.sessionConfigs[UserSessionViewType.USER_LIST_VIEW] || {};
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.SUCCEEDED,
      };
      userListAdapter.setAll(state.userListItems, items);
      if (sessionId) {
        const validSessionId = '' + sessionId;
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [validSessionId]: {
                count: total_items || 0,
                continuationCache: {
                  ...existingConfigs.continuationCache,
                  [Number(page)]: {
                    data: items,
                    continuationToken: continuation_token ?? null,
                  },
                },
                operationStatus: {
                  status: BDRequestStatus.SUCCEEDED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      const { sessionId } = action.meta.arg;
      state.operations[BDRequestType.GET_ALL] = {
        status: BDRequestStatus.FAILED,
        errors: [
          {
            type: BDAppErrorType.API,
            ...(action.payload || (action.error as BDError)),
            requestType: BDRequestType.GET_ALL,
            severity: SeverityLevel.ERROR,
          },
        ],
      };

      if (sessionId) {
        const validSessionId = '' + sessionId;
        state.sessionConfigs = merge(
          state.sessionConfigs,
          {
            [UserSessionViewType.USER_LIST_VIEW]: {
              [validSessionId]: {
                operationStatus: {
                  status: BDRequestStatus.FAILED,
                },
              },
            },
          },
          {
            arrayMerge: (_, sourceArray) => sourceArray,
          }
        );
      }
    });

    // reset state when persist store is purged on logout
    builder.addCase(PURGE, (state) => {
      state = initialState;
    });
  },
});

export const {
  setSelectedIndices,
  setUserSessionConfig,
  setUserListItems,
  resetUserListContinuationCache,
} = usersSlice.actions;

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors<RootState>((state: RootState) => state.users);

export const { selectAll: selectAllUserListItems } =
  userListAdapter.getSelectors<RootState>(
    (state: RootState) => state.users.userListItems
  );

export const selectSelectedUsers = (state: RootState): User[] => {
  return state.users.selectedIndices.reduce((users, index) => {
    if (index < state.users.ids.length) {
      const user = selectUserById(state, state.users.ids[index]);
      if (user) {
        users.push(user);
      }
    }
    return users;
  }, [] as User[]);
};

export const usersReducer = usersSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  EntitlementResponseDto,
  entitlementsApi,
  GetOrganizationEntitlementsApiArg,
  GetOrganizationEntitlementsApiResponse,
} from '../api/entitlementsApi';

enum EntitlementsActionType {
  FETCH_ENTITLEMENTS = 'entitlements/fetchEntitlements',
}

export interface EntitlementsState {
  rawEntitlements: EntitlementResponseDto | undefined;
  entitlementsOperation: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: EntitlementsState = {
  rawEntitlements: undefined,
  entitlementsOperation: 'idle',
};

export const fetchEntitlements = createAsyncThunk<
  GetOrganizationEntitlementsApiResponse | undefined,
  GetOrganizationEntitlementsApiArg
>(EntitlementsActionType.FETCH_ENTITLEMENTS, async (args, { dispatch }) => {
  const response = await dispatch(
    entitlementsApi.endpoints.getOrganizationEntitlements.initiate(args)
  );
  if (response && 'data' in response) {
    return response.data;
  }
  throw new Error('Failed to fetch org entitlements');
});

const entitlementsSlice = createSlice({
  name: 'entitlements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitlements.pending, (state) => {
        state.entitlementsOperation = 'loading';
      })
      .addCase(fetchEntitlements.fulfilled, (state, action) => {
        state.entitlementsOperation = 'succeeded';
        state.rawEntitlements = action.payload?.result;
      })
      .addCase(fetchEntitlements.rejected, (state) => {
        state.entitlementsOperation = 'failed';
      });
  },
});

export const selectOrgEntitlements = (state: {
  entitlements: EntitlementsState;
}) => state?.entitlements?.rawEntitlements;
export const entitlementsReducer = entitlementsSlice.reducer;

import enAccount from './en/account.json';
import enAlerts from './en/alerts.json';
import enAsset from './en/asset.json';
import enCommon from './en/common.json';
import enDashboard from './en/dashboard.json';
import enDocuments from './en/documents.json';
import enPlans from './en/entitlements.json';
import enFleet from './en/fleet.json';
import enHub from './en/hub.json';
import enInsight from './en/insight.json';
import enInspection from './en/inspection.json';
import enIntegration from './en/integration.json';
import enOrder from './en/order.json';
import enOrganization from './en/organization.json';
import enPeripheral from './en/peripheral.json';
import enPurchasePrograms from './en/purchasePrograms.json';
import enSettings from './en/settings.json';
import enTripHistory from './en/tripHistory.json';
import enUser from './en/user.json';
import esAlerts from './es/alerts.json';
import esAsset from './es/asset.json';
import esCommon from './es/common.json';
import esDashboard from './es/dashboard.json';
import esDocuments from './es/documents.json';
import esPlans from './es/entitlements.json';
import esFleet from './es/fleet.json';
import esHub from './es/hub.json';
import esInsight from './es/insight.json';
import esIntegration from './es/integration.json';
import esOrder from './es/order.json';
import esOrganization from './es/organization.json';
import esSettings from './es/settings.json';
import esUser from './es/user.json';
import frAlerts from './fr/alerts.json';
import frAsset from './fr/asset.json';
import frCommon from './fr/common.json';
import frDashboard from './fr/dashboard.json';
import frDocuments from './fr/documents.json';
import frPlans from './fr/entitlements.json';
import frFleet from './fr/fleet.json';
import frHub from './fr/hub.json';
import frInsight from './fr/insight.json';
import frIntegration from './fr/integration.json';
import frOrder from './fr/order.json';
import frOrganization from './fr/organization.json';
import frUser from './fr/user.json';

const TranslationBundle = {
  en: {
    common: enCommon,
    order: enOrder,
    organization: enOrganization,
    hub: enHub,
    fleet: enFleet,
    asset: enAsset,
    user: enUser,
    dashboard: enDashboard,
    alerts: enAlerts,
    documents: enDocuments,
    integration: enIntegration,
    insight: enInsight,
    inspection: enInspection,
    settings: enSettings,
    purchasePrograms: enPurchasePrograms,
    peripheral: enPeripheral,
    tripHistory: enTripHistory,
    account: enAccount,
    entitlements: enPlans,
  },
  fr: {
    common: frCommon,
    order: frOrder,
    organization: frOrganization,
    hub: frHub,
    fleet: frFleet,
    asset: frAsset,
    user: frUser,
    dashboard: frDashboard,
    alerts: frAlerts,
    documents: frDocuments,
    integration: frIntegration,
    insight: frInsight,
    entitlements: frPlans,
  },
  es: {
    common: esCommon,
    order: esOrder,
    organization: esOrganization,
    hub: esHub,
    fleet: esFleet,
    asset: esAsset,
    user: esUser,
    dashboard: esDashboard,
    alerts: esAlerts,
    documents: esDocuments,
    integration: esIntegration,
    settings: esSettings,
    insight: esInsight,
    entitlements: esPlans,
  },
};
export default TranslationBundle;

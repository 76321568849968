import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonIcon: {
    '& path': {
      fill: theme.palette.text.primary,
    },
  },
  selectedItemIcon: {
    height: '20px',
    width: '20px',
    flex: 'none',
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  menuToggleIcon: {
    minWidth: 16,
    paddingRight: '4px', // visually align with toggle of nav menu items
  },
  disabled: {
    opacity: 0.5,
  },
}));

export default useStyles;

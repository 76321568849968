import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/app/rootReducer';

export const selectCamerasSettingsState = (state: RootState) =>
  state.camerasSettings;

export const selectCamerasSettings = createSelector(
  selectCamerasSettingsState,
  (state) => state.settings
);

export const selectCamerasSettingsOperationStatus = createSelector(
  selectCamerasSettingsState,
  (state) => state.operationStatus
);

import { MRT_Cell, MRT_Row } from '@brightdrop/table-ui';
import { User } from '@gm-commercial/profile-model';
import { Box, Typography } from '@mui/material';
import find from 'lodash/find';
import { Link, useParams } from 'react-router-dom';

import { RouteParams } from '~/common/configs/route.config';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { Entity } from '~/common/models/common.model';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

interface UserOrgColumnCellProps {
  cell: MRT_Cell<User, string>;
  row: MRT_Row<User>;
  tenants?: Entity[] | undefined;
}

const UserOrgColumnCell = ({ row, tenants }: UserOrgColumnCellProps) => {
  const organizationsId = row.original?.organization?.id;
  const params = useParams<RouteParams>();
  const orgDetails = find(tenants, { id: organizationsId });
  const userDetailsLink = makeRoutePath(
    findRoute(RouteName.ADVISOR_ORG_DETAILS),
    {
      ...params,
      organizationsId,
    }
  );
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <Typography variant="small_body_medium">
          {orgDetails?.name ? (
            <Link to={userDetailsLink} data-testid="user-list-org-name-link">
              {orgDetails.name}
            </Link>
          ) : (
            DEFAULT_EMPTY_VALUE
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserOrgColumnCell;

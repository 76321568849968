import {
  InvitationStatus,
  Invite,
  Role,
  User,
} from '@gm-commercial/profile-model';

import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import {
  ApiAddressFields,
  mapApiResponseToAddress,
  stripPhoneNumber,
} from '~/common/mappers/common.mappers';
import {
  ActivationStatus,
  UserSupportedLocale,
} from '~/common/models/common.model';
import { UserFormFields } from '~/common/models/user.model';
import {
  lowercaseLiteral,
  uppercaseLiteral,
} from '~/common/utils/common.utils';

import { AddUserFormPayload, UpdateUserFormPayload } from '../../usersSlice';

type UserApiRequestPayload = {
  role: Role;
  organizationId: string | null;
  email: string;
  firstName: string;
  lastName: string;
  hubId: string | null;
  fleetId: string | null;
  phoneNumber?: string;
  status?: Uppercase<ActivationStatus>;
  userPreferences?: {
    locale?: UserSupportedLocale | null;
    timeZone?: string | null;
  };
};

const getDisplayName = ({
  displayName,
  firstName,
  lastName,
}: {
  displayName: unknown;
  firstName: unknown;
  lastName: unknown;
}): string => {
  const validatedDisplayName =
    displayName && typeof displayName === 'string' ? displayName.trim() : null;
  const validatedFirstName =
    firstName && typeof firstName === 'string' && firstName.trim()
      ? firstName.trim()
      : '';
  const validatedLastName =
    lastName && typeof lastName === 'string' && lastName.trim()
      ? lastName.trim()
      : '';
  return (
    validatedDisplayName ||
    (validatedFirstName && validatedLastName
      ? `${validatedFirstName} ${validatedLastName}`
      : DEFAULT_EMPTY_VALUE)
  );
};

export const mapApiResponseInviteToUserInvite = (
  apiInvite: Invite | undefined
) => {
  if (apiInvite) {
    //Backend returns canceled, instead of cancelled, so we need to map it correctly
    const apiCanceled = 'CANCELED';
    let inviteStatus: string | undefined;
    if (typeof apiInvite.status === 'string') {
      if (apiInvite.status === apiCanceled) {
        inviteStatus = InvitationStatus.CANCELLED;
      } else {
        inviteStatus = apiInvite.status;
      }
    } else {
      inviteStatus = undefined;
    }
    return {
      ...apiInvite,
      status: lowercaseLiteral(inviteStatus),
    };
  } else {
    return undefined;
  }
};

export const mapInvitationStatusToBackend = (
  invitationStatus: InvitationStatus
): string => {
  //Only Cancelled status is spelled differently in the Backend
  if (invitationStatus === InvitationStatus.CANCELLED) {
    return 'CANCELED';
  }
  return (
    Object.keys(InvitationStatus).find(
      (key) =>
        InvitationStatus[key as keyof typeof InvitationStatus] ===
        invitationStatus
    ) ?? invitationStatus.toString()
  );
};

export const mapApiResponseToUser = <
  Result extends Partial<Omit<User, 'address' | 'status'>> & {
    address: ApiAddressFields | null;
  } & Record<string, unknown>,
>(
  rawResult: Result
): User => {
  const { displayName, firstName, lastName } = rawResult;
  const mapped = {
    ...rawResult,
    role:
      typeof rawResult.role === 'string'
        ? Role[rawResult.role as unknown as keyof typeof Role]
        : DEFAULT_EMPTY_VALUE,
    name: getDisplayName({ displayName, firstName, lastName }),
    phoneNumber:
      rawResult.phoneNumber && rawResult.phoneNumber.length >= 10
        ? stripPhoneNumber(rawResult.phoneNumber)
        : DEFAULT_EMPTY_VALUE,
    address: rawResult.address
      ? mapApiResponseToAddress(rawResult.address)
      : undefined,
    status:
      typeof rawResult.status === 'string'
        ? lowercaseLiteral(rawResult.status)
        : undefined,
    invite: mapApiResponseInviteToUserInvite(rawResult.invite),
  };
  return mapped as User;
};

export const mapUserRoleAndInvite = (user: User): User => {
  return {
    ...user,
    role: Role[user.role as unknown as keyof typeof Role],
    invite: mapApiResponseInviteToUserInvite(user.invite),
  };
};

const isAddUserPayload = (
  p:
    | Pick<AddUserFormPayload, 'formFields'>
    | Pick<UpdateUserFormPayload, 'formFields'>
): p is AddUserFormPayload => {
  return !!(p as AddUserFormPayload).formFields.email;
};

const isUpdateUserPayload = (
  p:
    | Pick<AddUserFormPayload, 'formFields'>
    | Pick<UpdateUserFormPayload, 'formFields'>
): p is UpdateUserFormPayload => {
  return !!(p as UpdateUserFormPayload).formFields.status;
};

const mapUserPreferences = (formFields: UserFormFields) =>
  formFields.locale || formFields.timeZone
    ? {
        locale: formFields.locale,
        timeZone: formFields.timeZone,
      }
    : undefined;

export const mapUserFormToApiRequest = (
  payload: AddUserFormPayload | UpdateUserFormPayload
): UserApiRequestPayload => {
  let result = {
    role: Object.keys(Role)[
      Object.values(Role).indexOf(payload.formFields.role)
    ],
  } as UserApiRequestPayload;
  if (isAddUserPayload(payload)) {
    result = {
      ...result,
      email: payload.formFields.email,
      firstName: payload.formFields.firstName,
      lastName: payload.formFields.lastName,
      phoneNumber: payload.formFields.phoneNumber
        ? stripPhoneNumber(payload.formFields.phoneNumber)
        : undefined,
    };
  } else if (isUpdateUserPayload(payload)) {
    result = {
      ...result,
      organizationId: payload.params.organizationsId || null,
      hubId: payload.formFields.hub?.id || null,
      fleetId: payload.formFields.fleet?.id || null,
      status: uppercaseLiteral(payload.formFields.status),
      userPreferences: mapUserPreferences(payload.formFields),
    };
  }

  return result;
};

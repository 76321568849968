import { initialApi as api } from './initialApi';
export const addTagTypes = [
  'entitlements-controller',
  'license-controller',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrganizationEntitlements: build.query<
        GetOrganizationEntitlementsApiResponse,
        GetOrganizationEntitlementsApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}`,
        }),
        providesTags: ['entitlements-controller'],
      }),
      createLicense: build.mutation<
        CreateLicenseApiResponse,
        CreateLicenseApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}`,
          method: 'POST',
          body: queryArg.createLicenseDto,
        }),
        invalidatesTags: ['license-controller'],
      }),
      getLicenses: build.query<GetLicensesApiResponse, GetLicensesApiArg>({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}/licenses`,
          method: 'POST',
          body: queryArg.getLicenseListDto,
        }),
        providesTags: ['license-controller'],
      }),
      getOrganizationEnablements: build.query<
        GetOrganizationEnablementsApiResponse,
        GetOrganizationEnablementsApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}/enablements`,
          method: 'POST',
          body: queryArg.enablementsFiltersDto,
        }),
        providesTags: ['entitlements-controller'],
      }),
      reprocessLicensesForAssets: build.mutation<
        ReprocessLicensesForAssetsApiResponse,
        ReprocessLicensesForAssetsApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}/batch`,
          method: 'POST',
          body: queryArg.reprocessingDto,
        }),
        invalidatesTags: ['entitlements-controller'],
      }),
      getLicenseSummaries: build.query<
        GetLicenseSummariesApiResponse,
        GetLicenseSummariesApiArg
      >({
        query: (queryArg) => ({
          url: `/2024-06-01/organizations/${queryArg.orgId}/licenses/summaries`,
        }),
        providesTags: ['license-controller'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as entitlementsApi };
export type GetOrganizationEntitlementsApiResponse =
  /** status 200 OK */ GmResponseCommonEntitlementResponseDto;
export type GetOrganizationEntitlementsApiArg = {
  /** Organization ID. */
  orgId: string;
};
export type CreateLicenseApiResponse =
  /** status 201 Created */ GmResponseCommonLicenseDto;
export type CreateLicenseApiArg = {
  /** Organization ID. */
  orgId: string;
  createLicenseDto: CreateLicenseDto;
};
export type GetLicensesApiResponse =
  /** status 200 Success */ GmResponseCommonLicensePageDto;
export type GetLicensesApiArg = {
  /** Organization ID. */
  orgId: string;
  getLicenseListDto: GetLicenseListDto;
};
export type GetOrganizationEnablementsApiResponse =
  /** status 200 OK */ GmResponseCommonEnablementsDto;
export type GetOrganizationEnablementsApiArg = {
  /** Organization ID. */
  orgId: string;
  enablementsFiltersDto: EnablementsFiltersDto;
};
export type ReprocessLicensesForAssetsApiResponse =
  /** status 200 OK */ GmResponseCommonReprocessedLicensesDto;
export type ReprocessLicensesForAssetsApiArg = {
  /** Organization ID. */
  orgId: string;
  reprocessingDto: ReprocessingDto;
};
export type GetLicenseSummariesApiResponse =
  /** status 200 Success */ GmResponseCommonListLicenseSummaryDto;
export type GetLicenseSummariesApiArg = {
  /** Organization ID. */
  orgId: string;
};
export type EntitledProductDto = {
  /** Name of the entitled product. */
  name: string;
  /** Code of the entitled product. */
  code: string;
  /** Entitled product licenses level, which is a combination of all licenses levels for the product. */
  levels: ('ORGANIZATION' | 'ASSET')[];
  /** List of entitled features that are provided under this product. */
  features: string[];
  /** Expiration date of the entitlement for the product which is max of all licenses expirations for the product. */
  maxExpiration: string;
};
export type EntitlementDto = {
  product: EntitledProductDto;
};
export type EnablementsDto = {
  /** Superset os the all features codes by assert Type, by assetId the asset is both entitled (directly or via organization) and capable of. */
  features: {
    [key: string]: {
      [key: string]: string[];
    };
  };
  /** List of the products codes by assert Type, by assetId the asset is both entitled (directly orvia organization) and capable of. */
  products: {
    [key: string]: {
      [key: string]: string[];
    };
  };
  /** Last time the returned entities were updated (either license they have or their capabilities) */
  updatedAt: string;
};
export type EntitlementResponseDto = {
  /** Set of the entitled products which includes both org level entitlements and asset level entitlements. */
  entitlements: EntitlementDto[];
  /** Asset level enablements, which is a combination of asset entitlements, based both on directasset licences and those were assets are enrolled under org licenses, and asset capabilities. */
  enablements: EnablementsDto;
};
export type GmResponseError = {
  errorType: 'GENERIC' | 'VALIDATION';
  message: string;
  errorCode: string;
};
export type GmResponseCommonEntitlementResponseDto = {
  result?: EntitlementResponseDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type GmResponseCommon = {
  result?: object;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type RestrictionConditionDto = {
  type: 'ACTIVE_ASSETS_COUNT' | 'REMOTE_COMMANDS_COUNT';
};
export type UsageRestrictionDto = {
  /** Usage condition that carries expression to be evaluated to check the restriction. */
  condition: RestrictionConditionDto;
  /** Duration of usage restriction. */
  duration?: {
    years?: number;
    months?: number;
    days?: number;
    zero?: boolean;
    negative?: boolean;
    units?: {
      durationEstimated?: boolean;
      duration?: {
        seconds?: number;
        zero?: boolean;
        nano?: number;
        negative?: boolean;
        positive?: boolean;
      };
      timeBased?: boolean;
      dateBased?: boolean;
    }[];
    chronology?: {
      id?: string;
      calendarType?: string;
      isoBased?: boolean;
    };
  };
  /** Max value of a constraint counter allowed by the usage restriction for its duration.  */
  max: number;
};
export type LicensingOptionDto = {
  /** Licensing model type. */
  type: 'RPO' | 'MODEL_YEAR' | 'MAKE' | 'MODEL';
  /** Duration of licensing option */
  duration: {
    years?: number;
    months?: number;
    days?: number;
    zero?: boolean;
    negative?: boolean;
    units?: {
      durationEstimated?: boolean;
      duration?: {
        seconds?: number;
        zero?: boolean;
        nano?: number;
        negative?: boolean;
        positive?: boolean;
      };
      timeBased?: boolean;
      dateBased?: boolean;
    }[];
    chronology?: {
      id?: string;
      calendarType?: string;
      isoBased?: boolean;
    };
  };
  /** Usage restrictions condition to utilize the license */
  usageRestrictions?: UsageRestrictionDto[];
  /** Maximum number of seats available for the license */
  maxCapacity?: number;
  /** Allowed enrollment operations */
  allowedEnrollmentOperations?: ('REMOVE' | 'ADD' | 'REPLACE')[];
};
export type AcceptedValuesLicensingOptionDto = LicensingOptionDto & {
  /** Licensing option type, defines the attribute for the accepted values. */
  type?: 'RPO' | 'MODEL_YEAR' | 'MAKE' | 'MODEL';
  /** Set of accepted values for the attribute for which the license is valid for */
  acceptedValues?: string[];
};
export type ModelYearLicensingOptionDto = LicensingOptionDto & {
  /** Start Model year */
  start?: number;
  /** End Model year */
  end?: number;
};
export type EnrolledEntitiesDto = {
  /** The ids of the entities that are enrolled in the license. */
  entityIds: string[];
  /** The number of seats that are available for the license. */
  seatsCapacity: number;
};
export type LicenseDto = {
  /** Unique identifier for the license. */
  id: string;
  /** Id of the organization that license belongs to, directly or via asset. */
  organizationId: string;
  /** Short name of the license. */
  name: string;
  /** Channel (flow) through which the license was distributed. */
  channel: 'ONBOARDING' | 'ONBOARDING_REPROCESS' | 'MANUAL';
  /** Unique identifier of the product the license entitles access for. */
  productId: string;
  /** Well known code of the product, does not change across versions of the product. */
  productCode: string;
  /** Licensing option details for the license. It corresponds to the licensing model the license is based on. The attributes available on the option depend on the licensing model. */
  licensingOption:
    | AcceptedValuesLicensingOptionDto
    | ModelYearLicensingOptionDto;
  /** Level at which the license is applicable. */
  level: 'ORGANIZATION' | 'ASSET';
  /** Start date of the license validity period. */
  startDate: string;
  /** Expiration date of the license validity period. */
  expirationDate: string;
  /** Map of asset types to enrolled entities, representing the entities enrolled under the license. */
  enrolledEntities?: {
    [key: string]: EnrolledEntitiesDto;
  };
  /** Description of the license. */
  description?: string;
  /** Current status of the license, */
  status: 'PENDING' | 'ACTIVE' | 'SUSPENDED' | 'TERMINATED' | 'EXPIRED';
  /** Current payment status of the license. */
  paymentStatus: 'PENDING' | 'COMPLETE' | 'FAILED' | 'NOT_REQUIRED';
  /** List if the identifiers for a dependent licenses, if any. */
  dependentLicenseIds?: string[];
  /** Date and time this license was created. */
  createdDateTime: string;
  /** Unique identifier for the user who created the license. */
  createdBy: string;
  /** Date and time the license was updated. */
  updatedDateTime: string;
  /** Unique identifier for the user who last updated the license. */
  updatedBy: string;
};
export type GmResponseCommonLicenseDto = {
  result?: LicenseDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type CreateLicenseDto = {
  /** Product ID to create a license for */
  productId: string;
  /** Index of Licensing Model from the list of all licensing models of the product to create the license based on, 0 based. */
  licensingModelIndex: number;
  /** Start date of the license to be created */
  startDate: string;
  /** Expiration date of the license to be created */
  expirationDate?: string;
  /** The initial payment status of the license created. */
  paymentStatus: 'PENDING' | 'NOT_REQUIRED';
  /** Enrolled entities by asset type */
  enrolledEntities?: {
    [key: string]: EnrolledEntitiesDto;
  };
  /** Description of the license to be created */
  description?: string;
};
export type LicensePageDto = {
  items: LicenseDto[];
  totalItems?: number;
  previousToken?: string;
  token?: string;
  nextToken?: string;
  firstPageItemIndex: number;
};
export type GmResponseCommonLicensePageDto = {
  result?: LicensePageDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type FilterBy = object;
export type ArrayContainsFilter = FilterBy & {
  operator?: 'AND' | 'OR';
  allOfValues?: string[];
};
export type Between = {
  min: string;
  max: string;
};
export type BetweenFilter = FilterBy & {
  between?: Between;
};
export type InListFilter = FilterBy & {
  oneOfValues?: string[];
};
export type GetLicenseListDto = {
  sort?: {
    [key: string]: 'ASC' | 'DESC';
  };
  filter?: {
    [key: string]: ArrayContainsFilter | BetweenFilter | InListFilter;
  };
  token?: string;
  itemsPerResponse: number;
};
export type GmResponseCommonEnablementsDto = {
  result?: EnablementsDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type EnablementsFiltersDto = {
  /**  Enablements updated after the updatedSince date time will be included */
  updatedSince: string;
  /**  Asset type to get enablements for */
  assetType: 'VINS' | 'PERIPHERALS';
  /**  Asset Ids to get enablements for.If empty gets enablements for all assets for the asset type */
  assetIds?: string[];
};
export type ReprocessedLicensesDto = {
  added?: LicenseDto[];
  updated?: LicenseDto[];
};
export type GmResponseCommonReprocessedLicensesDto = {
  result?: ReprocessedLicensesDto;
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export type ReprocessingDto = {
  /** The flow to be re-processed. */
  flow: 'ONBOARDING' | 'OFFBOARDING';
  /** A list of the entities to re-process by the entity type as a key.
            There should be at least one list (key) in the map. */
  assets: {
    [key: string]: string[];
  };
};
export type LicenseSummaryDto = {
  /** Echoed back organization ID from the request */
  organizationId: string;
  /** Name of the product the summary is about */
  productName: string;
  /** ID of the product the summary refers to */
  productId: string;
  /** Well known product code for the summary element */
  productCode: string;
  /** License level (ORGANIZATION or ASSET) the summary is for */
  licenseLevel: string;
  /** The type of asset that the summary covers (VINS or PERIPHERALS) */
  assetType: string;
  /** Number of the occupied seats. For ASSET level, this is always equal to totalSeatsCapacity. */
  occupiedSeats: number;
  /**
                    Total number of seats available under all licenses for the level/type.
                    This will always be greater than or equal to occupiedSeats.
                 */
  totalSeatsCapacity: number;
};
export type GmResponseCommonListLicenseSummaryDto = {
  result?: LicenseSummaryDto[];
  targetUrl: string;
  __bd: boolean;
  success: boolean;
  unauthorizedRequest: boolean;
  errors: GmResponseError[];
  traceId?: string;
};
export const {
  useGetOrganizationEntitlementsQuery,
  useCreateLicenseMutation,
  useGetLicensesQuery,
  useGetOrganizationEnablementsQuery,
  useReprocessLicensesForAssetsMutation,
  useGetLicenseSummariesQuery,
} = injectedRtkApi;

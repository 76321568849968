// Part of Netradyne API POC - SDPCDXP - 56
export const POC_TENANT_UNIQUE_NAME = 'N357590847862436';
export const POC_VINS = ['1GYKPCRS2SZ105027'];
export const POC_VEHICLE_NUMBERS = ['AkashXT6'];
export const POC_VIN = '1GYKPCRS2SZ105027';

export const POC_TRIP_EVENT_ID = '3349153830';
export const POC_VIDEO_ID = '304029582963';

export const X_COORDINATE = 0;
export const Y_COORDINATE = 1;

export const enum TripsParamKey {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  VEHICLE_NUMBERS = 'vehicleNumbers',
  VIN = 'vin',
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  VIN_NUMBER = 'vinNumber',
}

import { createSlice } from '@reduxjs/toolkit';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';

import { TRIP_HISTORY_ACTIONS } from './tripHistorySlice.actions';
import { TripHistoryState } from './tripHistorySlice.model';
import {
  TRIP_HISTORY_EXTRA_REDUCER_BUILDERS,
  TRIP_HISTORY_REDUCERS,
} from './tripHistorySlice.reducers';

const initialState: TripHistoryState = {
  trips: [],
  selectedTripId: null,
  selectedEventId: null,
  operationStatus: {
    status: BDRequestStatus.IDLE,
    errors: [],
  },
};

export const tripHistorySlice = createSlice({
  name: 'tripHistory',
  initialState,
  reducers: {
    selectTrip: TRIP_HISTORY_REDUCERS.selectTrip,
    selectEvent: TRIP_HISTORY_REDUCERS.selectEvent,
  },
  extraReducers: (builder) => {
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchTokenReducer(builder);
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchTripsReducer(builder);
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchEventsReducer(builder);
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchVideoRequestIdReducer(
      builder
    );
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchVideoRequestStatusReducer(
      builder
    );
    TRIP_HISTORY_EXTRA_REDUCER_BUILDERS.buildFetchVideoUrlReducer(builder);
  },
});

export const { selectTrip, selectEvent } = tripHistorySlice.actions;
export const {
  fetchToken,
  fetchTrips,
  fetchEvents,
  fetchVideoRequestId,
  fetchVideoRequestStatus,
  fetchVideoUrl,
} = TRIP_HISTORY_ACTIONS;
export const tripHistoryReducer = tripHistorySlice.reducer;

export * from './tripHistorySlice.selectors';

import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  InactiveUserTimerMessage: {
    color: theme.new.color.textSecondary,
    paddingBottom: '32px',
  },

  InactiveUserExtendSessionMessage: {
    color: theme.new.color.textSecondary,
  },

  buttonContainer: {
    alignSelf: 'end',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    paddingBlockStart: '16px',
    width: '100%',
    justifyContent: 'end',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.new.color.line.hairline,
  },
}));

export default useStyles;

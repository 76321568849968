import { FormState } from '@brightdrop/bd-form';
import { InvitationStatus, Role } from '@gm-commercial/profile-model';

import {
  ActivationStatus,
  PartialEntity,
  SearchCriteria,
  UserSupportedLocale,
} from './common.model';
import { Fleet } from './fleet.model';
import { Hub } from './hub.model';
import { Organization } from './organization.model';

export type UserFormFields = {
  role: Role | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  locale: UserSupportedLocale | null;
  timeZone: string | null;
  status: ActivationStatus | null;
  hub?: PartialEntity<Hub> | null;
  fleet?: PartialEntity<Fleet> | null;
  doNotShowLegalNotice?: boolean | null;
};

export interface ProfileTelemetry {
  id?: string;
  organization?: PartialEntity<Organization> | null;
  hub?: PartialEntity<Hub> | null;
  fleet?: PartialEntity<Fleet> | null;
  role?: Role;
}

export type UserFormErrors = { [k in keyof UserFormFields]: string };

export type UserFormState = FormState<UserFormFields>;

export interface UserListFilterCriteria {
  statuses?: ActivationStatus[];
  fleetIds?: string[];
  roles?: Role[];
  inviteStatuses?: InvitationStatus[];
}

export const UserListFilterCriteriaKeys = [
  'statuses',
  'fleetIds',
  'roles',
  'inviteStatuses',
];

export interface UserSearchFilterInfo {
  searchCriteria?: SearchCriteria;
  filterCriteria?: UserListFilterCriteria;
}

export const USER_LIST_FILTER_EMPTY_STATE: UserListFilterCriteria = {
  statuses: [],
  fleetIds: [],
  roles: [],
  inviteStatuses: [],
};

import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';
import { BDError } from '~/common/models/error.model';

import { CAMERAS_SETTINGS_ACTIONS } from './camerasSettingsSlice.action';
import { CamerasSettingsState } from './camerasSettingsSlice.model';

export const buildFetchSettingsReducer = (
  builder: ActionReducerMapBuilder<CamerasSettingsState>
) => {
  builder.addCase(CAMERAS_SETTINGS_ACTIONS.fetchSettings.pending, (state) => {
    state.operationStatus = {
      status: BDRequestStatus.PENDING,
      errors: [],
    };
  });
  builder.addCase(
    CAMERAS_SETTINGS_ACTIONS.fetchSettings.fulfilled,
    (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.SUCCEEDED,
        errors: [],
      };
      state.settings = action.payload.result;
    }
  );
  builder.addCase(
    CAMERAS_SETTINGS_ACTIONS.fetchSettings.rejected,
    (state, action) => {
      state.operationStatus = {
        status: BDRequestStatus.FAILED,
        errors: [action.payload as BDError],
      };
    }
  );
};

export const CAMERAS_SETTINGS_EXTRA_REDUCER_BUILDERS = {
  buildFetchSettingsReducer,
};

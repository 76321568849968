import { MRT_Cell, MRT_Row } from '@brightdrop/table-ui';
import { Role, User } from '@gm-commercial/profile-model';
import { Box } from '@mui/material';

import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';

interface UserFleetColumnCellProps {
  cell: MRT_Cell<User, string>;
  row: MRT_Row<User>;
}

const UserFleetColumnCell = ({ cell, row }: UserFleetColumnCellProps) => {
  const fleet = cell.getValue();
  const cellFleetName = row.original.fleet?.name;
  const fleetName =
    !!fleet && !!cellFleetName ? cellFleetName : DEFAULT_EMPTY_VALUE;
  const role = row.original.role;
  return (
    <Box display="flex" data-testid="user-list-fleet-column">
      {role === Role.ORG_MANAGER ? translateText('common:all') : fleetName}
    </Box>
  );
};

export default UserFleetColumnCell;

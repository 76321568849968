import {
  API_VERSION_DEFAULTS,
  DEFAULT_API_CONFIG,
} from '~/common/apis/api.constants';
import { RouteParams } from '~/common/configs/route.config';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { BDError } from '~/common/models/error.model';
import {
  ApiCamerasSettings,
  CamerasSettings,
} from '~/common/models/settings.model';
import {
  addAcceptLanguageHeader,
  addCSFleetIdHeader,
  addCSHubIdHeader,
  addCSOrganizationIdHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

export enum CamerasSettingsActionType {
  FETCH_SETTINGS = 'cameraSettings/fetchSettings',
}

const CONFIG = DEFAULT_API_CONFIG;

type CamerasSettingsParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
>;

const fetchSettings = makeThunk(
  CamerasSettingsActionType.FETCH_SETTINGS,
  makeGetPayloadCreator<ApiResponse<CamerasSettings>, CamerasSettingsParams>({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/fleet-cameras/${API_VERSION_DEFAULTS.camerasSettingsAPI}/settings`,
    axiosOptions: ({ organizationsId, hubsId, fleetsId }, state) =>
      addAcceptLanguageHeader(
        addCSFleetIdHeader(
          addCSHubIdHeader(
            addCSOrganizationIdHeader(CONFIG, organizationsId),
            hubsId
          ),
          fleetsId
        ),
        state.profile.currentLocale
      ),
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<ApiCamerasSettings>(response)) {
        const { settings, updatedDateTime } = response.result;
        return {
          ...response,
          result: {
            ...settings,
            updatedDateTime,
          },
        };
      }
      throw new BDError('Unexpected fetch settings response', {
        data: response,
      });
    },
  })
);

export const CAMERAS_SETTINGS_ACTIONS = {
  fetchSettings,
};

import { AssetModel, AssetServiceStatus } from './asset.model';
import { VehicleDetailsType } from './asset-report.model';
import { Entity, PartialEntity } from './common.model';
import { Fleet } from './fleet.model';
import { Hub } from './hub.model';
import { Organization } from './organization.model';

export interface FieldActionSummary {
  ZPSR?: string[];
  ZPER?: string[];
  ZNCR?: string[];
  ZCSP?: string[];
}

export enum FieldActionType {
  ZPSR = 'ZPSR',
  ZPER = 'ZPER',
  ZNCR = 'ZNCR',
  ZCSP = 'ZCSP',
}
export const ApiToFieldActionTypeMap: { [k in FieldActionType]: string } = {
  [FieldActionType.ZPSR]: 'safety',
  [FieldActionType.ZPER]: 'emission',
  [FieldActionType.ZNCR]: 'nonCompliance',
  [FieldActionType.ZCSP]: 'customerSatisfaction',
};
export interface ApiRecallsItem extends Entity {
  organization?: PartialEntity<Organization>;
  hub?: PartialEntity<Hub>;
  fleet?: PartialEntity<Fleet>;
  availabilityState?: AssetServiceStatus;
  licensePlate?: string;
  fieldActionSummary?: FieldActionSummary;
  vehicleType?: VehicleDetailsType;
  type: AssetModel;
  imageUrl: string;
}

import {
  ACCEPT_LANGUAGE_HEADER,
  BD_HUB_HEADER_NAME,
  BD_ORGANIZATION_HEADER_NAME,
  CS_CONTINUATION_TOKEN_HEADER_NAME,
  CS_FLEET_HEADER_NAME,
  CS_HUB_HEADER_NAME,
  CS_ORGANIZATION_HEADER_NAME,
} from '../../apis/api.constants';
import {
  ApiResponse,
  ThunkCreatorConfig,
} from '../../models/apis/apiResponse.model';

export const resultIsArray = <T>(
  response: unknown
): response is ApiResponse<Array<T>> => {
  return (
    !!(response as ApiResponse<Array<T>>).result &&
    Array.isArray((response as ApiResponse<Array<T>>).result)
  );
};

export const hasApiResult = <T = unknown>(
  response: unknown,
  assertion = ((_: unknown) => true) as (result: unknown) => result is T
): response is ApiResponse<T> =>
  !!response &&
  !!(response as ApiResponse<unknown>).__bd &&
  !!(response as ApiResponse<unknown>).result &&
  assertion((response as ApiResponse<unknown>).result);

export const addHeader = (
  config: ThunkCreatorConfig,
  headerName: string,
  value?: string
): ThunkCreatorConfig => {
  if (!headerName || !value) {
    return config;
  }
  return {
    ...config,
    axiosConfig: {
      ...config.axiosConfig,
      headers: {
        ...config.axiosConfig?.headers,
        [headerName]: value,
      },
    },
  };
};

export const addAcceptLanguageHeader = (
  config: ThunkCreatorConfig,
  language?: string
): ThunkCreatorConfig => {
  return addHeader(config, ACCEPT_LANGUAGE_HEADER, language);
};

export const addOrganizationIdHeader = (
  config: ThunkCreatorConfig,
  organizationId?: string
): ThunkCreatorConfig => {
  return addHeader(config, BD_ORGANIZATION_HEADER_NAME, organizationId);
};

export const addCSOrganizationIdHeader = (
  config: ThunkCreatorConfig,
  organizationId?: string
): ThunkCreatorConfig => {
  return addHeader(config, CS_ORGANIZATION_HEADER_NAME, organizationId);
};

export const addCSContinuationTokenHeader = (
  config: ThunkCreatorConfig,
  continuationToken?: string | null
): ThunkCreatorConfig => {
  return addHeader(
    config,
    CS_CONTINUATION_TOKEN_HEADER_NAME,
    continuationToken ?? undefined
  );
};

export const addCSFleetIdHeader = (
  config: ThunkCreatorConfig,
  fleetId?: string
): ThunkCreatorConfig => {
  return addHeader(config, CS_FLEET_HEADER_NAME, fleetId);
};

export const addCSHubIdHeader = (
  config: ThunkCreatorConfig,
  hubId?: string
): ThunkCreatorConfig => {
  return addHeader(config, CS_HUB_HEADER_NAME, hubId);
};

export const addHubIdHeader = (
  config: ThunkCreatorConfig,
  hubId?: string
): ThunkCreatorConfig => {
  return addHeader(config, BD_HUB_HEADER_NAME, hubId);
};

import { Button, DialogComponentProps } from '@brightdrop/bd-ui';
import { Box, Typography } from '@mui/material';
import type { LocationDescriptorObject } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import { formatCountDownFromMilliseconds } from '~/common/utils/date-time.utils';
import {
  InactivityStorageKeys,
  modalCountdownTimeout,
} from '~/features/inactivityTracker/InactivityTracker.constants';
import { buildRedirectPathQuery } from '~/features/session/utils/location.utils';

import useStyles from './InactivityUserCountdownModal.styles';

export type InactiveUserCountdownModalProps<T = never> =
  DialogComponentProps<T> & {
    modalTimeout?: number;
  };

const InactiveUserCountdownModal = ({
  onCloseModal,
  modalTimeout = modalCountdownTimeout,
}: InactiveUserCountdownModalProps) => {
  const { classes } = useStyles();
  const { authenticationType } = useCustomAuthentication();
  const [timeRemaining, setTimeRemaining] = useState<number>(modalTimeout);
  const location = useLocation<Location>();
  const history = useHistory();
  const [redirectQuery, setRedirectQuery] = useState<string>();
  const handleLogout = useCallback(
    (isInactivityLogout: boolean = false) => {
      const path: LocationDescriptorObject = {
        pathname: `/auth/${authenticationType}/logout`,
      };
      //Only want to retain the last visited page upon logout due to inactivity
      //Else, treat the logout as the same when a user clicks on sign out from the header
      if (isInactivityLogout) {
        sessionStorage.setItem(InactivityStorageKeys.userSignedOut, 'true');
        path.search = redirectQuery;
      }
      history.push(path);
    },
    [authenticationType, history, redirectQuery]
  );

  const handleStorageEvent = (event: StorageEvent) => {
    //Sign out User based on inactivity in other window
    if (
      event.key === InactivityStorageKeys.userSignedOut &&
      event.newValue !== null
    ) {
      //location seems to be preserved when one tab is logged out, which causes the other tabs to log out due to
      //auth token being stored in localStorage
      handleLogout(true);
    }
  };

  const setupEventListeners = () => {
    window.addEventListener('storage', handleStorageEvent);
  };

  const handleNoButtonClick = () => {
    onCloseModal(false);
    //Note: the application detects when users are in an unauthenticated state,
    //so no need to emit an event for normal logout
    handleLogout(false);
  };

  const handleYesButtonClick = () => {
    onCloseModal(true);
  };

  useEffect(() => {
    setupEventListeners();
  });

  useEffect(() => {
    if (location) {
      setRedirectQuery(buildRedirectPathQuery(location));
    }
  }, [location]);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timerId = setInterval(() => {
        setTimeRemaining((prevState) => prevState - 1000);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      //Emit event to signal other users to sign out
      localStorage.setItem(InactivityStorageKeys.userSignedOut, 'true');
      localStorage.removeItem(InactivityStorageKeys.userSignedOut);
      handleLogout(true);
    }
  }, [handleLogout, timeRemaining]);

  return (
    <>
      <Typography
        variant="body_regular"
        className={classes.InactiveUserTimerMessage}
      >
        {translateText('common:inactivity:modal:timerMessage', {
          countdown: formatCountDownFromMilliseconds(timeRemaining),
        })}
      </Typography>
      <Box display="flex" flexDirection="column">
        <div className={classes.buttonContainer}>
          <Button
            onClick={handleNoButtonClick}
            variant={'tertiary'}
            data-testid="inactive-user-countdown-modal-no-button"
          >
            {translateText('common:inactivity:modal:signOut')}
          </Button>
          <Button
            onClick={handleYesButtonClick}
            data-testid="inactive-user-countdown-modal-yes-button"
          >
            {translateText('common:inactivity:modal:staySignedIn')}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default InactiveUserCountdownModal;

import { Theme } from '@mui/material/styles';
import { CSSObject } from 'styled-components';

import { ButtonProps } from './Button.model';

export const generateButtonCss = (
  theme: Theme,
  props: ButtonProps
): CSSObject => {
  const defaultColor = props.destructive
    ? theme.new.color.errorPrimary
    : theme.new.color.brandPrimary;

  const hoverColor = props.destructive
    ? theme.new.color.errorSecondary
    : theme.new.color.actionHover;

  const activeColor = props.destructive
    ? theme.new.color.danger[800]
    : theme.new.color.actionSelected;

  const disabledColor = props.destructive
    ? theme.new.color.danger[300]
    : theme.new.color.grey[400];

  return {
    borderRadius: '8px',
    cursor: 'pointer',
    flex: props.flex,
    fontFamily: theme.typography.body_medium?.fontFamily,
    fontSize: theme.typography.body_medium?.fontSize,
    fontWeight: theme.typography.body_medium?.fontWeight,
    lineHeight: theme.typography.body_medium?.lineHeight,
    margin: props.margin,
    minWidth: props.minWidth ?? '108px',
    padding: `16px 32px`,
    width: props.width ?? 'auto',

    '&.large': {
      height: '48px',
    },
    '&.medium': {
      height: '40px',
    },

    '&.icon-left': {
      paddingLeft: '28px',

      '.MuiButton-startIcon': {
        paddingRight: 0,
        marginRight: '4px',
      },
    },
    '&.icon-right': {
      paddingRight: '28px',

      '.MuiButton-endIcon': {
        paddingLeft: 0,
        marginLeft: '4px',
      },
    },

    '&.primary': {
      backgroundColor: defaultColor,
      border: `1px solid ${theme.new.color.border}`,
      color: theme.new.color.common.white,

      '&.fill-icon': {
        fill: theme.new.color.common.white,
      },

      '&.stroke-icon': {
        stroke: theme.new.color.common.white,
      },

      '&:hover': {
        backgroundColor: hoverColor,
      },

      '&:active': {
        backgroundColor: activeColor,
      },

      '&.Mui-disabled': {
        backgroundColor: props.destructive
          ? disabledColor
          : theme.new.color.grey[300],
        color: props.destructive ? theme.new.color.common.white : disabledColor,

        '&.fill-icon': {
          fill: disabledColor,
        },

        '&.stroke-icon': {
          stroke: disabledColor,
        },
      },
    },

    '&.secondary': {
      backgroundColor: 'none',
      border: `1px solid ${defaultColor}`,
      color: defaultColor,

      '&.fill-icon': {
        fill: defaultColor,
      },

      '&.stroke-icon': {
        stroke: defaultColor,
      },

      '&:hover': {
        border: `1px solid ${hoverColor}`,
        color: hoverColor,

        '&.fill-icon': {
          fill: hoverColor,
        },

        '&.stroke-icon': {
          stroke: hoverColor,
        },
      },

      '&:active': {
        border: `1px solid ${activeColor}`,
        color: activeColor,

        '&.fill-icon': {
          fill: activeColor,
        },

        '&.stroke-icon': {
          stroke: activeColor,
        },
      },

      '&.Mui-disabled': {
        borderColor: disabledColor,
        color: disabledColor,

        '&.fill-icon': {
          fill: disabledColor,
        },

        '&.stroke-icon': {
          stroke: disabledColor,
        },
      },
    },

    '&.tertiary': {
      backgroundColor: 'none',
      border: 'none',
      color: defaultColor,

      '&.fill-icon': {
        fill: defaultColor,
      },

      '&.stroke-icon': {
        stroke: defaultColor,
      },

      '&:hover': {
        color: hoverColor,

        '&.fill-icon': {
          fill: hoverColor,
        },

        '&.stroke-icon': {
          stroke: hoverColor,
        },
      },

      '&:active': {
        color: activeColor,

        '&.fill-icon': {
          fill: activeColor,
        },

        '&.stroke-icon': {
          stroke: activeColor,
        },
      },

      '&.Mui-disabled': {
        color: disabledColor,

        '&.fill-icon': {
          fill: disabledColor,
        },

        '&.stroke-icon': {
          stroke: disabledColor,
        },
      },
    },

    '&:focus-visible': {
      boxShadow: `0px 0px 0px 2px ${theme.new.color.common.white}, 0px 0px 0px 4px ${theme.new.color.brand[500]}`,
    },

    '&:not(.custom-icon-colors)': {
      '&.fill-icon': {
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          'svg, path': {
            fill: 'inherit !important',
            stroke: 'none',
          },
        },
      },

      '&.stroke-icon': {
        '.MuiButton-startIcon, .MuiButton-endIcon': {
          'svg, path': {
            stroke: 'inherit !important',
            fill: 'none',
          },
        },
      },
    },

    '&.loading': {
      path: {
        fill: 'none !important',
      },

      svg: {
        animation: 'spin-animation 1s infinite',
      },
    },

    '&.Mui-disabled': {
      cursor: 'default',
    },

    //TODO: Remove this when we have a design component for link buttons
    '&.no-padding': {
      padding: 0,
      minWidth: '45px',
    },

    '@keyframes spin-animation': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(359deg)',
      },
    },
  };
};

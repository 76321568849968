import { InvitationStatus } from '@gm-commercial/profile-model';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  invitationcellWrapper: {
    padding: '2px 8px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    width: 'auto',
    [`&.${InvitationStatus.ACCEPTED}-invitationStatus`]: {
      border: `1px solid ${theme.new.color.success[600]}`,
      backgroundColor: theme.new.color.success[100],
      color: theme.new.color.success[600],
    },
    [`&.${InvitationStatus.SENT}-invitationStatus`]: {
      border: `1px solid ${theme.new.color.warning[700]}`,
      backgroundColor: theme.new.color.warning[100],
      color: theme.new.color.warning[700],
    },
    [`&.${InvitationStatus.EXPIRED}-invitationStatus`]: {
      border: `1px solid ${theme.new.color.textTertiary}`,
      backgroundColor: theme.new.color.common.white,
      color: theme.new.color.textTertiary,
    },
    [`&.${InvitationStatus.CANCELLED}-invitationStatus`]: {
      border: `1px solid ${theme.new.color.textTertiary}`,
      backgroundColor: theme.new.color.grey[100],
      color: theme.new.color.textTertiary,
    },
  },
}));

export default useStyles;

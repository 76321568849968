import {
  BatterySocStatus,
  BrakePadStatus,
  FuelStatus,
  OilLifeStatus,
} from '~/common/models/asset-report.model';

export const MIN_VALID_FUEL_VALUE = 0;
export const MAX_VALID_FUEL_VALUE = 100;

type FuelChargeStatusValue = {
  start?: number;
  end?: number;
  inclusiveStart?: boolean;
  inclusiveEnd?: boolean;
};

//Filter modal status label
export const FUEL_CHARGE_FILTER_MODAL_STATUS: {
  [k in FuelStatus | BatterySocStatus]: FuelChargeStatusValue;
} = {
  [FuelStatus.CRITICAL_FUEL || BatterySocStatus.CRITICAL_CHARGE]: {
    end: 20,
    inclusiveEnd: true,
  },
  [FuelStatus.LOW_FUEL]: {
    start: 21,
    end: 50,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [FuelStatus.MEDIUM_FUEL]: {
    start: 51,
    end: 80,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [FuelStatus.HIGH_FUEL]: {
    start: 81,
    inclusiveStart: true,
  },
  [BatterySocStatus.LOW_CHARGE]: {
    start: 21,
    end: 50,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [BatterySocStatus.MEDIUM_CHARGE]: {
    start: 51,
    end: 80,
    inclusiveStart: true,
    inclusiveEnd: true,
  },
  [BatterySocStatus.HIGH_CHARGE]: {
    start: 81,
    inclusiveStart: true,
  },
};

export const OIL_LIFE_FILTER_MODAL_STATUS: {
  [k in OilLifeStatus]: {
    start?: number;
    end?: number;
  };
} = {
  [OilLifeStatus.GOOD]: {
    start: 21,
  },
  [OilLifeStatus.IMPAIRED]: {
    start: 6,
    end: 20,
  },
  [OilLifeStatus.CRITICAL]: {
    end: 5,
  },
};

export const BRAKE_PAD_FILTER_MODAL_STATUS: {
  [k in BrakePadStatus]: {
    start?: number;
    end?: number;
  };
} = {
  [OilLifeStatus.GOOD]: {
    start: 25,
  },
  [OilLifeStatus.IMPAIRED]: {
    start: 15,
    end: 25,
  },
  [OilLifeStatus.CRITICAL]: {
    end: 15,
  },
};

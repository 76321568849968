import { MRT_Cell, MRT_Row } from '@brightdrop/table-ui';
import { User } from '@gm-commercial/profile-model';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_EMPTY_VALUE,
} from '~/common/constants/common.constant';

interface UserLastSignedInColumnCellProps {
  cell: MRT_Cell<User, string>;
  row: MRT_Row<User>;
}

const UserLastSignedInColumnCell = ({
  cell,
}: UserLastSignedInColumnCellProps) => {
  const date = cell.getValue();
  //LastSignedInDate will be an Instant Class in Java, hence the string will be in an ISOString format
  const parsedDate = DateTime.fromISO(date);

  // Format the DateTime object to MM/DD/YYYY
  const updatedDate = parsedDate.toFormat(DEFAULT_DATE_FORMAT);

  return (
    <Box display="flex" data-testid="user-list-last-signed-in-column">
      {/*Luxon will automatically result in Invalid Datetime when there's an error in the above conversions */}
      {/*Hence, we need to make sure the actual date is not an empty string to begin with */}
      {date !== DEFAULT_EMPTY_VALUE ? updatedDate : DEFAULT_EMPTY_VALUE}
    </Box>
  );
};

export default UserLastSignedInColumnCell;

import { NavBarConfig } from '@gm-commercial/navbar-model';
import { Profile, Role } from '@gm-commercial/profile-model';
import { Dispatch } from 'redux';

import {
  ADMIN_ROLES,
  FLEET_EXPERIENCE_ROLES,
} from '~/common/constants/common.constant';
import { AppNames } from '~/common/models/appNames.model';
import { FeatureFlagValueType } from '~/common/models/featureFlags.model';

import { NAV_DRAWER_MESSAGES } from '../GlobalNavDrawer.messages';
import { getAccountNavBarConfig } from './account.navDrawer.config';
import { getFleetNavBarConfig } from './fleet.navDrawer.config';
import {
  getLegacyNavDrawerConfig,
  LegacyPath,
} from './legacy.navDrawer.config';
import { getSettingsNavBarConfig } from './settings.navDrawer.config';
import { getSupportNavBarConfig } from './support.navDrawer.config';

export const getNavDrawerConfig = ({
  messages,
  profileParams,
  hasRole,
  appName,

  dispatch,
  sessionId,
  findFlagScope,
}: {
  messages: typeof NAV_DRAWER_MESSAGES;
  profileParams: Profile['permissionsContextIds'];
  hasRole: (authorizedRoles: Role[]) => boolean;
  appName?: AppNames;
  dispatch?: Dispatch;
  sessionId?: string;
  findFlagScope?: (flagName: string) => FeatureFlagValueType | undefined;
}): NavBarConfig => {
  // If no appName is provided, return an empty config
  if (!appName) {
    return { items: [] };
  }
  switch (appName) {
    case AppNames.ACCOUNT:
      return getAccountNavBarConfig({
        messages: messages.accountMenu,
        profileParams,
        findFlagScope,
        hasRole,
      });
    case AppNames.SETTINGS:
      return getSettingsNavBarConfig({
        messages: messages.settingsMenu,
        profileParams,
        findFlagScope,
      });
    case AppNames.SUPPORT:
      return getSupportNavBarConfig({
        messages: messages.supportMenu,
        findFlagScope,
      });
    default: {
      if (hasRole(FLEET_EXPERIENCE_ROLES)) {
        return getFleetNavBarConfig({
          messages: messages.fleetMenu,
          profileParams,
          findFlagScope,
          dispatch,
          sessionId,
        });
      }
      if (hasRole(ADMIN_ROLES)) {
        return getLegacyNavDrawerConfig({
          messages: messages.legacyMenu,
          profileParams,
          sessionId,
          dispatch,
          findFlagScope,
        });
        // If appName is not recognized or user Role condition is not defined  , return an empty config
      } else return { items: [] };
    }
  }
};

export const getAppNameFromPath = (path: string) => {
  const appName = Object.values(AppNames).find((appName) =>
    path.trim().startsWith(`/${appName}`)
  );
  // Ensures legacy views still show the old nav drawer items
  if (!appName) {
    if (
      Object.values(LegacyPath).find((legacyPath) =>
        path.trim().startsWith(`/${legacyPath}`)
      )
    ) {
      return AppNames.LEGACY;
    } else {
      //Ensures that pages outside the fleet but are still valid (e.g. unauthorized, missing permissions, etc.)
      //don't show a blank space for the navigation
      return AppNames.FLEET;
    }
  }
  return appName;
};

import { DEFAULT_API_CONFIG } from '~/common/apis/api.constants';
import { ApiResponse } from '~/common/models/apis/apiResponse.model';
import { BDError } from '~/common/models/error.model';
import {
  Trip,
  TripEvent,
  TripEventsApiResponse,
  TripsApiResponse,
  VideoRequestIdApiResponse,
  VideoRequestStatusApiResponse,
  VideoUrlApiResponse,
} from '~/common/models/trip.model';
import {
  addAcceptLanguageHeader,
  addHeader,
  hasApiResult,
} from '~/common/utils/apis/api.utils';
import {
  makeGetPayloadCreator,
  makePostPayloadCreator,
  makeThunk,
} from '~/common/utils/store/thunk.helper';

import { mapTripDetails, mapTripEvents } from '../mappers/tripHistory.mappers';
import {
  buildFetchEventsRequestParams,
  buildFetchTripsRequestParams,
} from '../utils/tripHistoryQuery.utils';

export enum TripHistoryActionType {
  FETCH_TRIPS = 'tripHistory/fetchTrips',
  SELECT_TRIP = 'tripHistory/selectTrip',
  SELECT_EVENT = 'tripHistory/selectEvent',
  FETCH_TOKEN = 'tripHistory/fetchToken',
  FETCH_EVENTS = 'tripHistory/fetchEvents',
  FETCH_VIDEO_REQUEST_ID = 'tripHistory/fetchVideoRequestId',
  FETCH_VIDEO_REQUEST_STATUS = 'tripHistory/fetchVideoRequestStatus',
  FETCH_VIDEO_URL = 'tripHistory/fetchVideoUrl',
}

const CONFIG = DEFAULT_API_CONFIG;

type TripsParams = {
  tenantUniqueName: string;
  token: string;
  startTime: Date;
  endTime: Date;
  vehicleNumbers?: string[];
  vins?: string[];
  vin?: string;
};

type VideoRequestParams = Pick<TripsParams, 'tenantUniqueName' | 'token'> & {
  eventId?: string;
  requestId?: string;
  videoId?: string;
};

const fetchToken = makeThunk(
  TripHistoryActionType.FETCH_TOKEN,
  makeGetPayloadCreator<ApiResponse<string>, void>({
    url: `${globalThis.appConfig.apiBaseUrl}/peripherals/netradyne/access-token/2025-02-12-BETA`,
    responseAdapter: (response: unknown | ApiResponse<unknown>) => {
      if (!!response && hasApiResult<string>(response)) {
        return {
          ...response,
          result: 'facc6c78-ae8d-4ba4-a2dc-dcc6aa1e9863', // For immediate demo purposes
        };
      }
      throw new BDError('Unexpected fetch token response', {
        data: response,
      });
    },
  })
);

const fetchTrips = makeThunk(
  TripHistoryActionType.FETCH_TRIPS,
  makeGetPayloadCreator<Trip[], TripsParams>({
    url: ({ tenantUniqueName }) =>
      `https://api.netradyne.com/driveri/v1/tenants/${tenantUniqueName}/trips`,
    axiosOptions: ({ token }, state) =>
      addAcceptLanguageHeader(
        addHeader(CONFIG, 'Authorization', `Bearer ${token}`),
        state.profile.currentLocale
      ),
    argAdapter: ({ startTime, endTime, vehicleNumbers, vins }) => ({
      requestParams: buildFetchTripsRequestParams({
        startTime,
        endTime,
        vehicleNumbers,
        vins,
      }),
    }),
    responseAdapter: (response: unknown) => {
      if (response && typeof response === 'object' && 'data' in response) {
        return mapTripDetails(response as TripsApiResponse);
      }
      throw new BDError('Unexpected fetch trips response', {
        data: response,
      });
    },
  })
);

const fetchEvents = makeThunk(
  TripHistoryActionType.FETCH_EVENTS,
  makeGetPayloadCreator<TripEvent[], TripsParams>({
    url: ({ tenantUniqueName }) =>
      `https://api.netradyne.com/driveri/v1/tenants/${tenantUniqueName}/alerts`,
    axiosOptions: ({ token }, state) =>
      addAcceptLanguageHeader(
        addHeader(CONFIG, 'Authorization', `Bearer ${token}`),
        state.profile.currentLocale
      ),
    argAdapter: ({ startTime, endTime, vin }) => ({
      requestParams: buildFetchEventsRequestParams({
        fromDate: startTime,
        toDate: endTime,
        vinNumber: vin || '',
      }),
    }),
    responseAdapter: (response: unknown) => {
      if (response && typeof response === 'object' && 'data' in response) {
        return mapTripEvents(response as TripEventsApiResponse);
      }
      throw new BDError('Unexpected fetch events response', {
        data: response,
      });
    },
  })
);

const fetchVideoRequestId = makeThunk(
  TripHistoryActionType.FETCH_VIDEO_REQUEST_ID,
  makePostPayloadCreator<number | null, VideoRequestParams>({
    url: ({ tenantUniqueName, eventId }) =>
      `https://api.netradyne.com/driveri/v1/tenants/${tenantUniqueName}/videoRequestByAlertId/${eventId}`,
    axiosOptions: ({ token }, state) =>
      addAcceptLanguageHeader(
        addHeader(CONFIG, 'Authorization', `Bearer ${token}`),
        state.profile.currentLocale
      ),
    responseAdapter: (response: unknown) => {
      if (response && typeof response === 'object' && 'data' in response) {
        const { requestId } = (response as VideoRequestIdApiResponse).data;
        return requestId;
      }
      throw new BDError('Unexpected fetch video request id response', {
        data: response,
      });
    },
  })
);

const fetchVideoRequestStatus = makeThunk(
  TripHistoryActionType.FETCH_VIDEO_REQUEST_STATUS,
  makeGetPayloadCreator<string | null, VideoRequestParams>({
    url: ({ tenantUniqueName, requestId }) =>
      `https://api.netradyne.com/driveri/v1/tenants/${tenantUniqueName}/requests/${requestId}`,
    axiosOptions: ({ token }, state) =>
      addAcceptLanguageHeader(
        addHeader(CONFIG, 'Authorization', `Bearer ${token}`),
        state.profile.currentLocale
      ),
    responseAdapter: (response: unknown) => {
      if (response && typeof response === 'object' && 'data' in response) {
        const { videos } = (response as VideoRequestStatusApiResponse).data;
        return videos.length > 0 ? videos[0].id.toString() : null;
      }
      throw new BDError('Unexpected fetch video request status response', {
        data: response,
      });
    },
  })
);

const fetchVideoUrl = makeThunk(
  TripHistoryActionType.FETCH_VIDEO_URL,
  makeGetPayloadCreator<string | null, VideoRequestParams>({
    url: ({ tenantUniqueName, videoId }) =>
      `https://api.netradyne.com/driveri/v1/tenants/${tenantUniqueName}/videoplayurl/${videoId}`,
    axiosOptions: ({ token }, state) =>
      addAcceptLanguageHeader(
        addHeader(CONFIG, 'Authorization', `Bearer ${token}`),
        state.profile.currentLocale
      ),
    responseAdapter: (response: unknown) => {
      if (response && typeof response === 'object' && 'data' in response) {
        const { urls } = (response as VideoUrlApiResponse).data;
        return urls.length > 0 ? urls[0].url : null;
      }
      throw new BDError('Unexpected fetch video url response', {
        data: response,
      });
    },
  })
);

export const TRIP_HISTORY_ACTIONS = {
  fetchToken,
  fetchTrips,
  fetchEvents,
  fetchVideoRequestId,
  fetchVideoRequestStatus,
  fetchVideoUrl,
};

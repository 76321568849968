import { cx } from '@emotion/css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Accordion, AccordionSummary, Box, Link } from '@mui/material';
import { Location } from 'history';
import { MUIDataTableMeta } from 'mui-datatables';
import { HTMLAttributeAnchorTarget, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { RouteParams } from '../../../configs/route.config';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_EMPTY_VALUE,
  LanguageLocale,
} from '../../../constants/common.constant';
import { Entity } from '../../../models/common.model';
import { RouteConfig } from '../../../models/route.model';
import { BDTableData } from '../../../models/table/table.model';
import { format } from '../../../utils/date-time.utils';
import { makeRoutePath } from '../../../utils/route/route.utils';

export const dateRenderer = (
  value: string | Date | BDTableData,
  dateFormat = DEFAULT_DATE_FORMAT,
  locale: string = LanguageLocale.EN,
  timeZone?: string
): string => {
  // Using instanceof to check for Date values could produce unexpected results
  // if value is a malformed Date; consider restricting param types to string | BDTableData
  if (
    value &&
    value !== DEFAULT_EMPTY_VALUE &&
    (typeof value === 'string' || value instanceof Date)
  ) {
    return (
      (value && format(new Date(value), dateFormat, locale, timeZone)) ||
      DEFAULT_EMPTY_VALUE
    );
  }
  return DEFAULT_EMPTY_VALUE;
};

/**
 *
 * @param displayValue link text
 * @param href href to use for link
 * @param to react-router-dom value representing link destination; ignored if href is provided
 * @returns react link component
 */
export const getLinkRenderer = ({
  displayValue,
  href,
  to,
  alwaysLink = false,
  target,
}: {
  displayValue: string;
  href?: string;
  to?: string | Location | ((loc: Location) => string | Location);
  alwaysLink?: boolean;
  target?: HTMLAttributeAnchorTarget;
}): JSX.Element => {
  if (alwaysLink || (displayValue && displayValue !== DEFAULT_EMPTY_VALUE)) {
    if (href) {
      return (
        <Link href={href} title={displayValue} target={target}>
          {displayValue}
        </Link>
      );
    } else if (to) {
      return (
        <Link
          to={to}
          title={displayValue}
          component={RouterLink}
          target={target}
        >
          {displayValue}
        </Link>
      );
    } else {
      return <>{displayValue}</>;
    }
  }
  return <>{DEFAULT_EMPTY_VALUE}</>;
};

export const EmailLinkRenderer = (value: string): JSX.Element => {
  return (
    <Box component="div" className="emailAddress">
      {getLinkRenderer({ displayValue: value, href: `mailto:${value}` })}
    </Box>
  );
};

export const EmailTextRenderer = (value: string): JSX.Element => {
  return (
    <Box component="div" className="emailAddress">
      {value}
    </Box>
  );
};

export type ParamAdapterType<T> = (
  value: T,
  tableMeta: MUIDataTableMeta
) => RouteParams;

export const getRouteLinkRenderer = <ValueType,>({
  route,
  exact = false,
  valueAdapter = (value: ValueType) => `${value}`,
  paramAdapter = () => ({}),
  alwaysLink = false,
  disableRouting = false,
  target,
  withQueryParams = false,
}: {
  route: string | RouteConfig;
  exact?: boolean;
  valueAdapter?: (value: ValueType) => string;
  paramAdapter?: ParamAdapterType<ValueType>;
  alwaysLink?: boolean;
  disableRouting?: boolean;
  target?: HTMLAttributeAnchorTarget;
  withQueryParams?: boolean;
}): ((value: ValueType, tableMeta: MUIDataTableMeta) => JSX.Element) => {
  const RouteLinkRenderer = (
    value: ValueType,
    tableMeta: MUIDataTableMeta
  ): JSX.Element => {
    const displayValue = valueAdapter(value);
    const routeParams: RouteParams = paramAdapter(value, tableMeta);

    return getLinkRenderer({
      displayValue,
      to: !disableRouting
        ? makeRoutePath(route, routeParams, exact, withQueryParams)
        : undefined,
      alwaysLink,
      target,
    });
  };
  return RouteLinkRenderer;
};

export const iconRenderer = <ValueType, AdaptedType>({
  valueAdapter = (value: ValueType) => `${value}`,
  keyAdapter,
  iconConfig,
  otherDisplay = false,
  renderOther = (status?: string | AdaptedType) => {
    const display = status ? <>{status}</> : <>{DEFAULT_EMPTY_VALUE}</>;
    return display;
  },
  withValue,
}: {
  valueAdapter?: (
    value: ValueType,
    tableMeta?: MUIDataTableMeta
  ) => string | AdaptedType;
  keyAdapter?: (
    value: ValueType,
    tableMeta?: MUIDataTableMeta
  ) => string | AdaptedType;
  iconConfig?: { [key: string]: ReactElement };
  otherDisplay?: boolean;
  renderOther?: (
    status: string | AdaptedType,
    tableMeta?: MUIDataTableMeta
  ) => ReactElement;
  withValue?: boolean;
}): ((value: ValueType, tableMeta: MUIDataTableMeta) => JSX.Element) => {
  const IconRenderer = (
    value: ValueType,
    tableMeta: MUIDataTableMeta
  ): JSX.Element => {
    const adaptedValue = valueAdapter(value, tableMeta);
    const status = keyAdapter ? keyAdapter(value, tableMeta) : adaptedValue;

    return (
      <Box className={cx('iconDisplay', status as string)}>
        {otherDisplay
          ? renderOther(adaptedValue, tableMeta)
          : iconConfig && typeof status === 'string' && status in iconConfig
            ? iconConfig[status]
            : DEFAULT_EMPTY_VALUE}
        {withValue && <Box ml={1}>{adaptedValue}</Box>}
      </Box>
    );
  };
  return IconRenderer;
};

export const entityNameRenderer = <T extends Entity>(
  value: T | BDTableData
): string => {
  if (typeof value !== 'string') {
    if (value?.name && typeof value.name !== 'object') {
      return value.name;
    }
  }
  return DEFAULT_EMPTY_VALUE;
};

export const getCustomFaultDetailToggle = ({
  rowIndex,
  handleFaultToggle,
  expandedRows = [],
}: {
  rowIndex: number;
  handleFaultToggle: (rowIndex: number) => void;
  expandedRows?: number[];
}): JSX.Element => {
  const handleChange = (rowIndex: number) => () => {
    handleFaultToggle(rowIndex);
  };

  const rowExpanded = expandedRows && expandedRows.indexOf(rowIndex) !== -1;

  const display = (
    <Accordion
      expanded={rowExpanded}
      className="accordion"
      onChange={handleChange(rowIndex)}
    >
      <AccordionSummary
        expandIcon={
          <div className="circle">
            <ArrowDropUpIcon />
          </div>
        }
      ></AccordionSummary>
    </Accordion>
  );
  return display;
};

import { useTranslations } from '@brightdrop/localization-client';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useAppDispatch, useAppSelect } from '~/app/store';
import useInfiniteScroll from '~/common/hooks/useInfiniteScroll';
import usePageTitle from '~/common/hooks/usePageTitle';
import {
  setAlerts,
  useGetAlertsForCurrentUserQuery,
} from '~/features/alerts/alertsSlice';
import { selectHasMoreAlerts } from '~/features/alerts/alertsSlice.selectors';
import { NOTIFICATION_SETTINGS_MESSAGES } from '~/features/settings/settings.messages';

import {
  NotificationList,
  NotificationListSkeleton,
  NotificationTabs,
  SeeAllButton,
} from './components';
import { useStyles } from './Notifications.styles';

export enum TabType {
  all = 'all',
  unread = 'unread',
}

type Props = Partial<RouteComponentProps> & {
  context?: 'popover' | 'page';
  enableSeeAllBtn?: boolean;
};

const Notifications = ({
  context = 'page',
  enableSeeAllBtn = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const hasMoreAlerts = useAppSelect(selectHasMoreAlerts);
  const [tabValue, setTabValue] = useState(TabType.all);
  const [token, setToken] = useState<string | undefined>(undefined);

  const { translations } = useTranslations(NOTIFICATION_SETTINGS_MESSAGES);
  const { classes } = useStyles();
  const { data, isLoading } = useGetAlertsForCurrentUserQuery(
    {
      size: 20,
      'cs-continuation-token': token,
    },
    { refetchOnMountOrArgChange: true }
  );

  const loadMore = () => {
    if (
      !hasMoreAlerts ||
      isLoading ||
      tabValue === TabType.unread ||
      !data?.result?.continuation_token
    ) {
      return;
    }

    setToken(data.result.continuation_token);
  };

  const { elementRef } = useInfiniteScroll<HTMLUListElement>({
    callbackFn: loadMore,
    intersectProps: { threshold: 0.5 },
  });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: TabType) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    dispatch(
      setAlerts({
        alerts: data?.result?.items ?? [],
        totalAlerts: data?.result?.total_items ?? 0,
      })
    );
  }, [isLoading, dispatch, data?.result?.items, data?.result?.total_items]);

  usePageTitle(translations['settings:notifications.title']);

  return (
    <Box className={classes['rt--notifications--notifications--box-container']}>
      <NotificationTabs tabValue={tabValue} handleChangeTab={handleChangeTab} />
      <NotificationListSkeleton show={isLoading} />
      <NotificationList
        tabValue={tabValue}
        ulRef={elementRef}
        loading={isLoading}
        fixedWidth={enableSeeAllBtn}
        context={context}
      />
      {enableSeeAllBtn && <SeeAllButton />}
    </Box>
  );
};

export default Notifications;

import { AuthenticationType } from '~/features/session/model/authentication.model';

export const getDefaultProfileMenu = (
  authenticationType: AuthenticationType
) => [
  {
    label: 'common:header.signOut',
    testId: 'logout-menu',
    onClick: { redirect: `/auth/${authenticationType}/logout` },
    section: 'bottom',
  },
];

import {
  configureEntitlementsClient,
  entitlementsApi,
} from '@gm-commercial/entitlements-client';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { alertsApi, assetsApi } from '~/api';
import { VehicleOrdersActionType } from '~/features/orders/state/vehicleOrdersSlice.actions';

import { InspectionsActionType } from '../features/inspections/inspectionsSlice.model';
import { rootReducer, RootState } from './rootReducer';

/**
 * All reducers either omitted from persistence, or with customized persistence, must be blacklisted
 */
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'auth',
    'profile',
    'organizations',
    'hubs',
    'fleets',
    'assets',
    'peripherals',
    'users',
    'map',
    'navDrawer',
    'dashboard',
    'alerts',
    'utilization',
    'dataIntegrations',
    'featureFlags',
    'orders',
    'inspections',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          `${InspectionsActionType.GET_INSPECTION_LONG_TERM_REPORT}/fulfilled`, // returns blob
          `${VehicleOrdersActionType.GET_ORDER_LIST_EXPORT}/fulfilled`, //returns blob
        ],
      },
    }).concat([
      alertsApi.middleware,
      entitlementsApi.middleware,
      assetsApi.middleware,
    ]),
});

export const persistor = persistStore(store);

configureEntitlementsClient({
  apiBaseUrl: globalThis.appConfig.apiBaseUrl,
  authProvider: () => store.getState().auth.authToken?.value,
});

setupListeners(store.dispatch);

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelect: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;

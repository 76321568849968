import {
  MRT_Cell,
  MRT_Column,
  type MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from '@brightdrop/table-ui';
import { User } from '@gm-commercial/profile-model';
import { HTMLAttributeAnchorTarget, ReactNode, RefObject } from 'react';

import {
  EmailTextRenderer,
  entityNameRenderer,
  getLinkRenderer,
} from '~/common/components/table/renderers/columnRenderer';
import {
  getFormattedPhone,
  getFullAddress,
} from '~/common/components/table/utils/table.utils';
import { translateText } from '~/common/components/TranslatedLabel/TranslatedLabel';
import { RouteParams } from '~/common/configs/route.config';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { ActivationStatus, Entity } from '~/common/models/common.model';
import { RouteConfig, RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

import UserFleetColumnCell from './cells/UserFleetColumnCell';
import UserInvitationStatusColumnCell from './cells/UserInvitationStatusColumnCell';
import UserLastSignedInColumnCell from './cells/UserLastSignedInColumnCell';
import UserNameColumnCell from './cells/UserNameColumnCell';
import UserOrgColumnCell from './cells/UserOrgColumnCell';

type UserListParams = Pick<
  RouteParams,
  'organizationsId' | 'hubsId' | 'fleetsId'
>;

export const DEFAULT_COLUMN_SIZE = 100;

export const USER_LIST_ADAPTERS = {
  organization: entityNameRenderer,
  address: getFullAddress,
  hub: entityNameRenderer,
  fleet: entityNameRenderer,
  phoneNumber: getFormattedPhone,
};

export const getMRTRouteLinkRenderer = <
  ValueType extends string,
  RowType extends Record<string, any>,
>({
  route,
  exact = false,
  valueAdapter = (value: ValueType) => `${value}`,
  paramAdapter = () => ({}),
  alwaysLink = false,
  disableRouting = false,
  target,
  withQueryParams = false,
}: {
  route: string | RouteConfig;
  exact?: boolean;
  valueAdapter?: (value: ValueType) => string;
  paramAdapter?: (value: ValueType, tableMeta: RowType) => RouteParams;
  alwaysLink?: boolean;
  disableRouting?: boolean;
  target?: HTMLAttributeAnchorTarget;
  withQueryParams?: boolean;
}): ((props: {
  cell: MRT_Cell<RowType, ValueType>;
  column: MRT_Column<RowType, ValueType>;
  renderedCellValue: ReactNode;
  row: MRT_Row<RowType>;
  rowRef?: RefObject<HTMLTableRowElement>;
  staticColumnIndex?: number;
  staticRowIndex?: number;
  table: MRT_TableInstance<RowType>;
}) => ReactNode) => {
  const RouteLinkRenderer = (props: {
    cell: MRT_Cell<RowType, ValueType>;
    column: MRT_Column<RowType, ValueType>;
    renderedCellValue: ReactNode;
    row: MRT_Row<RowType>;
    rowRef?: RefObject<HTMLTableRowElement>;
    staticColumnIndex?: number;
    staticRowIndex?: number;
    table: MRT_TableInstance<RowType>;
  }): JSX.Element => {
    const displayValue = valueAdapter(props.cell.getValue());
    const routeParams: RouteParams = paramAdapter(
      props.cell.getValue(),
      props.row.original
    );

    return getLinkRenderer({
      displayValue,
      to: !disableRouting
        ? makeRoutePath(route, routeParams, exact, withQueryParams)
        : undefined,
      alwaysLink,
      target,
    });
  };
  return RouteLinkRenderer;
};

const NAME_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'displayName',
  header: translateText('user:name'),
  minSize: 240,
  maxSize: 240,
  size: 240,
  Cell: UserNameColumnCell,
});

const ACCOUNT_LIST_NAME_COLUMN = (): MRT_ColumnDef<User, string> => ({
  ...NAME_COLUMN(),
  Cell: ({ cell, row }) =>
    UserNameColumnCell({
      cell,
      row,
      showUserDetailLink: false,
      showEmail: false,
    }),
});

const ROLE_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'role',
  header: translateText('user:role'),
  Cell: ({ cell }) => translateText(`common:roleList.${cell.getValue()}`),
});

const PHONE_NUMBER_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'phoneNumber',
  header: translateText('user:phone'),
  Cell: ({ cell }) => getFormattedPhone(cell.getValue()),
});

const EMAIL_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'email',
  header: translateText('common:email'),
  Cell: ({ cell }) => EmailTextRenderer(cell.getValue()),
});

const ADDRESS_COLUMN = (): MRT_ColumnDef<User, string> => ({
  id: 'address',
  accessorFn: (row) =>
    row?.address ? getFullAddress(row.address) : DEFAULT_EMPTY_VALUE,
  header: translateText('user:address'),
});

const STATUS_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'status',
  header: translateText('common:status'),
  Cell: ({ cell }) =>
    translateText(
      `common:propertyState.${cell.getValue() as ActivationStatus}`
    ),
});

const ORG_COLUMN = (tenants?: Entity[]): MRT_ColumnDef<User, string> => ({
  header: translateText('user:organization'),
  accessorKey: 'organization',
  minSize: 240,
  maxSize: 240,
  size: 240,
  Cell: (props) => <UserOrgColumnCell {...props} tenants={tenants} />,
});

const HUB_COLUMN = (params?: UserListParams): MRT_ColumnDef<User, string> => ({
  accessorFn: (row) => (row?.hub?.name ? row.hub.name : DEFAULT_EMPTY_VALUE),
  id: 'hub',
  header: translateText('common:hub'),
  Cell: getMRTRouteLinkRenderer<string, User>({
    route: findRoute(RouteName.HUB_DETAILS),
    paramAdapter: (_name: string, tableMeta: User) => ({
      organizationsId: params?.organizationsId || tableMeta?.organization?.id,
      hubsId: params?.hubsId || tableMeta?.hub?.id,
    }),
  }),
});

const FLEET_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorKey: 'fleet',
  header: translateText('common:fleet'),
  Cell: UserFleetColumnCell,
});

const LAST_SIGNED_IN_COLUMN = (): MRT_ColumnDef<User, string> => ({
  accessorFn: (row) => row?.lastSignedInDate ?? DEFAULT_EMPTY_VALUE,
  accessorKey: 'lastSignedInDate',
  header: translateText('user:lastSignedIn'),
  size: 110,
  maxSize: 110,
  Cell: UserLastSignedInColumnCell,
});

const INVITATION_STATUS_COLUMN = (): MRT_ColumnDef<User, string> => ({
  //if users have an undefined status for their invite, then it means it's a legacy account or has been whitelisted
  accessorFn: (row) => row?.invite?.status || DEFAULT_EMPTY_VALUE,
  accessorKey: 'invite.status',
  header: translateText('user:invitationStatus'),
  size: 110,
  maxSize: 110,
  Cell: UserInvitationStatusColumnCell,
});

// User List for ALL Organizations
const ALL_ORGS_COLUMNS = (): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  ORG_COLUMN(),
  EMAIL_COLUMN(),
  PHONE_NUMBER_COLUMN(),
  ADDRESS_COLUMN(),
  STATUS_COLUMN(),
];

//Org Detail => User List for specific Organization
const SINGLE_ORG_COLUMNS = (
  params?: UserListParams
): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  HUB_COLUMN(params),
  FLEET_COLUMN(),
  EMAIL_COLUMN(),
  PHONE_NUMBER_COLUMN(),
  ADDRESS_COLUMN(),
  STATUS_COLUMN(),
];

//Organization Fleet Detail => User List for specific Fleet
const ORG_FLEET_COLUMNS = (
  params?: UserListParams
): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  HUB_COLUMN(params),
  FLEET_COLUMN(),
  EMAIL_COLUMN(),
  PHONE_NUMBER_COLUMN(),
  ADDRESS_COLUMN(),
  STATUS_COLUMN(),
];

//Organization Hub Detail => User List for specific Hub
const ORG_HUB_COLUMNS = (
  params?: UserListParams
): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  HUB_COLUMN(params),
  FLEET_COLUMN(),
  EMAIL_COLUMN(),
  PHONE_NUMBER_COLUMN(),
  ADDRESS_COLUMN(),
  STATUS_COLUMN(),
];

//Organization to Hub to Fleet Detail => User List is for specific Fleet and Hub
const ORG_HUB_FLEET_COLUMNS = (
  params?: UserListParams
): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  HUB_COLUMN(params),
  FLEET_COLUMN(),
  EMAIL_COLUMN(),
  PHONE_NUMBER_COLUMN(),
  ADDRESS_COLUMN(),
  STATUS_COLUMN(),
];

const SINGLE_ORG_USER_LIST_COLUMNS = (): MRT_ColumnDef<User, string>[] => [
  EMAIL_COLUMN(),
  ACCOUNT_LIST_NAME_COLUMN(),
  ROLE_COLUMN(),
  FLEET_COLUMN(),
  INVITATION_STATUS_COLUMN(),
  LAST_SIGNED_IN_COLUMN(),
];

export const getUserListColumnConfig = (
  params: UserListParams
): MRT_ColumnDef<User, string>[] => {
  // Route params determine which view to display
  const { organizationsId, hubsId, fleetsId } = params;

  /* 5 current routing scenarios to display the different User List View
  1 - No Org Id => All Organizations
  2 - Org Id => Specific Organization
  3 - Org Id to Hub Id => Specific Hub under Org
  4 - Org Id to Fleet Id => Specific Fleet under Org
  5 - Org Id to Hub Id and Fleet Id => Specific Hub and Fleet under Org
  */
  // return [ADDRESS_COLUMN(translations)];
  return organizationsId
    ? hubsId
      ? fleetsId
        ? ORG_HUB_FLEET_COLUMNS(params)
        : ORG_HUB_COLUMNS(params)
      : fleetsId
        ? ORG_FLEET_COLUMNS(params)
        : SINGLE_ORG_COLUMNS(params)
    : ALL_ORGS_COLUMNS();
};

export const getUserManagementListColumnConfig = (): MRT_ColumnDef<
  User,
  string
>[] => {
  return SINGLE_ORG_USER_LIST_COLUMNS();
};

export const getOrganizationUserColumnDefinitions = (
  tenants: Entity[]
): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  ORG_COLUMN(tenants),
  FLEET_COLUMN(),
  INVITATION_STATUS_COLUMN(),
  LAST_SIGNED_IN_COLUMN(),
];
export const getGMUserColumnDefinitions = (): MRT_ColumnDef<User, string>[] => [
  NAME_COLUMN(),
  ROLE_COLUMN(),
  LAST_SIGNED_IN_COLUMN(),
];

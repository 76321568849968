import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  'rt--notifications--cmp--buttons--see-all-box': {
    borderTop: '1px solid #0C111414',
    paddingBlockStart: '16px',
  },
  'rt--notifications--cmp--buttons--see-all-button': {
    border: `1px solid ${theme.new.color.brandPrimary}`,
    width: '468px',
  },
}));

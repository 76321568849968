import { User } from '@gm-commercial/profile-model';
import { UserTabType } from '@gm-commercial/profile-model/src/models/user.model';
import get from 'lodash/get';
import set from 'lodash/set';

import {
  DEFAULT_EMPTY_VALUE,
  ROLE_KEYS,
  SearchType,
} from '~/common/constants/common.constant';
import { Entity, SearchCriteria } from '~/common/models/common.model';
import {
  UserFormState,
  UserListFilterCriteria,
} from '~/common/models/user.model';

import {
  getGMUserColumnDefinitions,
  getOrganizationUserColumnDefinitions,
} from '../list/config/UserListView.config';
import { mapInvitationStatusToBackend } from '../list/mappers/users.mappers';

export const getUserFormState = (user?: Partial<User>): UserFormState => {
  return {
    firstName: { value: user?.firstName || null, error: '' },
    lastName: { value: user?.lastName || null, error: '' },
    email: { value: null, error: '' },
    role: { value: user?.role || null, error: '' },
    phoneNumber: {
      value:
        user?.phoneNumber && user?.phoneNumber !== DEFAULT_EMPTY_VALUE
          ? user.phoneNumber
          : null,
      error: '',
    },

    locale: { value: user?.userPreferences?.locale || null, error: '' },
    timeZone: { value: user?.userPreferences?.timeZone || null, error: '' },
    status: { value: user?.status || null, error: '' },
    fleet: { value: user?.fleet || null, error: '' },
  };
};
export const getAdvisorUserListColumnConfig = ({
  activeUserTab,
  tenants,
}: {
  activeUserTab: UserTabType;
  tenants: Entity[];
}) => {
  switch (activeUserTab) {
    case UserTabType.ORGANIZATION:
      return getOrganizationUserColumnDefinitions(tenants);
    case UserTabType.GM:
      return getGMUserColumnDefinitions();
  }
};

const userFieldSearchTypes: Record<string, string> = {
  [SearchType.USER_NAME]: 'name',
  [SearchType.USER_EMAIL]: 'email',
};

export const buildUserListQueryParams = ({
  search,
  filter,
  orgId,
  hubId,
}: {
  search?: SearchCriteria;
  filter?: UserListFilterCriteria;
  orgId?: string;
  hubId?: string;
}) => {
  const queryParams = {};
  const commaSeparator = ',';

  //Search to query
  const searchKey = userFieldSearchTypes[search?.searchType || ''];
  if (searchKey) {
    set(queryParams, searchKey, search?.input);
  }

  // Filters to query
  if (get(filter, 'statuses.length')) {
    set(queryParams, 'statuses', filter?.statuses?.join(commaSeparator));
  }

  if (get(filter, 'roles.length')) {
    const backendRoles = filter?.roles?.map((role) => ROLE_KEYS[role]);
    set(queryParams, 'roles', backendRoles?.join(commaSeparator));
  }

  if (get(filter, 'fleetIds.length')) {
    set(queryParams, 'fleetIds', filter?.fleetIds?.join(commaSeparator));
  }

  if (get(filter, 'inviteStatuses.length')) {
    const backendInviteStatuses = filter?.inviteStatuses?.map((inviteStatus) =>
      mapInvitationStatusToBackend(inviteStatus)
    );
    set(
      queryParams,
      'inviteStatuses',
      backendInviteStatuses?.join(commaSeparator)
    );
  }

  if (orgId) {
    set(queryParams, 'organizationId', orgId);
  }

  if (hubId) {
    set(queryParams, 'hubId', hubId);
  }

  return queryParams;
};

import { MRT_Cell, MRT_Row } from '@brightdrop/table-ui';
import { User } from '@gm-commercial/profile-model';
import { Box, Typography, useTheme } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';

import { RouteParams } from '~/common/configs/route.config';
import { DEFAULT_EMPTY_VALUE } from '~/common/constants/common.constant';
import { RouteName } from '~/common/models/route.model';
import { findRoute, makeRoutePath } from '~/common/utils/route/route.utils';

interface UserNameColumnCellProps {
  cell: MRT_Cell<User, string>;
  row: MRT_Row<User>;
  showEmail?: boolean;
  showUserDetailLink?: boolean;
}

const UserNameColumnCell = ({
  cell,
  row,
  showEmail = true,
  showUserDetailLink = true,
}: UserNameColumnCellProps) => {
  const userName = cell.getValue();
  const theme = useTheme();
  const email = row.original?.email;
  const usersId = row.original?.id;
  const match = useRouteMatch<RouteParams>();
  const userDetailsLink = makeRoutePath(findRoute(RouteName.USER_DETAILS), {
    ...match.params,
    usersId,
  });

  const userNameComp = () => {
    //The API can return a single whitespace as the displayName, so it's necessary to check for this use case
    if (
      userName &&
      userName !== DEFAULT_EMPTY_VALUE &&
      userName.trim() !== ''
    ) {
      if (showUserDetailLink) {
        return (
          <Link to={userDetailsLink} data-testid="user-list-name-column">
            {userName}
          </Link>
        );
      } else {
        return <>{userName}</>;
      }
    } else {
      return <>{DEFAULT_EMPTY_VALUE}</>;
    }
  };

  const userEmailComp = () => {
    if (showEmail) {
      return (
        <Box>
          <Typography
            variant="caption_regular"
            color={theme.new.color.textSecondary}
          >
            {email || DEFAULT_EMPTY_VALUE}
          </Typography>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <Typography variant="small_body_regular">{userNameComp()}</Typography>
      </Box>
      {userEmailComp()}
    </Box>
  );
};

export default UserNameColumnCell;

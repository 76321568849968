import { TripsParamKey } from '../constants/tripHistory.constants';

export const buildFetchTripsRequestParams = ({
  startTime,
  endTime,
  vehicleNumbers,
  vins,
}: {
  endTime: Date;
  startTime: Date;
  vehicleNumbers?: string[];
  vins?: string[];
}) => {
  const startTimeEpoc = startTime.getTime().toString();
  const endTimeEpoc = endTime.getTime().toString();

  const requestParams: Record<string, string> = {
    [TripsParamKey.START_TIME]: startTimeEpoc,
    [TripsParamKey.END_TIME]: endTimeEpoc,
  };

  if (vehicleNumbers) {
    requestParams[TripsParamKey.VEHICLE_NUMBERS] = vehicleNumbers.join(',');
  }

  if (vins) {
    requestParams[TripsParamKey.VIN] = vins.join(',');
  }

  return requestParams;
};

export const buildFetchEventsRequestParams = ({
  fromDate,
  toDate,
  vinNumber,
}: {
  fromDate: Date;
  toDate: Date;
  vinNumber: string;
}) => {
  const fromDateEpoc = fromDate.getTime().toString();
  const toDateEpoc = toDate.getTime().toString();

  const requestParams: Record<string, string> = {
    [TripsParamKey.FROM_DATE]: fromDateEpoc,
    [TripsParamKey.TO_DATE]: toDateEpoc,
    [TripsParamKey.VIN_NUMBER]: vinNumber,
  };

  return requestParams;
};

import { AppNames } from '../appNames.model';
export const FLEET_HOME = `/${AppNames.FLEET}/home`;
export const FLEET_VEHICLE_LIST = `/${AppNames.FLEET}/vehicle/list`;
export const MRT_FLEET_VEHICLE_LIST = `/${AppNames.FLEET}/vehicle/mrt/list`; // remove once MRT integration is complete
export const FLEET_VEHICLE_ORDERS_LIST = `/${AppNames.FLEET}/vehicle/orders/list`;
export const FLEET_VEHICLE_ORDERS_DETAILS = `/${AppNames.FLEET}/vehicle/orders/details`;
export const FLEET_NOTIFICATIONS = `/${AppNames.FLEET}/notifications`;
export const FLEET_VEHICLE_LIST_DETAILS = `/${AppNames.FLEET}/vehicle/details`;
export const FLEET_VEHICLE_PERIPHERALS_LIST = `/${AppNames.FLEET}/vehicle/peripherals/list`;
export const FLEET_ADD_VEHICLES_LIST = `/${AppNames.FLEET}/vehicle/list/add`;
export const FLEET_PENDING_VEHICLES_LIST = `/${AppNames.FLEET}/vehicle/list/pending`;
//TODO: update when advisor navConfig is defined within navDrawer.config file
export const ADVISOR_VEHICLE_LIST = `/${AppNames.LEGACY}/vehicle/list`;
export const ADVISOR_PENDING_VEHICLES_LIST = `/${AppNames.LEGACY}/vehicle/list/pending`;

import { createEntityAdapter } from '@reduxjs/toolkit';

import {
  AddVehiclesApiResponse,
  IncompleteVehicleDetailsApiResponse,
  PendingConnectionVehicleDetailsApiResponse,
  PendingVerificationVehicleDetailsApiResponse,
} from '~/common/models/addVehicles.model';

export const addVehiclesAdapter = createEntityAdapter<AddVehiclesApiResponse>({
  selectId: (addedVehicle: AddVehiclesApiResponse) => addedVehicle.batchId,
});

export const pendingVerificationVehiclesAdapter =
  createEntityAdapter<PendingVerificationVehicleDetailsApiResponse>({
    selectId: (
      pendingVerificationVehicle: PendingVerificationVehicleDetailsApiResponse
    ) => pendingVerificationVehicle.vin,
  });

export const pendingConnectionVehiclesAdapter =
  createEntityAdapter<PendingConnectionVehicleDetailsApiResponse>({
    selectId: (
      pendingConnectionVehicle: PendingConnectionVehicleDetailsApiResponse
    ) => pendingConnectionVehicle.vin,
  });

export const incompleteVehiclesAdapter =
  createEntityAdapter<IncompleteVehicleDetailsApiResponse>({
    selectId: (incompleteVehicle: IncompleteVehicleDetailsApiResponse) =>
      `${incompleteVehicle.vin}_${incompleteVehicle.sessionId}`, // we may have duplicate vins failed to onboard during different sessions
  });

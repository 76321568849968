import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  'rt--notifications--cmp--list--notification-list--box-container': {
    overflow: 'scroll',
  },
  'rt--notifications--cmp--list--notification-list--box-container--ul--li-done':
    {
      display: 'grid',
      justifyContent: 'center',
      gap: '16px',

      '& > div': {
        display: 'grid',
        justifyContent: 'center',
      },
    },
}));

import { entitlementsApi } from './api/entitlementsApi';
import { useEntitlements } from './hooks/useEntitlements';
import { entitlementsReducer } from './state/entitlementsSlice';

export let baseUrl: string = '';
export const getBaseUrl = () => baseUrl;

export let getAuthToken: () => string | undefined = () => void 0;

export const configureEntitlementsClient = ({
  apiBaseUrl,
  authProvider,
}: {
  apiBaseUrl: string;
  authProvider: () => string | undefined;
}) => {
  baseUrl = apiBaseUrl;
  getAuthToken = authProvider;
};

export { entitlementsApi, entitlementsReducer, useEntitlements };

import { createSlice } from '@reduxjs/toolkit';

import { BDRequestStatus } from '~/common/models/apis/apiResponse.model';

import { CAMERAS_SETTINGS_EXTRA_REDUCER_BUILDERS } from './camerasSettings.reducers';
import { CAMERAS_SETTINGS_ACTIONS } from './camerasSettingsSlice.action';
import { CamerasSettingsState } from './camerasSettingsSlice.model';

const initialState: CamerasSettingsState = {
  settings: null,
  operationStatus: {
    status: BDRequestStatus.IDLE,
    errors: [],
  },
};

export const camerasSettingsSlice = createSlice({
  name: 'camerasSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    CAMERAS_SETTINGS_EXTRA_REDUCER_BUILDERS.buildFetchSettingsReducer(builder);
  },
});

export const { fetchSettings } = CAMERAS_SETTINGS_ACTIONS;
export const camerasSettingsReducer = camerasSettingsSlice.reducer;

export * from './camerasSettingsSlice.selectors';

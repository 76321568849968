export const NAV_DRAWER_MESSAGES = {
  settingsMenu: {
    'settings:settings': 'Settings',
    'settings:notifications.title': 'Notifications',
    'common:vehicles': 'Vehicles',
    'common:users': 'Users',
    'common:account': 'Account',
    'common:safetyAndSecurity': 'Safety & Security',
  },
  accountMenu: {
    'common:account': 'Account',
    'account:organization.nav.title': 'Organization',
    'organization:orgDetails': 'Organization details',
    'organization:fleetPrograms': 'Fleet programs',
    'account:organization.nav.legal.title': 'Legal',
    'account:profile': 'Personal details',
    'account:personal': 'Personal',
    'account:preferences': 'Preferences',
    'account:languageAndRegion': 'Language & Region',
    'common:users': 'Users',
    'account:settings': 'Settings',
    'account:cameras': 'Cameras',
  },
  advisorMenu: {
    'common:vehicle': 'Vehicle',
    'common:user': 'User',
    'common:vehicle_plural': 'Vehicles',
    'common:user_plural': 'Users',
    'common:organization': 'Organization',
    'common:organization_plural': 'Organizations',
  },
  fleetMenu: {
    'common:home': 'Home',
    'common:vehicles': 'Vehicles',
    'asset:vehicleList': 'Vehicle list',
    'common:mrt:list': 'MRT List',
    'common:addVehicles': 'Add vehicles',
    'order:tableTitle': 'Orders',
    'peripheral:navItem': 'Peripherals',
    'common:summary.assetReport_plural': 'Assets',
    'peripheral:assetsList': 'Assets list',
  },
  supportMenu: {
    'account:support.nav.title': 'Support',
    'account:support.nav.contact': 'Contact',
    'account:support.nav.faqs': 'FAQs',
  },
  legacyMenu: {
    'common:map': 'Map',
    'dashboard:title': 'Dashboard',
    'common:organization': 'Organization',
    'common:hub': 'Hub',
    'common:fleet': 'Fleet',
    'common:organization_plural': 'Organizations',
    'common:asset_plural': 'Vehicles',
    'common:user_plural': 'Users',
    'common:alert_plural': 'Alerts',
    'common:footer.support': 'Support',
    'user:termsConditions.view': 'Terms and Conditions',
    'user:privacyStatement': 'Privacy Statement',
    'common:navDrawer.closeNav': 'Close navigation drawer',
    'common:documents.title': 'Documents',
    'common:dispatch.title': 'Dispatch Management',
    'insight:title': 'Insights',
    'peripheral:navItem': 'Assets List',
    'inspection:assetsInspections': 'Assets Inspections',
    'connectedCameras:title': 'Connected Cameras',
    'purchasePrograms:purchasePrograms': 'Fleet programs',
  },
};

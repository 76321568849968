import { lazy } from 'react';

import {
  CUSTOMER_SUPPORT_FLAG_NAME,
  FAQS_PAGE_FLAG,
} from '~/common/models/featureFlags.model';
import {
  SUPPORT_CONTACT_PATH,
  SUPPORT_FAQS_PATH,
} from '~/common/models/pages/supportPages.model';
import { RouteName } from '~/common/models/route.model';

const CSPlatformSupportContactView = lazy(
  () => import('~/features/support/contact/CSPlatformSupportContactView')
);
const FAQsView = lazy(() => import('~/features/support/faqs/FAQsView'));

export const SUPPORT_ROUTES = {
  [RouteName.SUPPORT_CONTACT]: {
    name: RouteName.CS_PLATFORM_SUPPORT_CONTACT,
    path: [SUPPORT_CONTACT_PATH],
    authorizedPerms: [],
    secure: true,
    component: CSPlatformSupportContactView,
    requiredFeatureFlags: [CUSTOMER_SUPPORT_FLAG_NAME],
  },
  [RouteName.FAQS]: {
    name: RouteName.FAQS,
    path: [SUPPORT_FAQS_PATH],
    authorizedPerms: [],
    secure: true,
    component: FAQsView,
    requiredFeatureFlags: [FAQS_PAGE_FLAG],
  },
};

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useActingProfile from '~/common/hooks/useActingProfile';
import {
  getNavDisabledPaths,
  isImmersiveNavPath,
} from '~/common/utils/route/route.utils';
import GlobalHeader from '~/features/header/GlobalHeader';
import GlobalNavDrawer, {
  DrawerHeader,
} from '~/features/navigationDrawer/GlobalNavDrawer';

import ScopedUserBanner from '../header/components/scopedUserBanner/ScopedUserBanner';
import {
  NAV_DRAWER_CLOSED_WIDTH,
  NAV_DRAWER_OPEN_WIDTH,
} from './GlobalNavDrawer.styles';

const GlobalNavWrapper = ({
  children,
}: {
  children?: ReactNode | undefined;
}): JSX.Element => {
  const { pathname } = useLocation();
  const { isActing } = useActingProfile();

  const theme = useTheme();
  const defaultExpanded = useMediaQuery(theme.breakpoints.up('md'));
  const navCoversContent = !defaultExpanded;
  const isImmersiveView = isImmersiveNavPath(pathname);
  const preventNavCollapse = isImmersiveView && !navCoversContent;

  const [navExpanded, setNavExpanded] = useState(
    defaultExpanded || preventNavCollapse
  );
  const toggleDrawer = useCallback(() => {
    setNavExpanded((previousValue) => !previousValue);
  }, []);
  const closeNav = () => setNavExpanded(false);

  // Close drawer on navigation if it obscures content while open
  const onNavItemClick = navCoversContent ? closeNav : undefined;

  // Certain content-only or flow-specific views should not display global nav components
  const matchUnsupportedRoute = matchPath(pathname, {
    path: getNavDisabledPaths(),
  });

  const matchUnsupportedNavDrawerRoute = !!matchUnsupportedRoute;

  useEffect(() => {
    setNavExpanded((currentlyExpanded) => {
      if (preventNavCollapse) {
        return true;
      }

      if (currentlyExpanded && navCoversContent) {
        return false;
      }
      return currentlyExpanded;
    });
  }, [pathname, preventNavCollapse, navCoversContent]);

  const ContentSpacer = useMemo(() => {
    const Spacer = () => (
      <>
        {!matchUnsupportedNavDrawerRoute && (
          <DrawerHeader isActing={isActing} />
        )}
      </>
    );
    return Spacer;
  }, [isActing, matchUnsupportedNavDrawerRoute]);

  // Adjust content width based on nav state when presented side-by-side
  const contentWidth =
    !matchUnsupportedNavDrawerRoute && !navCoversContent
      ? `calc(100% - ${
          navExpanded ? NAV_DRAWER_OPEN_WIDTH : NAV_DRAWER_CLOSED_WIDTH
        }px)`
      : '100%';

  return (
    <>
      <ScopedUserBanner />
      <Box
        sx={{
          display: 'flex',
          width: '100vw',
        }}
      >
        {!matchUnsupportedNavDrawerRoute && (
          <GlobalNavDrawer
            navExpanded={navExpanded}
            toggleDrawer={toggleDrawer}
            onNavItemClick={onNavItemClick}
            toggleable={!preventNavCollapse}
          />
        )}
        {!matchUnsupportedNavDrawerRoute && (
          <GlobalHeader navExpanded={navExpanded} />
        )}
        <Box
          component="main"
          width={contentWidth}
          sx={(theme) => ({
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: navExpanded
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
          })}
        >
          <ContentSpacer />
          {children}
        </Box>
      </Box>
    </>
  );
};

export default GlobalNavWrapper;

import { useEffect, useState } from 'react';

import useCustomAuthentication from '~/common/hooks/useCustomAuthentication';
import { InactivityTracker } from '~/features/inactivityTracker/inactivityTracker';
import {
  inactivityDefaultTimeout,
  InactivityEvents,
  InactivityStorageKeys,
  modalCountdownTimeout,
} from '~/features/inactivityTracker/InactivityTracker.constants';

const useInactivityTracker = () => {
  //Cannot use UseIsAuthenticated from MSAL, as provider is wrapped separately in the app tree
  //Need to use different hook for authentication
  const { token } = useCustomAuthentication();
  const [inactivityTracker, setInactivityTracker] =
    useState<InactivityTracker | null>(null);
  const [isUserInactive, setIsUserInactive] = useState(false);

  const handleStorageEvent = (event: StorageEvent) => {
    if (
      event.key === InactivityStorageKeys.userActive &&
      event.newValue !== null
    ) {
      setIsUserInactive(false);
      inactivityTracker?.resetTimer();
    }
    //need to ensure that the inactivity tracker is set up again after a user chooses to extend their session
    if (
      event.key === InactivityStorageKeys.userExtendSession &&
      event.newValue !== null
    ) {
      inactivityTracker?.tracker();
    }
  };

  const handleInactiveUser = () => {
    localStorage.setItem(InactivityStorageKeys.userInactive, 'true');
    localStorage.removeItem(InactivityStorageKeys.userInactive);
    //Need to make sure that a user cannot dismiss the consuming components through another tab
    //Once a user hits the inactivity timeout limit
    inactivityTracker?.removeTrackers();
    setIsUserInactive(true);
  };

  const setupEventListeners = () => {
    window.addEventListener('storage', handleStorageEvent);
    window.addEventListener(InactivityEvents.inactiveUser, handleInactiveUser);
  };

  const resetAllTimers = () => {
    if (inactivityTracker) {
      //need to ensure that the inactivity tracker is set up again after a user chooses to extend their session
      localStorage.setItem(InactivityStorageKeys.userExtendSession, 'true');
      localStorage.removeItem(InactivityStorageKeys.userExtendSession);
      inactivityTracker.tracker();
      inactivityTracker.resetTimer();
      inactivityTracker.emitActiveUser();
      setIsUserInactive(false);
    }
  };

  useEffect(() => {
    if (token) {
      const defaultTimeout = !isNaN(globalThis?.appConfig?.inactivityTimer)
        ? globalThis?.appConfig?.inactivityTimer
        : inactivityDefaultTimeout;
      //Because the modal timeout is included in the total allowed timeout for inactivity
      //it is necessary for the inactivity timeout to only be equal to the difference between total timeout and modal timeout
      const inactiveTimeOut =
        defaultTimeout > modalCountdownTimeout
          ? defaultTimeout - modalCountdownTimeout
          : defaultTimeout;
      setInactivityTracker(new InactivityTracker(inactiveTimeOut));
    } else {
      //Need to set the inactivity tracker to null in the case of other tabs detecting there's not a valid token
      setInactivityTracker(null);
      setIsUserInactive(false);
    }
  }, [token]);

  useEffect(() => {
    if (inactivityTracker !== null) {
      setupEventListeners();
    }
  }, [inactivityTracker]);

  return {
    isUserInactive,
    resetAllTimers,
  };
};

export default useInactivityTracker;

import { useTheme } from '@mui/material';

const ErrorIcon = (props: {
  width?: number;
  height?: number;
  color?: string;
}) => {
  const theme = useTheme();
  const {
    width = 16,
    height = 16,
    color = theme.new.color.dangerPrimary,
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.14648"
        y="2.14648"
        width="11.7072"
        height="11.7072"
        rx="2.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M8.00684 10.8938L7.9992 10.8938"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00635 4.98926L8.00635 8.11377"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIcon;
